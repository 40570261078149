import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatFormFieldModule } from '@angular/material/form-field'; 
import { MatInputModule } from '@angular/material/input';
import { FileManagerService } from 'app/main/apps/file-manager/file-manager.service';
import { MatSelectModule } from '@angular/material/select';
import { EmployeeProfileService } from 'app/main/pages/employee-edit/employee-edit.service';
import { EmployeeListComponent } from 'app/main/pages/employees-list/employees-list.component';   
import { MatTableDataSource } from '@angular/material/table';
import { MatTable } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';
 
const routes: Routes = [
    {
        path     : '**',
        component: EmployeeListComponent,
        children : [],
        resolve  : {
            files: FileManagerService
        }
    } 
];
@NgModule({
    declarations: [
            
    ],
    imports     : [
          
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        FuseSharedModule
    ],
    providers   : [ 
        EmployeeListComponent,
        FileManagerService
    ]
})
export class EmployeesProfileModule
{
}
