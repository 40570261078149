import { Injectable } from '@angular/core';
//import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ChkLogin, ForgotPasswordModel } from 'assets/Model/user-model';
import { environment } from '../../../../environments/environment';
import { clsScreen } from 'app/main/pages/profile/tabs/timeline/timeline.component';




@Injectable({
  providedIn: 'root'
})
export class CommonService {


  private APiURL = environment.BaseApiUrl + "Common";
  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  getScreenActions(screenName: string,) {
    var sessionobj = JSON.parse(localStorage.UserDetails);
    if (sessionobj != null && sessionobj.users_Responsibilities != undefined) {
      var resp = JSON.parse(sessionobj.users_Responsibilities);
      var filteredMenu = resp.filter((course) => {
        return course.ScreenId == screenName;
      });
      if (filteredMenu != undefined && filteredMenu.length > 0) {
        return filteredMenu[0].clsActions;
      }
    }
  }
  getActionPermissions(screenName: string, actionName: string) {
    var sessionobj = JSON.parse(localStorage.UserDetails);
    if (sessionobj != null && sessionobj.users_Responsibilities != undefined) {
      var resp = JSON.parse(sessionobj.users_Responsibilities);
      var filteredMenu = resp.filter((course) => {
        return course.ScreenId == screenName;
      });
      if (filteredMenu != undefined && filteredMenu.length > 0) {
        var actionFilter = filteredMenu[0].clsActions.filter((course) => {
          return course.ActionName == actionName;
        });
        if (actionFilter != undefined && actionFilter.length > 0) {
          return actionFilter[0].Value;
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }
  saveLocation(objcls: any) {
    return this.http.post(this.APiURL + '/saveLocation', objcls);
  }
  SaveraiseRequest(objcls: any) {
    return this.http.post(this.APiURL + '/SaveEmpRequest', objcls);
  }
  generateQRCode(objcls: any) {
    return this.http.post(this.APiURL + '/GenerateCustomQRCode', objcls);
  }
  updateRequest(objcls: any) {
    return this.http.post(this.APiURL + '/UpdateRequest', objcls);
  }
  saveAuditTrail(objcls: any) {
    return this.http.post(this.APiURL + '/SaveAuditTrail', objcls);
  }
  ExportData(objcls: any) {
    return this.http.post(this.APiURL + '/ExportData', objcls);
  }
  RequestDashboard(objcls: any) {
    return this.http.post(this.APiURL + '/RequestDashboard', objcls);
  }
  RegisteredList(objcls: any) {
    return this.http.post(this.APiURL + '/RegisteredList', objcls);
  }
  SpecimenData(objcls: any) {
    return this.http.post(this.APiURL + '/SaveUser', objcls);
  }
  SaveRole(objcls: any) {
    return this.http.post(this.APiURL + '/SaveRole', objcls);
  }
  GetMasterData(GetMasterModel) {
    return this.http.post(this.APiURL + '/GetMaster', GetMasterModel);
  }
  SaveSymptomsList(objcls: any) {
    return this.http.post(this.APiURL + '/SaveSymptoms', objcls);
  }
  SaveTestList(objcls: any) {
    return this.http.post(this.APiURL + '/SaveTests', objcls);
  }
  SaveInsurance(objcls: any) {
    return this.http.post(this.APiURL + '/SaveInsurance', objcls);
  }
  UpdateLocationReferral(objcls: any) {
    return this.http.post(this.APiURL + '/UpdateLocationReferral', objcls);
  }
  UpdateTestResults(GetMasterModel) {
    return this.http.post(this.APiURL + '/UpdateTestResults', GetMasterModel);
  }
  UpdateTestResultsAsync(GetMasterModel) {
    return this.http.post(this.APiURL + '/UpdateTestResultsAsync', GetMasterModel);
  }
  UpdateSelfTestResultsAsync(GetMasterModel) {
    return this.http.post(this.APiURL + '/UpdateSelfTestResultsAsync', GetMasterModel);
  }
  UpdateEmpTestResultsAsync(GetMasterModel) {
    return this.http.post(this.APiURL + '/UpdateEmpTestResultsAsync', GetMasterModel);
  }
  GetReportsData(GetMasterModel) {
    return this.http.post(this.APiURL + '/GetReportsByType', GetMasterModel);
  }
  getAdminJsonData() {
    return this.http.get('api/project-dashboard-projects');
  }
  getAllRequestList(clsobj: any) {
    return this.http.post(this.APiURL + "/getRequestListByPaging", clsobj);
  }
  getEmpRequestListByPaging(clsobj: any) {
    return this.http.post(this.APiURL + "/getEmpRequestListByPaging", clsobj);
  }
  getAuditLogsByPaging(clsobj: any) {
    return this.http.post(this.APiURL + "/getAuditLogsByPaging", clsobj);
  }
  getSelfTestByPaging(clsobj: any) {
    return this.http.post(this.APiURL + "/getSelfTestByPaging", clsobj);
  }
  employerForm(objcls: any) {
    return this.http.post(this.APiURL + '/UpdateEmployeer', objcls);
  }
  UpdateEmployee(objcls: any) {
    return this.http.post(this.APiURL + '/UpdateEmployee', objcls);
  }
  UploadEmployeerLogo(objcls: any) {
    return this.http.post(this.APiURL + '/UpdateEmployeerImage', objcls);
  }
  UploadEmployeeLogo(objcls: any) {
    return this.http.post(this.APiURL + '/UpdateEmployeeImage', objcls);
  }
  UploadEmployeeDocument(objcls: any) {
    return this.http.post(this.APiURL + '/UpdateEmployeeDocument', objcls);
  }
  UploadlocationLogo(objcls: any) {
    return this.http.post(this.APiURL + '/UpdateLocationImage', objcls);
  }
  Getlookupmasters(ModelLookups) {
    return this.http.post(this.APiURL + '/GetLookupMaster', ModelLookups);
  }
  getGeocodeResponse(address) {
    return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + address + '&key=AIzaSyBFENiEytjqSc_N7XLvARB727y-cq5UfRE');
  }
  employeesbyOrganization(objcls: any) {
    return this.http.post(this.APiURL + '/getEmployeesbyOrganization', objcls);
  }
  VerifyCovidResults(objcls: any) {
    return this.http.post(this.APiURL + '/VerifyCovidTests', objcls);
  }
  VerifyVaccineResults(objcls: any) {
    return this.http.post(this.APiURL + '/VerifyVaccineResults', objcls);
  }
  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  GetDashboardCount() {
    return this.http.get(this.APiURL + '/GetDashboardCountData');
  }
  GetArticleData() {
    return this.http.get(this.APiURL + '/getArticleData');
  }
  GetChartData(Data: any) {
    return this.http.post(this.APiURL + '/getChart', Data);
  }
  ChangePassword(screenModel: clsScreen) {
    return this.http.post(this.APiURL + '/ChangePassword', screenModel);
  }
  ResetPassword(ModelForgetPassword: ForgotPasswordModel) {
    return this.http.post(this.APiURL + '/ForgetPassword', ModelForgetPassword);
  }
  VendorData(objcls: any) {
    return this.http.post(this.APiURL + '/SaveVendor', objcls);
  }

}




