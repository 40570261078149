import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { ProfileService } from '../../profile.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from 'app/common/Service/common.service';
import { ActivatedRoute } from '@angular/router';
import { SnackbarService } from 'app/common/Service/snackbar.service'; 
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';

@Component({
    selector: 'profile-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ProfileTimelineComponent implements OnInit, OnDestroy {
    timeline: any;
    formGroup: FormGroup;
    screenModel: clsScreen = new clsScreen();
    // Private
    private _unsubscribeAll: Subject<any>;


    /**
     * Constructor
     *
     * @param {ProfileService} _profileService
     */
    constructor(
        private _profileService: ProfileService,
        private _formBuilder: FormBuilder,
        private commonservice: CommonService,
        private activatedRoute: ActivatedRoute,
        private _snackbarservice: SnackbarService,
        private _splashScreenService: FuseSplashScreenService,
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    users_Password: string;
    ngOnInit(): void {


        this.formGroup = this._formBuilder.group({
            oPassword: ['', Validators.required],
            nPassword: ['', Validators.required],
            conPassword: ['', Validators.required],
        })
        this._profileService.timelineOnChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(timeline => {
                this.timeline = timeline;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    changepassword() {
        if (this.validate()) {
            this._splashScreenService.show();
            let userdata = JSON.parse(localStorage.getItem("UserDetails") || '{}')
            if (userdata == undefined || userdata == null || Object.entries(userdata).length > 0) {
                this.screenModel.users_userid = userdata.users_userid;
                this.commonservice.ChangePassword(this.screenModel).subscribe(result => {
                    const apiResult:any=result;
                    if(apiResult.status==200)
                    { 
                        
                            this._snackbarservice.showSnackbar(apiResult.message);
                            this.formGroup.reset();
                            this._splashScreenService.hide();

                    }else
                    {
                        this._snackbarservice.showSnackbar(apiResult.message);
                        this._splashScreenService.hide();
                    }

                },
                    error => {
                        console.log(error);
                        this._splashScreenService.hide();

                    });
            }
            else {

            }
        }


    }

    validate() {
      
         
        if (this.screenModel.new_Password != this.screenModel.confirm_Password) {
            this._snackbarservice.showSnackbar("Confirm password didn't match with New password");
            return false;
        }
        else return true;
    }
}

export class clsScreen {
    users_userid: number;
    Password: string;
    new_Password: string;
    confirm_Password: string;
}
