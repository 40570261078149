import { Component, ElementRef, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'app/common/Service/common.service';
import { AnalyticsDashboardService } from 'app/main/apps/dashboards/analytics/analytics.service';
import { de } from 'date-fns/locale';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators, FormControl } from '@angular/forms';
import { SnackbarService } from 'app/common/Service/snackbar.service';
import { Router } from '@angular/router';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
@Component({
    selector: 'analytics-dashboard',
    templateUrl: './analytics.component.html',
    styleUrls: ['./analytics.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class AnalyticsDashboardComponent implements OnInit {
    @ViewChild('register_link') register_link: ElementRef;
    widgets: any;
    widget1SelectedYear = '2016';
    widget5SelectedDay = 'today';
    dosageWiseEmps: any = [];
    widget7: any = [];
    projects: any[];
    emp_code: string = "";
    flg: number = 0;
    ExportForm: FormGroup;
    AlertMessage: string = "";
    scheme: any = [];
    dashboard: any = [];
    empObject:any={};
    widget6: any = {};
    firstdosePercentage: number = 0;
    seconddosePercentage: number = 0;
    thirdosePercentage: number = 0;
    ExpiresList: any = [];
    dateNow = Date.now();
    /**
     * Constructor
     *
     * @param {AnalyticsDashboardService} _analyticsDashboardService
     */
    constructor(
        private _analyticsDashboardService: AnalyticsDashboardService,
        private dialog: MatDialog,
        private service: CommonService,
        private _formBuilder: FormBuilder,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _snackbarservice: SnackbarService,
        private router: Router
    ) {
        // Register the custom chart.js plugin
        this._registerCustomChartJSPlugin();
        this.scheme = {
            domain: ['#f44336', '#9c27b0', '#03a9f4', '#e91e63']
        };
        this.ExportForm = this._formBuilder.group({
            AlertMessage: ['', Validators.required], 
          });
        // this.widget6 = {
        //     currentRange : 'TW',
        //     legend       : false,
        //     explodeSlices: false,
        //     labels       : true,
        //     doughnut     : true,
        //     gradient     : false,
        //     scheme       : {
        //         domain: ['#f44336', '#9c27b0', '#03a9f4', '#e91e63']
        //     },
        //     onSelect     : (ev) => {
        //         console.log(ev);
        //     }
        // };
        setInterval(() => {
            this.dateNow = Date.now();
        }, 1000);
        
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    onSelect(ev) {

    }
    /**
     * On init
     */
    ngOnInit(): void {
        // Get the widgets from the service         
        if (localStorage.getItem("isRefresh") != undefined && localStorage.getItem("isRefresh") != ""
            && localStorage.getItem("isRefresh") == "1") {
            localStorage.setItem("isRefresh", "0");
            window.location.reload();
        }

        var sessionobj = JSON.parse(localStorage.UserDetails);
         
        this._fuseSplashScreenService.show();
        this.getMasterData(7, sessionobj.users_Customerid, "");//EmployeerData
       // this.router.navigateByUrl('/auth/login');
    }
    getMasterData(flg, filterId, code) {

        var clsmodel = {
            Type: flg,
            FilterId: filterId,
            flg:filterId,
            Code: code
        }
        this.service.GetMasterData(clsmodel).subscribe(result => {
            this._fuseSplashScreenService.hide();
            var resultObj = result;
            if (resultObj["status"] == 200) {
                if (flg == 7) {
                    this.dashboard = resultObj["resultObj"];
                    var _firstlist = this.dashboard.vaccinatedEmployees.filter((course) => {
                        return course.noOfDoses === 1;
                    });
                    var _secondlist = this.dashboard.vaccinatedEmployees.filter((course) => {
                        return course.noOfDoses === 2;
                    });
                    var _thirdlist = this.dashboard.vaccinatedEmployees.filter((course) => {
                        return course.noOfDoses === 3;
                    });

                    var TotalEmployees = this.dashboard.unVaccinatedEmployees.length + this.dashboard.vaccinatedEmployees.length;
                    this.firstdosePercentage = TotalEmployees != undefined && TotalEmployees > 0 ?
                        Math.round((_firstlist.length / TotalEmployees) * 100) : 0;
                    this.seconddosePercentage = TotalEmployees != undefined && TotalEmployees > 0 ? Math.round((_secondlist.length / TotalEmployees) * 100) : 0;
                    this.thirdosePercentage = TotalEmployees != undefined && TotalEmployees > 0 ? Math.round((_thirdlist.length / TotalEmployees) * 100) : 0;

                    if (this.dashboard.testResultsList.length > 0) {
                        for (let index = 0; index < 7; index++) {

                            if (index == 0) {
                                var _firstlist = this.dashboard.testResultsList.filter((course) => {
                                    return course.testUploaded <= 1;
                                });
                                if (_firstlist != null && _firstlist != undefined && _firstlist.length > 0) {
                                    this.ExpiresList.push({ title: "Today", cnt: (index + 1), per: Math.round(((index + 1) / this.dashboard.testResultsList.length) * 100) })
                                }
                            }
                            else {
                                var _firstlist = this.dashboard.testResultsList.filter((course) => {
                                    return course.testUploaded === (index + 1);
                                });
                                if (_firstlist != null && _firstlist != undefined && _firstlist.length > 0) {

                                    this.ExpiresList.push({ title: "Within " + (index + 1) + " days", cnt: (index + 1), per: Math.round(((index + 1) / this.dashboard.testResultsList.length) * 100) });
                                }
                            }
                        }
                    }

                }
                else if (flg == 50) {
                    this.dialog.closeAll();
                    this._snackbarservice.showSnackbar(resultObj["message"])
                }
            }

        }, error => {
            console.log(error);
        });
        return "";
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------
    registerPop(_flg, empCode) {
        this.emp_code = empCode;
        this.flg = _flg;
        this.openmodal(this.register_link);
    }
    openmodal(modal) {
        this.dialog.open(modal, { width: "700px", height: "auto", disableClose: true });
    }
    sendNotification() {
        if (this.emp_code == undefined || this.emp_code == null || this.emp_code == "") {
            this._snackbarservice.showSnackbar("Invalid employee selection")
        }
        else if (this.empObject.AlertMessage == undefined || this.empObject.AlertMessage == null || this.empObject.AlertMessage == "") {
            this._snackbarservice.showSnackbar("Invalid message")
        }
        else {
            this._fuseSplashScreenService.show();
            this.getMasterData(50, this.flg, this.emp_code + "~" + this.empObject.AlertMessage)
        }
    }
    /**
     * Register a custom plugin
     */
    private _registerCustomChartJSPlugin(): void {
        (window as any).Chart.plugins.register({
            afterDatasetsDraw: function (chart, easing): any {
                // Only activate the plugin if it's made available
                // in the options
                if (
                    !chart.options.plugins.xLabelsOnTop ||
                    (chart.options.plugins.xLabelsOnTop && chart.options.plugins.xLabelsOnTop.active === false)
                ) {
                    return;
                }

                // To only draw at the end of animation, check for easing === 1
                const ctx = chart.ctx;

                chart.data.datasets.forEach(function (dataset, i): any {
                    const meta = chart.getDatasetMeta(i);
                    if (!meta.hidden) {
                        meta.data.forEach(function (element, index): any {

                            // Draw the text in black, with the specified font
                            ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
                            const fontSize = 13;
                            const fontStyle = 'normal';
                            const fontFamily = 'Roboto, Helvetica Neue, Arial';
                            ctx.font = (window as any).Chart.helpers.fontString(fontSize, fontStyle, fontFamily);

                            // Just naively convert to string for now
                            const dataString = dataset.data[index].toString() + 'k';

                            // Make sure alignment settings are correct
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'middle';
                            const padding = 15;
                            const startY = 24;
                            const position = element.tooltipPosition();
                            ctx.fillText(dataString, position.x, startY);

                            ctx.save();

                            ctx.beginPath();
                            ctx.setLineDash([5, 3]);
                            ctx.moveTo(position.x, startY + padding);
                            ctx.lineTo(position.x, position.y - padding);
                            ctx.strokeStyle = 'rgba(255,255,255,0.12)';
                            ctx.stroke();

                            ctx.restore();
                        });
                    }
                });
            }
        });
    }
}

