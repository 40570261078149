import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'app/common/Service/common.service';
import { SnackbarService } from 'app/common/Service/snackbar.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute } from '@angular/router'; 
import { MatDialog } from '@angular/material/dialog';
@Component({
    moduleId:module.id,
    selector: 'employee-edit',
    templateUrl: './employee-edit.html',
    styleUrls: ['./employee-edit.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class EmpProfileComponent {
    /**
     * Constructor
     */
     id:string='';
    _testlist:any=[]; _empData:any=[];
  selectedList: any = []; 
    _reqList:any=[];
    ng_Check_all:boolean=false;
    IsSelected: number = 0;
_vaccineList:any=[];
empObject: any = {};
rows: any = [];
     _loginDetails:any;
     public UploadFile: File;
     ScreenActions: any = { IsEdit: false, IsPrint: false, Document: false, IsClone: false, IsSendNotification: false, IsTransfer: false, IsPDF: false, IsDelete: false }
     imagePath:string="assets/images/avatars/Velazquez.jpg";
     @ViewChild('fileInput') fileInput : ElementRef;
     @ViewChild('send_notification') send_notification: ElementRef;
    constructor(  
        private service: CommonService,private activatedRoute:ActivatedRoute, private dialog: MatDialog,
        private _snackbarservice: SnackbarService,) {


    }
    ngOnInit(): void {
        this.empObject.result = "0";
        if (localStorage != null && localStorage != undefined && localStorage["UserDetails"]) { 
            this._loginDetails = JSON.parse(localStorage.getItem('UserDetails') || '{}');
            
        }
        this.activatedRoute.params.subscribe(param => this.id = param.id);
        this.getMasterData(8, 0,this.id);//Employee data
    }
    onCheckboxChangeFn(event, seqno) {
        this.selectedList = [];
        var selectedIds = this.rows.filter((course) => {
          return course.isSelected == true;
        });
        if (selectedIds != undefined && selectedIds.length > 0) {
          selectedIds.forEach(element => {
            this.selectedList.push({ element });
          });
    
        }
      }
      emp_valueChange(row, $event) { 
        this.IsSelected = 0;
        var test = this._reqList.filter((course) => {
            return course.emp_select == true; //only rapid
        });
        if (test.length > 1)
            this.IsSelected = 1;
    }
    Check_all(){ 
       this._reqList.forEach((course) => {
          course.emp_select = this.ng_Check_all; //only rapid
      });  
      this.IsSelected = 0;
      var test = this._reqList.filter((course) => {
          return course.emp_select == true; //only rapid
      });
      if (test.length > 1)
      this.IsSelected = 1;
  }
    openreq_bulk(){
      var test = this._reqList.filter((course) => {
          return course.emp_select == true; //only rapid
      }); 
      let printContents='';
      test.forEach(element => {
          printContents +=this.BuiltPrintContent(element);
      });
      this.callprint(printContents,"Bulk");
  }
      BuiltPrintContent(item:any){ 
        let printContents; 
        printContents ='<table width="100%" style="padding:30px;color:white">';
    printContents +="<tr><td><div><table width='100%' style='color: #193a56;font-family:Arial;background-color: #193a56;'>";
    printContents +="<tr><td style='width:30%;background-color: #fff;text-align: center;' colspan='2'><img style='width:50px;height:50px' src='../assets/images/logos/favicon.png' /> </td></tr>"
    printContents +="<tr><td style='color:#fff;padding:10px 5px;font-size:15px' colspan='2'>"+this._empData.companyName+"</td></tr>";
    printContents +="<tr style='background:#f3f6fb ;'><td style='font-size:13px;width:40%;padding:5px'>Unique ID</td><td style='font-size:13px;width:60%;padding:5px'>"+this._empData.employeeID+"</td></tr>";
    printContents +="<tr style='background:#bcc7dc;'><td style='font-size:13px;width:40%;padding:5px'>Client</td><td style='font-size:13px;width:60%;padding:5px'>"+this._empData.companyName+"</td></tr>";
    printContents +="<tr style='background:#f3f6fb ;'><td style='font-size:13px;width:40%;padding:5px'>Patient Last Name</td><td style='font-size:13px;width:60%;padding:5px'>"+this._empData.lastName+"</td></tr>";
    printContents +="<tr style='background:#bcc7dc;'><td style='font-size:13px;width:40%;padding:5px'>Patient First Name</td><td style='font-size:13px;width:60%;padding:5px'>"+this._empData.firstName+"</td></tr>";
    printContents +="<tr style='background:#f3f6fb ;'><td style='font-size:13px;width:40%;padding:5px'>Gender</td><td style='font-size:13px;width:60%;padding:5px'>"+this._empData.gender+"</td></tr>";
    printContents +="<tr style='background:#bcc7dc;'><td style='font-size:13px;width:40%;padding:5px'>Date Of Birth</td><td style='font-size:13px;width:60%;padding:5px'>"+this._empData.dob+"</td></tr>";
    printContents +="<tr style='background:#f3f6fb ;'><td style='font-size:13px;width:40%;padding:5px'>Race</td><td style='font-size:13px;width:60%;padding:5px'>"+this._empData.race+"</td></tr>";
    printContents +="<tr style='background:#f3f6fb ;'><td style='font-size:13px;width:40%;padding:5px'>Ethnicity</td><td style='font-size:13px;width:60%;padding:5px'>"+this._empData.ethinicity+"</td></tr>";
    printContents +="<tr style='background:#bcc7dc;'><td style='font-size:13px;width:40%;padding:5px'>Email(Accuracy is important)</td><td style='font-size:13px;width:60%;padding:5px'>"+this._empData.emailAddress+"</td></tr>";
    printContents +="<tr style='background:#f3f6fb ;'><td style='font-size:13px;width:40%;padding:5px'>Phone Number</td><td style='font-size:13px;width:60%;padding:5px'>"+this._empData.mobileNumber+"</td></tr>";
    printContents +="<tr style='background:#bcc7dc;'><td style='font-size:13px;width:40%;padding:5px'>Street Address</td><td style='font-size:13px;width:60%;padding:5px'>"+this._empData.address+"</td></tr>";
    printContents +="<tr style='background:#f3f6fb ;'><td style='font-size:13px;width:40%;padding:5px'>City</td><td style='font-size:13px;width:60%;padding:5px'>"+this._empData.city+"</td></tr>";
    printContents +="<tr style='background:#bcc7dc;'><td style='font-size:13px;width:40%;padding:5px'>State</td><td style='font-size:13px;width:60%;padding:5px'>"+this._empData.stateNm+"</td></tr>";
    printContents +="<tr style='background:#f3f6fb ;'><td style='font-size:13px;width:40%;padding:5px'>Zip Code</td><td style='font-size:13px;width:60%;padding:5px'>"+this._empData.zipCode+"</td></tr>";
    printContents +="<tr style='background:#bcc7dc;'><td style='font-size:13px;width:40%;padding:5px'>Policy No</td><td style='font-size:13px;width:60%;padding:5px'>"+this._empData.documentNo+"</td></tr>";
    printContents +="<tr style='background:#bcc7dc;'><td style='font-size:13px;width:40%;padding:5px'>Insurance</td><td style='font-size:13px;width:60%;padding:5px'>"+this._empData.insuranceNameText+"</td></tr>";
    printContents +="<tr style='background:#f3f6fb ;'><td style='font-size:13px;width:40%;padding:5px'>Relation to Insured</td><td style='font-size:13px;width:60%;padding:5px'>self</td></tr>";
    printContents +="<tr style='background:#bcc7dc;'><td style='font-size:13px;width:40%;padding:5px'>Test Selection</td><td style='font-size:13px;width:60%;padding:5px'>"+item.selectedTest+"</td></tr>";
    printContents +="<tr style='background:#f3f6fb ;'><td style='font-size:13px;width:40%;padding:5px'>Collection Date</td><td style='font-size:13px;width:60%;padding:5px'>"+item.createdDate.split('T')[0]+"</td></tr>";
    printContents +="<tr style='background:#bcc7dc;'><td style='font-size:13px;width:40%;padding:5px'>Collection Time</td><td style='font-size:13px;width:60%;padding:5px'>"+item.createdDate.split('T')[1]+"</td></tr>";
    printContents +="<tr style='background:#f3f6fb ;'><td style='font-size:13px;width:40%;padding:5px'>Signature</td><td style='font-size:13px;width:60%;padding:5px'>"+(this._empData.signature!=null && this._empData.signature!="" ? "<img src='"+this._empData.signature+"' style='width:200px;height:30px' />" : "" )+"</td></tr>";
    printContents +="</table><div></td></tr></table>"

    printContents +="<div style='width:100%;margin-left:30px'><b>Disclaimer</b></div>";
    printContents +="<div style='width:100%;margin-left:30px;font-size:8px'>  <p>In consideration for participating in COVID-19 testing (hereinafter 'Testing'). which is provided by Clinify Test Center (the 'Company'), I hereby release, waive, discharge, covenant not to sue, and to hold harmless for any and all purposes Company and their healthcare staff, members, shareholders, officers, servants, agents, volunteers, contractors, or employees (herein referred to as 'indemnitees') from any and all liabilities, claims, demands, injuries (including death), or damages, including court costs and attorney fees and expenses, that may be sustained by me while participating in Testing, while traveling to and from the Testing, or while on the premises owned, leased or occupied by indemnitees.</p></div>";
    printContents +="<div style='width:100%;margin-left:30px;font-size:8px'><p>Due to the emergency nature of the COVID-19 pandemic, I am fully aware that the Testing provided by Company may involve COVID-19 tests that have not gone through a full FDA approval process and instead obtained emergency use authorization (EUA) or registered and are pending such processing and that the results could produce false positives or false negatives or be administered in a way that otherwise produces inaccurate results, or they are laboratory developed and shown similar performance to EUA authorized reagents. This test has been validated in accordance with the FDA guidance document (policy for diagnostics testing in laboratories certified to perform high complexity testing under EUA prior to emergency use authorization for coronavirus disease-2019 during the public health emergency) issued on February 29th, 2020. I am also fully aware that the Company is not providing medical care or giving a medical diagnosis with Testing and that I should consult my doctor as to the results of Testing.</p></div>";
    printContents +="<div style='width:100%;margin-left:30px;font-size:8px'><p>I hereby authorize Company to conduct collection and testing for COVID-19 through a nasopharyngeal swab (a swab inserted into my nose) and/or Serology antibody test (blood draw).</p></div>";
    printContents +="<div style='width:100%;margin-left:30px;font-size:8px'><p>I understand that I am not creating a patient/provider relationship with Company or any member of the Company by receiving a COVID-19 test. I understand that Company is not acting as my medical provider and that testing does not replace treatment by my medical provider. I take full responsibility to take appropriate action with regards to my test results when I receive them. I agree I will seek medical advice, care and treatment from my medical provider if I have questions or concerns after I receive the test, or if my condition worsens. I authorize my test results to be disclosed to the county, state, or to any other governmental entity as may be required by law. If applicable, I authorize my test results to be disclosed to a third party. I acknowledge that a positive test result is an indication that I must self-isolate in an effort to avoid infecting others. I understand that there is the potential for false positive or false negative test results. I, the undersigned, have been informed about the COVID-19 test purpose, procedures, possible benefits and risks. I have been given the opportunity to ask questions before I sign, and I have been told that I can ask other questions at any time.</p></div>";
    printContents +="<div style='width:100%;margin-left:30px;font-size:8px'><p>I hereby waive my rights regarding protected health information under HIPAA, to the extent necessary to complete the Testing and to allow Company to provide the results (whether positive or negative) of Testing to (1) the organization which has arranged for the testing, and/or (2) local and state public health authorities (which may result in further direct communication from those entities to me for further followup and contact tracing). Protected heath information will not be reused or disclosed by Company to any person or entity other than the above, except as required by law. I authorize Company to release to Clinify Test Center the results of my COVID-19 test so that they can determine whether or not I have tested positive for COVID-19.</p></div>";
  
    printContents +="<div class='page-break'></div>";
    return printContents;
    }
    PrintRecord(item:any){ 
        let printContents, popupWin; 
        printContents=this.BuiltPrintContent(item);
        this.callprint(printContents,item.employeeCode)
    
    }
    callprint(printContents:any,employeeCode:any){
        let  popupWin; 
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write('<head><title>'+employeeCode+'</title> \
    \ <style>@media print {.page-break { page-break-before: always; }  }</style> \
   </head>');
    popupWin.document.write('<div>' + printContents + '</div>');
    popupWin.document.write('</body></html>');
    popupWin.print();
    popupWin.document.close();
    } 
    getMasterData(flg, filterId,code) {

        var sessionobj = JSON.parse(localStorage.UserDetails);
        var clsmodel = {
            Type: flg,
            FilterId: filterId,
            Code:code
        }
        this.service.GetMasterData(clsmodel).subscribe(result => {

            var resultObj = result;
            if (resultObj["status"] == 200) { 
                  if (flg == 8) {  
                    if(resultObj["resultObj"]!=undefined){  
                    this._empData=resultObj["resultObj"];
                    this.imagePath=resultObj["resultObj"].imagePath; 
                    this._testlist=resultObj["resultObj"].clsCovidTests; 
                    this._vaccineList=resultObj["resultObj"].clsVaccines;
                    this._reqList=resultObj["resultObj"].clsEmpRequests; 
                    var tempreq=[]   
                    this._reqList.forEach(element => {
                        let checked = false; 
                        tempreq.push({ 
                            referenceNo: element.referenceNo, isSelected: checked, 
                            selectedLocation:element.selectedLocation,
                            selectedsymptons:element.selectedsymptons,
                            selectedTest:element.selectedTest,
                            seqno:element.seqno,
                            testResult:element.testResult,
                            location:element.lFirstName,
                            emp_select:false,
                            requestDate:element.requestDate,
                            createdDate:element.createdDate
                        }) 
                      this._reqList=tempreq;
                      });
                    }
                }
                
            }

        }, error => {
            console.log(error);
        });
        return "";
    }
    sendNotication() {
        if (this.selectedList.length > 0) {
          this.openmodal(this.send_notification);
        } else {
          this._snackbarservice.showSnackbar("No record[s] are selected");
        }
      }
      openmodal(modal) {
        this.dialog.open(modal, { width: "700px", height: "auto", disableClose: true });
      }
    SubmitForm() { 
        const formData = new FormData();
        formData.append('employerCode',this.id);
        if(this.UploadFile !== undefined)
            formData.append('profileImage', this.UploadFile, this.UploadFile.name);
        this.service.UploadEmployeeLogo(formData).subscribe(result => {

            var resultObj = result;

            if (resultObj["status"] == 200) {
                this._snackbarservice.showSnackbar(resultObj["message"]);
            }
            else
                this._snackbarservice.showSnackbar(resultObj["message"]);
            
        }, error => {
            console.log(error);
             
        });
         
    }
  onFileChanged(event) { 
    const file = event.target.files[0];
    if (!file.type.includes("image")) {
        alert("Please select image file only");

        return false;
      }
      this.UploadFile = file;
    var reader = new FileReader();
    reader.onload = (event: any) => {
       
        this.imagePath = event.target.result;
        this.SubmitForm();
    }
    reader.readAsDataURL(event.target.files[0]);

  }
}
