import { Component, ElementRef, ViewChild, OnInit, OnDestroy, AfterViewInit, NgModule } from '@angular/core';
import { CommonService } from 'app/common/Service/common.service';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from 'app/common/Service/snackbar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators, FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatPaginator } from '@angular/material/paginator';
import { TableUtil } from "../../../helpers/tableUtil";
import { DatePipe } from '@angular/common';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import * as XLSX from "xlsx";
@Component({
  selector: 'audit-log',
  templateUrl: './auditlogs.html',
  styleUrls: ['./auditlogs.component.scss']
})
export class AuditLogComponent implements OnInit, AfterViewInit {
  rows: any = [];rowslist: any = [];
  toxilabrows: any = [];
  requestform: FormGroup;
  TransferForm: FormGroup;
  result: any = {};
  testList: any = [];
  resultLength: number = 0;
  categories: any = []; currentCategory: string;
  storeData: any;
  filteredOptions1: Observable<string[]>;
  index: number = 0;
  searchKey: string = "";
  dataSource: any = [];
  usercode: string = "";
  empObject: any = {};
  clsmodel: any = {};
  test_type:string="all";
  buttonDisabled: boolean;
  ids: any[] = [];
  searchTerm: string = "";
  counter: number = 0;
  myControl = new FormControl();
  myControl1 = new FormControl();
  minDate: Date;
  usersdata: any = [];
  maxDate: Date;
  startDate: string;
  endDate: string;
  filteredOptions: Observable<string[]>;
  dateFormat: string;
  selectedLocation: any = [];
  FromLocation: any = [];
  ToLocation: any = [];
  ScreenActions: any = { IsEdit: false, IsPrint: false, IsSendNotification: false, IsTransfer: false, IsPDF: false, IsDelete: false }
  @ViewChild('view_Msg') view_Msg: ElementRef;
  @ViewChild(MatPaginator, { read: true }) paginator: MatPaginator;
  //@ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
  //    this.paginator = mp;
  //    this.setDataSourceAttributes();
  //}
   
  MsgResult:any={'msgType':"",'actionType' :"",'result':""};

  range = new FormGroup({
    start: new FormControl(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1)),
    end: new FormControl(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))
  });
  reference_no: string = "";
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['action','type', 'ref', 'txnDate', 'user',  'location','seqno']; 
  constructor(

    private service: CommonService,
    private dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private _snackbarservice: SnackbarService,
    private _splashScreenService: FuseSplashScreenService,
    private _fuseNavigationService: FuseNavigationService,
    private router: Router,
  ) {


    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 100, 0, 1);
    this.maxDate = new Date(currentYear, new Date().getMonth(), new Date().getDay());
    this.startDate = this.datePipe.transform(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1), "yyyy-MM-dd");
      this.endDate = this.datePipe.transform(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), "yyyy-MM-dd");

  }

  ngOnInit(): void {
    let isaccess=this._fuseNavigationService.checkAccess("UserList")
    if(!isaccess){
      this._snackbarservice.showSnackbar("Unauthorised Access to the page");
      this.router.navigateByUrl('/auth/login'); 
    }
    this.requestform = this._formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobileNo: ['', Validators.required],
      emailAddress: ['', Validators.required],
      gender: ['', Validators.required],
      DateOfBirth: ['', Validators.required],

    });
     
    var sessionObject = JSON.parse(localStorage.UserDetails)
    this.ScreenActions.IsEdit = this.service.getActionPermissions("RequestList", "Edit");
    this.ScreenActions.IsTransfer = this.service.getActionPermissions("RequestList", "Transfer");
    this.ScreenActions.IsPDF = this.service.getActionPermissions("RequestList", "SendPDF");
    this.ScreenActions.IsPrint = this.service.getActionPermissions("RequestList", "Print");
    this.ScreenActions.IsSendNotification = this.service.getActionPermissions("RequestList", "SendNotification");
    this.getMasterData(38, 0);//TestList  
    this.empObject.test_type = "0"
    this.test_type="all";
    this.empObject.reportType = "0";
    if (sessionObject.users_Usertype == "A")
      this.getMasterData(10, sessionObject.users_userid);//LocationUsers
    else
      this.getMasterData(30, sessionObject.users_userid);//LocationUsers

      this.searchlist();
  }
  closepdfwindow() {
    this.dialog.closeAll();
    this.reference_no = ""; 
  }
  ngAfterViewInit(): void {
    //this.dataSource.sort = this.sort;

    if (this.paginator !== undefined)
      this.dataSource.paginator = this.paginator;

    this.range.valueChanges.pipe(

    ).subscribe(event => {
      if (event.end) {
        this.onDateChanged(event);
      }
    });


  }
  onsearchEnter(_key) {
    if (_key.keyCode === 13) {
      this.searchlist();
    }
  }
  searchlist() {
    this.searchKey = this.searchTerm
    this.getdatawithcommonpaging(0, 50, 'createdDate', false, null, null);
  }
  generateReport() { 
    this.getdatawithcommonpaging(0, 50, 'createdDate', false, this.startDate, this.endDate);
  }
  flg = 0;
  onDateChanged(ev) {
    if (this.flg == 1) { 
      this.startDate = this.datePipe.transform(ev.start._d, "yyyy-MM-dd");
      this.endDate = this.datePipe.transform(ev.end._d, "yyyy-MM-dd");
      this.getdatawithcommonpaging(0, 50, 'createdDate', false, this.startDate, this.endDate);
      this.flg = 0;
    }
    else {
      this.flg++;
    }
  }

  viewmsg(msg,action){
    this.MsgResult.msgType=msg.split('Body:')[0];
    this.MsgResult.actionType=action;
    this.MsgResult.result=msg.split('Body:')[1];
    this.openmodal(this.view_Msg);
    var ele='';
   
  }
  exportExcel() {
    TableUtil.exportTableToExcel("tableData", "AuditReport");
  }
  exportToxilabExcel() {
    TableUtil.exportTableToExcel("tblToxiLabRpt", "AuditReport");
  }
  displayFn(selectedoption) {

    return selectedoption ? selectedoption.location_propertyName : undefined;
  }
  openmodal(modal) {
    this.dialog.open(modal, { width: "700px", height: "auto", disableClose: true });
  }
  openlocationmodal(modal) {
    this.dialog.open(modal, { width: "400px", height: "auto", disableClose: true });
  }
   
   
  onToSelectionChange(_ev) {
    if (_ev.option.value != null) {
      this.ToLocation = _ev.option.value;

    }
  }
  onFromSelectionChange(_ev) {
    if (_ev.option.value != null) {
      this.FromLocation = _ev.option.value;

    }
  }
getTotalRecords(testCode:string){ 
  let _tot=0;
  this.rows.forEach(element => {
    var _s=element.clsTestWiseCnts.filter ((course) => {
      return course.testName == testCode;
    });
    if(_s!=undefined && _s.length>0){
      _tot+=_s[0].cnt
    }
  });
  return _tot;
  
}

reloadRoute(){
  window.location.reload();
}
onPageFired(event) {
  let pgIndex = (event.length / event.pageSize) - 1 == event.pageIndex ? event.pageIndex : event.pageIndex;
  this.getdatawithcommonpaging(pgIndex, event.pageSize, this.clsmodel.sortKey, this.clsmodel.ordering, null, null);
}
getdatawithcommonpaging(page, pageSize, sortKey, ordering, stDate, endDate) {
  if (this.startDate == null || this.endDate == null)
    return;
    this.rows = [];
    this.rowslist = this.rows;  
    this.dataSource = new MatTableDataSource(this.rows);
  this._splashScreenService.show();
  var sessionobj = JSON.parse(localStorage.UserDetails);
  this.clsmodel.pageNumber = page;
  this.clsmodel.pageSize = pageSize;
  this.clsmodel.sortKey = sortKey;
  this.clsmodel.ordering = ordering;
  this.clsmodel.featureId = 100;
  this.clsmodel.startDate = this.startDate; this.clsmodel.endDate = this.endDate;
  this.clsmodel.searchKey = this.searchKey;
  this.clsmodel.categoryId =Number(this.empObject.reportType);
  this.clsmodel.accountId = sessionobj.users_userid;
  this.clsmodel.UserType = sessionobj.users_Usertype;
  this.service.getAuditLogsByPaging(this.clsmodel).subscribe((data) => {
    if (data["status"] == "200") {
      // then you can assign data to your dataSource like so
      this.result = data["resultObj"]["metadata"];
      this.resultLength = this.result.totalRecords;
      this.rows = data["resultObj"]["data"];
      this.rowslist = this.rows; 

      this.dataSource = new MatTableDataSource(this.rows);
      this.dataSource.sort = this.sort;

      const sortState: Sort = { active: 'createdDate', direction: 'desc' };
      this.sort.active = sortState.active;
      this.sort.direction = sortState.direction;
      this.sort.sortChange.emit(sortState);
      
      var positive = this.rows.filter((course) => {
        return course.status == "Detected";
      });
      var negative = this.rows.filter((course) => {
        return course.status == "Not Detected";
      });
      var Unsent = this.rows.filter((course) => {
        return (course.status == "Not Detected" || course.status == "Detected") && course.emailCounter == 0;
      });
      
      this._splashScreenService.hide();
    }
    else {
      this._snackbarservice.showSnackbar(data["message"]);
      this._splashScreenService.hide();
    }
  });

}
  _filter(value: string) {
    return this.usersdata.filter(option => option.location_propertyName.toLowerCase().includes(value.toLowerCase()));
  }
  getMasterData(flg, filterId) {
    var sessionobj = JSON.parse(localStorage.UserDetails);
    var clsmodel = {
      Type: flg,
      FilterId: filterId,
    }
    this.service.GetMasterData(clsmodel).subscribe(result => { 
      var resultObj = result;
      if (resultObj["status"] == 200) {
        if (flg == 34) {
          this.dateFormat = resultObj["resultObj"];
        }
        else if (flg == 30 || flg == 10) {
          this.usersdata = resultObj["resultObj"];           
          this.filteredOptions1 = this.myControl1.valueChanges
            .pipe(
              startWith(''),
              map(value => typeof value === 'string' ? value : value.location_propertyName),
              map(location_propertyName => location_propertyName ? this._filter(location_propertyName) : this.usersdata.slice())
            );
        }
        else if (flg == 38) {
          this.testList = resultObj["resultObj"];
        }

      }

    }, error => {
      console.log(error);
    });
    return "";

  }

  filterCoursesByCategory(_filterid): void {
    // Filter 
    if (_filterid.value == "all")
      this.getMasterData(10, 0,);//Countries  
    else
      this.getMasterData(30, _filterid.value,);//Countries  
  } 
  filterCoursesByTerm() {

  }
 

}
const routes: Routes = [
  {
    path: 'audit-logs',
    component: AuditLogComponent

  }
];