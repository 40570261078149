//import { environment } from '../../../environments/environment';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  private APiURL = environment.BaseApiUrl + "Common";
  constructor(
    private http: HttpClient
  ) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  SaveEmpDetails(objcls: any) { 
    return this.http.post(this.APiURL + '/RegisterEmployee', objcls);
  }
  employerSubmit(objcls: any) { 
    return this.http.post(this.APiURL + '/RegisterEmployeer', objcls);
  } 
  organizationDetails(objcls: any) { 
    return this.http.post(this.APiURL + '/getOrganizationDetails', objcls);
  } 
  uploadEmployee(objcls: any) { 
    return this.http.post(this.APiURL + '/UploadEmployee', objcls);
  } 
  sendRegistrationLink(objcls: any) { 
    return this.http.post(this.APiURL + '/sendRegistrationLink', objcls);
  } 
   
}
