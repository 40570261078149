import { Component, ElementRef, ViewChild, OnInit, OnDestroy, AfterViewInit, NgModule } from '@angular/core';
import { CommonService } from 'app/common/Service/common.service';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from 'app/common/Service/snackbar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ActivatedRoute, Router, Routes } from '@angular/router';
//import  "../../../../../assets/Dymoprint.js";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators, FormControl } from '@angular/forms';
//declare var dymo: any;
@Component({
  moduleId: module.id,
  selector: 'app-barcode-generator',
  templateUrl: 'barcodegenerator.html',
  styleUrls: ['./barcodegenerator.scss']
})

export class BarcodeGenerator {
  elementType = 'img';
  printType = 'Horizontal';
  rotateStyle="barcodeImg";rotateStyle1="";
  barcodeStyle="_barcodeNo1";
  requestData: any = [];
  value = 'DCL-EFG-00002';
  format = 'CODE128A';
  lineColor = '#000000';
  width = 2.5;
  height = 75;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'right';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 20;
  background = '#ffffff';
  margin = 10;
  marginTop = 10;
  marginBottom = 10;
  marginLeft = 10;
  marginRight = 10;
  constructor(
    private router: Router, private activatedRoute: ActivatedRoute,
    private service: CommonService,
  ) {
    debugger;
    this.activatedRoute.params.subscribe(param => this.value = param.id);
    if(this.value.includes('$'))
    this.getMasterData(82, 0, this.value.replace('$',''))
  else 
  this.getMasterData(31, 0, this.value)
  }
  get values(): string[] {
    return this.value.replace('$','').split('\n');
  }
  redirect2List(){
    if(this.value.includes('$'))
    this.router.navigate(['/emp-request-list']);
    else
    this.router.navigate(['/request-list']);
  }
  getMasterData(flg, filterId, code) {
    var sessionobj = JSON.parse(localStorage.UserDetails);
    var clsmodel = {
      Type: flg,
      FilterId: filterId,
      Code: code
    }
    this.service.GetMasterData(clsmodel).subscribe(result => {
      var resultObj = result;
      if (resultObj["status"] == 200) {
        if (flg == 31 || flg==82) { 
          this.requestData = resultObj["resultObj"];
        }
      }

    }, error => {
      console.log(error);
    });
    return "";

  }
  ChangeImage():void{ 
    this.rotateStyle=this.printType=="Horizontal" ? "barcodeImg" :"barcodeImg90";
    if(this.rotateStyle=='barcodeImg'){
    this.rotateStyle1="";
    this.barcodeStyle="_barcodeNo1";
    }
  else{
  this.rotateStyle1="transform: rotate(90deg)";
  this.barcodeStyle="_barcodeNo";
  }
  }
  PrintImg(): void {
    let printContents, popupWin;
    printContents = document.getElementById('divPrint').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write('<head><title>'+this.value.replace('$','')+'</title> \
    \ <style>.barcodeImg{background-color: #fff;    width: 600px;'+this.rotateStyle1+';} ._barcodeNo{position:absolute;top: 50%;left: 4%;font-size: 18px;font-weight: 800; }</style> \
   </head>');
    popupWin.document.write('<div style="'+this.rotateStyle1+';'+(this.rotateStyle1=="" ? "" : "margin-top:40%")+'">' + printContents + '</div>');
    popupWin.document.write('</body></html>');
    popupWin.print();
    popupWin.document.close();
  }
  codeList: string[] = [
    '', 'CODE128',
    'CODE128A', 'CODE128B', 'CODE128C',
    'UPC', 'EAN8', 'EAN5', 'EAN2',
    'CODE39',
    'ITF14',
    'MSI', 'MSI10', 'MSI11', 'MSI1010', 'MSI1110',
    'pharmacode',
    'codabar'
  ];
}
const routes: Routes = [
  {
    path: 'barcode-generator',
    component: BarcodeGenerator

  },
  {
    path: 'barcode-generator/:id',
    component: BarcodeGenerator

  }
];