import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from 'app/common/Service/common.service';
import { fuseAnimations } from '@fuse/animations';
import { MatDialog } from '@angular/material/dialog';
import { AgmCoreModule } from '@agm/core';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { GeocodeService } from './geocode.service';
import { SnackbarService } from 'app/common/Service/snackbar.service';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { element } from 'protractor';
import { arraysAreNotAllowedMsg } from '@ngrx/store/src/models';
import { environment } from '../../../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs';
import {
  HttpClient,
  HttpEventType,
  HttpErrorResponse
} from "@angular/common/http";

@Component({
  selector: 'centers',
  templateUrl: './centers.component.html',
  styleUrls: ['./centers.component.scss']
})
export class CentersComponent implements OnInit {

  categories: any[];
  courses: any[];
  usersdata: any = [];
  selectedFileName: string = "";
  locationWiseUsers: any = [];locationWiseInsurances: any = [];
  locationWiseTests: any = [];
  coursesFilteredByCategory: any[];
  filteredCourses: any = []; lstlatlngs: any = []; lstlatlngs1: any = [];
  alternateColor: any[] = ["android", "web", "cloud", "firebase"];
  currentCategory: string;
  searchTerm: string;
  usersearchTerm: string;
  counter: number = 0;
  lat = 41.8909409;
  _formGroup: any = [];
  _countries: any = [];
  usersdataList: any = [];
  _states: any = [];
  _cities: any = [];
  selLocation: number = 0;
  lng = -87.6898801;
  @ViewChild('add_Location') add_Location: ElementRef;
  @ViewChild('assignTestList') assignTestList: ElementRef;
  @ViewChild('assignuserpop') assignuserpop: ElementRef;
  @ViewChild('insuranceListpopup') insuranceListpopup: ElementRef;
  @ViewChild('referralpop') referralpop: ElementRef;
  @ViewChild('alertpop') alertpop: ElementRef;
  icon = {
    url: './assets/images/pin_location.png',
    scaledSize: {
      width: 32,
      height: 32
    }
  }; public UploadFile: File;
  imagePath: string = "assets/images/avatars/Velazquez.jpg";
  @ViewChild('fileInput') fileInput: ElementRef;
  zoom: number;
  empObject: any = {};
  btnText: string = "Save";
  getAddress: number;
  addlocation: FormGroup;
  addreferral: FormGroup;
  address = 'Raidurgam police station,gachibowli,hyderabad';
  location: Location;
  loading: boolean;
  usercode: string = "";
  deleteFlg: number = 0;
  TotalActive: number = 0;
  TotalInActive: number = 0;
  // Private
  private _unsubscribeAll: Subject<any>;


  constructor(
    private service: CommonService,
    private geocodeService: GeocodeService,
    private _snackbarservice: SnackbarService,
    private ref: ChangeDetectorRef, private _httpClient: HttpClient,
    private dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private http: HttpClient,private router: Router,
    private _splashScreenService: FuseSplashScreenService,
    private _fuseNavigationService: FuseNavigationService,
  ) {
    // Set the defaults
    this.currentCategory = 'all';
    this.searchTerm = '';
    this.empObject.insurance=0;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  addressToCoordinates(_location: string = "") {
    this.loading = true;
    this.geocodeService.geocodeAddress(_location)
      .subscribe((location: Location) => {
        console.log('latitude/longitude - ', location);
        this.lstlatlngs.push(location)
        this.loading = false;
        this.ref.detectChanges();
      }
      );
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------
  getAlternateColor() {
    if (this.counter == 3)
      this.counter = 0;
    else
      this.counter++;
    return this.alternateColor[this.counter] + "-bg";

  }
  /**
   * On init
   */
  ngOnInit(): void {

    this.getMasterData(18, 0, "");//EmployeerData

    this.addlocation = this._formBuilder.group({
      locationName: ['', Validators.required],
      locationAddress: ['', Validators.required],
      city: ['', Validators.required], state: ['', Validators.required],
      postalCode: ['', Validators.required],
      country: ['', Validators.required],
      latitude: ['', Validators.required],
      longitude: ['', Validators.required],
      prtype: ['', Validators.required],
      labDirector: ['', Validators.required],
      cliaIdnumber: ['', Validators.required],
      pdfAddress: ['', Validators.required], 
      npi: ['', Validators.required],deviceMethod: ['', Validators.required],
      managerName: ['', Validators.required],
      managernumber: ['', Validators.required],
      siteOwner: ['', Validators.required]
    });
    this.addreferral = this._formBuilder.group({
      referralName: ['', Validators.required],
      percentage: ['', Validators.required],
      remarks: [''],
    });
    this.getMasterData(1, 0, '');//Countries
    this._splashScreenService.show();
    var sessionObject = JSON.parse(localStorage.UserDetails)
    if (sessionObject.users_Usertype == "A")
      this.getMasterData(33, sessionObject.users_userid, '');//LocationUsers
    else
      this.getMasterData(30, sessionObject.users_userid, '');//LocationUsers 
      let isaccess=this._fuseNavigationService.checkAccess("Centers")
      if(!isaccess){
        this._snackbarservice.showSnackbar("Unauthorised Access to the page");
        this.router.navigateByUrl('/auth/login'); 
      }
    setTimeout(() => {
      this.lstlatlngs1 = this.lstlatlngs;
    }, 5000);

  }
  SaveUsermapping() {
 
    if (this.selLocation == undefined || this.selLocation == 0)
      return;
    var selectedUsers = "";
    this.usersdataList.forEach(element => {
      if (element.isChecked) {
        selectedUsers += element.users_userid + ",";
      }
    });
    this.getMasterData(28, this.selLocation, selectedUsers);//Countries
  }
  SaveTestmapping() {
 
    if (this.selLocation == undefined || this.selLocation == 0)
      return;
    var selectedTests = "";
    this.locationWiseTests.forEach(element => {
      if (element.isAvailable) {
        selectedTests += element.test_id + "$" + element.amount + "$" + element.desc +"$" + element.paymentLink + ",";
      }
    });
    this._splashScreenService.show();
    this.getMasterData(74, this.selLocation, selectedTests); 
  }
  addLocation() {
    this.btnText = "Save";
    this.openmodal(this.add_Location);
    this.empObject = {};
  }
  assignuser(_locationId) {
    this.selLocation = _locationId;
    this.getMasterData(32, _locationId, '');//getLocationWiseUsers
    this.openmodal(this.assignuserpop);

  }
  insuranceList(_locationId) {
    this.selLocation = _locationId;
    this.getMasterData(80, _locationId, '');//getLocationWiseUsers
    this.openmodal(this.insuranceListpopup);

  }
  Testlist(_locationId,TestLst) {
    this.locationWiseTests=TestLst;
    this.selLocation = _locationId;
    //this.getMasterData(32, _locationId, '');//getLocationWiseUsers
    this.openmodal(this.assignTestList);

  }
  Referral(accessCode) {
    this.usercode = accessCode;
    var _firstlist = this.filteredCourses.filter((course) => {
      return course.location_code === this.usercode;
    });
    if (_firstlist != undefined && _firstlist.length > 0) {
      this.empObject.referralName = _firstlist[0].location_referralName,
        this.empObject.referralCommission = _firstlist[0].location_referralCommission,
        this.empObject.referralRemarks = _firstlist[0].location_referralRemarks
    }

    this.openmodal(this.referralpop);

  }
  uservalueChange(loc, $event) {
    this.locationWiseUsers[loc].isChecked = $event.checked;
  }
  testvalueChange(loc, $event) { 
    this.locationWiseTests[loc].isAvailable = $event.checked;
  }
  paymentvalueChange(loc, $event) { 
    this.locationWiseTests[loc].isOnlinePayment = $event.checked;
  }
  openmodal(modal) {
    this.dialog.open(modal, { width: "700px", height: "auto", disableClose: true });
  }
  openmodal1(modal) {
    this.dialog.open(modal, { width: "400px", height: "auto", disableClose: true });
  }
  closewindow() {
    this.dialog.closeAll();
    //this.getMasterData(33, 0, "");//EmployeerData
  }
  defaultpagecount = 20;
  alldata: any = [];
  showsLoadMore: boolean = false;

  showMore() {

    const totalcount = this.alldata.length;
    const startcount = this.displaypagedata.length;
    let endCount = this.defaultpagecount + startcount;
    if (totalcount < endCount) {
      endCount = totalcount;
    }
    let adddata = this.alldata.slice(startcount, endCount);
    this.displaypagedata.push.apply(this.displaypagedata, adddata);
    if (totalcount == endCount) {
      this.showsLoadMore = false;
    }
    else {
      this.showsLoadMore = true;
    }
  }
  filteremployeeByID(obj): void {
    // Filter 


  }

  changeCountry(_obj, flag) {
    this.getMasterData(flag, _obj.value, '');
  }
  del_location(accessCode: string, flg: number) {
    this.openmodal1(this.alertpop);
    this.usercode = accessCode;
    this.deleteFlg = flg;
  }
  act_location(accessCode: string) {
    this.usercode = accessCode;
    this.getMasterData(30, 0, this.usercode);//EmployeerData
  }
  confirm_del() {
    this.getMasterData(29, this.deleteFlg, this.usercode);//EmployeerData
  }
  locationSubmit() {
    var clsmodel = {
      country: this.empObject.countryId,
      propertyType: this.empObject.propertytype,
      lat: this.empObject.location_latitude,
      lon: this.empObject.location_longitude,
      locationName: this.empObject.location_accountName,
      address: this.empObject.location_propertyName,
      city: this.empObject.location_city,
      state: this.empObject.location_state,
      zipCode: this.empObject.zipCode,
      locationCode: this.usercode,
      LabDirector: this.empObject.location_labDirector,
      cliaIdnumber: this.empObject.location_cliaIdnumber,
      pdfAddress: this.empObject.location_pdfAddress, 
      npi:this.empObject.npi, device:this.empObject.location_device,
      managerName: this.empObject.location_managerName,
      managerNumber: this.empObject.location_managernumber,
      siteOwner: this.empObject.location_siteOwner

    }
    this.service.saveLocation(clsmodel).subscribe(result => {
      var resultObj = result;
      if (resultObj["status"] == 200) {
        this.dialog.closeAll();
        this.usercode = "";
        this._snackbarservice.showSnackbar(resultObj["message"]);
        var sessionobj = JSON.parse(localStorage.UserDetails);
        if (sessionobj.users_Usertype == "A")
        this.getMasterData(33, sessionobj.users_userid, '');//LocationUsers
      else
        this.getMasterData(30, sessionobj.users_userid, '');//LocationUsers  
        this.usercode="";
      }


    }, error => {
      console.log(error);
    });
    return "";

  }
  referralSubmit() {

    var clsmodel = {
      referralName: this.empObject.referralName,
      referralCommission: Number(this.empObject.referralCommission),
      referralRemarks: this.empObject.referralRemarks,
      LocationCode: this.usercode

    }
    this.service.UpdateLocationReferral(clsmodel).subscribe(result => {
      var resultObj = result;
      if (resultObj["status"] == 200) {
        this._snackbarservice.showSnackbar(resultObj["message"]);
        var sessionobj = JSON.parse(localStorage.UserDetails);
        if (sessionobj.users_Usertype == "A")
        this.getMasterData(33, sessionobj.users_userid, '');//LocationUsers
      else
        this.getMasterData(30, sessionobj.users_userid, '');//LocationUsers 
      }


    }, error => {
      console.log(error);
    });
    return "";

  }
  edit_location(accessCode: string) {
    this.empObject = {};
    this.openmodal(this.add_Location);

    this.usercode = accessCode;

    var _firstlist = this.filteredCourses.filter((course) => {
      return course.location_code === this.usercode;
    });
    if (_firstlist != undefined && _firstlist.length > 0) {
      this.empObject.countryId = _firstlist[0].location_country,
        this.empObject.propertytype = _firstlist[0].location_propertyType,
        this.empObject.location_latitude = _firstlist[0].location_latitude,
        this.empObject.location_longitude = _firstlist[0].location_longitude,
        this.empObject.location_accountName = _firstlist[0].location_accountName,
        this.empObject.location_propertyName = _firstlist[0].location_propertyName,
        this.empObject.location_city = _firstlist[0].location_city,
        this.empObject.zipCode = _firstlist[0].location_zipCode
      this.empObject.location_labDirector = _firstlist[0].location_labDirector
      this.empObject.location_cliaIdnumber = _firstlist[0].location_cliaIdnumber 
      this.empObject.npi = _firstlist[0].location_npi
      this.empObject.location_device = _firstlist[0].location_device
      this.empObject.location_state = _firstlist[0].location_state
      this.empObject.location_pdfAddress = _firstlist[0].location_pdfAddress
      this.empObject.location_managerName = _firstlist[0].location_managerName
      this.empObject.location_managernumber = _firstlist[0].location_managernumber
      this.empObject.location_siteOwner = _firstlist[0].location_siteOwner
    }

    this.btnText = "Update";
  }

  displaypagedata: any = [];
  getMasterData(flg, filterId, code,) {

   
    var clsmodel = {
      Type: flg,
      FilterId: filterId,
      Code: code
    }
    this.service.GetMasterData(clsmodel).subscribe(result => {

      var resultObj = result;
      if (resultObj["status"] == 200) {
        var sessionobj = JSON.parse(localStorage.UserDetails);
        if (flg == 33 || flg == 30) {
          this._splashScreenService.hide();

          this.filteredCourses = resultObj["resultObj"];
          this.alldata = resultObj["resultObj"];
          this.displaypagedata = this.alldata.slice(0, this.defaultpagecount);
          this.showsLoadMore = true;
          if (this.alldata.length <= 6) {
            this.showsLoadMore = false;
          }
          var Tot = this.filteredCourses.filter((course) => {
            return course.location_status == "Open";
          });
          if (Tot != undefined && Tot.length > 0) {
            this.TotalActive = Tot.length;
          }
          Tot = this.filteredCourses.filter((course) => {
            return course.location_status == "Closed";
          });
          if (Tot != undefined && Tot.length > 0) {
            this.TotalInActive = Tot.length;
          }
          var _filter = [];
          this.filteredCourses.forEach(element => {
            element.location_className = this.getAlternateColor();
            _filter.push(element);
            this.lstlatlngs.push({
              lat: element.location_latitude,
              lon: element.location_longitude
            });
          });
          this.filteredCourses = _filter;
          this.coursesFilteredByCategory = this.filteredCourses;
        }
        else if (flg == 18) {
          this.usersdata = resultObj["resultObj"];
        }
        else if (flg == 80) {
          this.locationWiseInsurances = resultObj["resultObj"];
        }
      else  if (flg == 1) {
          this._countries = resultObj["resultObj"];
          this._states.push({ state_id: 0, state_name: "Others" })
          this._formGroup.stateId = 0;
          this._cities.push({ city_Id: 0, city_name: "Others" })
          this._formGroup.cityId = 0;
        }
        else if (flg == 3) {
          this._cities = resultObj["resultObj"];
          this._cities.push({ city_Id: 0, city_name: "Others" })
          this._formGroup.cityId = 0;
        }

        else if (flg == 32) {
          let locationUsers = resultObj["resultObj"];
          this.locationWiseUsers = [];

          this.usersdata.forEach(element => {
            let checked = false;
            if (locationUsers != null) {
              var filter = locationUsers.filter((course) => {
                return course.location_userId == element.users_userid;
              });
              if (filter != undefined && filter.length > 0) {
                checked = true;
              }
            }
            this.locationWiseUsers.push({
              users_AccessToken: element.users_AccessToken,
              users_Fullname: element.users_Fullname,
              users_Username: element.users_Username, isChecked: checked,
              users_userid: element.users_userid
            })

            this.usersdataList = this.locationWiseUsers;
          });
        }
        else if (flg == 26 || flg==73 || flg==74) {
          this._snackbarservice.showSnackbar(resultObj["message"]);
          if (sessionobj.users_Usertype == "A")
      this.getMasterData(33, sessionobj.users_userid, '');//LocationUsers
    else
      this.getMasterData(30, sessionobj.users_userid, '');//LocationUsers  
          this.dialog.closeAll();
        }
        else if (flg == 28) {
          this._snackbarservice.showSnackbar(resultObj["message"]);
          this.selLocation = 0;
          this.dialog.closeAll();
        }
        else if (flg == 29) {
          this._snackbarservice.showSnackbar(resultObj["message"]);
          var sessionobj = JSON.parse(localStorage.UserDetails);
        if (sessionobj.users_Usertype == "A")
        this.getMasterData(33, sessionobj.users_userid, '');//LocationUsers
      else
        this.getMasterData(30, sessionobj.users_userid, '');//LocationUsers 
          this.dialog.closeAll();
        }
      }


    }, error => {
      console.log(error);
    });
    return "";

  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Filter courses by category
   */
  filterCoursesByCategory(_filterid): void {
    // Filter 
    this._splashScreenService.show();
    if (_filterid.value == "all")
      this.getMasterData(33, 0, '');//Countries  
    else
      this.getMasterData(30, _filterid.value, '');//Countries 
    this._splashScreenService.hide();
  }
  valueChange(locationID, testid) {
    this.getMasterData(73, testid, locationID);//Countries  
  }
  searchlist(): void {
    const searchTerm = this.usersearchTerm.toLowerCase();
    if (searchTerm === '') {
      this.locationWiseUsers = this.usersdataList;
    }
    else {
      this.locationWiseUsers = this.usersdataList.filter((course) => {
        return course.users_Username.toLowerCase().includes(searchTerm);
      });
    }
  }
  /**
   * Filter courses by term
   */
  filterCoursesByTerm(): void {
    const searchTerm = this.searchTerm.toLowerCase();

    // Search
    if (searchTerm === '') {
      this.displaypagedata = this.alldata.slice(0, 20);
      this.showsLoadMore = true;
    }
    else {
      this.displaypagedata = this.alldata.filter((course) => {
        return course.location_accountName.toLowerCase().includes(searchTerm);
      });
    }
  }
  SubmitForm() {
    const formData = new FormData();
    formData.append('locationCode', this.usercode);
    if (this.UploadFile !== undefined)
      formData.append('UploadFile', this.UploadFile, this.UploadFile.name);
    this.service.UploadlocationLogo(formData).subscribe(result => {

      var resultObj = result;

      if (resultObj["status"] == 200) {
        var filter = this.filteredCourses.filter((course) => {
          return course.location_code == this.usercode;
        });
        if (filter != undefined && filter.length > 0) {
          filter[0].locationImage = resultObj["resultObj"]
        }
        this._snackbarservice.showSnackbar(resultObj["message"]);
      }
      else
        this._snackbarservice.showSnackbar(resultObj["message"]);

    }, error => {
      console.log(error);

    });

  }
  onFileChanged(event, accessCode) {
    
    this.usercode = accessCode;
    const file = event.target.files[0];
    if (!file.type.includes("image")) {
      alert("Please select image file only");

      return false;
    }
    this.UploadFile = file;
    var reader = new FileReader();
    reader.onload = (event: any) => {

      this.imagePath = event.target.result;
      this.SubmitForm();
    }
    reader.readAsDataURL(event.target.files[0]);
  }
}
