<div id="file-list" class="page-layout simple">
    <div class="center">
        <div class="header accent p-24" fxLayout="row" fxLayoutAlign="space-between start">
          
        <div class="breadcrumb text-truncate h1" fxLayout="row" fxLayoutAlign="start center"
        [@animate]="{value:'*',params:{x:'50px'}}">
       <div fxLayout="row" fxLayoutAlign="start center">
           <span>Upload Covid Test</span> 
       </div>
       
   </div>
    
</div>  
</div>
<div class="content p-24"> 
    
   
    <div fxLayout="column" fxLayoutAlign="center"> 

        <div class="filters" fxLayout="column" fxLayoutAlign="center center"
        fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center"> 
       <mat-form-field appearance="fill" class="category-selector">
           <mat-label>Members</mat-label>
           <mat-select [(ngModel)]="currentCategory"
           (selectionChange)="filteremployeeByID($event)">
               <mat-option [value]="'all'">
                   All
               </mat-option>
               <mat-option *ngFor="let category of rows" [value]="category.employeeCode">
                   {{category.name}}
               </mat-option>
           </mat-select>
       </mat-form-field>
       <img class="profile-image avatar huge" src="{{this.imagePath}}"  
       [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center">
        <mat-tab-group dynamicHeight="true"> 
            <mat-tab label="Covid Tests">
                <div id="about" class="p-24" fxLayout="row wrap">

                    <div class="about-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="100">

                        <div class="profile-box info-box general" fxLayout="column">

                            <!-- <header class="grey">
                                <div class="title">Covid Test List</div>
                            </header> -->

                            <div class="content">
                                <table width="100%" class="simple invoice-table">
                                    <thead>
                                        <tr class="mat-header-row bdr-radius">
                                            <th>Sno</th>
                                            <th>ReferenceNo</th>
                                            <th>Status</th>
                                            <th>Attachment</th>
                                            <th>Uploaded Date</th>
                                            <th>Valid Till</th> 
                                            <th>Action</th>
                                    </thead>
                                    <tbody>
                                        <tr class="mat-header-row" *ngFor="let row of _testlist ;let i=index">
                                            <td>{{i+1}}</td>
                                            <td> {{row.referenceNo}}</td>
                                            <td> {{row.status}}</td>
                                            <td><a href="{{row.filePath}}" target="_blank">document</a>
                                            </td>
                                            <td>{{row.uploadedOn}}</td>
                                            <td>{{row.validTill | date: 'dd/MM/yyyy'}}</td>
                                            <td><button mat-icon-button *ngIf="(row.status=='Pending')"  (click)="editcovidpop(0,row.testid,row.filePath)"><mat-icon>edit</mat-icon></button></td>

                                        </tr>
                                        <tr *ngIf="_testlist==null || _testlist.length==0">
                                            <td colspan="7"><h3 class="no-rec">No Records Found</h3></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Vaccination Details">
                <div id="about" class="p-24" fxLayout="row wrap">

                    <div class="about-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="100">

                        <div class="profile-box info-box general" fxLayout="column">

                            <!-- <header class="grey">
                                <div class="title">Vaccine List</div>
                            </header> -->

                            <div class="content">
                                <table width="100%" class="simple invoice-table">
                                    <thead>
                                        <tr class="mat-header-row bdr-radius">
                                            <th>Sno</th>
                                            <th>ReferenceNo</th> 
                                            <th>Attachment</th>
                                            <th>Uploaded Date</th>
                                            <th>Valid Till</th>
                                            <th>No Of Doses</th>
                                            <th>Vaccine</th>
                                            <th>Action</th>
                                    </thead>
                                    <tbody>
                                        <tr class="mat-header-row" *ngFor="let row of _vaccineList ;let i=index">
                                            <td>{{i+1}}</td>
                                            <td> {{row.referenceNo}}</td>
                                            <td><a href="{{row.filePath}}" target="_blank">document</a>
                                            </td>
                                            <td>{{row.uploadedOn}}</td>
                                            <td>{{row.validTill | date: 'dd/MM/yyyy'}}</td>
                                            <td>{{row.noOfDoses}}</td>
                                            <td>{{row.vaccine}}</td>
                                            <td><button mat-icon-button *ngIf="(row.status=='Uploaded')"  (click)="editcovidpop(1,row.testid,row.filePath)"><mat-icon>edit</mat-icon></button></td>

                                        </tr>
                                        <tr *ngIf="_testlist==null || _testlist.length==0">
                                            <td colspan="8"><h3 class="no-rec">No Records Found</h3></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
            </div>
    </div>  
</div>



<ng-template #editcovidtest>
   
    <form fxLayout="column" fxFlex="1 0 auto" name="invitation1Form" [formGroup]="invitation1Form">
        <h1 mat-dialog-title>Verify Document</h1>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto"> 
            <img src="{{this.filePath}}" class="document-img"/>    
        
        </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto"> 
    <mat-form-field appearance="fill" fxFlex="100">
        <mat-label>Remarks</mat-label>
        <textarea matInput cols="80" rows="4" placeholder="Remarks"  [(ngModel)]="remarks" formControlName="remarks"></textarea> 
    </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto"> 
        <button mat-raised-button class="accent mr-4 " (click)="UploadStatus('1')"><mat-icon>check</mat-icon> Valid</button>  
        <button mat-raised-button class="primary mr-4 " (click)="UploadStatus('0')"><mat-icon>cancel</mat-icon> In Valid</button>  
    <button mat-raised-button class="warn mr-4 " (click)="closewindow()" ><mat-icon>close</mat-icon> Close</button>  
   
   </div>   
</form>

</ng-template>