import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private route: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const UserData = JSON.parse(localStorage.getItem("UserDetails"));  
    if (UserData != undefined && UserData != null && UserData != "" && Object.entries(UserData).length != 0) {

      return true;
    }
    else {      
      this.route.navigate["auth/login"];
      return false;
    }
  }
}
