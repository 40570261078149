<div id="login" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">
    <div
        id="login-form-wrapper"
        fusePerfectScrollbar
        [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }"
    >
        <div id="login-form">
            <div class="logo">
                <img src="assets/images/logos/logo.png" />
            </div>

            <div class="title">LOGIN TO YOUR ACCOUNT</div>

            <form
                name="loginForm"
                [formGroup]="loginForm"
                novalidate
                (ngSubmit)="onSubmit()"
                *ngIf="IsloginActive"
            >
                <mat-form-field appearance="fill">
                    <mat-label>Email</mat-label>
                    <input
                        matInput
                        formControlName="email"
                        [(ngModel)]="loginchk.username"
                        placeholder="User Name"
                    />
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error
                        *ngIf="loginForm.get('email').hasError('required')"
                    >
                        Email is required
                    </mat-error>
                    <mat-error
                        *ngIf="
                            !loginForm.get('email').hasError('required') &&
                            loginForm.get('email').hasError('email')
                        "
                    >
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Password</mat-label>
                    <input
                        matInput
                        type="password"
                        formControlName="password"
                        placeholder="Password"
                        [(ngModel)]="loginchk.password"
                    />
                    <mat-icon matSuffix class="secondary-text"
                        >vpn_key</mat-icon
                    >
                    <mat-error> Password is required </mat-error>
                </mat-form-field>

                <div
                    class="remember-forgot-password"
                    fxLayout="row"
                    fxLayout.xs="column"
                >
                    <a
                        class="forgot-password text-right"
                        [routerLink]="'/auth/forgot-password-2'"
                    >
                        Forgot Password?
                    </a>
                </div>
                <button
                    mat-raised-button
                    color="accent"
                    class="submit-button"
                    aria-label="LOGIN"
                    type="submit"
                    [disabled]="loginForm.invalid"
                >
                    <mat-icon>lock_open</mat-icon> LOGIN
                </button>
            </form>

            <div class="separator">
                <span class="text warn">OR</span>
            </div>

            <div
                class="register"
                fxLayout="column"
                fxLayoutAlign="center center"
            >
                <a class="link" [routerLink]="'/auth/register-2'"
                    >Client Registration</a
                >
                <!-- <a class="link" [routerLink]="'/auth/request'"><mat-icon>assignment</mat-icon> Request Form</a>   -->
            </div>
        </div>
    </div>
</div>
