import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ChkLogin, ForgotPasswordModel } from 'assets/Model/user-model';
import { Router } from '@angular/router';
import { UserService } from './user-profile.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
    selector: 'login-2',
    templateUrl: './login-2.component.html',
    styleUrls: ['./login-2.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class Login2Component implements OnInit {
    loginForm: FormGroup;
    loginchk: ChkLogin = new ChkLogin();
    ResultData: any;
    ResultObjData: any = [];

    submitted = false;
    IsloginActive = true;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder, private MatAlert: MatSnackBar, private UserService: UserService, private formBuilder: FormBuilder, private router: Router
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });
       
        localStorage.setItem("QrLocation","");
        setInterval(() => {
            if(document.getElementById('hubspot-messages-iframe-container')!=null && document.getElementById('hubspot-messages-iframe-container')
            !=undefined)
            document.getElementById('hubspot-messages-iframe-container').setAttribute("style","display:none!important")
        }, 500);
    }

    onSubmit() {
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }

        this.UserService.CheckLogin(this.loginchk).subscribe(data => {
            
            this.ResultData = data;

            if (this.ResultData.status == 200) {
                localStorage.setItem("UserDetails", JSON.stringify(this.ResultData["resultObj"]));
                const user = JSON.parse(localStorage.getItem('UserDetails') || '{}'); 
                localStorage.setItem("isRefresh","1");  
                
                if (user.users_Usertype == "S") {
                    this.router.navigateByUrl('/request-list');                                    
                }
                else if (user.users_Usertype == "O") {
                    this.router.navigateByUrl('/upload-data');                                    
                }
                else
                    this.router.navigateByUrl('/request-list');
            }
            else {
                // this.router.navigateByUrl('');
                // this.MatAlert.open("Error :", this.ResultData.message);
                this.MatAlert.open("Error : ", this.ResultData.message);

            }
        }, error => //console.error(error));
            // alert("User Not Found");
            this.MatAlert.open("Internal Server Error :", "The server cannot meet the requirements "));
        setTimeout(() => {
            this.MatAlert.dismiss();
        }, 5000)
    }
}
