import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router'; 
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FuseSharedModule } from '@fuse/shared.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import { ClientListComponent } from './client-list/client-list.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { UserListComponent } from './user-list/user-list.component';
import { RoleListComponent } from './role-list/role-list.component';
import { MatCheckboxModule } from '@angular/material/checkbox'; 
import { MatDialogModule } from '@angular/material/dialog'; 
import { InsuranceListComponent } from './insurance-list/insurance-list.component'; 
import { SymptomsListComponent } from './symptoms-list/symptoms-list.component';
import { TestListComponent } from './test-list/test-list.component';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { VendorListComponent } from './vendor-list/vendor-list.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import { VendorWorkflowComponent } from './vendor-workflow/vendor-workflow.component'; 
import { MatTableModule } from '@angular/material/table';    
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
const routes = [
    {
        path        : 'dashboards/analytics',
        loadChildren: () => import('./dashboards/analytics/analytics.module').then(m => m.AnalyticsDashboardModule)
    },
    
    {
        path        : 'upload-data',
        loadChildren: () => import('./file-manager/file-manager.module').then(m => m.FileManagerModule)
    }, 
];

@NgModule({
   
    imports     : [
        RouterModule.forChild(routes),
        FuseSharedModule,MatInputModule,
        MatIconModule,MatButtonModule,MatFormFieldModule,MatSelectModule,
        MatDialogModule,BrowserModule,BrowserAnimationsModule,MatTooltipModule,MatCheckboxModule,MatTabsModule,
        FuseWidgetModule,MatPaginatorModule,MatTableModule,MatSlideToggleModule
    ],
    providers: [],
    declarations: [ClientListComponent,InsuranceListComponent, SymptomsListComponent,TestListComponent, VendorWorkflowComponent],
})
export class AppsModule
{
}
