import { Component, ElementRef, ViewChild, OnInit, NgModule } from '@angular/core';
import { CommonService } from 'app/common/Service/common.service';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from 'app/common/Service/snackbar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'app-insurance-list',
  templateUrl: './insurance-list.component.html',
  styleUrls: ['./insurance-list.component.scss']
})
export class InsuranceListComponent implements OnInit {
  usersdata: any = [];userslistdata: any = [];locationData: any = [];
  categories: any = []; currentCategory: string;
  storeData: any; locations: any = []; locationdataList: any = []; 
  index: number = 0;
  usercode: string = "";
  insurance_id:number=0;
  searchTerm: string;
  usersearchTerm: string;
  insurancesearchTerm: string;
  empObject: any = {};
  deleteFlg: number = 0;
  insuranceList: FormGroup;
  isChecked = true;
  @ViewChild('add_Insurance') add_Insurance: ElementRef;
  @ViewChild('alertpop') alertpop: ElementRef;
  @ViewChild('assignuserpop') assignuserpop: ElementRef;
  constructor(

    private service: CommonService,
    private dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private _snackbarservice: SnackbarService,
    private _splashScreenService: FuseSplashScreenService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    //link : ['', Validators.required],  
    var sessionobj = JSON.parse(localStorage.UserDetails);
    this.getMasterData(70, 0, "");//EmployeerData
    this.getMasterData(33, 0, '');//LocationUsers
    this.insuranceList = this._formBuilder.group({
      name: ['', Validators.required],
      code: ['', [Validators.required]],

    });
  }
  addInsurance() {
    this.empObject.Name = "";
    this.empObject.code = "";
    this.openmodal(this.add_Insurance);
  }
  openmodal(modal) {
    this.dialog.open(modal, { width: "500px", height: "auto", disableClose: true });
  }
  Slidechanged(accessCode) {
    this.usercode = accessCode;
  }
  del_ins(accessCode: string, flg: number) {
    this.openmodal1(this.alertpop);
    this.usercode = accessCode;
    this.deleteFlg = flg;

  }
  openmodal1(modal) {
    this.dialog.open(modal, { width: "400px", height: "auto", disableClose: true });
  }
  openmodal2(modal) {
    this.dialog.open(modal, { width: "800px", height: "auto", disableClose: true });
  }
  confirm_del() {
    this.getMasterData(69, this.deleteFlg, this.usercode.toString());//EmployeerData
  }
  searchlist(): void { 
    const searchTerm = this.usersearchTerm.toLowerCase();
    if (searchTerm === '') {
      this.locations = this.locationdataList;
    }
    else {
      this.locations = this.locationdataList.filter((course) => {
        return course.location_accountName.toLowerCase().includes(searchTerm) ||
        course.location_propertyName.toLowerCase().includes(searchTerm) ;
      });
    }
  }
  searchInsurancelist(): void {  
    const searchTerm = this.insurancesearchTerm.toLowerCase();
    if (searchTerm === '') {
      this.usersdata = this.userslistdata;
    }
    else {
      this.usersdata = this.userslistdata.filter((course) => {
        return course.insurance_Name.toLowerCase().includes(searchTerm) ||
        course.insurance_code.toLowerCase().includes(searchTerm) ;
      });
    }
  }
  edt_ins(accessCode: string) {
    this.openmodal(this.add_Insurance);
    this.usercode = accessCode; 
    this.getMasterData(70, 0, accessCode.toString());//EmployeerData
  }
  act_ins(accessCode: string, flg: number) {
    this.openmodal1(this.alertpop);
    this.usercode = accessCode;
    this.deleteFlg = flg;
  }
  showAssignLocations(accessCode: number, flg: number) {
    this.getMasterData(78, accessCode, '');//getLocationWiseUsers
    this.openmodal2(this.assignuserpop);
    this.insurance_id = accessCode; 
  }
  insuranceSubmit() {
    if (this.insuranceList.invalid) {
      return;
    }
    this._splashScreenService.show();
    this.empObject.seqno = this.usercode == "" || this.usercode == "0" ? 0 : this.usercode;
    this.service.SaveInsurance(this.empObject).subscribe(result => {

      var resultObj = result;

      if (resultObj["status"] == 200) {
        this.insuranceList.reset();
        this._snackbarservice.showSnackbar(resultObj["message"]);
        this.getMasterData(70, 0, "");//EmployeerData
        this.usercode = "0";
      }
      else
        this._snackbarservice.showSnackbar(resultObj["message"]);
      this._splashScreenService.hide();
    }, error => {
      console.log(error);
      this._splashScreenService.hide();
    });

  }
  getMasterData(flg, filterId, code) {
    var sessionobj = JSON.parse(localStorage.UserDetails);
    var clsmodel = {
      Type: flg,
      FilterId: filterId,
      Code: code,
    }
    this.service.GetMasterData(clsmodel).subscribe(result => {
      var resultObj = result;
      if (resultObj["status"] == 200) {
        if (flg == 70) { 
          this.usersdata = resultObj["resultObj"];
          this.userslistdata=this.usersdata;
          var _firstlist = this.usersdata.filter((course) => {
            return course.insurance_id === this.usercode;
          });
          if (_firstlist != undefined && _firstlist.length > 0) {
            this.empObject.Name = _firstlist[0].insurance_Name;
                this.empObject.code = _firstlist[0].insurance_code;
          } 
        }
        else if (flg == 79) {
          this._snackbarservice.showSnackbar(resultObj["message"]);
          this.insurance_id = 0;
          this._splashScreenService.hide();
          this.dialog.closeAll();
        }
        else if (flg == 33) { 
          this.locationData = resultObj["resultObj"]; 
        }
        else if (flg == 69) {
          this._snackbarservice.showSnackbar(resultObj["message"]);
          this.getMasterData(70, 0, '');//EmployeerData 
          this.dialog.closeAll();
        }
        else if (flg == 78) {
          let locationUsers = resultObj["resultObj"];
          this.locations = [];

          this.locationData.forEach(element => {
            let checked = false;
            if (locationUsers != null) {
              var filter = locationUsers.filter((course) => {
                return course.location_id == element.location_Id;
              });
              if (filter != undefined && filter.length > 0) {
                checked = true;
              }
            }
            this.locations.push({
              location_code: element.location_code,
              location_accountName: element.location_accountName,
              location_propertyName: element.location_propertyName, isChecked: checked,
              location_Id: element.location_Id
            })

            this.locationdataList = this.locations;
          });
        }
      }

    }, error => {
      console.log(error);
    });
    return "";

  }
  uservalueChange(loc, $event) {
    this.locations[loc].isChecked = $event.checked;
  }
  SaveUsermapping() {
 
    if (this.insurance_id == undefined || this.insurance_id == 0)
      return;
    var selectedUsers = "";
    this._splashScreenService.show();
    this.locationdataList.forEach(element => {
      if (element.isChecked) {
        selectedUsers += element.location_Id + ",";
      }
    });
    this.getMasterData(79, this.insurance_id, selectedUsers);//Countries
  }
}

const routes: Routes = [
  {
    path: 'insurance-list',
    component: InsuranceListComponent

  }
];