<div id="file-list" class="page-layout simple">
    <div class="center">
        <div class="header accent p-24" fxLayout="row" fxLayoutAlign="space-between start">

            <div class="breadcrumb text-truncate h1" fxLayout="row" fxLayoutAlign="start center"
                [@animate]="{value:'*',params:{x:'50px'}}">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <span>Role List</span>
                </div>

            </div>

        </div>
    </div>
    <div class="content p-24">

        <div *ngIf="!isEdit">
            <div fxLayout="column" fxLayoutAlign="center">

                <div class="filters" fxLayout="column" fxLayoutAlign="end center" fxLayout.gt-xs="row"
                    fxLayoutAlign.gt-xs="end">
                    <button mat-raised-button class="primary mb-8 right" (click)="addUser()"
                        [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
                        <mat-icon style="font-size: 20px;">person_add</mat-icon>
                        Add Role
                    </button>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center">
                <div id="about" fxLayout="row wrap">
                    <div class="about-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="100">
                        <div class="profile-box info-box general" fxLayout="column">
                            <div class="content">
                                <table width="100%" class="simple invoice-table">
                                    <thead>
                                        <tr class="accent1 mat-header-row bdr-radius">
                                            <th>S No.</th>
                                            <th>Role Name</th>
                                            <th>Role Type</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                    </thead>
                                    <tbody>
                                        <tr class="mat-header-row" *ngFor="let row of usersdata; let i=index">
                                            <td>{{i+1}}</td>
                                            <td><a href="javascript::"
                                                    (click)="edit_user(row.role_id)">{{row.role_name}}</a></td>
                                            <td>{{row.role_type}}</td>
                                            <td>{{row.role_isActive==true ? "Active" : "InActive"}}</td>
                                            <td><button mat-button class="button-min-width ht-normal"
                                                    (click)="edit_user(row.role_id)">
                                                    <mat-icon class="ft-18">edit</mat-icon>
                                                </button>
                                                <button class="button-min-width ht-normal" mat-button
                                                    *ngIf="row.role_isActive==true" (click)="del_user(row.role_id)">
                                                    <mat-icon class="ft-18">delete</mat-icon>
                                                </button>
                                                <button class="button-min-width ht-normal" mat-button
                                                    *ngIf="row.role_isActive==false" (click)="act_user(row.role_id)">
                                                    <mat-icon class="ft-18">check</mat-icon>
                                                </button>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>


            </div>
        </div>
        <div *ngIf="isEdit">
            <form fxLayout="column" fxLayoutAlign="center" name="specimenDetails" [formGroup]="specimenDetails">
                <div class="filters" fxLayout="column" fxLayoutAlign="end center" fxLayout.gt-xs="row"
                    fxLayoutAlign.gt-xs="end">
                    <button mat-raised-button class="primary mb-8 right" (click)="specimenSubmit()"
                        [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
                        <mat-icon style="font-size: 20px;">settings</mat-icon>
                        {{btnText}}
                    </button>
                    <button mat-raised-button class="warn mb-8 right" (click)="back()"
                        [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
                        <mat-icon style="font-size: 20px;">cancel</mat-icon>
                        Cancel
                    </button>
                </div>
                <div fxLayout="column" fxLayoutAlign="end center" fxLayout.gt-xs="row">
                <mat-form-field appearance="fill" fxFlex="33" fxFlex.gt-xs="33" class="category-selector mr-4">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name" [(ngModel)]="empObject.Name">
                    <mat-error>
                        Name is required
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" fxFlex="33" fxFlex.gt-xs="33" class="category-selector">
                    <mat-label>Role Type</mat-label>
                    <mat-select [ngModelOptions]="{standalone: true}" formControlName="prtype"
                        [(ngModel)]="empObject.propertytype" required>
                        <mat-option value="A">
                            Administrator
                        </mat-option>
                        <mat-option value="S">
                            Staff/Speciman
                        </mat-option>
                        <mat-option value="O">
                            Employer
                        </mat-option>
                        <mat-option value="P">
                            Customer
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        Role Type is required
                    </mat-error>
                </mat-form-field>
                </div>
                <mat-accordion>
                    <mat-expansion-panel *ngFor="let mainmodule of ModuleDefaultMenu" (opened)="panelOpenState = true"
                        (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{mainmodule.Module}}
                            </mat-panel-title>

                        </mat-expansion-panel-header>

                        <div class="courses" fxLayout="row wrap" fxLayoutAlign="center">
                            <div class="course" *ngFor="let course of mainmodule.Menu" fxFlex="100" fxFlex.gt-xs="50"
                                fxFlex.gt-sm="25" [ngClass]="category">

                                <div class="course-content" fxLayout="column" fxFlex="1 1 auto">

                                    <div class="header fx-lt-grey" fxLayout="row" fxLayoutAlign="center center"
                                        [ngClass]="course.Icon">

                                        <div class="category" fxFlex>
                                            <mat-checkbox name="Add" class="mr-4" [checked]="false"
                                                (change)="valueChange(course.ScreenId,'All',$event)">
                                            </mat-checkbox> {{course.ScreenName}}
                                        </div>

                                    </div>

                                    <div class="content" fxLayout="column" fxLayoutAlign="start" fxFlex="1 1 auto">
                                        <div class="chk" fxLayout="row" fxLayoutAlign="start"
                                            *ngFor="let item of  course.clsActions">
                                            <mat-checkbox name="{{item.Value}}" [checked]="item.Value"
                                                (change)="valueChange(course.ScreenId,item.ActionName,$event)">
                                                {{item.ActionName}}
                                            </mat-checkbox>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </form>
        </div>
    </div>
</div>


<ng-template #alertpop>


    <form fxLayout="column" fxFlex="1 0 auto" name="specimenDetails" [formGroup]="specimenDetails">
        <h1 mat-dialog-title>Confirmation</h1>
        <div fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">
            <h4>Are you sure you want to suspended the role?</h4>

        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <button mat-raised-button class="warn mr-4 " (click)="closewindow()" mat-dialog-close>
                <mat-icon>close</mat-icon>Cancel
            </button>
            <button mat-raised-button (click)="confirm_del()" class="primary mr-4" mat-dialog-close>
                <mat-icon>delete</mat-icon>Delete
            </button>
        </div>
    </form>

</ng-template>