<div id="academy-courses" class="page-layout simple">
    <div class="center">
        <div class="header accent p-24" fxLayout="row" fxLayoutAlign="space-between start">

            <div class="breadcrumb text-truncate h1" fxLayout="row" fxLayoutAlign="start center"
                [@animate]="{value:'*',params:{x:'50px'}}">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <span>Batch List</span>
                </div>

            </div>

        </div>
    </div>
    
    <div fxLayout="column" fxLayoutAlign="center">
        <div class="content p-24">
            <div fxLayout="column" fxLayoutAlign="center">
                <fieldset>
                    <legend>
                        <h3>Filters</h3>
                    </legend>
                    <div class="filters" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
                        fxLayoutAlign.gt-xs="start center">
                        <mat-form-field appearance="fill" floatLabel="always" fxFlex="33" class="course-search">
                            <mat-label>Search for a center</mat-label>
                            <input matInput placeholder="Enter a keyword..."
                                   [(ngModel)]="searchTerm"
                                   (input)="filterCoursesByTerm()">
                        </mat-form-field>
                     
                        <mat-form-field appearance="fill" fxFlex="33" class="category-selector">
                            <mat-label>Users</mat-label>
                            <mat-select [(ngModel)]="currentCategory"
                            (selectionChange)="filterCoursesByCategory($event)"     >
                                <mat-option [value]="'all'">
                                    All
                                </mat-option>
                                <mat-option *ngFor="let row of usersdata" [value]="row.users_userid">
                                    {{ row.users_Username}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        
                
                    </div>
                </fieldset>
                
                </div>
            
<div class="filters" fxLayout="column" fxLayoutAlign="end center"
fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="end"> 
<div class="captions">

    <span class="dosespan mat-raised-button">Total Active - &nbsp;<label class="text-green">{{CntList.ActiveList}}</label></span>  

       <span class="dosespan mat-raised-button">Total InActive - &nbsp;<label class="text-warn">{{CntList.InActiveList}}</label> </span>

    <span class="dosespan mat-raised-button">Total Clients - &nbsp;<label class="text-accent">{{CntList.TotalClients}}</label> </span> </div>
 
</div>
            <div class="courses" fxLayout="row wrap" fxLayoutAlign="center">
                <div class="course" *ngFor="let row of rows" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33"
                    fxFlex.gt-md="25">

                    <div class="course-content" fxLayout="column" fxFlex="1 1 auto">

                        <div class="header fx-lt-grey" fxLayout="row" fxLayoutAlign="center center"
                            [ngClass]="row.location_className">

                            <div class="category" fxFlex>
                                {{row.employeer_orgName}}
                            </div>
                        </div>

                        <div class="content" fxLayout="column" fxLayoutAlign="start" fxFlex="1 1 auto">
                            <div class="h1"></div>
                            <div class="updated">
                                <mat-icon class="length-icon s-20 mr-4">location_city</mat-icon>{{row.employeer_code}}
                            </div>
                            <div class="updated">
                                <mat-icon class="length-icon s-20 mr-4">mobile_friendly</mat-icon> {{row.employeer_mobileNo}}
                            </div>
                            <div class="updated">
                                <mat-icon class="length-icon s-20 mr-4">mail</mat-icon> {{row.employeer_emailAddress}}
                            </div>
                        </div>


                        <div class="footer" fxLayout="row" fxLayoutAlign="center center">
                            <button class="nr-wid" mat-button matTooltip="Approve" matTooltipClass="tooltip-blue"
                                *ngIf="(!row.employeer_isActive)" (click)="invitationPop(row.employeer_code,15)">
                                <mat-icon>check</mat-icon>
                            </button>
                            <button class="nr-wid" mat-button matTooltip="Edit" matTooltipClass="tooltip-blue"
                                [routerLink]="['/profile/',row.employeer_code]" routerLinkActive="router-link-active">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button class="nr-wid" *ngIf="(!row.employeer_isActive)" (click)="invitationPop(row.employeer_code,15)"  mat-button matTooltip="Active" matTooltipClass="tooltip-blue">
                                <mat-icon class="green1">brightness_1</mat-icon>
                            </button>
                            <button class="nr-wid" *ngIf="(row.employeer_isActive)" mat-button matTooltip="Delete" matTooltipClass="tooltip-blue"
                                (click)="invitationPop(row.employeer_code,16)">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button class="nr-wid" mat-button matTooltip="Assign" (click)="assignuser(row.employeer_id)"
                                matTooltipClass="tooltip-blue">
                                <mat-icon>assignment_ind</mat-icon>
                            </button>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>

    <ng-template #confirmpopup>
        <div class="mb-24" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="column">
            <h2>Confirm</h2>
            <h1>
                Are you sure you want to {{modalTitle}}
            </h1>

        </div>
        <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

            <button mat-raised-button color="secondary" class="mr-8 warn" mat-dialog-close (click)="closewindow()"
                aria-label="No">
                Cancel
            </button>

            <button mat-raised-button color="accent" class="save-button accent" (click)="ApproveClient()">
                Approve

            </button>

        </div>
    </ng-template>

    <ng-template #assignuserpop>
        <div class="content">
            <mat-form-field appearance="fill" floatLabel="always" class="course-search">
                <mat-label>Search for a user</mat-label>
                <input matInput placeholder="Enter a keyword..." [(ngModel)]="usersearchTerm" (input)="searchlist()">
            </mat-form-field>

            <div style="height: 400px;overflow:auto;">
                <table width="100%" class="simple invoice-table">
                    <thead>
                        <tr class="accent1">
                            <th>S No.</th>
                            <th>User Code</th>
                            <th>Name</th>
                            <th>Assign</th>
                    </thead>
                    <tbody>
                        <tr class="mat-header-row" *ngFor="let row of locationWiseUsers; let i=index">
                            <td>{{i+1}}</td>
                            <td> {{row.users_AccessToken}}</td>

                            <td>{{row.users_Username}}</td>
                            <td>
                                <mat-checkbox [name]="row.users_userid" [ngModelOptions]="{standalone: true}"
                                    (change)="uservalueChange(i,$event)" [id]="row.users_userid"
                                    [(ngModel)]="row.isChecked">

                                </mat-checkbox>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <div class="mr-t-30">

                <button mat-raised-button class="warn mr-4 " (click)="closewindow()" mat-dialog-close>
                    <mat-icon>close</mat-icon>Cancel
                </button>
                <button mat-raised-button class="primary mr-4" (click)="SaveUsermapping()">
                    <mat-icon>save</mat-icon>Update
                </button>

            </div>
        </div>
    </ng-template>