<div id="file-list" class="page-layout simple">

	<div class="center">
		<div class="header accent p-24" fxLayout="row" fxLayoutAlign="space-between start">

			<div class="breadcrumb text-truncate h1" fxLayout="row" fxLayoutAlign="start center"
				[@animate]="{value:'*',params:{x:'50px'}}">
				<div fxLayout="row" fxLayoutAlign="start center">
					<span>Barcode Generator</span>
				</div>

			</div>

		</div>
	</div>
	<div class="content p-24">

		<div fxLayout="row" flex="100" fxLayoutAlign="center">

			<div fxLayout="column" fxFlex="50">
				<mat-form-field appearance="fill" floatLabel="always" class="course-search">
                    <mat-label>Print Type</mat-label>
					<mat-select   [(ngModel)]="printType" (selectionChange)="ChangeImage()"  required>
						<mat-option value="Horizontal">
							Horizontal
						</mat-option> 
						<mat-option value="Verticle">
							Vertical
						</mat-option>   
					</mat-select> 
                </mat-form-field>

				<mat-form-field appearance="fill" floatLabel="always" class="course-search">
                    <mat-label>Print Value</mat-label>
                    <input placeholder="bc-value" matInput placeholder="Enter a keyword..." [(ngModel)]="value"
                         > 
                </mat-form-field>
				<mat-form-field appearance="fill" floatLabel="always" class="course-search">
                    <mat-label>Print Type</mat-label>
					<mat-select   [(ngModel)]="elementType"  required>
						<mat-option value="svg">
							svg
						</mat-option> 
						<mat-option value="img">
							img
						</mat-option> 
						<mat-option value="canvas">
							canvas
						</mat-option> 
					 
					</mat-select> 
                </mat-form-field>
				<mat-form-field appearance="fill" fxFlex="50" class="mr-4">
					<mat-label>Format</mat-label>
					<mat-select   [(ngModel)]="format"  required>
						<mat-option *ngFor="let code of codeList" [value]="code">
							{{code}}
						</mat-option> 
					</mat-select>  
				</mat-form-field>   
				<mat-form-field appearance="fill" floatLabel="always" class="course-search">
                    <mat-label>Line Color</mat-label>
                    <input placeholder="bc-value" matInput   [(ngModel)]="lineColor"
                         > 
                </mat-form-field>
				<mat-form-field appearance="fill" floatLabel="always" class="course-search">
                    <mat-label>Width</mat-label>
                    <input placeholder="bc-value" matInput [(ngModel)]="width"
                         > 
                </mat-form-field>
				<mat-form-field appearance="fill" floatLabel="always" class="course-search">
                    <mat-label>Height</mat-label>
                    <input placeholder="bc-value" matInput [(ngModel)]="height"
                         > 
                </mat-form-field>
				<!-- <mat-form-field appearance="fill" floatLabel="always" class="course-search">
                    <mat-label>Display Value</mat-label> 
						 <mat-checkbox [(ngModel)]="displayValue" [ngModelOptions]="{standalone: true}"
                                        ></mat-checkbox>
                </mat-form-field> -->
				<mat-form-field appearance="fill" floatLabel="always" class="course-search">
                    <mat-label>Font Options</mat-label>
                    <input placeholder="bc-value" matInput [(ngModel)]="fontOptions"
                         > 
                </mat-form-field>
				<mat-form-field appearance="fill" floatLabel="always" class="course-search">
                    <mat-label>Font</mat-label>
                    <input placeholder="bc-value" matInput [(ngModel)]="font"
                         > 
                </mat-form-field>  
		 
				<mat-form-field appearance="fill" floatLabel="always" class="course-search">
                    <mat-label>Text Align</mat-label>
                    <input placeholder="bc-text-align" matInput [(ngModel)]="textAlign"
                         > 
                </mat-form-field>  
				<mat-form-field appearance="fill" floatLabel="always" class="course-search">
                    <mat-label>Text Position</mat-label>
                    <input placeholder="bc-text-align" matInput [(ngModel)]="textPosition"
                         > 
                </mat-form-field>  
				<mat-form-field appearance="fill" floatLabel="always" class="course-search">
                    <mat-label>Text Margin</mat-label>
                    <input type="number" placeholder="bc-text-align" matInput [(ngModel)]="textMargin"
                         > 
                </mat-form-field>  
				<mat-form-field appearance="fill" floatLabel="always" class="course-search">
                    <mat-label>Font Size</mat-label>
                    <input type="number" placeholder="bc-text-align" matInput [(ngModel)]="fontSize"
                         > 
                </mat-form-field>  
			 
				<mat-form-field appearance="fill" floatLabel="always" class="course-search">
                    <mat-label>Background</mat-label>
                    <input type="number" placeholder="bc-background" matInput [(ngModel)]="background"
                         > 
                </mat-form-field> 
				<mat-form-field appearance="fill" floatLabel="always" class="course-search">
                    <mat-label>Margin</mat-label>
                    <input type="number" placeholder="bc-text-align" matInput [(ngModel)]="margin"
                         > 
                </mat-form-field> 
				<mat-form-field appearance="fill" floatLabel="always" class="course-search">
                    <mat-label>Margin Top</mat-label>
                    <input type="number" placeholder="bc-text-align" matInput [(ngModel)]="marginTop"
                         > 
                </mat-form-field> 
				<mat-form-field appearance="fill" floatLabel="always" class="course-search">
                    <mat-label>Margin Bottom</mat-label>
                    <input type="number" placeholder="bc-text-align" matInput [(ngModel)]="marginBottom"
                         > 
                </mat-form-field>  
				<mat-form-field appearance="fill" floatLabel="always" class="course-search">
                    <mat-label>Margin Left</mat-label>
                    <input type="number" placeholder="bc-text-align" matInput [(ngModel)]="marginLeft"
                         > 
                </mat-form-field>  
				<mat-form-field appearance="fill" floatLabel="always" class="course-search">
                    <mat-label>Margin Right</mat-label>
                    <input type="number" placeholder="bc-text-align" matInput [(ngModel)]="marginRight"
                         > 
                </mat-form-field>  
			 
			</div>
			<div  fxLayout="column" fxFlex="50"  class="ml-sm-32">
				<div   id="divPrint" [ngClass]="rotateStyle"  >
				<ngx-barcode *ngFor="let bcValue of values" [bc-element-type]="elementType" [bc-value]="bcValue"
					[bc-format]="format" [bc-line-color]="lineColor" [bc-width]="width" [bc-height]="height"
					[bc-display-value]="displayValue" [bc-font-options]="fontOptions" [bc-font]="font"
					[bc-text-align]="textAlign" [bc-text-position]="textPosition" [bc-text-margin]="textMargin"
					[bc-font-size]="fontSize" [bc-background]="background" [bc-margin]="margin"
					[bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom" [bc-margin-left]="marginLeft"
					[bc-margin-right]="marginRight"></ngx-barcode>
					<label [ngClass]="barcodeStyle">{{requestData.location}}</label>
					<table width="100%"><tr><td></td><td><label><strong>CD:{{this.requestData.createdDate | date: 'MM/dd/yyyy'}}</strong></label></td></tr><tr><td><label><strong>N:<span class="spanClass">{{this.requestData.name}}</span></strong></label></td><td><label><strong>DOB:{{this.requestData.dob | date: 'MM/dd/yyyy'}}</strong></label></td></tr></table>
				</div>
					<div fxLayout="row" fxLayoutAlign="start center" class="mr-4"  fxFlex="1 0 auto">  
						<button mat-raised-button   class="warn mr-4"  (click)="redirect2List()"  ><mat-icon>list</mat-icon>List</button> 
						<button mat-raised-button   class="primary mr-4" (click)="PrintImg()" ><mat-icon>print</mat-icon>Print</button> 
					   </div>   
			</div>
		</div>
	</div>
</div>