import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
 
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';  
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ChartsModule} from 'ng2-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatDialogModule } from '@angular/material/dialog';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { RequestDashboardComponent } from 'app/main/apps/request-dashboard/request-dashboard.component';
import { AnalyticsDashboardComponent } from 'app/main/apps/dashboards/analytics/analytics.component';
import { AnalyticsDashboardService } from 'app/main/apps/dashboards/analytics/analytics.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { SnackbarService } from 'app/common/Service/snackbar.service';
import {MatGridListModule} from '@angular/material/grid-list';
import { LoaderService } from 'app/common/Service/loader.service';
import { RegisteredListComponent } from '../../registered-list/registered-component';
const routes: Routes = [
    // {
    //     path     : 'dashboard',
    //     component: AnalyticsDashboardComponent,
    //     resolve  : {
    //         data: AnalyticsDashboardService
    //     }
    // }
     {
        path: 'request-dashboard',
        component: RequestDashboardComponent,       
    }
    , {
        path: 'registered-list',
        component: RegisteredListComponent,       
    }
];

@NgModule({
    declarations: [
        AnalyticsDashboardComponent,RequestDashboardComponent,RegisteredListComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatSelectModule,
        MatTabsModule,
        MatProgressBarModule,
        MatDatepickerModule,
        ChartsModule,
        NgxChartsModule,
        MatGridListModule,
        MatSnackBarModule,
        FuseSharedModule,
        FuseWidgetModule
    ],
    providers   : [
        AnalyticsDashboardService,DatePipe, SnackbarService, 
    ]
})
export class AnalyticsDashboardModule
{
}

