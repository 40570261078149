<div id="file-list" class="page-layout simple">


    <div class="center">
        <div class="header accent p-24 space-bet" fxLayout="row" fxLayoutAlign="start center">

            <div class="breadcrumb text-truncate h1" fxLayout="row" fxLayoutAlign="start center"
                [@animate]="{value:'*',params:{x:'50px'}}">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <span tourAnchor="start.tour">Member Request List</span> 
                </div>
            </div>
            <div class="captions" tourAnchor="angular-ui-tour">
                <span class="dosespan mat-raised-button">Total Requests :&nbsp;<label
                        class="text-accent">{{rows.length}}</label></span>
                <span class="dosespan mat-raised-button">Detected Results :&nbsp;<label
                        class="text-green">{{positiveRecords}}</label>
                </span>
                <span class="dosespan mat-raised-button">Not Detected Results :&nbsp;<label
                        class="text-warn">{{NegativeRecords}}</label>
                </span>
                <span class="dosespan mat-raised-button">Inconclusive Results :&nbsp;<label
                    class="text-warn">{{InconclusiveRecords}}</label>
            </span>
                <!-- <span (click)="UnSentResults()" class="dosespan mat-raised-button ">Results Unsent :&nbsp;<label
                    [ngClass]="UnsentEmails>0 ? 'text-warn blinking' : 'text-warn'">{{UnsentEmails}}</label>
            </span> -->
            </div>
            <!-- <div class="clsCaption"> Displaying records between {{startDate}} to {{endDate}}</div> -->
        </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content p-24">

        <div fxLayout="column" fxLayoutAlign="center">
            <fieldset [style.display]="showfilter ? 'block' : 'none'">
                <legend>
                    <h3>Filters</h3>
                </legend>
                <div class="filters" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
                    fxLayoutAlign.gt-xs="space-between center">

                    <mat-form-field appearance="fill" floatLabel="always" fxFlex="25" class="course-search mr-4">
                        <mat-label>Search</mat-label>
                        <input matInput placeholder="Enter a keyword..."  (keyup)="onsearchEnter($event)"   [(ngModel)]="searchTerm">
                        <button mat-raised-button class="primary mb-8 right btn_search" (click)="searchlist()"
                        [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
                        <mat-icon matSuffix class="secondary-text" >search</mat-icon>
                        Search
                    </button>

                        
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="date-picker mr-4" fxFlex="25">
                        <mat-label>Enter a date range</mat-label>
                        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                            <input matStartDate formControlName="start" placeholder="Start date">
                            <input matEndDate formControlName="end" placeholder="End date">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>

                        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date
                        </mat-error>
                        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="category-selector mr-4" *ngIf="!isEmployer" fxFlex="25">

                        <mat-label>Centers</mat-label>
                        <input type="text" placeholder="Pick one" aria-label="Number" matInput [formControl]="myControl"
                            [matAutocomplete]="auto">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                            (optionSelected)="onSelectionChange($event)" [displayWith]="displayFn">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                {{option.location_propertyName}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="course-search mr-4" *ngIf="!isEmployer" fxFlex="25">
                        <mat-label>Clients</mat-label>
                        <input type="text" placeholder="Pick one" aria-label="Number" matInput [formControl]="myControl2"
                        [matAutocomplete]="autoclient">
                    <mat-autocomplete autoActiveFirstOption #autoclient="matAutocomplete"
                        (optionSelected)="onClientSelectionChange($event)" [displayWith]="displayClientFn">
                        <mat-option *ngFor="let option2 of filteredOptions2 | async" [value]="option2">
                            {{option2.employeer_orgName}}
                        </mat-option>
                    </mat-autocomplete>                      
                    </mat-form-field>

                </div>
            </fieldset>
            <div class="filters" fxLayout="column" fxLayoutAlign="end right" fxLayout.gt-xs="row"
                fxLayoutAlign.gt-xs="end">
                <div fxLayout="row" *ngIf="selectedList.length >0">
                    <mat-form-field appearance="fill" fxFlex="100" class="result-course-search">
                        <mat-label>Result</mat-label>
                        <mat-select [(ngModel)]="empObject.result" required>
                            <mat-option value="0">
                                Select Result
                            </mat-option>
                            <mat-option value="Detected">
                                Detected
                            </mat-option>
                            <mat-option value="Not Detected">
                                Not Detected
                            </mat-option>
                            <mat-option value="Inconclusive">
                                Inconclusive
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button mat-raised-button fxFlex="100" [disabled]="empObject.result==undefined || empObject.result=='0'"
                        class="accent mb-8 right" (click)="sendNotication()"
                        [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
                        <mat-icon style="font-size: 20px;">send</mat-icon>
                        Send now
                    </button>
                </div>
                <button mat-raised-button class="primary mb-8 right" (click)="exportExcel()"
                    [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
                    <mat-icon style="font-size: 20px;">cloud_download</mat-icon>
                    Export List
                </button>
                <button mat-raised-button class="warn mb-8 right" (click)="reloadRoute()"
                    [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
                    <mat-icon style="font-size: 20px;">refresh</mat-icon>
                    Refresh
                </button>
                <button mat-raised-button class="accent mb-8 right" (click)="filterdiv()">
                    <mat-icon style="font-size: 20px;">filter_list</mat-icon>
                    {{filtertxt}}
                </button>
            </div>
            <div fxLayout="column" fxLayoutAlign="center">

                <div class="content">
                    <div class="table-responsive">
                        <table class="table" mat-table id="tableData" [dataSource]="dataSource" matSort
                            >

                            <ng-container matColumnDef="isSelected">
                                <th class="mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox [(ngModel)]="element.isSelected"
                                        *ngIf="CheckSendButton(element.testCode,ScreenActions.IsSendNotification)" [ngModelOptions]="{standalone: true}"
                                        (change)="onCheckboxChangeFn($event,element.seqno)"></mat-checkbox>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="dclNo">
                                <th class="mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef> Clinify No.
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <a style="pointer-events: auto;cursor:pointer;"
                                    (click)="edit_request(element.referenceNo)">{{element.referenceNo}}
                                    {{element.testCode=='3A5A1D17' ? '(RP)' :element.testCode=='3A5A1D18' ? '(P)' :element.testCode=='3A5A1D19' ? '(R)' :
                                    ''}}</a>
                                </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="requestName">
                                <th class="mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef
                                mat-sort-header="name"> Full Name </th>
                                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                            </ng-container>

                            <!-- Weight Column -->
                            <ng-container matColumnDef="mobileNo">
                                <th class="mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef> Mobile
                                    No. </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.mobileNo}}

                                </td>
                            </ng-container>

                            <!-- Symbol Column -->
                            <ng-container matColumnDef="emailAddress">
                                <th class="mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef> Email
                                    Address </th>
                                <td mat-cell *matCellDef="let element"> {{element.emailAddress}} </td>
                            </ng-container>
                            <ng-container matColumnDef="DOB">
                                <th class="mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef>DOB</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.dob}} </td>
                            </ng-container>
                            <ng-container matColumnDef="status">
                                <th class="mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef
                                    mat-sort-header="status">Status</th>
                                    <td mat-cell *matCellDef="let element" 
                                    [ngClass]="{'indigo-100-bg': element.status === 'Pending','orange-100-bg' : element.status === 'Inconclusive', 'red-100-bg' : element.status === 'Not Detected','green-100-bg' : element.status === 'Detected' }">
                                    <a style="pointer-events: auto;cursor:pointer;"  (click)="OnStatusClick(element.referenceNo)" >{{element.status}}</a> </td>
                            </ng-container>
                            <ng-container matColumnDef="requestDate">
                                <th class="mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef
                                    mat-sort-header="createdDate">Requested Date Time</th>
                                <td mat-cell *matCellDef="let element"> {{element.requestDate | date: 'MMM dd, yyyy'}} {{element.createdTime}}
                                </td>
                            </ng-container> 
                            <ng-container matColumnDef="test">
                                <th class="mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef
                                    mat-sort-header="selectedTest"> Service </th>
                                <td mat-cell *matCellDef="let element"> {{element.selectedTest}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="seqno">
                                <th class="mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef>&nbsp;
                                    Actions
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <a mat-button matTooltip="Edit" *ngIf="ScreenActions.IsEdit"
                                    [routerLink]="['/employee-edit/',element.employee_code ]"   class="button-min-width">
                                        <mat-icon class="ft-18">edit</mat-icon>
                                </a>
                                    <button mat-button matTooltip="Clone Request" (click)="clone_request(element.referenceNo)"
                                        *ngIf="ScreenActions.IsClone" class="button-min-width">
                                        <mat-icon class="ft-18">file_copy</mat-icon>
                                    </button>
                                    <button mat-button matTooltip="Print" *ngIf="ScreenActions.IsPrint"
                                        class="button-min-width"
                                        [routerLink]="['/barcode-generator/',  element.referenceNo+'$']">
                                        <mat-icon class="ft-18">print</mat-icon>
                                    </button>
                                    <!-- <a mat-button matTooltip="View Document" *ngIf="ScreenActions.Document"
                                    class="button-min-width" target="_blank" href="{{element.filepath}}" >
                                    <mat-icon class="ft-18">attach_file</mat-icon>
                                </a> -->
                                    <!-- <button mat-button matTooltip="Print" *ngIf="ScreenActions.IsPrint"
                                        class="button-min-width" (click)="OpenStatus(element.referenceNo)">
                                        <mat-icon class="ft-18">print</mat-icon>
                                    </button> -->
                                    <a
                                    mat-button
                                    matTooltip="Download pdf" 
                                    class="button-min-width"
                                    target="_blank"
                                    *ngIf="ScreenActions.Download && element.status != 'Pending'"
                                    (click)="download_pdf(element.referenceNo)"
                                >
                                    <mat-icon class="ft-18"
                                        >picture_as_pdf</mat-icon
                                    >
                                </a>
                                    <button mat-button matTooltip="Send PDF" *ngIf="ScreenActions.IsPDF"
                                        (click)="sendPDF(element.referenceNo)" class="button-min-width">
                                        <mat-icon class="ft-18">send</mat-icon>
                                    </button>
                                    <button mat-button *ngIf="ScreenActions.IsTransfer" matTooltip="Transfer"
                                        (click)="open_transfer_request(element.referenceNo)" class="button-min-width">
                                        <mat-icon class="ft-18">transfer_within_a_station</mat-icon>
                                    </button>

                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" matTooltipClass="toolTipClass"
                                matTooltip="Location : {{row.location}} &#13; &#13;Symptoms : {{row.selectedsymptons}} &#13; &#13;  Address:{{row.street}}&#13; &#13;  Ethnicity:{{row.ethnicity}}&#13; &#13;  Race:{{row.race}}">
                            </tr>


                        </table>

                    </div>

                    <div *ngIf="rows.length <= 0" align="center" class="p-12" fxflex="" ng-reflect-flex=""
                        style="flex: 1 1 0%; box-sizing: border-box;">
                        <h3>No request[s] found on assigned locations, try filtering above location list</h3>
                    </div>

                    <mat-paginator [length]="resultLength" [pageSize]="clsmodel.pageSize"
                        [pageSizeOptions]="[5,10,20,50,100]" (page)="onPageFired($event)" showFirstLastButtons
                        #paginator></mat-paginator>


                </div>

            </div>
            <!-- / CONTENT -->

        </div>

    </div>
</div>
 
<ng-template #update_request>
    <form fxLayout="column" fxFlex="1 0 auto" name="requestform" [formGroup]="requestform">
        <h1 mat-dialog-title>Request Details</h1>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" fxFlex="50" class="mr-4">
                <mat-label>First Name</mat-label>
                <input matInput formControlName="firstName" [(ngModel)]="empObject.request_name" required>
                <mat-error>
                    Name is required
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="50">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="lastName" [(ngModel)]="empObject.request_lastName" required>
                <mat-error>LastName is required!</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" fxFlex="50" class="mr-4">
                <mat-label>Mobile No</mat-label>
                <input matInput formControlName="mobileNo" [(ngModel)]="empObject.request_mobileNo" required>
                <mat-error>
                    Mobile No is required
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="50">
                <mat-label>Email Address</mat-label>
                <input matInput formControlName="emailAddress" [(ngModel)]="empObject.request_email" required>
                <mat-error>Email address is required!</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" fxFlex="33">
                <mat-label>Gender</mat-label>
                <mat-select formControlName="gender" [(ngModel)]="empObject.request_gender" required>
                    <mat-option value="M">
                        Male
                    </mat-option>
                    <mat-option value="F">
                        Female
                    </mat-option>
                    <mat-option value="U">
                        Undisclosed
                    </mat-option>

                </mat-select>
                <mat-error>Gender is required!</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="34" class="px-4">
                <mat-label>Date of Birth</mat-label>
                <input matInput [min]="minDate" placeholder="MM/DD/YYYY" [max]="maxDate" [matDatepicker]="picker"
                    [(ngModel)]="empObject.request_DOB" formControlName="DateOfBirth">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>

                <mat-error>
                    Date of Birth is required
                </mat-error>
            </mat-form-field>

        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <button mat-raised-button class="warn mr-4 " (click)="closewindow()" mat-dialog-close>
                <mat-icon>close</mat-icon>Cancel
            </button>
            <button mat-raised-button [disabled]="requestform.invalid" (click)="requestSubmit()" class="primary mr-4"
                mat-dialog-close>
                <mat-icon>save</mat-icon>Update
            </button>
            <!-- <button mat-raised-button [disabled]="requestform.invalid" (click)="requestSubmit()" class="primary mr-4"
                mat-dialog-close>
                <mat-icon>save</mat-icon>Update & Send to Ovation
            </button> -->
        </div>
    </form>

</ng-template>


<ng-template #clone_request_modal>
    <form fxLayout="column" fxFlex="1 0 auto" name="CloneRequestForm" [formGroup]="CloneRequestForm">
        <h1 mat-dialog-title>Clone Request :  {{reference_no}}</h1>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

            <mat-radio-group formControlName="cloneRadio" aria-labelledby="example-radio-group-label" class="example-radio-group"
                [(ngModel)]="CloneselectedTest">
                <mat-radio-button class="example-radio-button" [ngModelOptions]="{standalone: true}" *ngFor="let test of testlist" [value]="test.test_id">
                    {{test.test_name}}
                </mat-radio-button>
            </mat-radio-group>
        </div>      
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" fxFlex="100" class="mr-4">
                <mat-label>Remarks</mat-label>
                <input matInput formControlName="cloneremarks" [(ngModel)]="empObject.cloneremarks" required>
                <mat-error>
                    Remarks is required
                </mat-error>
            </mat-form-field>

        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <button mat-raised-button class="warn mr-4 " (click)="closepdfwindow()" mat-dialog-close>
                <mat-icon>close</mat-icon>Cancel
            </button>
            <button mat-raised-button [disabled]="CloneRequestForm.invalid" (click)="CloneRequest()" class="primary mr-4"
                 >
                <mat-icon>check</mat-icon>Confirm
            </button>

        </div>
    </form>

</ng-template>

<ng-template #transfer_request>
    <form fxLayout="column" fxFlex="1 0 auto" name="TransferForm" [formGroup]="TransferForm">
        <h1 mat-dialog-title>Transfer</h1>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" class="category-selector">

                <mat-label>From Center</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Number" matInput formControlName="fromcenter"
                    readonly  [(ngModel)]="this.empObject.FromLocation" >
              
            </mat-form-field>

        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" class="category-selector">

                <mat-label>To Center</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Number" matInput  
                    [formControl]="myControl1" [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" 
                    (optionSelected)="onToSelectionChange($event)" [displayWith]="displayFn">
                    <mat-option *ngFor="let _option of filteredOptions1 | async" [value]="_option">
                        {{_option.location_propertyName}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" fxFlex="100" class="mr-4">
                <mat-label>Reason</mat-label>
                <input matInput formControlName="remarks" [(ngModel)]="empObject.transferremarks" required>
                <mat-error>
                    Reason is required
                </mat-error>
            </mat-form-field>

        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <button mat-raised-button class="warn mr-4 " (click)="closewindow()" mat-dialog-close>
                <mat-icon>close</mat-icon>Cancel
            </button>
            <button mat-raised-button [disabled]="TransferForm.invalid" (click)="TransferSubmit()" class="primary mr-4"
                 >
                <mat-icon>save</mat-icon>Update
            </button>

        </div>
    </form>

</ng-template>

<ng-template #export_list>
    <form fxLayout="column" fxFlex="1 0 auto" name="ExportForm" [formGroup]="ExportForm">
        <h1 mat-dialog-title>Export Data</h1>       
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" fxFlex="100"  class="category-selector">

                <mat-label>Center</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Number" matInput  
                    [formControl]="myControl1" [matAutocomplete]="auto"  >
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" 
                    (optionSelected)="onToSelectionChange($event)" [displayWith]="displayFn">
                    <mat-option *ngFor="let _option of filteredOptions1 | async" [value]="_option">
                        {{_option.location_propertyName}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" fxFlex="100" >
                <mat-label>From Date</mat-label>
                <input matInput [min]="minDate" placeholder="MM/DD/YYYY" [max]="maxDate" [matDatepicker]="picker"
                    [(ngModel)]="empObject.ExportFromDate" formControlName="ExportFromDate" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>

                <mat-error>
                    From Date is required
                </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" fxFlex="100">
                <mat-label>To Date</mat-label>
                <input matInput [min]="minDate" placeholder="MM/DD/YYYY" [max]="maxDate" [matDatepicker]="picker1"
                    [(ngModel)]="empObject.ExportToDate" formControlName="ExportToDate" required>
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>

                <mat-error>
                    To Date is required
                </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">            
            <mat-list role="list">
                <mat-list-item role="listitem" *ngFor="let x of exportlist; let i = index">
                    <mat-radio-button color="primary" [checked]="i == ExportType" [value]="i" (change)="ExportType = $event.value">
                     {{x}}
                    </mat-radio-button>
                </mat-list-item>
            </mat-list>
             
            </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <button mat-raised-button class="warn mr-4 " (click)="closepdfwindow()" mat-dialog-close>
                <mat-icon>close</mat-icon>Cancel
            </button>
            <button mat-raised-button [disabled]="ExportForm.invalid" (click)="ExportData()" class="primary mb-8 right"
                 >
                <mat-icon>check</mat-icon>Export
            </button>

        </div>
    </form>

</ng-template>
<ng-template #pdf_confirmation>
    <form fxLayout="column" fxFlex="1 0 auto" name="TransferForm" [formGroup]="TransferForm"
        style="align-items: center;width: 100%;">
        <h1 class="center">Are you sure you want send result pdf now?</h1>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mar-t-30">
            <button mat-raised-button class="warn mr-4 " (click)="closepdfwindow()" mat-dialog-close>
                <mat-icon>close</mat-icon> Close
            </button>
            <button mat-raised-button (click)="sendPDFConfirmation()" class="primary mr-4" mat-dialog-close>
                <mat-icon>send</mat-icon> Send Now
            </button>

        </div>
    </form>

</ng-template>
<ng-template #results_Unsent>
    <form fxLayout="column" fxFlex="1 0 auto"  
        style="width: 100%;">
        <h1>Results unsent</h1>
        <div style="width: 100%; height: 250px; overflow-y: auto;">
            <table class="simple invoice-table">
                <thead class="accent mat-header-row bdr-radius">
                    <tr>
                        <th>Reference No</th>
                        <th>EmailAddress</th>
                        <th>Result</th>
                        <th>#</th>
                    </tr>
                </thead>
                <tbody style="background-color: #fff;">
                    <tr *ngFor="let item of ResultsUnsentList">
                        <td>{{item.referenceNo}}</td>
                        <td>{{item.emailAddress}}</td>
                        <td>{{item.status}}</td>
                        <td>
                            <a href='javascript::' (click)="ResentPDF(item.referenceNo,item.status)" >Send now</a></td>
                    </tr>

            </table>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mar-t-30">
            <button mat-raised-button class="warn mr-4 " (click)="closepdfwindow()" >
                <mat-icon>close</mat-icon> Close
            </button> 

        </div>
    </form>

</ng-template>
<div class="email_status_md {{divbackgroundClass}}" *ngIf="emailStatus_div">
    <form fxLayout="column" fxFlex="1 0 auto" name="TransferForm" [formGroup]="TransferForm"
        style="align-items: center;width: 100%;">
        <h1 class="center">Sending status....Closes automcatically when email[s] sent</h1>
        <div style="width: 90%; height: 250px; overflow-y: auto;">
            <table class="simple invoice-table">
                <thead class="accent1 mat-header-row bdr-radius">
                    <tr>
                        <th>Reference No</th>
                        <th>Status</th>
                        <th>#</th>
                    </tr>
                </thead>
                <tbody style="background-color: #fff;">
                    <tr *ngFor="let item of EmailList">
                        <td>{{item.refNo}}</td>
                        <td>{{item.Status}}</td>
                        <td><img *ngIf="item.Status=='Pending'" src="../../../../assets/images/loading.gif" /></td>
                    </tr>

            </table>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mar-t-30">
            <button mat-raised-button class="warn mr-4 " *ngIf="divbackgroundClass=='' || divbackgroundClass==undefined"
                (click)="sendEmailBackground()" mat-dialog-close>
                <mat-icon>close</mat-icon> Send in Background
            </button>
        </div>
    </form>

</div>
<ng-template #send_notification>
    <form fxLayout="column" fxFlex="1 0 auto" name="TransferForm" [formGroup]="TransferForm"
        style="align-items: center;width: 100%;">
        <h1 class="center">Are you sure?</h1>
        <p class="center red_alert">Stop - Before sending out the results please verify the Email / Name / and Result below.
        </p>
        <div fxFlex="1 0 auto" style="height: 300px;overflow-y: auto;width:100%">
            <table width="100%" class="simple invoice-table" *ngFor="let row of selectedList">
                <thead>
                    <tr class="accent mat-header-row bdr-radius">
                        <th>Inputs</th>
                        <th>Patient Information</th>
                </thead>
                <tbody>
                    <tr>
                        <td>Patient E-mail</td>
                        <td>{{row.element.emailAddress}}</td>
                    </tr>
                    <tr>
                        <td>Patient Name</td>
                        <td>{{row.element.name}}</td>
                    </tr>
                    <tr>
                        <td>Patient DOB</td>
                        <td>{{row.element.dob}}</td>
                    </tr>
                    <tr>
                        <td>Patient Sex</td>
                        <td>{{row.element.gender=='M'? 'Male': row.element.gender=='F'? 'Female':
                            row.element.gender=='O'? 'Other' : 'Undisclosed'}}</td>
                    </tr>
                    <tr>
                        <td>Covid Result</td>
                        <td [ngClass]="empObject.result=='Negative' ? 'text-warn' : 'bg-green'">{{empObject.result}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mar-t-30">
            <button mat-raised-button class="warn mr-4 " (click)="closewindow()" mat-dialog-close>
                <mat-icon>close</mat-icon> Close
            </button>
            <button mat-raised-button (click)="submitnotification()" class="primary mr-4" mat-dialog-close>
                <mat-icon>send</mat-icon> Send Now
            </button>

        </div>
    </form>

</ng-template>