import { Location } from '@angular/common';
import { Component, ViewEncapsulation, OnInit} from '@angular/core';
import { Router } from '@angular/router'; 
@Component({
    selector     : 'content',
    templateUrl  : './content.component.html',
    styleUrls    : ['./content.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContentComponent
{
    /**
     * Constructor
     */
    constructor(private route: Router, private location:Location)
    {
        const UserData = JSON.parse(localStorage.getItem("UserDetails"));
    if (UserData != undefined && UserData != null && UserData != "" && Object.entries(UserData).length != 0) {
            if(!location.path().includes("register") && !location.path().includes("forgot")) {
                this.route.navigateByUrl(location.path());
            }else{
                this.route.navigate(['dashboard']);
            }
        }
        else {
            if(!location.path().includes("register") && !location.path().includes("forgot"))
                this.route.navigate(['auth','login']);
                else
                this.route.navigateByUrl(location.path());
        }
    }
}
 
