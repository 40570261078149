<div id="dashboard-analytics" class="page-layout blank">



    <div class="content">

        <div class="left mr-lg-32">

            <div class="pb-24 font-size-18 font-weight-300">
                Test results expiry list
            </div>

            <div class="fuse-card auto-width" style="height:400px;overflow: auto ;">

                <table width="100%" class="simple invoice-table testresults">
                    <thead>
                        <tr class="mat-header-row bdr-radius">
                            <th>&nbsp;</th>
                            <th>Employee Code</th>
                            <th>Employee Name</th>
                            <th>Uploaded On</th>
                            <th>Remaining days</th>
                            <th>Actions</th>
                    </thead>
                    <tbody>
                        <tr    [ngClass]="(row.testUploaded<=0)?'mat-header-row primary':'mat-header-row'" *ngFor="let row of dashboard.testResultsList ;let i=index" >
                            <td><img class="pro_pic" src="{{row.photograph}}" /></td>
                            <td><a [routerLink]="['/employee-edit/',row.employeeCode ]"
                                    routerLinkActive="router-link-active">{{row.employeeCode}}</a></td>
                            <td>{{row.name}}<br>
                                <span class="Dept">{{row.department}}</span>
                            </td>
                            <td>{{row.doseColor}}</td>
                            <td>{{row.testUploaded}}</td>
                            <td>
                                <div class="example-button-container">
                                    <button mat-icon-button title="Send sms" (click)="registerPop(0,row.employeeCode)" ><mat-icon>sms</mat-icon></button>
                                    <button mat-icon-button title="Send email" (click)="registerPop(1,row.employeeCode)" ><mat-icon>email</mat-icon></button>
                                </div>
                            </td>

                        </tr>
                        <tr *ngIf="(dashboard!=undefined && dashboard.testResultsList!=undefined && dashboard.testResultsList.length==0)"><td colspan="5">
                            <h3  class="no-rec">No employees found <a href='javascript::' [routerLink]="['/upload-data/' ]"
                                    routerLinkActive="router-link-active">click to upload</a></h3>
                        </td></tr>
                    </tbody>
                </table>



            </div>

            <div class="pt-48 pb-24 font-size-18 font-weight-300">
                Vaccinated Employees
            </div>

            <div class="fuse-card auto-width" style="height:400px;overflow: auto ;">

                <table width="100%" class="simple invoice-table">
                    <thead>
                        <tr class="mat-header-row bdr-radius">
                            <th>&nbsp;</th>
                            <th>Employee Code</th>
                            <th>Employee Name</th>
                            <th>Vaccine</th>
                            <th>Dosage</th>
                    </thead>
                    <tbody>
                        <tr class="mat-header-row" *ngFor="let row of dashboard.vaccinatedEmployees ;let i=index">
                            <td><img class="pro_pic" src="{{row.photograph}}" /></td>
                            <td><a [routerLink]="['/employee-edit/',row.employeeCode ]"
                                    routerLinkActive="router-link-active">{{row.employeeCode}}</a></td>
                            <td>{{row.name}}<br>
                                <span class="Dept">{{row.department}}</span>
                            </td>
                            <td>{{row.vaccine}}</td>
                            <td>
                                <div class="example-button-container">
                                    <button mat-mini-fab color="{{row.doseColor}}">
                                        {{row.noOfDoses}}
                                    </button>
                                </div>
                            </td>

                        </tr>
                        <tr *ngIf="(dashboard!=undefined && dashboard.vaccinatedEmployees!=undefined && dashboard.vaccinatedEmployees.length==0)"><td colspan="5">
                            <h3  class="no-rec">No employees found <a href='javascript::' [routerLink]="['/upload-data/' ]"
                                    routerLinkActive="router-link-active">click to upload</a></h3>
                        </td></tr>
                    </tbody>
                </table>



            </div>
            <!-- / Widget 5 -->
            <div class="pt-48 pb-24 font-size-18 font-weight-300">
                Not Vaccinated Employees
            </div>

            <div class="fuse-card auto-width" style="height:400px;overflow: auto ;">

                <table width="100%" class="simple invoice-table unvaccinated">
                    <thead>
                        <tr class="mat-header-row bdr-radius">
                            <th>&nbsp;</th>
                            <th>Employee Code</th>
                            <th>Employee Name</th>
                            <th>Test Uploaded</th>
                            <th>Dosage</th>
                    </thead>
                    <tbody>
                        <tr class="mat-header-row" *ngFor="let row of dashboard.unVaccinatedEmployees ;let i=index">
                            <td><img class="pro_pic" src="{{row.photograph}}" /></td>
                            <td><a [routerLink]="['/employee-edit/',row.employeeCode ]"
                                    routerLinkActive="router-link-active">{{row.employeeCode}}</a></td>
                            <td>{{row.name}}<br>
                                <span class="Dept">{{row.department}}</span>
                            </td>
                            <td><a href=''>{{row.testUploaded}}</a></td>
                            <td>
                                <div class="example-button-container">
                                    <button mat-mini-fab color="{{row.doseColor}}">
                                        {{row.noOfDoses}}
                                    </button>
                                </div>
                            </td>

                        </tr>
                        <tr *ngIf="(dashboard!=undefined && dashboard.unVaccinatedEmployees!=undefined && dashboard.unVaccinatedEmployees.length==0)"><td colspan="5">
                            <h3  class="no-rec">No employees found <a href='javascript::' [routerLink]="['/upload-data/' ]"
                                    routerLinkActive="router-link-active">click to upload</a></h3>
                        </td></tr>
                    </tbody>
                </table>



            </div>





            <!-- Widget 5 -->
           <!--   <div class="pt-48 pb-24 font-size-18 font-weight-300">
                How many pages your users visit?
            </div>

            <div class="fuse-card auto-width">

                <div class="position-relative p-24" fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="column" fxLayoutAlign="start start">
                        <span class="h2">Visitors & Page views</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div class="py-8 px-12 border-radius-2 line-height-1 mr-8 cursor-pointer"
                            (click)="widget5SelectedDay = 'yesterday'"
                            [ngClass]="{'accent': widget5SelectedDay === 'yesterday'}">
                            Yesterday
                        </div>
                        <div class="py-8 px-12 border-radius-2 line-height-1 mr-8 cursor-pointer"
                            (click)="widget5SelectedDay = 'today'"
                            [ngClass]="{'accent': widget5SelectedDay === 'today'}">
                            Today
                        </div> 
                    </div>
                </div>

              <div class="position-relative h-368 pb-16">
                     <canvas baseChart [datasets]="widgets.widget5.datasets[widget5SelectedDay]"
                        [labels]="widgets.widget5.labels" [colors]="widgets.widget5.colors"
                        [options]="widgets.widget5.options" [chartType]="widgets.widget5.chartType">
                    </canvas> 
                </div>

            </div>-->
            <!-- / Widget 5 -->


        </div>

        <div class="right">

            <div fxLayout="row wrap" fxLayout.gt-md="column">
                <div class="mb-48" [ngClass.lt-lg]="'mr-32'" [ngClass.xs]="'mr-0'">
 
                    <div class="fuse-card">

                        <div class="p-16">
                            <div class="h1 font-weight-300">Expiry within 7 days</div>
                        </div>

                        <div class="p-16 pb-16">
                            <div class="progressbar primary"  *ngFor="let row of ExpiresList ;let i=index">
                                <h5 class="dose">{{row.title}} : ({{ExpiresList.length}})</h5>
                                <mat-progress-bar mode="determinate" value="{{row.per}}"></mat-progress-bar>
                            </div> 
                        </div>
                    </div>

                </div>
                <!-- Widget 7 -->
                <div class="mb-48" [ngClass.lt-lg]="'mr-32'" [ngClass.xs]="'mr-0'">
 
                    <div class="fuse-card">

                        <div class="p-16">
                            <div class="h1 font-weight-300">Employees by Doses</div>
                        </div>

                        <div class="p-16 pb-16">
                            <div class="progressbar warn">
                                <h5 class="dose">Dose 1 : ({{firstdosePercentage}}) %</h5>
                                <mat-progress-bar mode="determinate" value="{{firstdosePercentage}}"></mat-progress-bar>
                            </div>
                            <div class="progressbar accent">
                                <h5 class="dose">Dose 2 ({{seconddosePercentage}}) %</h5>
                                <mat-progress-bar mode="determinate" value="{{seconddosePercentage}}">
                                </mat-progress-bar>
                            </div>
                            <div class="progressbar green">
                                <h5 class="dose">Dose 3 ({{thirdosePercentage}}) %</h5>
                                <mat-progress-bar mode="determinate" value="{{thirdosePercentage}}"></mat-progress-bar>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- / Widget 7 -->

                <!-- Widget 8 -->

                <!-- WIDGET 6 -->
                <div class="mb-48" [ngClass.lt-lg]="'mr-32'" [ngClass.xs]="'mr-0'">

                    <div class="pb-24 font-size-18 font-weight-300">

                    </div>

                    <div class="fuse-card">
                        <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
                            fxFlex="100" fxFlex.gt-sm="100"> 
                                        <div class="h3 font-weight-300 ng-tns-c315-13">Department wise Employees</div>
                                      
                                <div class="h-200">
                                    <ngx-charts-pie-chart *fuseIfOnDom [scheme]="scheme"
                                        [results]="this.dashboard.departmentWises"
                                        [legend]="false" [explodeSlices]="false"
                                        [labels]="true" [doughnut]="true"
                                        [gradient]="false" (select)="onSelect($event)">
                                    </ngx-charts-pie-chart>
                                </div> 
                            



                        </fuse-widget>
                    </div>
                </div>
                <!-- / WIDGET 6 -->
<!-- NOW WIDGET --> 
<fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="p-0">

    <!-- Front -->
    <div class="fuse-widget-front">

        <div class="pl-16 pr-8 py-16" fxLayout="row" fxLayoutAlign="space-between center">

            <div class="h3">{{dateNow | date: 'EEEE, HH:mm:ss'}}</div>

            <div> 
                <mat-menu #moreMenu="matMenu">
                    <button mat-menu-item aria-label="Flip widget">
                        Details
                    </button>
                </mat-menu>
            </div>
        </div>

        <div class="p-16 pb-24" fxLayout="column" fxLayoutAlign="center center">
            <div class="h1 secondary-text">
                <span>{{dateNow | date: 'MMMM'}}</span>
            </div>

            <div class="font-size-72 line-height-88 secondary-text font-weight-400">
                {{dateNow | date: 'd'}}
            </div>

            <div class="h1 secondary-text">
                <span>{{dateNow | date: 'y'}}</span>
            </div>
        </div>

    </div>
    <!-- / Front -->

</fuse-widget> 

<!-- / NOW WIDGET -->

                <!-- / Widget 8 -->



            </div>

        </div>

    </div>

</div>


<ng-template #register_link>    

   
    <form fxLayout="column" fxFlex="1 0 auto"  name="ExportForm" [formGroup]="ExportForm">
        <h1 mat-dialog-title>Send Alerts</h1>
      
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto"> 
    <mat-form-field appearance="fill" fxFlex="100">
        <mat-label>Message</mat-label>
        <textarea matInput required cols="80" rows="4" formControlName="AlertMessage"  [(ngModel)]="empObject.AlertMessage" placeholder="Enter Message"></textarea> 
    </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto"> 
    <button mat-raised-button class="warn mr-4 " mat-dialog-close><mat-icon>close</mat-icon>Cancel</button> 
    <button mat-raised-button  (click)="sendNotification()" class="primary mr-4" ><mat-icon>send</mat-icon>Send</button> 
   </div>   
</form>

</ng-template>