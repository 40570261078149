import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { UserService } from '../login-2/user-profile.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
    selector     : 'forgot-password-2',
    templateUrl  : './forgot-password-2.component.html',
    styleUrls    : ['./forgot-password-2.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ForgotPassword2Component implements OnInit
{
    forgotPasswordForm: FormGroup;
    emailAddress:string="";
    ResultData:any=[];
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private UserService : UserService
        , private router: Router,
        private MatAlert: MatSnackBar,
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.forgotPasswordForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }
    sendPassword(){ 
        if (this.forgotPasswordForm.invalid) {
          return;
        }
        var clsmodel = {
            Username: this.emailAddress
        }
        this.UserService.ForgotPassword(clsmodel).subscribe(data => {
          this.ResultData = data;
       
          if (this.ResultData.status == 200) {
            this.MatAlert.open("Success : ",this.ResultData.message);
            setTimeout(() => {
                this.MatAlert.dismiss();
              }, 5000)
              console.log("4")
            this.router.navigateByUrl('/auth/login');
            
          }
          else {
            // this.router.navigateByUrl('');
            // this.MatAlert.open("Error :", this.ResultData.message);
            this.MatAlert.open("Error : ",this.ResultData.message);
            setTimeout(() => {
                this.MatAlert.dismiss();
              }, 5000)
          }
        }, error => //console.error(error));
        // alert("User Not Found");
        this.MatAlert.open("Internal Server Error :", "The server cannot meet the requirements "));
        setTimeout(() => {
          this.MatAlert.dismiss();
        }, 5000)
    }
}
