import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
     
            {
                id       : 'WorkPlaceTesting',
                title    : 'Batch Testing', 
                type     : 'collapsable',
                icon     : 'business',  
                children : [ 
                    {
                        id       : 'dashboards',
                        title    : 'Dashboards', 
                        type     : 'item',
                        icon     : 'dashboard', 
                        url      : '/dashboard',
                    },
                    {
                        id       : 'calendar',
                        title    : 'Members List', 
                        type     : 'item',
                        icon     : 'cloud_upload',
                        url      : '/upload-data'
                    }, 
                    {
                        id       : 'Centeremployees',
                        title    : 'Center Wise Members', 
                        type     : 'item',
                        icon     : 'supervised_user_circle',
                        url      : '/center-employees'
                    }, 
                    {
                        id       : 'EmpRequestList',
                        title    : 'Member Request List', 
                        type     : 'item',
                        icon     : 'supervised_user_circle',
                        url      : '/emp-request-list'
                    }, 
                    // {
                    //     id       : 'EmployeeList',
                    //     title    : 'Employee List', 
                    //     type     : 'item',
                    //     icon     : 'supervised_user_circle',
                    //     url      : '/employee-list'
                    // },
                    {
                        id       : 'CovidTestUploads',
                        title    : 'Covid Test Uploads', 
                        type     : 'item',
                        icon     : 'local_hospital',
                        url      : '/covid-upload'
                    } ,
                    {
                        id       : 'ClientsList',
                        title    : 'Batch List', 
                        type     : 'item',
                        icon     : 'supervisor_account',
                        url      : '/client-list'
                    } ,
                ]
                 
            },
            {
                id       : 'CenterTesting',
                title    : 'Center Testing', 
                type     : 'collapsable',
                icon     : 'location_city', 
                children : [ 
                    {
                        id       : 'RequestDashboard',
                        title    : 'Request Dashboard', 
                        type     : 'item',
                        icon     : 'dashboard',
                        url      : '/request-dashboard'
                    }, 
                    {
                        id       : 'Centers',
                        title    : 'List of Centers', 
                        type     : 'item',
                        icon     : 'local_pharmacy',
                        url      : '/centers'
                    }, 
                    {
                        id       : 'LocationQR',
                        title    : 'Location QR', 
                        type     : 'item',
                        icon     : 'center_focus_strong',
                        url      : '/location-qr'
                    }, 
                    {
                        id       : 'InsutanceList',
                        title    : 'Insurance List', 
                        type     : 'item',
                        icon     : 'file_copy',
                        url      : '/insurance-list'
                    } ,
                  
                    {
                        id       : 'SymptomsList',
                        title    : 'Sypmtoms List', 
                        type     : 'item',
                        icon     : 'face',
                        url      : '/symptoms-list'
                    } ,
                    {
                        id       : 'TestList',
                        title    : 'Test List', 
                        type     : 'item',
                        icon     : 'local_pharmacy',
                        url      : '/test-list'
                    } ,
                    {
                        id       : 'RegisteredList',
                        title    : 'Check-In List', 
                        type     : 'item',
                        icon     : 'list_alt',
                        url      : '/registered-list',
                        
                    },
                    {
                        id       : 'RequestList',
                        title    : 'Request List', 
                        type     : 'item',
                        icon     : 'list',
                        url      : '/request-list'
                    }
                ]
            },
            // {
            //     id       : 'SelfTesting',
            //     title    : 'Self Testing', 
            //     type     : 'collapsable',
            //     icon     : 'supervised_user_circle', 
            //     children : [  
            //         {
            //             id       : 'Vendors',
            //             title    : 'List of Vendors', 
            //             type     : 'item',
            //             icon     : 'store',
            //             url      : '/vendor-list'
            //         },    
            //         {
            //             id       : 'SelfTestList',
            //             title    : 'Self Test List', 
            //             type     : 'item',
            //             icon     : 'supervised_user_circle',
            //             url      : '/self-test-list'
            //         },
            //         {
            //             id       : 'VendorsWorkflow',
            //             title    : 'Vendor Workflow', 
            //             type     : 'item',
            //             icon     : 'access_time',
            //             url      : '/vendor-workflow'
            //         }
            //     ]
            // },
            {
                id       : 'Settings',
                title    : 'Settings', 
                type     : 'collapsable',
                icon     : 'settings', 
                children : [
                    {
                        id       : 'UserList',
                        title    : 'User List', 
                        type     : 'item',
                        icon     : 'supervisor_account',
                        url      : '/user-list'
                    } 
                    ,
                    {
                        id       : 'RoleList',
                        title    : 'Role List', 
                        type     : 'item',
                        icon     : 'format_list_numbered',
                        url      : '/role-list'
                    }  
                ]
            },
            {
                id       : 'Reports',
                title    : 'Reports', 
                type     : 'collapsable',
                icon     : 'list', 
                children : [
                    {
                        id       : 'TestReport',
                        title    : 'Test Reports', 
                        type     : 'item',
                        icon     : 'supervisor_account',
                        url      : '/test-report'
                    } 
                    ,
                    {
                        id       : 'VendorReport',
                        title    : 'Vendor Reports', 
                        type     : 'item',
                        icon     : 'list',
                        url      : '/vendor-report'
                    } 
                    , 
                    {
                        id       : 'AuditLogs',
                        title    : 'Audit Logs', 
                        type     : 'item',
                        icon     : 'list',
                        url      : '/audit-logs'
                    } , 
                    {
                        id       : 'BatchReport',
                        title    : 'Batch Reports', 
                        type     : 'item',
                        icon     : 'supervisor_account',
                        url      : '/batch-report'
                    } 
                    
                ]
            },
            
        
];
