<div id="academy-courses" class="page-layout simple">

    <!-- HEADER -->
    <div class="header accent p-16 p-sm-24" fxLayout="column" fxLayoutAlign="center center">
         
         

    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content p-24">

        <div fxLayout="column" fxLayoutAlign="center">

            <div class="filters" fxLayout="column" fxLayoutAlign="end center"
                 fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="end center">

                <mat-form-field appearance="fill" floatLabel="always" class="course-search">
                    <mat-label>Search for a course</mat-label>
                    <input matInput placeholder="Enter a keyword..."
                           [(ngModel)]="searchTerm"
                           (input)="filterCoursesByTerm()">
                </mat-form-field>

                <mat-form-field appearance="fill" class="category-selector">
                    <mat-label>Category</mat-label>
                    <mat-select [(ngModel)]="currentCategory"
                                (selectionChange)="filterCoursesByCategory()">
                        <mat-option [value]="'all'">
                            All
                        </mat-option>
                        <mat-option *ngFor="let category of categories" [value]="category.value">
                            {{ category.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>

            <div class="courses" fxLayout="row wrap" fxLayoutAlign="center" [@animateStagger]="{value:'50'}">

                <div class="course" *ngFor="let course of filteredCourses"  fxFlex="100" fxFlex.gt-xs="50"
                     fxFlex.gt-sm="33" [ngClass]="course.category" [@animate]="{value:'*',params:{y:'100%'}}">

                    <div class="course-content" fxLayout="column" fxFlex="1 1 auto">

                        <div class="header" fxLayout="row" fxLayoutAlign="center center"
                             [ngClass]="course.category + '-bg'">

                            <div class="category" fxFlex>
                                {{course.category}}
                            </div>

                            <div class="length" fxLayout="row" fxLayoutAlign="center center">
                                <mat-icon class="length-icon s-20">access_time</mat-icon>
                                <div class="min">{{course.length}} min</div>
                            </div>

                        </div>

                        <div class="content" fxLayout="column" fxLayoutAlign="center center" fxFlex="1 1 auto">
                            <div class="h1">{{course.title}}</div>
                            <div class="updated">Updated {{course.updated}}</div>
                        </div>

                        <div class="footer" fxLayout="row" fxLayoutAlign="center center">
                            <button mat-button color="accent"
                                    [routerLink]="'/apps/academy/courses/' + course.id + '/' + course.slug">
                                START
                            </button>
                        </div>

                    </div>

                </div>
                
                <div class="no-courses" *ngIf="filteredCourses.length === 0">
                    No courses found!
                </div>

            </div>

        </div>

    </div>
    <!-- / CONTENT -->

</div>
