<div id="file-list" class="page-layout simple">


    <div class="center">
        <div class="header accent p-24" fxLayout="row" fxLayoutAlign="space-between start">

            <div class="breadcrumb text-truncate h1" fxLayout="row" fxLayoutAlign="start center"
                [@animate]="{value:'*',params:{x:'50px'}}">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <span>Self Test List</span>
                </div>

            </div>

        </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content p-24">

        <div fxLayout="column" fxLayoutAlign="center">
            <fieldset [style.display]="showfilter ? 'block' : 'none'">
                <legend>
                    <h3>Filters</h3>
                </legend>
            <div fxLayout="row" class="filters" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
                fxLayoutAlign.gt-xs="space-between center">
                <mat-form-field appearance="fill" floatLabel="always" fxFlex="33" class="course-search">
                    <mat-label>Search</mat-label>
                    <input matInput placeholder="Enter a keyword..." (keyup)="onsearchEnter($event)"
                        [(ngModel)]="searchTerm">
                    <button mat-raised-button class="accent mb-8 right btn_search" (click)="searchlist()"
                        [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
                        <mat-icon matSuffix class="secondary-text">search</mat-icon>
                        Search
                    </button>


                </mat-form-field>
                <mat-form-field appearance="fill" fxFlex="33" class="date-picker">
                    <mat-label>Enter a date range</mat-label>
                    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                        <input matStartDate formControlName="start" placeholder="Start date">
                        <input matEndDate formControlName="end" placeholder="End date">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>

                    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date
                    </mat-error>
                    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" class="category-selector" fxFlex="33">
                    <mat-label>Status</mat-label>
                    <mat-select [(ngModel)]="empObject.reportType" (selectionChange)="generateReport()"
                        required>
                        <mat-option value="Pending">
                           Pending
                        </mat-option>
                        <mat-option value="Detected">
                            Detected
                        </mat-option>
                        <mat-option value="Not Detected">
                            Not Detected
                        </mat-option>
                        <mat-option value="Inconclusive">
                            Inconclusive
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
</fieldset> 

            <div class="filters" fxLayout="column" fxLayoutAlign="end center" fxLayout.gt-xs="row"
                fxLayoutAlign.gt-xs="end">
                <div fxLayout="row" *ngIf="selectedList.length >0">
                    <mat-form-field appearance="fill" fxFlex="100" class="result-course-search">
                        <mat-label>Result</mat-label>
                        <mat-select [(ngModel)]="empObject.result" required>
                            <mat-option value="0">
                                Select Result
                            </mat-option>
                            <mat-option value="Detected">
                                Detected
                            </mat-option>
                            <mat-option value="Not Detected">
                                Not Detected
                            </mat-option>
                            <mat-option value="Inconclusive">
                                Inconclusive
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button mat-raised-button fxFlex="100" [disabled]="empObject.result==undefined || empObject.result=='0'"
                        class="accent mb-8 right" (click)="sendNotication()"
                        [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
                        <mat-icon style="font-size: 20px;">send</mat-icon>
                        Send now
                    </button>
                </div>
                <button mat-raised-button class="primary mb-8 right" (click)="exportExcel()"
                [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
                <mat-icon style="font-size: 20px;">cloud_download</mat-icon>
                Export List
            </button>
            <button mat-raised-button class="warn mb-8 right" (click)="reloadRoute()"
                [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
                <mat-icon style="font-size: 20px;">refresh</mat-icon>
                Refresh
            </button> 
            <button mat-raised-button class="accent mb-8 right" (click)="filterdiv()">
                <mat-icon style="font-size: 20px;">filter_list</mat-icon>
                {{filtertxt}}
            </button>
            </div>
            <div fxLayout="column" fxLayoutAlign="center">

                <div class="content">
                    <div class="table-responsive">
                        <table class="table" mat-table id="tableData" [dataSource]="dataSource" matSort>
                            <ng-container matColumnDef="isSelected">
                                <th class="accent mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox [(ngModel)]="element.isSelected"
                                        *ngIf="ScreenActions.IsSendNotification" [ngModelOptions]="{standalone: true}"
                                        (change)="onCheckboxChangeFn($event,element.seqno)"></mat-checkbox>
                                </td>
                            </ng-container>
                            
                            <ng-container matColumnDef="code">
                                <th class="accent mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef> DCL No.
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.referenceNo}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="mobileNo">
                                <th class="accent mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef> Mobile No.
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.mobileNo}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="dob">
                                <th class="accent mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef> DOB
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.dob}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="fullname">
                                <th class="accent mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef
                                    mat-sort-header="name"> Full Name </th>
                                <td mat-cell *matCellDef="let element"> {{element.fullName}} </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="createdDate">
                                <th class="accent mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef
                                    mat-sort-header="name"> Created Date </th>
                                <td mat-cell *matCellDef="let element"> {{element.createdDate + ' ' +
                                    element.createdtime}} </td>
                            </ng-container>

                            <ng-container matColumnDef="status">
                                <th class="accent mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef> Status
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="{'indigo-100-bg': element.testResult === 'Pending','orange-100-bg' : element.testResult === 'Inconclusive', 'red-100-bg' : element.testResult === 'Not Detected','green-100-bg' : element.testResult === 'Detected' }">
                                    <a style="pointer-events: auto;cursor:pointer;"  (click)="OnStatusClick(element.referenceNo)" >
                                    {{element.testResult}}
</a>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="counter">
                                <th class="accent mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef> Grace Time
                                </th>
                                <td mat-cell *matCellDef="let element" [innerHtml]="GetLatestDuration(element.referenceNo)" > 
                                   
                                 
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="seqno">
                                <th class="accent mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef>&nbsp;
                                    Actions
                                </th>
                                <td mat-cell *matCellDef="let element">

                                    <a mat-button matTooltip="View Document" 
                                    *ngIf="ScreenActions.Document"
                                      class="button-min-width" target="_blank"
                                        (click)="view_document(element.referenceNo)" >
                                        <mat-icon class="ft-18">attach_file</mat-icon>
                                    </a>
                                    <a mat-button matTooltip="Download pdf" *ngIf="ScreenActions.Download && element.testResult != 'Pending'"
                                    class="button-min-width" target="_blank" (click)="download_pdf(element.referenceNo)"  >
                                    <mat-icon class="ft-18">cloud_download</mat-icon>
                                </a>
                                <button mat-button matTooltip="Send PDF" *ngIf="ScreenActions.IsPDF"
                                (click)="sendPDF(element.referenceNo)" class="button-min-width">
                                <mat-icon class="ft-18">picture_as_pdf</mat-icon>
                            </button>
                                    <!-- <button mat-button matTooltip="Print" *ngIf="ScreenActions.IsPrint"
                                        class="button-min-width" (click)="OpenStatus(element.referenceNo)">
                                        <mat-icon class="ft-18">print</mat-icon>
                                    </button> -->


                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" matTooltipClass="toolTipClass"
                                 >
                            </tr>


                        </table>

                    </div>

                    <div *ngIf="rows.length <= 0" align="center" class="p-12" fxflex="" ng-reflect-flex=""
                        style="flex: 1 1 0%; box-sizing: border-box;">
                        <h3>No record[s] found,try changing the date range filter</h3>
                    </div>

                    <mat-paginator [length]="resultLength" [pageSize]="clsmodel.pageSize"
                        [pageSizeOptions]="[5,10,20,50,100]" (page)="onPageFired($event)" showFirstLastButtons
                        #paginator></mat-paginator>


                </div>

            </div>
            <!-- / CONTENT -->

        </div>

    </div>
</div>

<ng-template #view_Msg>
    <form fxLayout="column" fxFlex="1 0 auto" name="TransferForm" [formGroup]="TransferForm"
        style="align-items: center;width: 100%;">
        <h1>Info : </h1>
        <table class="simple table" id="tblInfo" width='100%' >
            <tr>
                <td class="accent mat-header-row bdr-radius">Type:</td>
                <td>{{MsgResult.actionType}}</td>
            </tr>
            <tr>
                <td class="accent mat-header-row bdr-radius">Status:</td>
                <td>{{MsgResult.msgType}}</td>
            </tr>
            <tr>
                <td class="accent mat-header-row bdr-radius">Body:</td>
                <td>
                    <div [innerHtml]="MsgResult.result" id="_divL" style="height:300px;overflow:auto;"></div>
                </td>
            </tr>
        </table>


        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mar-t-30">
            <button mat-raised-button class="warn mr-4 " (click)="closepdfwindow()" mat-dialog-close>
                <mat-icon>close</mat-icon> Close
            </button>
        </div>
    </form>

</ng-template>
<div class="email_status_md {{divbackgroundClass}}" *ngIf="emailStatus_div">
    <form fxLayout="column" fxFlex="1 0 auto" name="TransferForm" [formGroup]="TransferForm"
        style="align-items: center;width: 100%;">
        <h1 class="center">Sending status....Closes automcatically when email[s] sent</h1>
        <div   class="title dis-cen"> <mat-icon
            (click)="closeemailsendingdiv()" class="close_history">cancel</mat-icon>
    </div>
        <div style="width: 90%; height: 250px; overflow-y: auto;">
            <table class="simple invoice-table">
                <thead class="accent mat-header-row bdr-radius">
                    <tr>
                        <th>Reference No</th>
                        <th>Status</th>
                        <th>#</th>
                    </tr>
                </thead>
                <tbody style="background-color: #fff;">
                    <tr *ngFor="let item of EmailList">
                        <td>{{item.refNo}}</td>
                        <td>{{item.Status}}</td>
                        <td><img *ngIf="item.Status=='Pending'" src="../../../../assets/images/loading.gif" /></td>
                    </tr>

            </table>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mar-t-30">
            <button mat-raised-button class="warn mr-4 " *ngIf="divbackgroundClass=='' || divbackgroundClass==undefined"
                (click)="sendEmailBackground()" mat-dialog-close>
                <mat-icon>close</mat-icon> Send in Background
            </button>
        </div>
    </form>

</div>
<ng-template #send_notification>
    <form fxLayout="column" fxFlex="1 0 auto" name="TransferForm" [formGroup]="TransferForm"
        style="align-items: center;width: 100%;">
        <h1 class="center">Are you sure?</h1>
        <p class="center red_alert">Stop - Before sending out the results please verify the Email / Name / and Result below.
        </p>
        <div fxFlex="1 0 auto" style="height: 300px;overflow-y: auto;width:100%">
            <table width="100%" class="simple invoice-table" *ngFor="let row of selectedList">
                <thead>
                    <tr class="accent mat-header-row bdr-radius">
                        <th>Inputs</th>
                        <th>Patient Information</th>
                </thead>
                <tbody>
                    <tr>
                        <td>Patient E-mail</td>
                        <td>{{row.element.emailAddress}}</td>
                    </tr>
                    <tr>
                        <td>Patient Name</td>
                        <td>{{row.element.fullName}}</td>
                    </tr>  
                       <tr>
                        <td>Patient DOB</td>
                        <td>{{row.element.dob}}</td>
                    </tr>
                    <tr>
                        <td>Covid Result</td>
                        <td [ngClass]="empObject.result=='Not Detected' ? 'text-warn' : empObject.result=='Detected' ? 'bg-green' : 'orange-100-bg'">{{empObject.result}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mar-t-30">
            <button mat-raised-button class="warn mr-4 " (click)="closewindow()" mat-dialog-close>
                <mat-icon>close</mat-icon> Close
            </button>
            <button mat-raised-button (click)="submitnotification()" class="primary mr-4" mat-dialog-close>
                <mat-icon>send</mat-icon> Send Now
            </button>

        </div>
    </form>

</ng-template>
<ng-template #pdf_confirmation>
    <form fxLayout="column" fxFlex="1 0 auto" name="TransferForm" [formGroup]="TransferForm"
        style="align-items: center;width: 100%;">
        <h1 class="center">Are you sure you want send result pdf now?</h1>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" class="mar-t-30">
            <button mat-raised-button class="warn mr-4 " (click)="closepdfwindow()" mat-dialog-close>
                <mat-icon>close</mat-icon> Close
            </button>
            <button mat-raised-button (click)="sendPDFConfirmation()" class="primary mr-4" mat-dialog-close>
                <mat-icon>send</mat-icon> Send Now
            </button>

        </div>
    </form>

</ng-template>