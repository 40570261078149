<div id="register" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">
    <div id="register-intro" fxFlex fxHide fxShow.gt-xs>
        <div class="logo" [@animate]="{ value: '*', params: { scale: '0.2' } }">
            <img src="assets/images/logos/logo.png" />
        </div>

        <div
            class="title"
            [@animate]="{ value: '*', params: { delay: '50ms', y: '25px' } }"
        >
            Welcome to the Center for COVID Control
        </div>

        <div
            class="description"
            [@animate]="{ value: '*', params: { delay: '100ms', y: '25px' } }"
        >
            Center for COVID Control is a distinctive organization applying the
            highest level of service in the fight against SARS – coV-2
            (Covid-19) by offering various forms of testing.
        </div>
    </div>

    <div
        id="register-form-wrapper"
        fusePerfectScrollbar
        [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }"
    >
        <div id="register-form" *ngIf="viewHide == 0">
            <div class="logo1">
                <img src="assets/images/logos/logo.png" />
            </div>

            <div class="title">Terms & Conditions</div>
            <p>
                I verify that all data and information provided by me on this
                app is accurate and entered correctly.<br /><br />

                I acknowledge and give consent to Center for Covid Control to
                share test result information, vaccination status, and detailed
                vaccine information about me to their subcontractors and my
                employer.

                <br />
                <br />
                I acknowledge and give consent to Center for Covid Control to
                keep data about me including but not limited to; test result
                information, vaccination status, and detailed vaccine
                information on record for as long as they deem appropriate in
                accordance with applicable law.
            </p>
            <form name="registerForm" [formGroup]="frmValidate" novalidate>
                <button
                    mat-raised-button
                    color="accent"
                    class="submit-button"
                    aria-label="Sign Up"
                    [disabled]=""
                    (click)="showNextView(1, 0)"
                >
                    Agree & Continue
                </button>
            </form>
        </div>
        <div id="register-form" *ngIf="viewHide == 1">
            <div class="title">You are ready to go!</div>

            <form name="registerForm" [formGroup]="frmValidate" novalidate>
                <img
                    src="assets/images/self-test/img-1.svg"
                    class="s-img mr-30"
                />
                <p>
                    We promise, it's easy. Just follow these simple steps to
                    administer your Antigen Self-Test see your results quickly.
                </p>
                <button
                    mat-raised-button
                    color="accent"
                    class="submit-button mr-t-30"
                    aria-label="Sign Up"
                    [disabled]=""
                    (click)="showNextView(2, 0)"
                >
                    Next
                </button>
            </form>
        </div>
        <div id="register-form" *ngIf="viewHide == 2">
            <a
                class="back_button"
                href="javacript::"
                aria-label="Sign Up"
                (click)="showNextView(1, 1)"
            >
                <mat-icon class="back-icon">arrow_back</mat-icon> BACK
            </a>
            <div class="title">Step 1</div>

            <form name="registerForm" [formGroup]="frmValidate" novalidate>
                <img
                    src="assets/images/self-test/img-2.svg"
                    class="s-img mr-30"
                />
                <p>
                    Wash your hands thoroughly for at least 20 seconds before
                    the test.
                </p>
                <button
                    mat-raised-button
                    color="accent"
                    class="submit-button mr-t-30"
                    aria-label="Sign Up"
                    [disabled]=""
                    (click)="showNextView(3, 0)"
                >
                    Next
                </button>
            </form>
        </div>
        <div id="register-form" *ngIf="viewHide == 3">
            <a
                class="back_button"
                href="javacript::"
                aria-label="Sign Up"
                (click)="showNextView(2, 1)"
            >
                <mat-icon class="back-icon">arrow_back</mat-icon> BACK
            </a>
            <div class="title">Step 2</div>

            <form name="registerForm" [formGroup]="frmValidate" novalidate>
                <img
                    src="assets/images/self-test/img-3.svg"
                    class="s-img mr-30"
                />
                <p>
                    Gently insert the swab about 3/4 of an inch into the LEFT
                    nostril. Then, slowly rotate the swab at least 5 times in a
                    circular path for a total of 15 seconds.
                </p>
                <button
                    mat-raised-button
                    color="accent"
                    class="submit-button mr-t-30"
                    aria-label="Sign Up"
                    [disabled]=""
                    (click)="showNextView(4, 0)"
                >
                    Next
                </button>
            </form>
        </div>
        <div id="register-form" *ngIf="viewHide == 4">
            <a
                class="back_button"
                href="javacript::"
                aria-label="Sign Up"
                (click)="showNextView(3, 1)"
            >
                <mat-icon class="back-icon">arrow_back</mat-icon> BACK
            </a>
            <div class="title">Step 2</div>

            <form name="registerForm" [formGroup]="frmValidate" novalidate>
                <flx-circle-timer
                    #timer
                    color="#039be5"
                    [startDate]="startDate"
                    (onComplete)="onTimerComplete()"
                    [duration]="duration"
                ></flx-circle-timer>
                <div class="controls">
                    <button
                        mat-flat-button
                        color="accent"
                        type="button"
                        [disabled]="timer.isTicking()"
                        class="mr-5"
                        (click)="timer.start()"
                    >
                        Start
                    </button>
                    <button
                        mat-flat-button
                        color="warn"
                        type="button"
                        (click)="timer.init()"
                    >
                        Reset
                    </button>
                </div>
                <p>
                    Gently insert the swab about 3/4 of an inch into the LEFT
                    nostril. Then, slowly rotate the swab at least 5 times in a
                    circular path for a total of 15 seconds.
                </p>
                <button
                    mat-raised-button
                    color="accent"
                    class="submit-button mr-t-30"
                    aria-label="Sign Up"
                    [disabled]="this.empObject.IsStep3Completed == 0"
                    (click)="showNextView(5, 0)"
                >
                    Next
                </button>
            </form>
        </div>
        <div id="register-form" *ngIf="viewHide == 5">
            <a
                class="back_button"
                href="javacript::"
                aria-label="Sign Up"
                (click)="showNextView(4, 1)"
            >
                <mat-icon class="back-icon">arrow_back</mat-icon> BACK
            </a>
            <div class="title">Step 3</div>

            <form name="registerForm" [formGroup]="frmValidate" novalidate>
                <img
                    src="assets/images/self-test/img-8.svg"
                    class="s-img mr-30 refl"
                />
                <p>
                    Gently insert the swab about 3/4 of an inch into the RIGHT
                    nostril. Then, slowly rotate the swab at least 5 times in a
                    circular path for a total of 15 seconds.
                </p>
                <button
                    mat-raised-button
                    color="accent"
                    class="submit-button mr-t-30"
                    aria-label="Sign Up"
                    [disabled]=""
                    (click)="showNextView(6, 0)"
                >
                    Next
                </button>
            </form>
        </div>
        <div id="register-form" *ngIf="viewHide == 6">
            <a
                class="back_button"
                href="javacript::"
                aria-label="Sign Up"
                (click)="showNextView(5, 1)"
            >
                <mat-icon class="back-icon">arrow_back</mat-icon> BACK
            </a>
            <div class="title">Step 4</div>

            <form name="registerForm" [formGroup]="frmValidate" novalidate>
                <flx-circle-timer
                    #timer
                    color="#039be5"
                    (onComplete)="onTimer1Complete()"
                    [startDate]="startDate"
                    [duration]="duration"
                ></flx-circle-timer>
                <div class="controls">
                    <button
                        mat-flat-button
                        color="accent"
                        type="button"
                        [disabled]="timer.isTicking()"
                        class="mr-5"
                        (click)="timer.start()"
                    >
                        Start
                    </button>
                    <button
                        mat-flat-button
                        color="warn"
                        type="button"
                        (click)="timer.init()"
                    >
                        Reset
                    </button>
                </div>
                <p>
                    Gently insert the swab about 3/4 of an inch into the RIGHT
                    nostril. Then, slowly rotate the swab at least 5 times in a
                    circular path for a total of 15 seconds.
                </p>
                <button
                    mat-raised-button
                    color="accent"
                    class="submit-button mr-t-30"
                    aria-label="Sign Up"
                    [disabled]="this.empObject.IsStep6Completed == 0"
                    (click)="showNextView(7, 0)"
                >
                    Next
                </button>
            </form>
        </div>
        <div id="register-form" *ngIf="viewHide == 7">
            <a
                class="back_button"
                href="javacript::"
                aria-label="Sign Up"
                (click)="showNextView(6, 1)"
            >
                <mat-icon class="back-icon">arrow_back</mat-icon> BACK
            </a>
            <div class="title">Step 4</div>

            <form name="registerForm" [formGroup]="frmValidate" novalidate>
                <img
                    src="assets/images/self-test/img-4.svg"
                    class="s-img mr-30"
                />
                <p>
                    Place the swab into the extraction vial. Rotate the swab
                    vigorously at least 5 times.
                </p>
                <button
                    mat-raised-button
                    color="accent"
                    class="submit-button mr-t-30"
                    aria-label="Sign Up"
                    [disabled]=""
                    (click)="showNextView(8, 0)"
                >
                    Next
                </button>
            </form>
        </div>
        <div id="register-form" *ngIf="viewHide == 8">
            <a
                class="back_button"
                href="javacript::"
                aria-label="Sign Up"
                (click)="showNextView(7, 1)"
            >
                <mat-icon class="back-icon">arrow_back</mat-icon> BACK
            </a>
            <div class="title">Step 5</div>

            <form name="registerForm" [formGroup]="frmValidate" novalidate>
                <img
                    src="assets/images/self-test/img-5.svg"
                    class="s-img mr-30"
                />
                <p>Close the vial by pushing the cap firmly onto the vial.</p>
                <button
                    mat-raised-button
                    color="accent"
                    class="submit-button mr-t-30"
                    aria-label="Sign Up"
                    [disabled]=""
                    (click)="showNextView(9, 0)"
                >
                    Next
                </button>
            </form>
        </div>
        <div id="register-form" *ngIf="viewHide == 9">
            <a
                class="back_button"
                href="javacript::"
                aria-label="Sign Up"
                (click)="showNextView(8, 1)"
            >
                <mat-icon class="back-icon">arrow_back</mat-icon> BACK
            </a>
            <div class="title">Step 6</div>

            <form name="registerForm" [formGroup]="frmValidate" novalidate>
                <img
                    src="assets/images/self-test/img-6.svg"
                    class="s-img mr-30"
                />
                <p>
                    With your finger, mix thoroughly by flicking the bottom of
                    the vial. Then remove the Cap.
                </p>
                <button
                    mat-raised-button
                    color="accent"
                    class="submit-button mr-t-30"
                    aria-label="Sign Up"
                    [disabled]=""
                    (click)="showNextView(10, 0)"
                >
                    Next
                </button>
            </form>
        </div>
        <div id="register-form" *ngIf="viewHide == 10">
            <a
                class="back_button"
                href="javacript::"
                aria-label="Sign Up"
                (click)="showNextView(9, 1)"
            >
                <mat-icon class="back-icon">arrow_back</mat-icon> BACK
            </a>
            <div class="title">Step 7</div>

            <form name="registerForm" [formGroup]="frmValidate" novalidate>
                <img
                    src="assets/images/self-test/img-7.svg"
                    class="s-img mr-30"
                />
                <p>
                    Invert the extraction vial and hold the sample vertically
                    above the sample well. Squeeze the vial gently. Allow THREE
                    (3) drops of sample to fall into the sample well.
                </p>
                <button
                    mat-raised-button
                    color="accent"
                    class="submit-button mr-t-30"
                    aria-label="Sign Up"
                    [disabled]=""
                    (click)="showNextView(11, 0)"
                >
                    Next
                </button>
            </form>
        </div>
        <div id="register-form" *ngIf="viewHide == 11">
            <a
                class="back_button"
                href="javacript::"
                aria-label="Sign Up"
                (click)="showNextView(10, 1)"
            >
                <mat-icon class="back-icon">arrow_back</mat-icon> BACK
            </a>
            <div class="title">The test is doing its job!</div>

            <form name="registerForm" [formGroup]="frmValidate" novalidate>
                <flx-circle-timer
                    #timer
                    color="#039be5"
                    (onComplete)="onTimer2Complete()"
                    [startDate]="startDate"
                    [duration]="duration"
                ></flx-circle-timer>
                <div class="controls">
                    <button
                        mat-flat-button
                        color="accent"
                        type="button"
                        [disabled]="timer.isTicking()"
                        class="mr-5"
                        (click)="timer.start()"
                    >
                        Start
                    </button>
                    <button
                        mat-flat-button
                        color="warn"
                        type="button"
                        (click)="timer.init()"
                    >
                        Reset
                    </button>
                </div>
                <p>
                    When the timer completes, you will need to photograph your
                    test cassete within 5 minutes to interpret the result.
                </p>
                <button
                    mat-raised-button
                    color="accent"
                    class="submit-button mr-t-30"
                    aria-label="Sign Up"
                    [disabled]="this.empObject.IsStep11Completed == 0"
                    (click)="showNextView(12, 0)"
                >
                    Next
                </button>
            </form>
        </div>
        <div id="register-form" *ngIf="viewHide == 12">
            <a
                class="back_button"
                href="javacript::"
                aria-label="Sign Up"
                (click)="showNextView(11, 1)"
            >
                <mat-icon class="back-icon">arrow_back</mat-icon> BACK
            </a>
            <div class="title">Final Stage</div>

            <form name="frmUpload" [formGroup]="frmUpload" novalidate>
                <div class="tel-input">
                    <fieldset>
                        <legend>Contact Number</legend>
                        <ngx-intl-tel-input
                            matinput
                            [cssClass]="'custom'"
                            [(ngModel)]="empObject.PhoneInput"
                            [preferredCountries]="preferredCountries"
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="true"
                            [searchCountryFlag]="true"
                            [searchCountryField]="[
                                SearchCountryField.Iso2,
                                SearchCountryField.Name
                            ]"
                            [selectFirstCountry]="false"
                            [selectedCountryISO]="CountryISO.UnitedStates"
                            [maxLength]="10"
                            [phoneValidation]="true"
                            [separateDialCode]="true"
                            [numberFormat]="PhoneNumberFormat.National"
                            name="phone"
                            formControlName="Cnumber"
                            required
                        >
                        </ngx-intl-tel-input>
                    </fieldset>
                </div>
                <mat-form-field appearance="fill" class="width-300">
                    <mat-label>Email Address</mat-label>
                    <input
                        matInput
                        formControlName="VEmail"
                        required
                        type="text"
                        [(ngModel)]="empObject.EmailAddress"
                    />
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error> Email Address is required </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" class="width-300">
                    <mat-label>Full Name</mat-label>
                    <input
                        matInput
                        formControlName="fullname"
                        required
                        type="text"
                        [(ngModel)]="empObject.FullName"
                    />
                    <mat-icon matSuffix class="secondary-text">user</mat-icon>
                    <mat-error> Full Name is required </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" class="width-300">
                    <mat-label>DOB</mat-label>
                    <input
                        matInput
                        [min]="minDate"
                        required
                        placeholder="MM/DD/YYYY"
                        [max]="maxDate"
                        [matDatepicker]="picker"
                        [(ngModel)]="empObject.DOB"
                        formControlName="DateOfBirth"
                    />
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error> DOB is required </mat-error>
                </mat-form-field>
                <div class="file-uploader">
                    <input
                        hidden
                        type="file"
                        formControlName="document"
                        #fileInput
                        accept="image/*"
                        (change)="onFileChanged($event)"
                    />
                    <button
                        mat-raised-button
                        class="add-file-button"
                        (click)="fileInput.click()"
                        aria-label="UPLOAD FILE"
                        [@animate]="{
                            value: '*',
                            params: { delay: '300ms', scale: '0.2' }
                        }"
                    >
                        <mat-icon>cloud_upload</mat-icon>
                        UPLOAD FILE <br />
                        (only images allowed upto 12 MB)
                    </button>
                    <span *ngIf="selectedFileName">{{ selectedFileName }}</span>

                    <div class="progress" *ngIf="progress">
                        <div
                            class="progress-bar"
                            [style.width]="progress + '%'"
                        >
                            {{ progress }}%
                        </div>
                    </div>
                </div>
                <div class="image-preview">
                    <img
                        class="preview-image"
                        src="{{ this.empObject.imagePath }}"
                        [@animate]="{
                            value: '*',
                            params: { delay: '50ms', scale: '0.2' }
                        }"
                    />
                </div>
                <div class="fix-btn">
                    <button
                        mat-raised-button
                        color="accent"
                        class="submit-button mr-t-30"
                        aria-label="Sign Up"
                        (click)="employerSubmit()"
                    >
                        SUBMIT
                    </button>
                </div>
            </form>
        </div>
        <div id="register-form" *ngIf="viewHide == 13">
            <!-- <a    class="back_button" href="javacript::" aria-label="Sign Up"
            (click)="showNextView(9,1)">
            <mat-icon class="back-icon">arrow_back</mat-icon> BACK
            </a> 
            <div class="title">Step 7</div>-->
            <div class="test-img">
                <img src="assets/images/self-test/waiting.gif" />
            </div>
            <form name="registerForm" [formGroup]="frmValidate" novalidate>
                <p class="final_test">
                    Please note reference number for future use.
                </p>
                <p class="ft-16 final_test">
                    Reference No : <label>{{ referenceNo }}</label>
                </p>
                <p class="font-normal">
                    Please wait we are parsing the uploaded image, automatically
                    will be redirecting to the result page in
                </p>

                <span class="final_timer">{{ display }}</span>

                <button
                    mat-raised-button
                    color="accent"
                    class="submit-button mr-t-30"
                    aria-label="Sign Up"
                    [disabled]="IsResultGenerated == 0"
                >
                    Download pdf
                </button>
            </form>
        </div>
    </div>
</div>
