import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { FuseSharedModule } from '@fuse/shared.module';
import { Register3Component } from 'app/main/pages/authentication/register-3/register-3.component';
import { Register2Component } from 'app/main/pages/authentication/register-2/register-2.component';
import { PresignedUrlComponent } from '../register-4/register-4.component';
const routes = [
    {
        path     : 'auth/register-2',
        component: Register2Component
    },
    {
        path     : 'auth/register-4/:id',
        component: PresignedUrlComponent
    } ,
    {
        path     : 'auth/register-3/:id',
        component: Register3Component
    }
];

@NgModule({
    declarations: [
        Register2Component,
        Register3Component,
        PresignedUrlComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
          
    ]
})
export class Register2Module
{
}
