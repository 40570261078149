<div id="academy-courses" class="page-layout simple">
    <div class="center">
        <div class="header accent p-24" fxLayout="row" fxLayoutAlign="space-between start">

            <div class="breadcrumb text-truncate h1" fxLayout="row" fxLayoutAlign="start center"
                [@animate]="{value:'*',params:{x:'50px'}}">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <span>Vendor List</span>
                </div>

            </div>

        </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="center">
        <div class="content p-24">
            <div class="filters" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
                fxLayoutAlign.gt-xs="space-between center">

                <mat-form-field appearance="fill" floatLabel="always" class="course-search">
                    <mat-label>Search for a center</mat-label>
                    <input matInput placeholder="Enter a keyword..." [(ngModel)]="searchTerm"
                        (input)="filterCoursesByTerm()">
                </mat-form-field>

                <mat-form-field appearance="fill" class="category-selector">
                    <mat-label>Users</mat-label>
                    <mat-select [(ngModel)]="currentCategory" (selectionChange)="filterCoursesByCategory($event)">
                        <mat-option [value]="'all'">
                            All
                        </mat-option>
                        <mat-option *ngFor="let row of usersdata" [value]="row.users_userid">
                            {{ row.users_Username}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
            <div class="filters" fxLayout="column" fxLayoutAlign="end center" fxLayout.gt-xs="row"
                fxLayoutAlign.gt-xs="end">
                <div class="captions">

                    <span class="dosespan mat-raised-button">Total Active - &nbsp;<label
                            class="text-green">{{CntList.ActiveList}}</label></span>

                    <span class="dosespan mat-raised-button">Total InActive - &nbsp;<label
                            class="text-warn">{{CntList.InActiveList}}</label> </span>

                    <span class="dosespan mat-raised-button">Total Vendors - &nbsp;<label
                            class="text-accent">{{CntList.TotalClients}}</label> </span>
                </div>
                <button mat-raised-button class="primary right mar-left-5 button1" (click)="addVendor()"
                    [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
                    <mat-icon style="font-size: 20px;">store</mat-icon>
                    Add Vendor
                </button>
            </div>
            <div class="courses" fxLayout="row wrap" fxLayoutAlign="center">

                <div class="course" *ngFor="let row of rows" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33"
                    fxFlex.gt-md="25">

                    <div class="course-content" fxLayout="column" fxFlex="1 1 auto">

                        <div class="header" fxLayout="row" fxLayoutAlign="center center"
                            [ngClass]="row.location_className">

                            <div class="category" fxFlex>
                                {{row.vendor_name}}
                            </div>
                        </div>

                        <div class="content" fxLayout="column" fxLayoutAlign="start" fxFlex="1 1 auto">
                            <div class="h1"></div>
                            <div class="updated">
                                <mat-icon class="length-icon s-20">location_city</mat-icon>{{row.vendor_code}}
                            </div>
                            <div class="updated">
                                <mat-icon class="length-icon s-20">mobile_friendly</mat-icon> {{row.vendor_contactNo}}
                            </div>
                            <div class="updated">
                                <mat-icon class="length-icon s-20">mail</mat-icon> {{row.vendor_emailAddress}}
                            </div>
                        </div>


                        <div class="footer" fxLayout="row" fxLayoutAlign="center center">

                            <button class="nr-wid" mat-button matTooltip="Edit" matTooltipClass="tooltip-blue"
                                (click)="edit_user(row.vendor_code)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button class="nr-wid" *ngIf="(!row.vendor_isActive)"
                                (click)="invitationPop(row.vendor_code,1)" mat-button matTooltip="Active"
                                matTooltipClass="tooltip-blue">
                                <mat-icon class="green1">brightness_1</mat-icon>
                            </button>
                            <button class="nr-wid" *ngIf="(row.vendor_isActive)" mat-button matTooltip="Delete"
                                matTooltipClass="tooltip-blue" (click)="invitationPop(row.vendor_code,0)">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button class="nr-wid" mat-button matTooltip="preferences"
                                (click)="assignuser(row.vendor_code)" matTooltipClass="tooltip-blue">
                                <mat-icon>settings</mat-icon>
                            </button>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>

    <ng-template #confirmpopup>
        <div class="mb-24" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="column">
            <h2>Confirm</h2>
            <h1>
                Are you sure you want to {{modalTitle}}
            </h1>

        </div>
        <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

            <button mat-raised-button color="secondary" class="mr-8 warn" mat-dialog-close (click)="closewindow()"
                aria-label="No">
                Cancel
            </button>

            <button mat-raised-button color="accent" class="save-button accent" (click)="ApproveClient()">
                Approve

            </button>

        </div>
    </ng-template>

    <ng-template #assignuserpop>
        <div class="content">
           
            <div id="register-form" *ngIf="viewHide==1">
                <!--Testing Symptons for only Retest -->

                <div class="title">Select Preferrences</div>
                <form fxLayout="column" name="preferenceForm" [formGroup]="preferenceForm" novalidate>
                    <mat-carousel timings="250ms ease-in" [autoplay]="false" interval="5000" color="accent"
                        maxWidth="auto" proportion="25" slides="20" [loop]="true" [hideArrows]="false"
                        [hideIndicators]="false" [useKeyboard]="false" [useMouseWheel]="false" orientation="ltr">
                        <mat-carousel-slide *ngFor="let item of listpref" #matCarouselSlide overlayColor="#00000040"
                            [hideOverlay]="false">
                            <img class="img-prev" src="{{this.empObject.imagePath || item.vendorpref_imagePath}}"/>
                            <div class="file-uploader">
                                <input hidden type="file" formControlName="document" #fileInput accept="image/*"
                                    (change)="onFileChanged($event)" />
                                <button mat-raised-button class="add-file-button center" (click)="fileInput.click()"
                                    aria-label="UPLOAD FILE"
                                    [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
                                    <mat-icon>cloud_upload</mat-icon>
                                    UPLOAD FILE <br />
                                    <span style="font-size: 8px;">(only images allowed upto 12 MB)</span>
                                </button>
                                <span *ngIf="selectedFileName">{{selectedFileName}}</span>

                                <div class="progress" *ngIf="progress">
                                    <div class="progress-bar" [style.width]="progress + '%'">{{progress}}%</div>
                                </div>
                            </div>

                            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                                <mat-form-field class="mr-4" appearance="fill" fxFlex="100">
                                    <mat-label>Description</mat-label>
                                    <textarea matInput placeholder="" formControlName="Description"
                                        [(ngModel)]="item.vendorpref_desc"></textarea>
                                    <mat-error>

                                    </mat-error>
                                </mat-form-field>

                            </div>
                        </mat-carousel-slide>
                        
                    
                    </mat-carousel>
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                        <button mat-raised-button class="warn mr-4 " (click)="closewindow()" mat-dialog-close>
                            <mat-icon>close</mat-icon>Cancel
                        </button>
                        <button mat-raised-button (click)="vendorSubmit()"
                            class="primary mr-4">
                            <mat-icon>save</mat-icon>{{btnText}}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </ng-template>

    <ng-template #add_Vendor>


        <form fxLayout="column" fxFlex="1 0 auto" name="vendorDetails" [formGroup]="vendorDetails">
            <h1 mat-dialog-title>Vendor Details</h1>

            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                <mat-form-field appearance="fill" fxFlex="50" class="mr-4">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name" [(ngModel)]="empObject.name" required>
                    <mat-error>
                        Name is required
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" fxFlex="50">
                    <mat-label>Mobile Number</mat-label>
                    <input matInput formControlName="contactno" [(ngModel)]="empObject.contactno" maxlength="10"
                        (keydown)="validateNumber($event)" required>

                    <mat-error>
                        Mobile Number is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <mat-form-field appearance="fill" fxFlex="50" class="mr-4">
                    <mat-label>Email Address</mat-label>
                    <input matInput formControlName="emailAddress" type="email" [(ngModel)]="empObject.emailAddress"
                        required>
                    <mat-error>
                        Email is required
                    </mat-error>
                    <mat-error>
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" fxFlex="50">
                    <mat-label>Website</mat-label>
                    <input matInput formControlName="website" [(ngModel)]="empObject.website">
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <mat-form-field appearance="fill" fxFlex="50" class="mr-4">
                    <mat-label>Manufacturer</mat-label>
                    <input matInput formControlName="manufacturer" [(ngModel)]="empObject.manufacturer">
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <mat-form-field class="mr-4" appearance="fill" fxFlex="100">
                    <mat-label>Address</mat-label>
                    <textarea matInput placeholder="" formControlName="address" [(ngModel)]="empObject.address"
                        required></textarea>
                    <mat-error>
                        Address is required
                    </mat-error>
                </mat-form-field>

            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <button mat-raised-button class="warn mr-4 " (click)="closewindow()" mat-dialog-close>
                    <mat-icon>close</mat-icon>Cancel
                </button>
                <button mat-raised-button [disabled]="vendorDetails.invalid" (click)="vendorSubmit()"
                    class="primary mr-4">
                    <mat-icon>save</mat-icon>{{btnText}}
                </button>
            </div>
        </form>

    </ng-template>