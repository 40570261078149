<div id="file-list" class="page-layout simple">
    <div class="center">
        <div
            class="header accent p-24"
            fxLayout="row"
            fxLayoutAlign="space-between start"
        >
            <div
                class="breadcrumb text-truncate h1"
                fxLayout="row"
                fxLayoutAlign="start center"
                [@animate]="{ value: '*', params: { x: '50px' } }"
            >
                <div fxLayout="row" fxLayoutAlign="start center">
                    <span>Symptoms List</span>
                </div>
            </div>
        </div>
    </div>
    <div class="content p-24">
        <div
            class="filters p-15"
            fxLayout="column"
            fxLayoutAlign="end center"
            fxLayout.gt-xs="row"
            fxLayoutAlign.gt-xs="end"
        >
            <button
                mat-raised-button
                class="primary right mar-left-5 button1"
                (click)="addSymptoms()"
                [@animate]="{
                    value: '*',
                    params: { delay: '300ms', scale: '0.2' }
                }"
            >
                <mat-icon style="font-size: 20px">supervisor_account</mat-icon>
                Add Symptoms
            </button>
        </div>
        <div fxLayout="column" fxLayoutAlign="center">
            <div id="about" fxLayout="row wrap">
                <div
                    class="about-content"
                    fxLayout="column"
                    fxFlex="100"
                    fxFlex.gt-sm="50"
                    fxFlex.gt-md="100"
                >
                    <div class="profile-box info-box general" fxLayout="column">
                        <div class="content">
                            <table width="100%" class="simple invoice-table">
                                <thead>
                                    <tr
                                        class="accent1 mat-header-row bdr-radius"
                                    >
                                        <th>S No.</th>
                                        <th>Name</th>
                                        <th>Edit</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr
                                        class="mat-header-row"
                                        *ngFor="
                                            let row of usersdata;
                                            let i = index
                                        "
                                    >
                                        <td>{{ i + 1 }}</td>
                                        <td>{{ row.symptoms_Name }}</td>
                                        <td>
                                            <button
                                                mat-button
                                                class="button-min-width"
                                                matTooltip="Edit"
                                                (click)="
                                                    edt_symp(row.symptoms_id)
                                                "
                                            >
                                                <mat-icon class="ft-18"
                                                    >edit</mat-icon
                                                >
                                            </button>
                                        </td>
                                        <td>
                                            <button
                                                mat-button
                                                class="button-min-width"
                                                matTooltip="Delete"
                                                *ngIf="
                                                    row.symptoms_isActive == 1
                                                "
                                                (click)="
                                                    del_symp(row.symptoms_id, 0)
                                                "
                                            >
                                                <mat-icon class="ft-18"
                                                    >delete</mat-icon
                                                >
                                            </button>
                                            <button
                                                mat-button
                                                class="button-min-width"
                                                matTooltip="Activate"
                                                *ngIf="
                                                    row.symptoms_isActive == 0
                                                "
                                                (click)="
                                                    act_symp(row.symptoms_id, 1)
                                                "
                                            >
                                                <mat-icon class="ft-18"
                                                    >check</mat-icon
                                                >
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #add_Symptoms>
    <form
        fxLayout="column"
        fxFlex="1 0 auto"
        name="symptomsList"
        [formGroup]="symptomsList"
    >
        <h1 mat-dialog-title>User Details</h1>

        <div fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" fxFlex="100">
                <mat-label>Symptom Name</mat-label>
                <input
                    matInput
                    formControlName="name"
                    [(ngModel)]="empObject.Name"
                />
                <mat-error> Name is required </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto"></div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <button
                mat-raised-button
                class="warn mr-4"
                (click)="closewindow()"
                mat-dialog-close
            >
                <mat-icon>close</mat-icon>Cancel
            </button>
            <button
                mat-raised-button
                [disabled]="symptomsList.invalid"
                (click)="symptomSubmit()"
                class="primary mr-4"
                mat-dialog-close
            >
                <mat-icon>save</mat-icon> Save
            </button>
        </div>
    </form>
</ng-template>

<ng-template #alertpop>
    <form
        fxLayout="column"
        fxFlex="1 0 auto"
        name="specimenDetails"
        [formGroup]="specimenDetails"
    >
        <h1 mat-dialog-title>Confirmation</h1>
        <div fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">
            <h4>
                Are you sure you want to{{
                    this.deleteFlg == 1 ? "'Active'" : "'Suspend'"
                }}
                the Symptom?
            </h4>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <button
                mat-raised-button
                class="warn mr-4"
                (click)="closewindow()"
                mat-dialog-close
            >
                <mat-icon>close</mat-icon>Cancel
            </button>
            <button
                mat-raised-button
                (click)="confirm_del(0)"
                class="primary mr-4"
            >
                <mat-icon>{{
                    this.deleteFlg == 1 ? "save" : "delete"
                }}</mat-icon
                >Save
            </button>
        </div>
    </form>
</ng-template>
