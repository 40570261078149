import { Component, ElementRef, ViewChild, OnInit, OnDestroy, AfterViewInit, NgModule } from '@angular/core';
import { CommonService } from 'app/common/Service/common.service';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from 'app/common/Service/snackbar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators, FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatPaginator } from '@angular/material/paginator';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { DatePipe } from '@angular/common';
import * as XLSX from "xlsx";
import { de, tr } from 'date-fns/locale';
import { timeStamp } from 'console';
import { unset } from 'lodash';
@Component({
  selector: 'emp-request-list',
  templateUrl: './emp-request-list.html',
  styleUrls: ['./emp-request.scss']
})
export class EmpRequestListComponent implements OnInit, AfterViewInit {
  usersdata: any = [];
  clientsList: any = [];
  requestform: FormGroup;
  TransferForm: FormGroup;
  ExportForm: FormGroup;
  CloneRequestForm: FormGroup;
  rows: any = [];
  rowslist: any = [];
  result: any = {};
  isEmployer:boolean=false;
  resultLength: number = 0;
  categories: any = []; currentCategory: string;
  storeData: any;
  index: number = 0;
  searchKey: string = "";
  dataSource: any = [];
  usercode: string = "";
  empObject: any = {};
  ExportType: number = 0
  exportlist: any = ["Excel", "PDF", "JSON"];
  NegativeRecords: number = 0;
  UnsentEmails: number = 0;
  positiveRecords: number = 0;InconclusiveRecords: number = 0;
  divbackgroundClass = "";
  IsHidePCRSent: boolean = false;
  emailStatus_div: boolean = false;
  clsmodel: any = {};
  buttonDisabled: boolean;
  ids: any[] = [];
  searchTerm: string = "";
  counter: number = 0;
  selectedList: any = [];
  EmailList: any = [];
  ResultsUnsentList: any = [];
  myControl = new FormControl();
  myControl1 = new FormControl();
  myControl2 = new FormControl();
  minDate: Date;
  filtertxt: string = "Hide Filter";
  maxDate: Date;
  startDate: string;
  endDate: string;
  CloneselectedTest: number = 0;
  filteredOptions: Observable<string[]>;
  filteredOptions2: Observable<string[]>;
  dateFormat: string; filteredOptions1: Observable<string[]>;
  selectedLocation: any = [];
  selectedClient: any = [];
  FromLocation: any = [];
  ToLocation: any = [];
  testlist: any = []
  showfilter: boolean = true;
  ScreenActions: any = { IsEdit: false, IsPrint: false, Document: false, IsClone: false, IsSendNotification: false, IsTransfer: false, IsPDF: false, IsDelete: false }

  @ViewChild(MatPaginator, { read: true }) paginator: MatPaginator;
  //@ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
  //    this.paginator = mp;
  //    this.setDataSourceAttributes();
  //}
  range = new FormGroup({
    start: new FormControl(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1)),
    end: new FormControl(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))
  });
  reference_no: string = "";
  seqno: number = 0;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['isSelected', 'dclNo', 'requestName', 'mobileNo', 'emailAddress', 'DOB', 'status', 'requestDate',   'test', 'seqno'];
  @ViewChild('update_request') update_request: ElementRef;
  @ViewChild('transfer_request') transfer_request: ElementRef;
  @ViewChild('export_list') export_list: ElementRef;
  @ViewChild('clone_request_modal') clone_request_modal: ElementRef;
  @ViewChild('send_notification') send_notification: ElementRef;
  @ViewChild('pdf_confirmation') pdf_confirmation: ElementRef;
  @ViewChild('results_Unsent') results_Unsent: ElementRef;
  @ViewChild('email_status') email_status: ElementRef;
  constructor(

    private service: CommonService,
    private dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private _fuseSidebarService: FuseSidebarService,
    private _snackbarservice: SnackbarService,
    private _splashScreenService: FuseSplashScreenService,
    private router: Router,
  ) {
    if (this._fuseSidebarService.getSidebar('navbar') != undefined)
      this._fuseSidebarService.getSidebar('navbar').toggleFold();

    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 100, 0, 1);
    this.maxDate = new Date(currentYear, new Date().getMonth(), new Date().getDate());
    //this.showTourGuid();
  }

  ngOnInit(): void {
    this.startDate = this.datePipe.transform(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), "yyyy-MM-dd");
    this.endDate = this.datePipe.transform(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), "yyyy-MM-dd");

    //link : ['', Validators.required],  
    if (localStorage.getItem("isRefresh") != undefined && localStorage.getItem("isRefresh") != ""
      && localStorage.getItem("isRefresh") == "1") {
      localStorage.setItem("isRefresh", "0");
      window.location.reload();
    }
    this.requestform = this._formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobileNo: ['', Validators.required],
      emailAddress: ['', Validators.required],
      gender: ['', Validators.required],
      DateOfBirth: ['', Validators.required],

    });
    this.TransferForm = this._formBuilder.group({
      fromcenter: ['', Validators.required],
      remarks: ['', Validators.required],

    });
    this.ExportForm = this._formBuilder.group({
      ExportToDate: ['', Validators.required],
      ExportFromDate: ['', Validators.required],
    });

    this.CloneRequestForm = this._formBuilder.group({
      cloneRadio: ['', Validators.required],
      cloneremarks: ['', Validators.required]

    });
    var sessionObject = JSON.parse(localStorage.UserDetails)
    this.ScreenActions.IsEdit = this.service.getActionPermissions("RequestList", "Edit");
    this.ScreenActions.IsTransfer = this.service.getActionPermissions("RequestList", "Transfer");
    this.ScreenActions.IsPDF = this.service.getActionPermissions("RequestList", "SendPDF");
    this.ScreenActions.Download = this.service.getActionPermissions("RequestList", "Download");
    this.ScreenActions.IsPrint = this.service.getActionPermissions("RequestList", "Print");
    this.ScreenActions.Document = this.service.getActionPermissions("RequestList", "Document");
    this.ScreenActions.IsClone = this.service.getActionPermissions("RequestList", "Clone");
    this.ScreenActions.IsSendNotification = this.service.getActionPermissions("RequestList", "SendNotification"); 4
    if (sessionObject.users_Usertype == "A") {
      this.getMasterData(10, sessionObject.users_userid, '');//LocationUsers
      this.IsHidePCRSent = true;
    }
    else
      this.getMasterData(30, sessionObject.users_userid, '');//LocationUsers

    if (sessionObject.users_Usertype == "A")
      this.getMasterData(13, sessionObject.users_userid, '');// 
    else
      this.getMasterData(46, sessionObject.users_userid, '');//

    if(sessionObject.users_Usertype=="O")
      this.isEmployer=true

    this.getMasterData(34, 0, '');//DateFormat
    this.getMasterData(38, 0, '');// 
    // setTimeout(() => {
    //   this.searchlist()
    // }, 500);
    this.empObject.result = "0";
    this.range.valueChanges.pipe(
    ).subscribe(event => {
      if (event.end) {
        this.onDateChanged(event);
      }
    });

  }
  ngAfterViewInit(): void {
    //this.dataSource.sort = this.sort;

    if (this.paginator !== undefined)
      this.dataSource.paginator = this.paginator;


  }
  CheckSendButton(testCode, sendButton) {
    if (testCode == '3A5A1D18' && !this.IsHidePCRSent)
      return false;
    else if (sendButton)
      return true;
    else
      return false;
  }

  flg = 0;
  onDateChanged(ev) {
    if (this.flg == 1) {
      this.startDate = this.datePipe.transform(ev.start._d, "yyyy-MM-dd");
      this.endDate = this.datePipe.transform(ev.end._d, "yyyy-MM-dd");
      this.getdatawithcommonpaging(0, 50, 'createdDate', false, this.startDate, this.endDate);
      this.flg = 0;
    }
    else {
      this.flg++;
    }
  }
  filterdiv() {
    if (this.showfilter) {
      this.filtertxt = "Show Filter";
      this.showfilter = false;
    }
    else {
      this.showfilter = true;
      this.filtertxt = "Hide Filter";
    }
  }
  filterRowslist() {
    const searchTerm = this.searchTerm.toLowerCase();

    // Search
    if (searchTerm === '') {
      this.rows = this.rowslist;
    }
    else {
      this.rows = this.rowslist.filter((course) => {
        return course.name.toLowerCase().includes(searchTerm) || course.emailAddress.toLowerCase().includes(searchTerm)
          || course.status.toLowerCase().includes(searchTerm) || course.mobileNo.toLowerCase().includes(searchTerm);
      });
    }
    this.dataSource = new MatTableDataSource(this.rows);
  }
  TransferSubmit() {
    if (this.ToLocation != null && this.ToLocation.location_Id > 0)
      this.getMasterData(49, this.ToLocation.location_Id, this.reference_no.toString());//   
    else {
      this._snackbarservice.showSnackbar("Invalid to location");
    }
  }
  requestSubmit() {

    if (this.requestform.invalid)
      return;
    if (this.empObject.request_DOB !== undefined && this.empObject.request_DOB !== null) {
      this.empObject.request_DOB = this.datePipe.transform(this.empObject.request_DOB, this.dateFormat);
    }

    var clsmodel = {
      mobileNo: this.empObject.request_mobileNo,
      Name: this.empObject.request_name,
      LastName: this.empObject.request_lastName,
      EmailAddress: this.empObject.request_email,
      Gender: this.empObject.request_gender,
      DOB: this.empObject.request_DOB,
      ReferenceNo: this.reference_no,

    }
    this.service.updateRequest(clsmodel).subscribe(result => {
      var resultObj = result;
      if (resultObj["status"] == 200) {
        this._snackbarservice.showSnackbar(resultObj["message"]);
        this.getdatawithcommonpaging(0, 10, 'createdDate', false, null, null);
      }
      else {
        this._snackbarservice.showSnackbar(resultObj["message"]);
      }


    }, error => {
      console.log(error);
    });
    return "";


  }
  reloadRoute() {
    window.location.reload();
  }
  closewindow() {
    this.dialog.closeAll();
    this.selectedList = [];
    this.searchlist()
  }
  closepdfwindow() {
    this.dialog.closeAll();
    this.reference_no = "";
    this.CloneselectedTest = 0;
    this.empObject.cloneremarks = "";
  }
  sendEmailBackground() {
    this.divbackgroundClass = "email_status";
  }
  exportExcel() {

    this.openlocationmodal(this.export_list);

    //TableUtil.exportTableToExcel("tableData", "RequestList");
  }
  ExportData() {
    if (this.ExportForm.invalid)
      return;
    var sessionobj = JSON.parse(localStorage.UserDetails);
    if (sessionobj.users_Usertype == "S") {
      if (this.ToLocation == null || this.ToLocation.location_Id == undefined) {
        this._snackbarservice.showSnackbar("Invalid location")
        return;
      }
    }
    let FmDate;
    let toD;
    if (this.empObject.ExportFromDate !== undefined && this.empObject.ExportFromDate !== null) {
      FmDate = this.datePipe.transform(this.empObject.ExportFromDate, this.dateFormat);
    }
    if (this.empObject.ExportToDate !== undefined && this.empObject.ExportToDate !== null) {
      toD = this.datePipe.transform(this.empObject.ExportToDate, this.dateFormat);
    }
    this._splashScreenService.show();

    var clsmodel = {
      startDate: FmDate,
      endDate: toD,
      FilterId: this.ToLocation.location_Id,
      flg: this.ExportType
    }
    this.service.ExportData(clsmodel).subscribe(result => {
      var resultObj = result;
      this._splashScreenService.hide();
      if (resultObj["status"] == 200) {
        let link = document.createElement('a');
        link.setAttribute('type', 'hidden');
        link.download = "ExportData";
        link.target = "_blank"
        link.href = resultObj["resultObj"]["filePath"];
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.dialog.closeAll();
      }
      else {
        this._snackbarservice.showSnackbar(resultObj["message"]);
      }


    }, error => {
      console.log(error);
    });
  }
  displayFn(selectedoption) {

    return selectedoption ? selectedoption.location_propertyName : undefined;
  }
  displayClientFn(selectedoption) {

    return selectedoption ? selectedoption.employeer_orgName : undefined;
  }
  openmodal(modal) {
    this.dialog.open(modal, { width: "700px", height: "auto", disableClose: true });
  }
  openmodalonBottom(modal) {
    this.dialog.open(modal, {
      width: "400px", height: "auto", disableClose: false, position: { right: '10px', bottom: '5%' }
    });
  }
  openlocationmodal(modal) {
    this.dialog.open(modal, { width: "400px", height: "auto", disableClose: true });
  }
  open_transfer_request(_referenceNo) {
    this.reference_no = _referenceNo;
    var requestObject = this.rows.filter((course) => {
      return course.referenceNo == _referenceNo;
    });
    if (requestObject != undefined && requestObject.length > 0) {
      this.empObject.FromLocation = requestObject[0].location;
    }
    this.openlocationmodal(this.transfer_request);
  }
  clone_request(_referenceNo) {
    this.reference_no = _referenceNo;
    this.openlocationmodal(this.clone_request_modal);
  }
  edit_request(_referenceNo) { 
    this.reference_no = _referenceNo;
    var requestObject = this.rows.filter((course) => {
      return course.referenceNo == _referenceNo;
    });
    if (requestObject != undefined && requestObject.length > 0) {
      this.empObject.request_name = requestObject[0].sFirstName
      this.empObject.request_lastName = requestObject[0].lastName
      this.empObject.request_mobileNo = requestObject[0].mobileNo
      this.empObject.request_email = requestObject[0].emailAddress
      this.empObject.request_gender = requestObject[0].gender
      this.empObject.request_DOB = requestObject[0].dateOfBirth
    }
    this.openmodal(this.update_request);
  }
  download_pdf(_referenceNo) {
    this.getMasterData(54, 0, _referenceNo);

  }
  onToSelectionChange(_ev) {
    if (_ev.option.value != null) {
      this.ToLocation = _ev.option.value;

    }
  }
  onFromSelectionChange(_ev) {
    if (_ev.option.value != null) {
      this.FromLocation = _ev.option.value;

    }
  }
  onSelectionChange(_ev) {
    if (_ev.option.value != null) {
      this.selectedLocation = _ev.option.value;
      this.getdatawithcommonpaging(0, 50, 'createdDate', false, null, null);
    }

  }
  onClientSelectionChange(_ev) {
    if (_ev.option.value != null) {
      this.selectedClient = _ev.option.value;
      this.getdatawithcommonpaging(0, 50, 'createdDate', false, null, null);
    }

  }
  searchlist() {
    this.searchKey = this.searchTerm
    this.getdatawithcommonpaging(0, 50, 'createdDate', false, null, null);
  }
  _filter(value: string) {
    return this.usersdata.filter(option => option.location_propertyName.toLowerCase().includes(value.toLowerCase()));
  }
  _filterClients(value: string) {
    return this.clientsList.filter(option => option.employeer_orgName.toLowerCase().includes(value.toLowerCase()));
  }
  getMasterData(flg, filterId, code) {
    var sessionobj = JSON.parse(localStorage.UserDetails);
    var clsmodel = {
      Type: flg,
      FilterId: filterId,
      Code: code
    }
    this.service.GetMasterData(clsmodel).subscribe(result => {
      var resultObj = result;
      if (resultObj["status"] == 200) {
        if (flg == 34) {
          this.dateFormat = resultObj["resultObj"];
        }
        else if (flg == 49) {
          this._snackbarservice.showSnackbar(resultObj["message"]);
          this.seqno = 0;
          this.ToLocation = null;
          this.empObject.FromLocation = "";
          this.empObject.transferremarks = "";
          this.dialog.closeAll();
          this._splashScreenService.hide();
          this.searchlist();
        }
        else if (flg == 38) {
          this.testlist = resultObj["resultObj"];
        }
        else if (flg == 54) {
          let link = document.createElement('a');
          link.setAttribute('type', 'hidden');
          link.download = "DownloadPdf";
          link.target = "_blank"
          link.href = resultObj["resultObj"];
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
        else if (flg == 13 || flg == 46) {
          this.clientsList = resultObj["resultObj"];
          this.filteredOptions2 = this.myControl2.valueChanges
            .pipe(
              startWith(''),
              map(value => typeof value === 'string' ? value : value.employeer_orgName),
              map(employeer_orgName => employeer_orgName ? this._filterClients(employeer_orgName) : this.clientsList.slice())
            );
        }
        else if (flg == 30 || flg == 10) {
          this.usersdata = resultObj["resultObj"];
          this.filteredOptions = this.myControl.valueChanges
            .pipe(
              startWith(''),
              map(value => typeof value === 'string' ? value : value.location_propertyName),
              map(location_propertyName => location_propertyName ? this._filter(location_propertyName) : this.usersdata.slice())
            );
          this.filteredOptions1 = this.myControl1.valueChanges
            .pipe(
              startWith(''),
              map(value => typeof value === 'string' ? value : value.location_propertyName),
              map(location_propertyName => location_propertyName ? this._filter(location_propertyName) : this.usersdata.slice())
            );
        }
        else if (flg == 48) {
          this._snackbarservice.showSnackbar(resultObj["message"]);
          this.reference_no = "";
          this.dialog.closeAll();
          this._splashScreenService.hide();
        }
        else if (flg == 25) {
          this.usersdata = resultObj["resultObj"];
        }
        else if (flg == 47) {
          this._snackbarservice.showSnackbar(resultObj["message"]);
          this.reference_no = "";
          this.empObject.cloneremarks = "";
          this.CloneselectedTest = 0;
          this.dialog.closeAll();
          this._splashScreenService.hide();
          this.searchlist();
        }
      }
      else {
        this._snackbarservice.showSnackbar(resultObj["message"]);
        this._splashScreenService.hide();
      }

    }, error => {
      this._splashScreenService.hide();
      console.log(error);
    });
    return "";

  }
  onPageFired(event) {
    let pgIndex = (event.length / event.pageSize) - 1 == event.pageIndex ? event.pageIndex : event.pageIndex;
    this.getdatawithcommonpaging(pgIndex, event.pageSize, this.clsmodel.sortKey, this.clsmodel.ordering, null, null);
  }
  filterCoursesByCategory(_filterid): void {
    // Filter 
    if (_filterid.value == "all")
      this.getMasterData(10, 0, '');//Countries  
    else
      this.getMasterData(30, _filterid.value, '');//Countries  
  }
  OnStatusClick(_referenceNo) {
    this.reference_no = _referenceNo;
    var selectedIds = this.rows.filter((course) => {
      return course.referenceNo == _referenceNo;
    });
    if (selectedIds != undefined && selectedIds[0].testCode == '3A5A1D18' && !this.IsHidePCRSent) {
      this._snackbarservice.showSnackbar("Unable to send results, please contact administrator")
      return;
    }
    else if (!this.ScreenActions.IsSendNotification)
      {this._snackbarservice.showSnackbar("Unable to send results, please contact administrator")
      return;}
    if (selectedIds != undefined && selectedIds.length > 0) {
      selectedIds.forEach(element => {
        this.selectedList.push({ element });
      });

    }

  }
  onCheckboxChangeFn(event, seqno) {
    this.selectedList = [];
    var selectedIds = this.rows.filter((course) => {
      return course.isSelected == true;
    });
    if (selectedIds != undefined && selectedIds.length > 0) {
      selectedIds.forEach(element => {
        this.selectedList.push({ element });
      });

    }
  }
  sendNotication() {
    if (this.selectedList.length > 0) {
      this.openmodal(this.send_notification);
    } else {
      this._snackbarservice.showSnackbar("No record[s] are selected");
    }
  }
  UnSentResults() {
    this.ResultsUnsentList = this.rows.filter((course) => {
      return (course.status == "Not Detected" || course.status == "Detected") && course.emailCounter == 0;
    });

    this.openmodal(this.results_Unsent);
  }
  OpenStatus(ref) {
    this.emailStatus_div = true;
  }
  sendPDF(referenceno) {
    this.reference_no = referenceno;
    if (this.reference_no != undefined && this.reference_no != "") {
      this.openmodal(this.pdf_confirmation);
    } else {
      this._snackbarservice.showSnackbar("No record[s] are selected");
    }
  }
  sendPDFConfirmation() {
    if (this.reference_no != undefined && this.reference_no != "") {
      this._splashScreenService.show();
      this.getMasterData(48, 0, this.reference_no);//send pdf  
    } else {
      this._snackbarservice.showSnackbar("Invalid reference no");
    }
  }
  CloneRequest() {
    if (this.CloneRequestForm.invalid)
      return;
    if (this.CloneselectedTest == 0) {
      this._snackbarservice.showSnackbar("Invalid test selection")
      return;
    }
    if (this.reference_no != undefined && this.reference_no != "") {
      this._splashScreenService.show();
      this.getMasterData(47, this.CloneselectedTest, this.reference_no + "~" + this.empObject.cloneremarks);//send pdf  
    } else {
      this._snackbarservice.showSnackbar("Invalid reference no");
    }
  }
  sendEmailInAsync() {
    var seqnos = "";
    this.EmailList = [];
    this.selectedList.forEach(element => {
      this.EmailList.push({ refNo: element.element.referenceNo, seqno: element.element.seqno, Status: "Pending" })
    });
    let counter = 0;
    this.EmailList.forEach(element => {
      var sessionobj = JSON.parse(localStorage.UserDetails);
      var clsmodel = {
        TestResult: this.empObject.result,
        requestIDs: element.refNo,
        UserId: sessionobj.users_userid

      }
      this.service.UpdateEmpTestResultsAsync(clsmodel).subscribe(result => {
        var resultObj = result;
        if (resultObj["status"] == 200) {
          element.Status = "Sent";
          counter++;
          if (counter == this.EmailList.length) {
            this.emailStatus_div = false;
            this.empObject.result = "0";
            this.EmailList = [];
            this.selectedList = [];
            this.searchlist();
          }
        }
        else {
          element.Status = resultObj["message"];
        }


      }, error => {
        console.log(error);
      });
    });

  }
  ResentPDF(refNo, _result) {
    this._splashScreenService.show();
    var sessionobj = JSON.parse(localStorage.UserDetails);
    var clsmodel = {
      TestResult: _result,
      requestIDs: refNo,
      UserId: sessionobj.users_userid

    }
    this.service.UpdateTestResultsAsync(clsmodel).subscribe(result => {
      var resultObj = result;
      this._splashScreenService.hide();
      if (resultObj["status"] == 200) {
        this._snackbarservice.showSnackbar("Successfully sent");
        this.dialog.closeAll();
        this.searchlist();
      }
      else {
        this._snackbarservice.showSnackbar(resultObj["message"]);
      }


    }, error => {
      console.log(error);
    });
  }
  submitnotification() {
    if (this.selectedList.length > 0) {
      this.emailStatus_div = true;
      this.sendEmailInAsync();

    } else {
      this._snackbarservice.showSnackbar("No record[s] are selected");
    }
  }
  filterCoursesByTerm() {

  }
  sortData(sort: Sort) {
    const data = this.rows.slice();
    if (!sort.active || sort.direction === '') {
      this.getdatawithcommonpaging(0, this.clsmodel.pageSize, sort.active, false, null, null);
      return;
    }
    const isAsc = sort.direction === 'desc';
    this.getdatawithcommonpaging(0, this.clsmodel.pageSize, sort.active, isAsc, null, null);


    this.dataSource = new MatTableDataSource(this.rows);
    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  }
  onsearchEnter(_key) {
    if (_key.keyCode === 13) {
      this.searchlist();
    }
  }

  getdatawithcommonpaging(page, pageSize, sortKey, ordering, stDate, endDate) {
    if (this.startDate == null || this.endDate == null)
      return;
      this.rows = [];
      this.rowslist = this.rows;

      this.dataSource = new MatTableDataSource(this.rows);
    this._splashScreenService.show();
    var sessionobj = JSON.parse(localStorage.UserDetails);
    this.clsmodel.pageNumber = page;
    this.clsmodel.pageSize = pageSize;
    this.clsmodel.sortKey = sortKey;
    this.clsmodel.ordering = ordering;
    this.clsmodel.featureId = 100;
    this.clsmodel.startDate = this.startDate; this.clsmodel.endDate = this.endDate;
    this.clsmodel.searchKey = this.searchKey;
    this.clsmodel.locationId = this.selectedLocation != null && this.selectedLocation != "" ?
      this.selectedLocation.location_Id : 0;
    if (sessionobj.users_Usertype == "O")
      this.clsmodel.clientId = sessionobj.users_Customerid;
    else
      this.clsmodel.clientId = this.selectedClient != undefined && this.selectedClient != "" ?
        this.selectedClient.employeer_id : 0;
    this.clsmodel.accountId = sessionobj.users_userid;
    this.clsmodel.UserType = sessionobj.users_Usertype;
    this.service.getEmpRequestListByPaging(this.clsmodel).subscribe((data) => {
      if (data["status"] == "200") {
        // then you can assign data to your dataSource like so
        this.result = data["resultObj"]["metadata"];
        this.resultLength = this.result.totalRecords;
        this.rows = data["resultObj"]["data"];
        this.rowslist = this.rows;

        this.dataSource = new MatTableDataSource(this.rows);
        this.dataSource.sort = this.sort;

        const sortState: Sort = { active: 'createdDate', direction: 'desc' };
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);
        this.UnsentEmails = 0;
        this.positiveRecords = 0;
        this.NegativeRecords = 0;
        this.InconclusiveRecords = 0;
        var positive = this.rows.filter((course) => {
          return course.status == "Detected";
        });
        var negative = this.rows.filter((course) => {
          return course.status == "Not Detected";
        });
        var Inconclusive = this.rows.filter((course) => {
          return course.status == "Inconclusive";
        });
        var Unsent = this.rows.filter((course) => {
          return (course.status == "Not Detected" || course.status == "Detected") && course.emailCounter == 0;
        });
        if (positive != undefined && positive.length > 0) {
          this.positiveRecords = positive.length;
        }
        if (Unsent != undefined && Unsent.length > 0) {
          this.UnsentEmails = Unsent.length;
        }
        if (negative != undefined && negative.length > 0) {
          this.NegativeRecords = negative.length;
        }
        if (Inconclusive != undefined && Inconclusive.length > 0) {
          this.InconclusiveRecords = Inconclusive.length;
        }
        this._splashScreenService.hide();
      }
      else {
        this._snackbarservice.showSnackbar(data["message"]);
        this._splashScreenService.hide();
      }
    });

  }
}
const routes: Routes = [
  {
    path: 'emp-request-list',
    component: EmpRequestListComponent

  }
];