import { Component, ElementRef, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'app/common/Service/common.service';
import { AnalyticsDashboardService } from 'app/main/apps/dashboards/analytics/analytics.service';
import { de } from 'date-fns/locale';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { SnackbarService } from 'app/common/Service/snackbar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { DatePipe } from '@angular/common'; 
import { TableUtil } from "../../../helpers/tableUtil"; 
import * as XLSX from 'xlsx';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators, FormControl } from '@angular/forms';
@Component({
    selector: 'request-dashboard',
    templateUrl: './request-dashboard.html',
    styleUrls: ['./request-dashboard.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class RequestDashboardComponent implements OnInit {
    @ViewChild('register_link') register_link: ElementRef;
    widgets: any;
    widget1SelectedYear = '2016';
    widget5SelectedDay = 'today';
    dosageWiseEmps: any = [];
    result:string;
    widget7: any = [];
    selectedLocation: any = [];
    projects: any[];
    scheme: any = []; filteredOptions: Observable<string[]>;
    usersdata: any = [];
    colorScheme:any=[];
    dashboard: any = [];
    widget6: any = {};
    firstdosePercentage: number = 0;
    seconddosePercentage: number = 0;
    thirdosePercentage: number = 0;
    myControl = new FormControl();
    ExpiresList: any = [];
    range = new FormGroup({
        start: new FormControl(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 10)),
        end: new FormControl(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))
    });
    dateNow = Date.now();
    minDate: Date;
    maxDate: Date;
    startDate: string;
    view: any[] = [600, 300];
    endDate: string;
    constructor(
        private _analyticsDashboardService: AnalyticsDashboardService,
        private dialog: MatDialog,
        private service: CommonService,
        private _fuseSidebarService: FuseSidebarService,
        private _snackbarservice: SnackbarService,
        private _splashScreenService: FuseSplashScreenService,
        private datePipe: DatePipe,
    ) {
        const currentYear = new Date().getFullYear();
        this.minDate = new Date(currentYear - 100, 0, 1);
        this.maxDate = new Date(currentYear, new Date().getMonth(), new Date().getDate());
        this._registerCustomChartJSPlugin();
        this.scheme = {
            domain: ['#f44336', '#9c27b0', '#03a9f4', '#e91e63']
        };
        this.colorScheme = {
            domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
          };
        this.startDate = this.datePipe.transform(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-10), "yyyy-MM-dd");
        this.endDate = this.datePipe.transform(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), "yyyy-MM-dd");
    
       // this.generateReport(this.startDate,this.endDate);
        // this.widget6 = {
        //     currentRange : 'TW',
        //     legend       : false,
        //     explodeSlices: false,
        //     labels       : true,
        //     doughnut     : true,
        //     gradient     : false,
        //     scheme       : {
        //         domain: ['#f44336', '#9c27b0', '#03a9f4', '#e91e63']
        //     },
        //     onSelect     : (ev) => {
        //         console.log(ev);
        //     }
        // };
        setInterval(() => {
            this.dateNow = Date.now();
        }, 1000);
        this.range.valueChanges.pipe(
        ).subscribe(event => {
            if (event.end) {
                this.onDateChanged(event);
            }
        });
    }
    displayFn(selectedoption) {

        return selectedoption ? selectedoption.location_propertyName : undefined;
      }
    _filter(value: string) {
        return this.usersdata.filter(option => option.location_accountName.toLowerCase().includes(value.toLowerCase()) ||
        option.location_propertyName.toLowerCase().includes(value.toLowerCase()));
    }
    onSelectionChange(_ev) {
        if (_ev.option.value != null) {
          this.selectedLocation = _ev.option.value;
          this.generateReport(this.startDate,this.endDate);
        }
    
      }
      LoadData(){
        this.generateReport(this.startDate,this.endDate);
      }
    flg = 0;
    onDateChanged(ev) {
        if (this.flg == 1) {
            this.startDate = this.datePipe.transform(ev.start._d, "yyyy-MM-dd");
            this.endDate = this.datePipe.transform(ev.end._d, "yyyy-MM-dd");
            this.generateReport(this.startDate,this.endDate);
            //this.getdatawithcommonpaging(0, 50, 'createdDate', false, this.selectedLocation.location_Id, this.startDate, this.endDate);
            this.flg = 0;
        }
        else {
            this.flg++;
        }
    }
    
      
    
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    onSelect(ev) {

    }
    exportExcel() { 
        
        let element = document.getElementById('_divL'); 
        var ele= element.innerHTML;
        let printContents, popupWin; 
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write('<head><title>Print Data</title> \
        \ <style> .simple, .simple thead, .simple tr, .simple tbody{     display: inline-block;     width: 100%;  }  table.simple tbody tr td {      padding: 10px 8px !important;  }  .simple thead th, .simple tbody td{      width: 50%;      display: inline-block;  }  .table{      width: 100%;      margin-top: 10px;      padding: 0 15px;  }  .table tbody tr td:first-child {      padding-left: 10px;  }  .table tr th {      background-color: #039be5;      color: #fff !important;      padding-left: 10px;  }  .table tr th:last-child {       padding-right: 10px;  }  table thead tr th {      text-align: left;  }  table thead tr td {      text-align: left;      padding-left: 10px;  }   .table tbody tr td {      border-bottom: 1px solid rgba(0, 0, 0, 0.12);      padding: 5px 8px;  }  ._wid_20{      width: 20%!important;      display: inline-block!important;  }  table.simple thead tr th:first-child{      padding-left: 10px;  }  table.simple thead tr th:last-child{      padding-right: 10px;  }  .no-bdr{      border-bottom:0 !important;  }  .bdr-white{      border-bottom:1px solid #fff !important;      text-align: center !important;  }</style> \
       </head>');
        popupWin.document.write('<div>' + ele + '</div>');
        popupWin.document.write('</body></html>');
        popupWin.print();
        popupWin.document.close(); 
      }
    /**
     * On init
     */
    ngOnInit(): void {
        // Get the widgets from the service         
        if (localStorage.getItem("isRefresh") != undefined && localStorage.getItem("isRefresh") != ""
            && localStorage.getItem("isRefresh") == "1") {
            localStorage.setItem("isRefresh", "0");
            window.location.reload();
        }

        var sessionobj = JSON.parse(localStorage.UserDetails);
        this.getMasterData(7, sessionobj.users_Customerid);//EmployeerData
        if (sessionobj.users_Usertype == "A")
            this.getMasterData(10, sessionobj.users_userid);//LocationUsers
        else
            this.getMasterData(30, sessionobj.users_userid);//LocationUsers

    }
    generateReport(stDate,endDate){
        if (stDate == null || endDate == null)
             return;
        var sessionobj = JSON.parse(localStorage.UserDetails);
        // if (sessionobj.users_Usertype == "S") {
        //   if (this.selectedLocation == null || this.selectedLocation.location_Id == undefined) { 
        //     return;
        //   }
        // }
        this.dashboard = [];
        this._splashScreenService.show();
    
        var clsmodel = {
          startDate: stDate,
          endDate: endDate,
          FilterId: this.selectedLocation.location_Id,
          flg: 0,
          locationId:this.result,
          userType:sessionobj.users_Usertype,
          userid:sessionobj.users_userid
        }
        this.service.RequestDashboard(clsmodel).subscribe(result => {
          var resultObj = result; 
          this._splashScreenService.hide();
          if (resultObj["status"] == 200) {  
            this.dashboard = resultObj["resultObj"];
          }
          else {
            this._snackbarservice.showSnackbar(resultObj["message"]);
          }
    
    
        }, error => {
          console.log(error);
        });
    }
    
    getMasterData(flg, filterId) {

        var clsmodel = {
            Type: flg,
            FilterId: filterId
        }
        this.service.GetMasterData(clsmodel).subscribe(result => {
            var resultObj = result;
            if (resultObj["status"] == 200) {
                 if (flg == 30 || flg == 10) {
                    this.usersdata = resultObj["resultObj"];
                    this.filteredOptions = this.myControl.valueChanges
                        .pipe(
                            startWith(''),
                            map(value => typeof value === 'string' ? value : value.location_propertyName),
                            map(location_propertyName => location_propertyName ? this._filter(location_propertyName) : this.usersdata.slice())
                        );
                        var sessionobj = JSON.parse(localStorage.UserDetails);
                        
                        // if (sessionobj.users_Usertype == "S") {
                        //     this.selectedLocation=this.usersdata[0];
                        //     this.generateReport(this.startDate,this.endDate)
                        //   }
                }

            }

        }, error => {
            console.log(error);
        });
        return "";
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------
    registerPop(flg) {
        this.openmodal(this.register_link);
    }
    openmodal(modal) {
        this.dialog.open(modal, { width: "700px", height: "auto", disableClose: true });
    }
    sendNotification() {

    }

    /**
     * Register a custom plugin
     */
    private _registerCustomChartJSPlugin(): void {
        (window as any).Chart.plugins.register({
            afterDatasetsDraw: function (chart, easing): any {
                // Only activate the plugin if it's made available
                // in the options
                if (
                    !chart.options.plugins.xLabelsOnTop ||
                    (chart.options.plugins.xLabelsOnTop && chart.options.plugins.xLabelsOnTop.active === false)
                ) {
                    return;
                }

                // To only draw at the end of animation, check for easing === 1
                const ctx = chart.ctx;

                chart.data.datasets.forEach(function (dataset, i): any {
                    const meta = chart.getDatasetMeta(i);
                    if (!meta.hidden) {
                        meta.data.forEach(function (element, index): any {

                            // Draw the text in black, with the specified font
                            ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
                            const fontSize = 13;
                            const fontStyle = 'normal';
                            const fontFamily = 'Roboto, Helvetica Neue, Arial';
                            ctx.font = (window as any).Chart.helpers.fontString(fontSize, fontStyle, fontFamily);

                            // Just naively convert to string for now
                            const dataString = dataset.data[index].toString() + 'k';

                            // Make sure alignment settings are correct
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'middle';
                            const padding = 15;
                            const startY = 24;
                            const position = element.tooltipPosition();
                            ctx.fillText(dataString, position.x, startY);

                            ctx.save();

                            ctx.beginPath();
                            ctx.setLineDash([5, 3]);
                            ctx.moveTo(position.x, startY + padding);
                            ctx.lineTo(position.x, position.y - padding);
                            ctx.strokeStyle = 'rgba(255,255,255,0.12)';
                            ctx.stroke();

                            ctx.restore();
                        });
                    }
                });
            }
        });
    }

}

