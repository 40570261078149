import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { FuseSharedModule } from '@fuse/shared.module';
import { AgmCoreModule } from '@agm/core';
import { RequestComponent } from 'app/main/pages/authentication/request/request.component';
import { ThankyouComponent } from 'app/main/pages/authentication/request/Thankyou/thankyou.component';  
import { InvalidQrComponent } from './invalidqrcode/invalidqr.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete'; 
import { MatDialogModule } from '@angular/material/dialog';
import { SelfTestComponent } from '../self-test/self-test.component';   
import { CircleTimerModule } from '@flxng/circle-timer';    
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {MatSlideToggleModule} from '@angular/material/slide-toggle'
const routes = [
    {
        path     : 'auth/request',
        component: RequestComponent
    }, 
    {
        path     : 'auth/thankyou/:id',
        component: ThankyouComponent
    } ,
    {
        path     : 'auth/invalidqr/',
        component: InvalidQrComponent
    } ,
   {
        path: 'auth/self-test',
        component: SelfTestComponent, 
    },
];
 

@NgModule({
    declarations: [
        RequestComponent,
        ThankyouComponent,InvalidQrComponent,
        SelfTestComponent ,
    ],
    imports     : [
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatButtonModule,
        MatCheckboxModule,
        MatRadioModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,MatDialogModule,
        MatAutocompleteModule, 
        MatSelectModule,
        MatDatepickerModule,           
        CircleTimerModule,
        NgxIntlTelInputModule,
        MatSlideToggleModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyCWjB2Aht_Vamw6HfxqA1UkZhXkphNCV24',
            libraries: ['places'],
            language: 'en'
        }),
    ]
})
export class RequestModule
{
}
