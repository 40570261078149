import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgModule } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { RegisterService } from '../register/register.service';
import { SnackbarService } from 'app/common/Service/snackbar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { MatDialog } from '@angular/material/dialog';

import { ActivatedRoute, Router } from '@angular/router';
@Component({
    selector: 'register-3',
    templateUrl: './register-3.component.html',
    styleUrls: ['./register-3.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class Register3Component implements OnInit, OnDestroy {
    registerForm: FormGroup;
    empObject: any = {};
    id: string = "";
    isValid: boolean = false;
    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _Registerservice: RegisterService,
        private _snackbarservice: SnackbarService,
        private _splashScreenService: FuseSplashScreenService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) {

        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();


    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void { 
        this.activatedRoute.params.subscribe(param => this.id = param.id); 
        if (this.id != undefined && this.id != "" && this.id != null) {
            localStorage.setItem("QrLocation",this.id)
            this.isValid = true;
            this.router.navigateByUrl('auth/request');   
        }
        else {
            this.isValid = false;
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


}
