<div id="academy-courses" class="page-layout simple">

    <div class="center">
        <div class="header accent p-24" fxLayout="column" fxLayoutAlign="center start">

            <div class="breadcrumb text-truncate h1" fxLayout="row" fxLayoutAlign="start center"
                [@animate]="{value:'*',params:{x:'50px'}}">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <span>Location QR</span>
                </div>
            </div>

            <!-- <div class="clsCaption"> Displaying records between {{startDate}} to {{endDate}}</div> -->
        </div>
    </div>
    <!-- / HEADER -->
    <!-- CONTENT -->
    <div class="content p-24">

        <div fxLayout="column" fxLayoutAlign="center">
            <div fxLayout="column" fxLayoutAlign="center">
                <fieldset>
                    <legend>
                        <h3>Filters</h3>
                    </legend>
                    <div class="filters" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
                        fxLayoutAlign.gt-xs="start center">
                        <mat-form-field appearance="fill" fxFlex="33" floatLabel="always" class="course-search">
                            <mat-label>Search for a center</mat-label>
                            <input matInput placeholder="Enter a keyword..."
                                   [(ngModel)]="searchTerm"
                                   (input)="filterCoursesByTerm()">
                        </mat-form-field>
                        
                
                    </div>
                </fieldset>
                
                </div> 
         
            <div class="courses" fxLayout="row wrap" fxLayoutAlign="center">

                <div class="course" *ngFor="let course of filteredCourses" fxFlex="100" fxFlex.gt-xs="50"
                    fxFlex.gt-sm="33" fxFlex.gt-md="25" [ngClass]="course.category">

                    <div class="course-content" fxLayout="column" fxFlex="1 1 auto">

                        <div class="header fx-lt-grey" fxLayout="row" fxLayoutAlign="center center"
                            [ngClass]="course.location_className">

                            <div class="category" fxFlex>
                                {{course.location_accountName}}
                                <span>{{course.location_code}}</span>
                            </div>

                            <div class="length" fxLayout="row" fxLayoutAlign="center center">
                                <div class="min">{{course.location_status}} </div>
                            </div>

                        </div>

                        <div class="content" fxLayout="column" fxLayoutAlign="start" fxFlex="1 1 auto">
                            <div class="h1">{{course.location_propertyName}}</div>
                            <div class="updated">
                                <mat-icon class="length-icon s-20 mr-4">location_city</mat-icon>{{course.location_city}}
                            </div>
                            <div class="chk" fxLayout="row" fxLayoutAlign="start">
                                <img class="chk"
                                src="{{course.qrCode}}" />
                            </div>
                            <div class="updated qrlink">
                                <mat-icon class="length-icon s-20 mr-4">link</mat-icon><a href="{{course.location_shortURL}}">
                                    {{course.location_shortURL}}
                                </a>
                            </div>
                        </div>


                        <div class="footer" fxLayout="row" fxLayoutAlign="center center">
                            <button mat-button *ngIf="course.qrCode!='../../../../assets/images/no-file.png'" matTooltip="Print" matTooltipClass="tooltip-blue"
                                (click)="downloadQR(course.location_code)">
                                <mat-icon>print</mat-icon>
                            </button>
                            <button mat-button matTooltip="Generate QR" matTooltipClass="tooltip-blue"
                                (click)="addLocation(course.location_code)">
                                <mat-icon>center_focus_strong</mat-icon>
                            </button>
                            <button mat-button *ngIf="course.qrCode!='../../../../assets/images/no-file.png'" matTooltip="Print QR" matTooltipClass="tooltip-blue"
                            (click)="printqr(course.location_code)">
                            <mat-icon>credit_card</mat-icon>
                        </button>

                        </div>

                    </div>

                </div>

                <div class="no-courses" *ngIf="filteredCourses.length == 0">
                    No centers found!
                </div>

            </div>

        </div>

    </div>
    <!-- / CONTENT -->
</div>
<ng-template #add_Location>
    <form fxLayout="column" fxFlex="1 0 auto" name="addlocation" [formGroup]="addlocation">
        <h1 mat-dialog-title>QR Generation</h1>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" fxFlex="100" class="mr-4">
                <mat-label>Frame Name</mat-label>
                <input matInput formControlName="frameName" readonly [(ngModel)]="empObject.frameName" required>
                <mat-error>
                    Frame Name is required
                </mat-error>
            </mat-form-field>

        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" fxFlex="100">
                <mat-label>Url</mat-label>
                <input matInput formControlName="Url" readonly [(ngModel)]="empObject.Url" required>
                <mat-error>Url is required!</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" fxFlex="50" class="mr-4">
                <mat-label>Frame Color</mat-label>
                <input matInput type="color" value="#02bfff" formControlName="frameColor"
                    [(ngModel)]="empObject.frameColor">
                <mat-error>Frame Color is required!</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="50" class="px-4">
                <mat-label>Text Color</mat-label>
                <input matInput type="color" value="#000000" formControlName="textcolor"
                    [(ngModel)]="empObject.textcolor">
            </mat-form-field>

        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <button mat-raised-button class="warn mr-4 " (click)="closewindow()" mat-dialog-close>
                <mat-icon>close</mat-icon>Cancel
            </button>
            <button mat-raised-button [disabled]="addlocation.invalid" (click)="locationSubmit()" class="primary mr-4"
                mat-dialog-close>
                <mat-icon>save</mat-icon>Generate
            </button>
        </div>
    </form>

</ng-template>

<ng-template #printqrdiv>
    <form fxLayout="column" fxFlex="1 0 auto" name="printQRcop" [formGroup]="printQRcop">
        <h1 mat-dialog-title>Print QR</h1>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" fxFlex="100" class="mr-4">
                <mat-label>No of Copies</mat-label>
                <input matInput type="number" formControlName="numofcopies"  [(ngModel)]="numofcopies" >
                
            </mat-form-field>

        </div> 
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <button mat-raised-button class="warn mr-4 " (click)="closewindow()" mat-dialog-close>
                <mat-icon>close</mat-icon>Cancel
            </button>
            <button mat-raised-button class="primary mr-4" (click)="prntQrcop()"
            mat-dialog-close>
                <mat-icon>save</mat-icon>Generate
            </button>
        </div>
    </form>

</ng-template>