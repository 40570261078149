import { Component, ElementRef, ViewChild, OnInit, OnDestroy, AfterViewInit, NgModule } from '@angular/core';
import { CommonService } from 'app/common/Service/common.service';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from 'app/common/Service/snackbar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators, FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatPaginator } from '@angular/material/paginator';
import { TableUtil } from "../../../helpers/tableUtil";
import { DatePipe } from '@angular/common';
import * as XLSX from "xlsx"; 
@Component({
  selector: 'vendor-report',
  templateUrl: './vendorreport.html',
  styleUrls: ['./vendorreport.component.scss']
})
export class VendorReportComponent implements OnInit, AfterViewInit {
  rows: any = [];
  toxilabrows: any = [];
  requestform: FormGroup;
  TransferForm: FormGroup;
  result: any = {};
  testList: any = [];
  resultLength: number = 0;
  categories: any = []; currentCategory: string;
  storeData: any;
  filteredOptions1: Observable<string[]>;
  index: number = 0;
  searchKey: string = "";
  dataSource: any = [];
  usercode: string = "";
  empObject: any = {};
  clsmodel: any = {};
  test_type:string="all";
  buttonDisabled: boolean;
  ids: any[] = [];
  searchTerm: string = "";
  counter: number = 0;
  myControl = new FormControl();
  myControl1 = new FormControl();
  minDate: Date;
  usersdata: any = [];
  maxDate: Date;
  startDate: string;
  endDate: string;
  filteredOptions: Observable<string[]>;
  dateFormat: string;
  selectedLocation: any = [];
  FromLocation: any = [];
  ToLocation: any = [];
  ScreenActions: any = { IsEdit: false, IsPrint: false, IsSendNotification: false, IsTransfer: false, IsPDF: false, IsDelete: false }

  @ViewChild(MatPaginator, { read: true }) paginator: MatPaginator;
  //@ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
  //    this.paginator = mp;
  //    this.setDataSourceAttributes();
  //}
  range = new FormGroup({
    start: new FormControl(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1)),
    end: new FormControl(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))
  });
  reference_no: string = "";
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['isSelected', 'dclNo', 'requestName', 'mobileNo', 'emailAddress', 'status', 'requestDate', 'test', 'seqno'];
  @ViewChild('update_request') update_request: ElementRef;
  @ViewChild('transfer_request') transfer_request: ElementRef;
  constructor(

    private service: CommonService,
    private dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private _snackbarservice: SnackbarService,
    private _splashScreenService: FuseSplashScreenService,
    private router: Router,
  ) {


    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 100, 0, 1);
    this.maxDate = new Date(currentYear, new Date().getMonth(), new Date().getDay());
    this.startDate = this.datePipe.transform(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1), "yyyy-MM-dd");
      this.endDate = this.datePipe.transform(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), "yyyy-MM-dd");

  }

  ngOnInit(): void {

    this.requestform = this._formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobileNo: ['', Validators.required],
      emailAddress: ['', Validators.required],
      gender: ['', Validators.required],
      DateOfBirth: ['', Validators.required],

    });
    this.TransferForm = this._formBuilder.group({
      tocenter: ['', Validators.required],
      fromcenter: ['', Validators.required],
      remarks: ['', Validators.required],

    });
    var sessionObject = JSON.parse(localStorage.UserDetails)
    this.ScreenActions.IsEdit = this.service.getActionPermissions("RequestList", "Edit");
    this.ScreenActions.IsTransfer = this.service.getActionPermissions("RequestList", "Transfer");
    this.ScreenActions.IsPDF = this.service.getActionPermissions("RequestList", "SendPDF");
    this.ScreenActions.IsPrint = this.service.getActionPermissions("RequestList", "Print");
    this.ScreenActions.IsSendNotification = this.service.getActionPermissions("RequestList", "SendNotification");
    this.getMasterData(38, 0);//TestList  
    this.empObject.test_type = "0"
    this.test_type="all";
    this.empObject.reportType = "1";
    if (sessionObject.users_Usertype == "A")
      this.getMasterData(10, sessionObject.users_userid);//LocationUsers
    else
      this.getMasterData(30, sessionObject.users_userid);//LocationUsers
  }
  
  ngAfterViewInit(): void {
    //this.dataSource.sort = this.sort;

    if (this.paginator !== undefined)
      this.dataSource.paginator = this.paginator;

    this.range.valueChanges.pipe(

    ).subscribe(event => {
      if (event.end) {
        this.onDateChanged(event);
      }
    });


  }
  generateReport() { 
    this.getReportsData(3, Number(this.empObject.test_type), this.empObject.reportType,0);
  }
  generateReport1() { 
    this.getReportsData(3, Number(this.empObject.test_type), this.empObject.reportType,1);
  }
  flg = 0;
  onDateChanged(ev) {
    if (this.flg == 1) {
      this.startDate = this.datePipe.transform(ev.start._d, "yyyy-MM-dd");
      this.endDate = this.datePipe.transform(ev.end._d, "yyyy-MM-dd");
      this.flg = 0;
    }
    else {
      this.flg++;
    }
  }


  exportExcel() { 
     
    TableUtil.exportTableToExcel("tableData", "TestReportList");
  }
  exportToxilabExcel() {
    TableUtil.exportTableToExcel("tblToxiLabRpt", "TestReportList");
  }
  displayFn(selectedoption) {

    return selectedoption ? selectedoption.location_propertyName : undefined;
  }
  openmodal(modal) {
    this.dialog.open(modal, { width: "700px", height: "auto", disableClose: true });
  }
  openlocationmodal(modal) {
    this.dialog.open(modal, { width: "400px", height: "auto", disableClose: true });
  }
  open_transfer_request(_referenceNo) {
    this.reference_no = _referenceNo;
    var requestObject = this.rows.filter((course) => {
      return course.referenceNo == _referenceNo;
    });
    if (requestObject != undefined && requestObject.length > 0) {
      this.empObject.location = requestObject[0].location;
    }
    this.openlocationmodal(this.transfer_request);
  }
  edit_request(_referenceNo) {
    this.reference_no = _referenceNo;
    var requestObject = this.rows.filter((course) => {
      return course.referenceNo == _referenceNo;
    });
    if (requestObject != undefined && requestObject.length > 0) {
      this.empObject.request_name = requestObject[0].sFirstName
      this.empObject.request_lastName = requestObject[0].lastName
      this.empObject.request_mobileNo = requestObject[0].mobileNo
      this.empObject.request_email = requestObject[0].emailAddress
      this.empObject.request_gender = requestObject[0].gender
      this.empObject.request_DOB = requestObject[0].dateOfBirth
    }
    this.openmodal(this.update_request);
  }
  onToSelectionChange(_ev) {
    if (_ev.option.value != null) {
      this.ToLocation = _ev.option.value;

    }
  }
  onFromSelectionChange(_ev) {
    if (_ev.option.value != null) {
      this.FromLocation = _ev.option.value;

    }
  }
getTotalRecords(testCode:string){ 
  let _tot=0;
  this.rows.forEach(element => {
    var _s=element.clsTestWiseCnts.filter ((course) => {
      return course.testName == testCode;
    });
    if(_s!=undefined && _s.length>0){
      _tot+=_s[0].cnt
    }
  });
  return _tot;
  
}

reloadRoute(){
  window.location.reload();
}

  getReportsData(TypeId, filterId, flg:number,removeZero:number) {
    this.rows=[]; 
    this.testList = [];
    this._splashScreenService.show();
    var sessionobj = JSON.parse(localStorage.UserDetails);
    // if (sessionobj.users_Usertype == "S") {
    //   if (this.ToLocation == null || this.ToLocation.location_Id == undefined) {
    //     this._snackbarservice.showSnackbar("Invalid location")
    //     return;
    //   }
    // }
  
    var clsmodel = {
      Type: Number(flg)==0 ? 2 : 3,
      flg: 1,
      FilterId: filterId,
      startDate: this.startDate,
      endDate: this.endDate,
      IsRemoveZeros:removeZero,
      locationId: this.ToLocation.location_Id!=undefined? this.ToLocation.location_Id.toString() :"", 
      userType:sessionobj.users_Usertype,
      userid:sessionobj.users_userid
    }
    this.service.ExportData(clsmodel).subscribe(result => {
      var resultObj = result;
      this._splashScreenService.hide();
      if (resultObj["status"] == 200) { 
        if (TypeId == 3) {  
          this.rows = resultObj["resultObj"]["clsTests"];
          
        }
        else if (TypeId == 38) {
          this.testList = resultObj["resultObj"];
        }

      }
      else {
        this._snackbarservice.showSnackbar(resultObj["message"]);
      }

    }, error => {
      console.log(error);
    });
    return "";

  }
  _filter(value: string) {
    return this.usersdata.filter(option => option.location_accountName.toLowerCase().includes(value.toLowerCase()) ||
        option.location_propertyName.toLowerCase().includes(value.toLowerCase()));
  }
  getMasterData(flg, filterId) {
    var sessionobj = JSON.parse(localStorage.UserDetails);
    var clsmodel = {
      Type: flg,
      FilterId: filterId,
    }
    this.service.GetMasterData(clsmodel).subscribe(result => {
      var resultObj = result;
      if (resultObj["status"] == 200) {
        if (flg == 34) {
          this.dateFormat = resultObj["resultObj"];
        }
        else if (flg == 30 || flg == 10) {
          this.usersdata = resultObj["resultObj"];           
          this.filteredOptions1 = this.myControl1.valueChanges
            .pipe(
              startWith(''),
              map(value => typeof value === 'string' ? value : value.location_propertyName),
              map(location_propertyName => location_propertyName ? this._filter(location_propertyName) : this.usersdata.slice())
            );
        }
        else if (flg == 38) {
          this.testList = resultObj["resultObj"];
        }

      }

    }, error => {
      console.log(error);
    });
    return "";

  }

  filterCoursesByCategory(_filterid): void {
    // Filter 
    if (_filterid.value == "all")
      this.getMasterData(10, 0,);//Countries  
    else
      this.getMasterData(30, _filterid.value,);//Countries  
  }
  onCheckboxChangeFn(event) {
     if(event.checked){
      this.getReportsData(3, 0, this.empObject.reportType,1);
     }
     else{
      this.getReportsData(3, 0, this.empObject.reportType,0);
     }
  }
  filterCoursesByTerm() {

  }

  addRequest() {
    this.router.navigateByUrl("/auth/request")
  }

}
const routes: Routes = [
  {
    path: 'vendor-report',
    component: VendorReportComponent

  }
];