//import { environment } from '../../../environments/environment';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment'; 
import { catchError, tap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  private APiURL = environment.BaseApiUrl + "Common";
  constructor(
    private http: HttpClient
  ) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  SaveEmpDetails(objcls: any) {
    return this.http.post(this.APiURL + '/RegisterEmployee', objcls);
  }
  employerSubmit(objcls: any) {
    return this.http.post(this.APiURL + '/RegisterEmployeer', objcls);
  }
  SaveRequest(objcls: any) {
    return this.http.post(this.APiURL + '/SaveRequest', objcls);
  }
  SelfTestRequest(objcls: any) {
    return this.http.post(this.APiURL + '/SelfTestRequest', objcls);
  }
  UploadRequestFile(objcls: any) {
    return this.http.post(this.APiURL + '/SaveRequest', objcls);
  }
  GetMasterData(GetMasterModel) {
    return this.http.post(this.APiURL + '/GetMaster', GetMasterModel);
  }
  ReTestRequest(GetMasterModel) {
    return this.http.post(this.APiURL + '/ReTestRequest', GetMasterModel);
  }
  sendVerificationCode(objcls: any) {
    return this.http.post(this.APiURL + '/sendVerificationCode', objcls);
  }
  uploadEmployee(objcls: any) {
    return this.http.post(this.APiURL + '/UploadEmployee', objcls);
  }
  sendRegistrationLink(objcls: any) {
    return this.http.post(this.APiURL + '/sendRegistrationLink', objcls);
  }
getUserIPDetails(){
  return this.http.get('https://geolocation-db.com/json/') 
}

}
