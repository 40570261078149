import { CommonService } from './Service/common.service'; 
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(public ServiceLoader: LoaderService, private ServiceCommon: CommonService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = req.headers.set("ApiKey", environment.ApiKey);
    let NewReq: any; 
    if (req.url.includes("geolocation-db.com/json"))
      NewReq = req.clone();
    else
      NewReq = req.clone({ headers });

 
    return next.handle(NewReq).pipe(
      finalize(
        () => {
          this.ServiceLoader.isloader.next(false);
        }


      )
    );

  }
}




