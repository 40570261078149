<div id="file-list" class="page-layout simple">
    <div class="center">
        <div
            class="header accent p-24"
            fxLayout="row"
            fxLayoutAlign="space-between start"
        >
            <div
                class="breadcrumb text-truncate h1"
                fxLayout="row"
                fxLayoutAlign="start center"
                [@animate]="{value:'*',params:{x:'50px'}}"
            >
                <div fxLayout="row" fxLayoutAlign="start center">
                    <span>Vendor Report</span>
                </div>
            </div>
        </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content p-24">
        <div fxLayout="column" fxLayoutAlign="center">
            <fieldset>
                <legend>
                    <h3>Filters</h3>
                </legend>
                <div
                    class="filters"
                    fxLayout="column"
                    fxLayoutAlign="center center"
                    fxLayout.gt-xs="row"
                    fxLayoutAlign.gt-xs="start center"
                >
                    <mat-form-field
                        appearance="fill"
                        fxFlex="25"
                        class="mr-4 category-selector"
                    >
                        <mat-label>Enter a date range</mat-label>
                        <mat-date-range-input
                            [formGroup]="range"
                            [rangePicker]="picker"
                        >
                            <input
                                matStartDate
                                formControlName="start"
                                placeholder="Start date"
                            />
                            <input
                                matEndDate
                                formControlName="end"
                                placeholder="End date"
                            />
                        </mat-date-range-input>
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="picker"
                        ></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>

                        <mat-error
                            *ngIf="range.controls.start.hasError('matStartDateInvalid')"
                            >Invalid start date
                        </mat-error>
                        <mat-error
                            *ngIf="range.controls.end.hasError('matEndDateInvalid')"
                            >Invalid end date</mat-error
                        >
                    </mat-form-field>
                    <mat-form-field
                        appearance="fill"
                        fxFlex="25"
                        class="mr-4 category-selector"
                    >
                        <mat-label>Test Type</mat-label>
                        <mat-select [(ngModel)]="empObject.test_type" required>
                            <mat-option value="0"> All </mat-option>
                            <mat-option
                                *ngFor="let role of testList"
                                [value]="role.test_id"
                            >
                                {{role.test_name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field
                        appearance="fill"
                        fxFlex="25"
                        class="mr-4 category-selector"
                    >
                        <mat-label>Report Type</mat-label>
                        <mat-select
                            [(ngModel)]="empObject.reportType"
                            [disabled]
                            (selectionChange)="generateReport()"
                            required
                        >
                            <mat-option value="1"> State Report </mat-option>
                            <mat-option value="0"> Billing </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field
                        appearance="fill"
                        fxFlex="25"
                        class="category-selector"
                    >
                        <mat-label>Center</mat-label>
                        <input
                            type="text"
                            placeholder="Pick one"
                            aria-label="Number"
                            matInput
                            [formControl]="myControl1"
                            [matAutocomplete]="auto"
                        />
                        <mat-autocomplete
                            autoActiveFirstOption
                            #auto="matAutocomplete"
                            (optionSelected)="onToSelectionChange($event)"
                            [displayWith]="displayFn"
                        >
                            <mat-option
                                *ngFor="let _option of filteredOptions1 | async"
                                [value]="_option"
                            >
                                {{_option.location_accountName + " - " +
                                _option.location_propertyName}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </fieldset>

            <div
                class="filters"
                fxLayout="column"
                fxLayoutAlign="end center"
                fxLayout.gt-xs="row"
                fxLayoutAlign.gt-xs="end"
            >
                <span
                    class="mb-8 right span_label"
                    *ngIf="empObject.reportType=='1'"
                    >Report is displaying only with status(Detected,
                    Not-Detected, Inconclusive) &nbsp;
                </span>
                <mat-checkbox
                    [(ngModel)]="empObject.wrongAttempts"
                    (change)="onCheckboxChangeFn($event)"
                    style="display: none"
                    [ngModelOptions]="{standalone: true}"
                >
                    Remove zero data record[s]
                </mat-checkbox>

                <button
                    mat-raised-button
                    class="blue_btn mb-8 right"
                    (click)="generateReport()"
                    [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}"
                >
                    <mat-icon style="font-size: 20px">settings</mat-icon>
                    Generate
                </button>
                <!-- <button mat-raised-button class="primary mb-8 right"  *ngIf="empObject.reportType=='1'" (click)="generateReport1()"
                [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
                <mat-icon style="font-size: 20px;">cloud_download</mat-icon>
                Upload To FTP
            </button> -->
                <button
                    mat-raised-button
                    class="primary mb-8 right"
                    (click)="exportExcel()"
                    [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}"
                >
                    <mat-icon style="font-size: 20px">cloud_download</mat-icon>
                    Export List
                </button>
                <button
                    mat-raised-button
                    class="warn mb-8 right"
                    (click)="reloadRoute()"
                    [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}"
                >
                    <mat-icon style="font-size: 20px">refresh</mat-icon>
                    Clear Filters
                </button>
            </div>
            <div fxLayout="column" fxLayoutAlign="center">
                <div class="content">
                    <div
                        *ngIf="rows!=undefined && rows.length <= 0"
                        align="center"
                        class="p-12"
                        fxflex=""
                        ng-reflect-flex=""
                        style="flex: 1 1 0%; box-sizing: border-box"
                    >
                        <h3>No record[s] found</h3>
                    </div>
                    <div
                        style="width: 100%; height: 600px; overflow: auto"
                        id="_divL"
                    >
                        <table
                            id="tableData"
                            width="100%"
                            *ngIf="empObject.reportType=='1' && rows.length > 0"
                            class="simple invoice-table"
                        >
                            <thead>
                                <tr class="accent1 mat-header-row bdr-radius">
                                    <!-- <th>S No.</th> -->
                                    <th>Test Performed</th>
                                    <th>Test Result</th>
                                    <th>Result Date</th>
                                    <th>Test Ordered Date</th>
                                    <th>Report Date</th>
                                    <th>Specimen Collected Date</th>
                                    <th>AcessionSpecimenID</th>
                                    <th>Perf Facility Name; CLIA#</th>
                                    <th>Perf Facility Zip Code</th>
                                    <th>Reporting Facility</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Patient Address</th>
                                    <th>Patient City</th>
                                    <th>Patient State</th>
                                    <th>Patient Zip Code</th>
                                    <th>Patient County</th>
                                    <th>Patient Phone</th>
                                    <th>Age</th>
                                    <th>DOB</th>
                                    <th>Race</th>
                                    <th>Ethnicity</th>
                                    <th>Sex</th>
                                    <th>PatientMRN</th>
                                    <th>Ordering provider</th>
                                    <th>Provider Address</th>
                                    <th>Provider City</th>
                                    <th>Provider State</th>
                                    <th>Provider Zip</th>
                                    <th>Provider Phone</th>
                                    <th>LOINC CODE</th>
                                    <th>LOINC DESCRIPTION</th>
                                    <th>SNOMED CODE</th>
                                    <th>SNOMED DESCRIPTION</th>
                                    <th>NPI</th>
                                    <th>Device/Method</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    class="mat-header-row"
                                    *ngFor="let row of rows; let i=index"
                                >
                                    <!-- <td>{{i+1}}</td> -->
                                    <td>{{row.testname}}</td>
                                    <td>{{row.status}}</td>
                                    <td>{{row.result_updatedDate}}</td>
                                    <td>{{row.orderedDate}}</td>
                                    <td>{{row.result_updatedDate}}</td>
                                    <td>{{row.createddate}}</td>
                                    <td>{{row.request_code}}</td>
                                    <td>{{row.location_cliaid}}</td>
                                    <td>{{row.locationZipCode}}</td>
                                    <td>{{row.locationName}}</td>
                                    <td>{{row.fname}}</td>
                                    <td>{{row.lname}}</td>
                                    <td>{{row.request_street1}}</td>
                                    <td>{{row.request_city}}</td>
                                    <td>{{row.request_state}}</td>
                                    <td>{{row.request_zipcode}}</td>
                                    <td>{{row.request_county}}</td>
                                    <td>{{row.request_mobileNo}}</td>
                                    <td>{{row.patientAge}}</td>
                                    <td>{{row.request_dob}}</td>
                                    <td>{{row.request_race}}</td>
                                    <td>{{row.request_ethnicity}}</td>
                                    <td>{{row.request_gender}}</td>
                                    <td>{{row.patientMRNID}}</td>
                                    <td>{{row.orderingprovider}}</td>
                                    <td>{{row.providerAddress}}</td>
                                    <td>{{row.providerCity}}</td>
                                    <td>{{row.providerState}}</td>
                                    <td>{{row.providerZip}}</td>
                                    <td>{{row.providerPhone}}</td>
                                    <td>{{row.lOINCCODE}}</td>
                                    <td>{{row.lOINCDESCRIPTION}}</td>
                                    <td>{{row.sNOMEDCODE}}</td>
                                    <td>{{row.sNOMEDDESCRIPTION}}</td>
                                    <td>{{row.npi}}</td>
                                    <td>{{row.device}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <!-- <table id="tableData" width="100%" *ngIf="empObject.reportType=='0' && rows.length > 0" class="simple invoice-table">
                            <thead>
                                <tr class="accent1 mat-header-row bdr-radius">
                                    <th>S No.</th>
                                   <th>Clinify No.</th>
                                    <th>Name</th> 
                                    <th>Mobile No</th>
                                    <th>Email ID</th>
                                    <th>DOB</th>
                                    <th>Gender</th>
                                    <th>Req. Tests</th>
                                    <th>LOINC</th>
                                    <th>Location</th>
                                    <th>Requested Date</th> 
                                    <th>Submitted By</th> 
                                    <th>Status</th>  
                                    <th>Ethnicity</th>  
                                    <th>Race</th>  
                                    <th>Street</th>   
                                    <th>City</th>  
                                    <th>County</th>  
                                    <th>State</th>  
                                    <th>PostalCode</th> 
                                    <th>Country</th> 
                                    <th>Insurance Name</th>
                                    <th>Payer Code</th>
                                    <th>Policy Id</th>
                                    <th>Group</th>
                                    <th>Subscriber FName</th>
                                    <th>Subscriber LName</th>
                                    <th>Relationship</th>
                                    <th>Payment Type</th>
                            </thead>
                            <tbody>
                                <tr class="mat-header-row" *ngFor="let row of rows; let i=index">
                                    <td>{{i+1}}</td>
                                   <td>{{row.request_code}}</td>
                                    <td>{{row.name}}</td>
                                    <td>{{row.request_mobileNo}}</td>   
                                    <td>{{row.request_email}}</td>                                                                           
                                    <td>{{row.request_dob}}</td> 
                                    <td>{{row.request_gender}}</td> 
                                    <td>{{row.testname}}</td> 
                                    <td>{{row.testname=='RAPID' ? '94558-4' :'94500-6'}}</td> 
                                    <td>{{row.location}}</td> 
                                    <td>{{row.createddate}}</td>
                                    <td>{{row.updatedBy}}</td> 
                                    <td>{{row.status}}</td> 
                                    <td>{{row.request_ethnicity}}</td> 
                                    <td>{{row.request_race}}</td> 
                                    <td>{{row.request_street1}}</td> 
                                    <td>{{row.request_city}}</td> 
                                    <td>{{row.request_county}}</td> 
                                    <td>{{row.request_state}}</td> 
                                    <td>{{row.request_zipcode}}</td> 
                                    <td>{{row.request_country}}</td> 
                                    <td>{{row.request_payerCode =="" && row.request_policyID!="" ? "95964" : row.request_payerName}}</td> 
                                    <td>{{row.request_payerCode =="" && row.request_policyID!="" ? "95964" : row.request_payerCode}}</td> 
                                    <td>{{row.request_policyID}}</td> 
                                    <td>{{row.request_group}}</td> 
                                    <td>{{row.request_subscribe_fname}}</td> 
                                    <td>{{row.request_subscribe_lname}}</td> 
                                    <td>{{row.request_subscribe_relation}}</td> 
                                    <td>{{row.paymentOption}}</td> 
                                </tr>
                                
                            </tbody>
                        </table> -->
                        <table
                            id="tableData"
                            width="100%"
                            *ngIf="empObject.reportType=='0' && rows.length > 0"
                            class="simple invoice-table"
                        >
                            <thead>
                                <tr class="accent1 mat-header-row bdr-radius">
                                    <th>Clinify Number</th>
                                    <th>Collection Date</th>
                                    <th>Test Type</th>
                                    <th>Patient First Name</th>
                                    <th>Patient Last Name</th>
                                    <th>Gender</th>
                                    <th>DOB</th>
                                    <th>Client ID (CLIA #)</th>
                                    <th>Client Name (Company)</th>
                                    <th>Insurance Company Name</th>
                                    <th>Policy ID/Number</th>
                                    <th>Payer Code</th>
                                    <th>Group</th>
                                    <th>Subscriber First Name</th>
                                    <th>Subscriber Last Name</th>
                                    <th>Relationship</th>
                                    <th>Patient Street Address</th>
                                    <th>Patient City</th>
                                    <th>Patient State</th>
                                    <th>Patient Zip/Postal Code</th>
                                    <th>Patient Country</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    class="mat-header-row"
                                    *ngFor="let row of rows; let i=index"
                                >
                                    <td>{{row.request_code}}</td>
                                    <td>{{row.createddate}}</td>
                                    <td>{{row.testname}}</td>
                                    <td>{{row.fname}}</td>
                                    <td>{{row.lname}}</td>
                                    <td>{{row.request_gender}}</td>
                                    <td>{{row.request_dob}}</td>

                                    <td>{{row.location_cliaid}}</td>
                                    <td>{{row.locationName}}</td>
                                    <td>{{row.request_payerName}}</td>
                                    <td>{{row.request_payerCode}}</td>
                                    <td>{{row.request_policyID}}</td>
                                    <td>{{row.request_group}}</td>
                                    <td>{{row.request_subscribe_fname}}</td>
                                    <td>{{row.request_subscribe_lname}}</td>
                                    <td>{{row.request_subscribe_relation}}</td>
                                    <td>{{row.request_street1}}</td>
                                    <td>{{row.request_city}}</td>
                                    <td>{{row.request_state}}</td>
                                    <td>{{row.request_zipcode}}</td>
                                    <td>{{row.request_country}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <!-- / CONTENT -->
        </div>
    </div>
</div>
