import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from 'app/common/Service/common.service';
import { fuseAnimations } from '@fuse/animations';
import { MatDialog } from '@angular/material/dialog';
import { AgmCoreModule } from '@agm/core';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { SnackbarService } from 'app/common/Service/snackbar.service';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { element } from 'protractor';
import { arraysAreNotAllowedMsg } from '@ngrx/store/src/models';
import { environment } from '../../../../../environments/environment';
import { forEach, forEachRight } from 'lodash';
@Component({
    selector: 'location-qr',
    templateUrl: './location-qr.component.html',
    styleUrls: ['./location-qr.component.scss']
})
export class LocationQrComponent implements OnInit {
    numofcopies: number = 0;
    categories: any[];
    courses: any[];
    _loc_qr: string = "";
    usersdata: any = [];
    locationWiseUsers: any = [];
    coursesFilteredByCategory: any[];
    filteredCourses: any = []; lstlatlngs: any = []; lstlatlngs1: any = [];
    alternateColor: any[] = ["android", "web", "cloud", "firebase"];
    currentCategory: string;
    searchTerm: string;
    usersearchTerm: string;
    counter: number = 0;
    _formGroup: any = [];
    selLocation: number = 0;
    @ViewChild('add_Location') add_Location: ElementRef;
    @ViewChild('printqrdiv') printqrdiv: ElementRef;
    @ViewChild('assignuserpop') assignuserpop: ElementRef;
    @ViewChild('alertpop') alertpop: ElementRef;
    zoom: number;
    empObject: any = {};
    btnText: string = "Save";
    location: Location;
    loading: boolean;
    locationcode: string = "";
    deleteFlg: number = 0;
    addlocation: FormGroup;
    printQRcop: FormGroup;
    printdiv: FormGroup;
    TotalActive: number = 0;
    TotalInActive: number = 0;
    // Private
    private ApiUrl = environment.BaseApiUrl;
    private ApplicationUrl = environment.ApplicationUrl;
    private _unsubscribeAll: Subject<any>;


    constructor(
        private service: CommonService,
        private _snackbarservice: SnackbarService,
        private ref: ChangeDetectorRef, private _httpClient: HttpClient,
        private dialog: MatDialog,
        private _formBuilder: FormBuilder,
        private _splashScreenService: FuseSplashScreenService,
    ) {
        // Set the defaults
        this.currentCategory = 'all';
        this.searchTerm = '';

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    getAlternateColor() {
        if (this.counter == 3)
            this.counter = 0;
        else
            this.counter++;
        return this.alternateColor[this.counter] + "-bg";

    }
    /**
     * On init
     */
    ngOnInit(): void {
        var sessionObject = JSON.parse(localStorage.UserDetails)
        if (sessionObject.users_Usertype == "A")
            this.getMasterData(10, sessionObject.users_userid, '');//LocationUsers
        else
            this.getMasterData(30, sessionObject.users_userid, '');//LocationUsers
        this.addlocation = this._formBuilder.group({
            frameName: ['', Validators.required],
            Url: ['', Validators.required],
        });
        this.printQRcop = this._formBuilder.group({
            numofcopies: [''],
        })
        this.empObject.frameColor = "#98ad38";
        this.empObject.frameName = "bottom-frame";
        this.empObject.textcolor = "#000000";
    }
    addLocation(_locationCode) {
        this.locationcode = _locationCode;
        this.empObject.Url = this.ApplicationUrl + "auth/register-3/" + this.locationcode;;
        this.locationSubmit();
    }
    closewindow() {
        this.locationcode = "";
        this.dialog.closeAll();
    }
    downloadQR(_locationQr) {
        this.getMasterData(65, 0, _locationQr);

    }
    prntQrcop() {
        var ele = '';
        let counter = 1;
        var locName = "";
        var locQR = "";
        let filterLoc = this.coursesFilteredByCategory.filter((course) => {
            return course.location_code == this._loc_qr;
        });
        if (filterLoc != null && filterLoc.length > 0) {
            locName = filterLoc[0].location_accountName
            locQR= filterLoc[0].qrCode
        }
        for (var i = 0; i < this.numofcopies; i++) {
            ele += '<div style="width:46%;margin:2%;float: left;font-family:Arial"><h1 style="width:100%;text-align:center;float: left;font-size:18px">' + locName + '</h1><div style="width:25%;height:200px;margin-right: 2%;float: left;"><img style="height:100%;width:100%" src="' + locQR + '" /></div><div style="width:72%;float: left;">';
            ele += '<span style="font-size:12px;text-align:center;margin:10px 0;display:inline-block;">Scan the QR code to complete your registration</span><div style="margin:10px 0;display:inline-block;width:100%;"><label style="margin-right:5px;">Name:</label><input type="text"/ style="width: 86%;border: none;border-bottom: 1px solid;"></div>';
            ele += '<div style="margin:10px 0;display:inline-block;width:100%;"><label style="margin-right:5px;">CLINIFY:</label><input type="text"/ style="width: 86%;border: none;border-bottom: 1px solid;"></div><div style="margin:10px 0;display:inline-block;width:100%;"><label style="margin-right:5px;">DOB:</label><input type="text"/ style="width: 86%;border: none;border-bottom: 1px solid;"></div><div style="margin:10px 0;display:inline-block;width:100%;"><input type="checkbox" value="Positive"><label for="Positive">Positive</label>&nbsp;&nbsp;&nbsp;<input type="checkbox" value="Negative"><label for="Negative">Negative</label>&nbsp;&nbsp;&nbsp;<input type="checkbox" value="Inconclusive"><label for="Inconclusive">Inconclusive</label></div></div></div>';
            if (counter == 6) {
                ele += '<div style="clear: both;page-break-after: always;"></div>'
                counter = 1;
            }
            else {
                counter = counter + 1;
            }
        }
        let printContents, popupWin;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write('<head><title>Print QR Copies</title> \
    \ <style></style> \
   </head>');
        popupWin.document.write('<div>' + ele + '</div>');
        popupWin.document.write('</body></html>');
        setTimeout(() => {
            popupWin.print();
           }, 2000);
        popupWin.document.close();
    }
    locationSubmit() {
        var clsmodel = {
            frame_name: this.empObject.frameName,
            qr_code_text: this.empObject.Url,
            frame_color: this.empObject.frameColor,
            frame_text_color: this.empObject.textcolor,
            foreground_color: this.empObject.textcolor,
            image_width: 500,
            background_color: "#ffffff",
            image_format: "SVG",
            locationCode: this.locationcode,

        }
        this.service.generateQRCode(clsmodel).subscribe(result => {
            var resultObj = result;
            if (resultObj["status"] == 200) {
                this.dialog.closeAll();
                window.location.reload();
            }
            else {
                this._snackbarservice.showSnackbar(resultObj["message"])
            }


        }, error => {
            console.log(error);
        });
        return "";
    }
    printqr(_locationQr) {
        this._loc_qr = _locationQr;
        this.openmodal1(this.printqrdiv);
    }
    openmodal(modal) {
        this.dialog.open(modal, { width: "700px", height: "auto", disableClose: true });
    }
    openmodal1(modal) {
        this.dialog.open(modal, { width: "400px", height: "auto", disableClose: true });
    }

    getMasterData(flg, filterId, code) {
        this._splashScreenService.show();
        var sessionobj = JSON.parse(localStorage.UserDetails);
        var clsmodel = {
            Type: flg,
            FilterId: filterId,
            Code: code
        }
        this.service.GetMasterData(clsmodel).subscribe(result => {
            this._splashScreenService.hide();
            var resultObj = result;
            if (resultObj["status"] == 200) {
                if (flg == 10 || flg == 30) {
                    this._splashScreenService.show();
                    this.filteredCourses = resultObj["resultObj"];
                    var _filter = [];
                    this.filteredCourses.forEach(element => {
                        element.location_className = this.getAlternateColor();
                        var clsmodel1 = {
                            Type: 65,
                            FilterId: 0,
                            Code: element.location_code
                        }
                        this.service.GetMasterData(clsmodel1).subscribe(result => {
                            element.qrCode=result["resultObj"]["awsLink"];
                            element.location_shortURL=result["resultObj"]["url"];
                            _filter.push(element);
                        }, error => {
                            console.log(error);
                        }); 
                       
                    }); 
                   setTimeout(() => { 
                    _filter=_filter.sort(function(a, b) {
                        return a.location_Id === b.location_Id ? 0 : a.location_Id < b.location_Id ? -1 : 1;
                      });
                   }, 2000);
                    this.filteredCourses = _filter;
                    this.coursesFilteredByCategory = this.filteredCourses;
                    this._splashScreenService.hide();
                  

                }
                else if (flg == 65) {
                    var locationimage = "";
                    if (resultObj["resultObj"]["_userList"]["locationLogo"] == "../../../../assets/images/no-file.png")
                        locationimage = "";
                    else
                        locationimage = resultObj["resultObj"]["_userList"]["locationLogo"];
 
                    var ele = '<div style="width:100%"><center><img style="width:128px;height:100px" src="' + locationimage + '" /> <br/> <br/>';
                    ele += '<img style="width:800px;height:600px" src="' + resultObj["resultObj"]["awsLink"] + '" /> <br/> <br/>Link:  ' + resultObj["resultObj"]["url"] + ' <br/> <br/> <br/>Website:  ' + environment.ApplicationUrl + ' </center></div>';
                    let printContents, popupWin;
                    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
                    popupWin.document.open();
                    popupWin.document.write('<html><head><title>' + resultObj["resultObj"]["_userList"]["location_propertyName"] + '</title> \
                    \ <style></style> \
                   </head>');
                    popupWin.document.write('<body><div>' + ele + '</div>');
                    popupWin.document.write('</body></html>');
                   setTimeout(() => {
                    popupWin.print();
                   }, 2000);
                    
                    popupWin.document.close();
                  
                }
                else if (flg == 18) {
                    this.usersdata = resultObj["resultObj"];
                }
            }


        }, error => {
            console.log(error);
        });
        return "";

    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    searchlist(): void {
        const searchTerm = this.usersearchTerm.toLowerCase();
        if (searchTerm === '') {

        }

    }
    /**
     * Filter courses by term
     */
    filterCoursesByTerm(): void {
        const searchTerm = this.searchTerm.toLowerCase();

        // Search
        if (searchTerm === '') {
            this.filteredCourses = this.coursesFilteredByCategory;
        }
        else {
            this.filteredCourses = this.coursesFilteredByCategory.filter((course) => {
                return course.location_accountName.toLowerCase().includes(searchTerm)||
                course.location_code.toLowerCase().includes(searchTerm)||
                course.location_propertyName.toLowerCase().includes(searchTerm);
            });
        }
    }

}
