import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatFormFieldModule } from '@angular/material/form-field'; 
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { EmployeeProfileService } from 'app/main/pages/employee-edit/employee-edit.service';
import { EmpProfileComponent } from 'app/main/pages/employee-edit/employee-edit'; 
import { EmpProfileAboutComponent } from 'app/main/pages/employee-edit/tabs/about/emp.about.component'; 


const routes = [
    {
        path     : 'employee-edit/:id',
        component: EmpProfileComponent,
        resolve  : {
            profile: EmployeeProfileService
        }
    },
     
];

@NgModule({
    declarations: [
        EmpProfileComponent, 
        EmpProfileAboutComponent,  
    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        FuseSharedModule,
    ],
    providers   : [
        EmployeeProfileService
    ]
})
export class EmpProfileModule
{
}
