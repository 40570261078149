<!-- PROGRESS BAR -->
<fuse-progress-bar></fuse-progress-bar>
<!-- / PROGRESS BAR -->

<!-- VERTICAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-1'">
    <vertical-layout-1></vertical-layout-1>
</ng-container>
<!-- / VERTICAL LAYOUT 1 --> 
<!-- HORIZONTAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'horizontal-layout-1'">
    <horizontal-layout-1></horizontal-layout-1>
</ng-container>
<!-- / HORIZONTAL LAYOUT 1 -->
<fuse-sidebar name="themeOptionsPanel" class="theme-options-sidebar" position="right" [invisibleOverlay]="true">
    <fuse-theme-options></fuse-theme-options>
</fuse-sidebar>
<!-- / THEME OPTIONS PANEL -->
<ng-template #sessionpopup>
    <!--<div class="mb-24" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="column">-->
        <h2>Session Timeout Warning!</h2>
        <span>
            You are being timed out due to inactivity. Do you want to continue the current login session ?
        </span>

    <!--</div>-->
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="start center">
        <span class="final_timer">Loging Out in {{display}}</span>
        <button mat-raised-button color="secondary"
                class="mr-8"
                (click)="cancelConfirm()"
                aria-label="Cancel"
                matTooltip="Cancel">
            <span class="mat-button-wrapper"><mat-icon aria-label="Cancel" class="mat-icon material-icons" role="img" aria-hidden="true">cancel</mat-icon> Cancel </span>
        </button>

        <button mat-raised-button color="primary"
                class="save-button mat-accent"
                (click)="confirm()"
                aria-label="Confirm">
            <span class="mat-button-wrapper"><mat-icon aria-label="Confirm" class="mat-icon material-icons" role="img" aria-hidden="true">check_circle_outline</mat-icon> Stay Logged in </span>

        </button>

    </div>
</ng-template>
<div class="page-loder" *ngIf="loader">
    <!---->
    <div class="loader">
        <mat-spinner></mat-spinner>
    </div>
    
</div> 
<div class="staging_logo" *ngIf="!hideStaging" >
    <img src="assets/images/staging.png">
</div>

