<div id="register" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">
    <div id="register-intro1" fxShow.gt-xs>
        <div class="logo" [@animate]="{ value: '*', params: { scale: '0.2' } }">
            <img src="assets/images/logos/logo.png" />
        </div>

        <div
            class="title"
            [@animate]="{ value: '*', params: { delay: '50ms', y: '25px' } }"
        >
            Thank you for Registering
        </div>

        <div
            class="description"
            [@animate]="{ value: '*', params: { delay: '100ms', y: '25px' } }"
        >
            Please note your confirmation code <br />
            <strong class="dcl">{{ confirmationCode }} </strong>for future use.
        </div>
        <div
            class="description"
            [@animate]="{ value: '*', params: { delay: '100ms', y: '25px' } }"
        >
            <a class="link" [routerLink]="'/auth/request'"
                >Track your Request here</a
            >
        </div>
    </div>
    <div class="overlay"></div>
</div>
