import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgModule } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { RegisterService } from '../register/register.service';
import { SnackbarService } from 'app/common/Service/snackbar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { MatDialog } from '@angular/material/dialog';

import { ActivatedRoute, Router } from '@angular/router';
@Component({
    selector: 'register-2',
    templateUrl: './register-2.component.html',
    styleUrls: ['./register-2.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class Register2Component implements OnInit, OnDestroy {
    registerForm: FormGroup;
    empObject: any = {};

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _Registerservice: RegisterService,
        private _snackbarservice: SnackbarService,
        private _splashScreenService: FuseSplashScreenService,
        private router: Router
    ) {

        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();


    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.registerForm = this._formBuilder.group({
            organizationName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            Cnumber: ['null', [Validators.required, Validators.pattern("[0-9 ]{10}")]],
            address: ['', Validators.required],
            acceptterms:[false,Validators.requiredTrue]

        }); 


    }

    employerSubmit() {
        if (this.registerForm.invalid) {
            return;
        }
        this._splashScreenService.show();

        this._Registerservice.employerSubmit(this.empObject).subscribe(result => {

            var resultObj = result;

            if (resultObj["status"] == 200) {
                this.registerForm.reset();
                this._snackbarservice.showSnackbar(resultObj["message"]);                
                this.router.navigate(['/auth/login']);
            }
            else
                this._snackbarservice.showSnackbar(resultObj["message"]);
            this._splashScreenService.hide();
        }, error => {
            console.log(error);
            this._splashScreenService.hide();
        });

    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    validateNumber(event) { 
        const keyCode = event.keyCode;

        const excludedKeys = [8, 37, 39, 46];
        if (keyCode == 9 || keyCode == 13)
            return;
        if (!((keyCode >= 48 && keyCode <= 57) ||
            (keyCode >= 96 && keyCode <= 105) ||
            (excludedKeys.includes(keyCode)))) {
            event.preventDefault();
        }
    }


}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if (!control.parent || !control) {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if (!password || !passwordConfirm) {
        return null;
    }

    if (passwordConfirm.value === '') {
        return null;
    }

    if (password.value === passwordConfirm.value) {
        return null;
    }

    return { passwordsNotMatching: true };
};
