<div id="profile" class="page-layout simple tabbed">

    <!-- HEADER -->
    <div class="header p-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
        fxLayoutAlign.gt-sm="space-between end">

        <div class="user-info" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="start center">
            <img class="profile-image avatar huge" src="{{this.imagePath}}"
                [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
            <div class="name" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                {{_empData.firstName + " "+ _empData.lastName}}
            </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between">
            <div class="actions" fxLayout="row" style="display: none;">
                <button mat-raised-button color="accent" style="display: none;" aria-label="Follow">
                    <mat-icon>edit</mat-icon> Edit
                </button>
            </div>
            <div class="file-uploader">
                <input hidden type="file" (change)="onFileChanged($event)" #fileInput />
                <button mat-raised-button color="primary" class="add-file-button" (click)="fileInput.click()"
                    aria-label="Upload file" [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
                    <mat-icon>cloud_upload</mat-icon> Upload Photo
                </button>
            </div>
        </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content">

        <mat-tab-group dynamicHeight="true">

            <mat-tab label="About">
                <employee-edit-about></employee-edit-about>
            </mat-tab>
           
            <mat-tab label="Request List">
                <div id="about" class="p-24" fxLayout="row wrap">
                
                    <div class="about-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="100">

                        <div class="profile-box info-box general" fxLayout="column">

                            <header class="primary">
                                <div class="title">Request List</div>
                            </header>

                            <div class="content">
                                <div class="load-more-div">
                                    <button mat-flat-button color="primary" *ngIf="IsSelected==1"  (click)="openreq_bulk()">
                                        <mat-icon style="font-size:20px">print</mat-icon> Bulk Print
                                    </button> 
                                </div>
                                <table width="100%" class="simple invoice-table">
                                    <thead>
                                        <tr class="mat-header-row bdr-radius">
                                            <th>  
                                                 <input type="checkbox"  [ngModelOptions]="{standalone: true}"
                                                (change)="Check_all()"  [(ngModel)]="ng_Check_all">
                                                  </th>
                                            <th>Clinify No</th> 
                                            <th>Request Date</th>
                                            <th>Location</th>
                                            <th>Symptoms</th>
                                            <th>Test</th>
                                            <th>Status</th>
                                            <th>Print</th>
                                          </tr>  
                                    </thead>
                                    <tbody>
                                        <tr class="mat-header-row" *ngFor="let row of _reqList;let i = index">
                                            <td>  
                                                <input type="checkbox" [name]="row.referenceNo" [ngModelOptions]="{standalone: true}"
                                                (change)="emp_valueChange(i,row)" [id]="row.referenceNo" [(ngModel)]="row.emp_select">
                                                    
                                            </td>
                                            <td> {{row.referenceNo}}</td> 
                                            <td>{{row.requestDate}}</td>
                                            <td>{{row.location}}</td>
                                            <td>{{row.selectedsymptons}}</td>
                                            <td>{{row.selectedTest}}</td>
                                            <td [ngClass]="{'indigo-100-bg': row.testResult === 'Pending', 'red-100-bg' : row.testResult === 'Not Detected','green-100-bg' : row.testResult === 'Detected' }">{{row.testResult}}</td>
                                            <td>
                                                <button
                                        mat-button
                                        matTooltip="Print" 
                                        class="button-min-width"
                                        (click)="PrintRecord(row)"
                                    >
                                        <mat-icon class="ft-18">print</mat-icon>
                                    </button>
                                  </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>

    </div>
    <!-- / CONTENT -->

</div>