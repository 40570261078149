import { Component, OnDestroy, OnInit, AfterViewChecked, ViewEncapsulation, NgZone, Injectable, Directive,ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NgModule, ViewChild, ElementRef } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { RequestService } from '../request/request.service';
import { SnackbarService } from 'app/common/Service/snackbar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { environment } from '../../../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import * as $ from 'jquery';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    HttpClient,
    HttpEventType,
    HttpErrorResponse
} from "@angular/common/http";
import { filter } from "rxjs/operators";
import { DatePipe } from '@angular/common';
import { de, th } from 'date-fns/locale';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
export const MY_DATE_FORMATS = {
    parse: {
        dateInput: 'MM/DD/YYYY',
    },
    display: {
        dateInput: 'MM/DD/YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    },
};
declare var google: any;
import SignaturePad from "signature_pad"; 
@Component({
    moduleId: module.id,
    selector: 'auth/request',
    templateUrl: './request.component.html',
    styleUrls: ['./request.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    host: {
        '(keyup.ctrl.k)': 'clear()'
      }
})

export class RequestComponent implements OnInit, OnDestroy, AfterViewChecked {

    frmValidate: FormGroup; frmPatientAddress: FormGroup; frmInsuranceInfo: FormGroup;
    frmUpload: FormGroup; frmTrackRequest: FormGroup;
    frmPatientInfo: FormGroup; frmVerification: FormGroup;
    @ViewChild("verifycode1") input0: ElementRef;
    @ViewChild("verifycode2") input1: ElementRef;
    @ViewChild('search') public searchElementRef: ElementRef;
    @ViewChild('streetaddress') public streetaddressElementRef: ElementRef;
    @ViewChild("verifycode3") input2: ElementRef;
    @ViewChild('fileInput') fileInput: ElementRef;
    @ViewChild("verifycode4") input3: ElementRef;
    @ViewChild('location_confirmation') location_confirmation: ElementRef;
    
    
    sig: SignaturePad;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

    confirmEmailValid: boolean = false;
    userlatitude: number;
    userlongitude: number;
    zoom: number;
    useraddress: string;
    geoCoder: any;
    empObject: any = {};
    TrackResult: any = [];
    dateFormat: string;
    selectedFileName: string;
    TestList: any = [];
    QrLocationId: string = ""; 
    locationNm:string="Clinify IO";
    locationImage:string="";
    insuranceOption:number=0;
    progress: number;
    viewHide: number = 0;
    @ViewChild("canvas", { static: false }) public canvas: ElementRef; 
    lat = 41.8909409;
    lng = -87.6898801;
    isUploadPending: boolean = true;
    _countries: any = [];
    _states: any = [];
    _previousHistory: any = [];
    hideHistory: boolean = false;
    ReferenceNo: string = "";
    VCode: string = "";
    minDate: Date;
    maxDate: Date;
    icon = {
        url: './assets/images/pin_location.png',
        scaledSize: {
            width: 32,
            height: 32
        }
    };
    symptons: any = []; symptonsData: any = [];
    insuranceList: any = [];
    filteredCourses: any = []; totalLocations: any = []; filteredLocations: any = []; lstlatlngs: any = []; lstlatlngs1: any = [];
    // Private
    myControl = new FormControl();
    filteredOptions: Observable<string[]>;
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _Registerservice: RequestService,
        private datePipe: DatePipe,
        private _snackbarservice: SnackbarService,
        private _splashScreenService: FuseSplashScreenService,
        private router: Router,
        private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone,
        private http: HttpClient,
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute, private elementRef: ElementRef,
        private changeDetectorRef: ChangeDetectorRef
    ) {


        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        }; 
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        const currentYear = new Date().getFullYear();
        this.minDate = new Date(currentYear - 100, 0, 1);
        this.maxDate = new Date(currentYear, new Date().getMonth(), new Date().getDate());
        this.empObject.Race = "";
        this.empObject.Ethnicity = "";
        this.QrLocationId = "";
        this.empObject.IsEnableEmailVerification = true;
        this._Registerservice.getUserIPDetails().subscribe(result => {
            this.empObject.IpAddress = JSON.stringify(result);
        });
        setInterval(() => {
            if(document.getElementById('hubspot-messages-iframe-container')!=null && document.getElementById('hubspot-messages-iframe-container')
            !=undefined)
            document.getElementById('hubspot-messages-iframe-container').setAttribute("style","display:none!important")
        }, 500);
    }
    ngAfterViewChecked() {
        // var s = document.createElement("script");
        // s.type = "text/javascript";
        // s.src = "https://maps.google.com/maps/api/js?key=AIzaSyBFENiEytjqSc_N7XLvARB727y-cq5UfRE&libraries=places";
        // this.elementRef.nativeElement.appendChild(s);
    }
    displayFn(selectedoption) {

        return selectedoption ? selectedoption.insurance_Name : undefined;
    }
    onSelectionChange(_ev) {
        if (_ev.option.value != null) {
            this.empObject.InsuranceName = _ev.option.value;
        }
    }

    _filter(value: string) {
        return this.insuranceList.filter(option => option.insurance_Name.toLowerCase().includes(value.toLowerCase()));
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
      
         
        
        this.empObject.IsUSresident = '';
        this.empObject.IsForSelf = '';
        this.frmPatientInfo = this._formBuilder.group({
            Name: ['', Validators.required],
            Email: ['', [Validators.required, Validators.email]],
            Confirmemail: ['', [Validators.required, Validators.email]],
            phoneNumber: ['null', [Validators.required, Validators.pattern("[0-9 ]{10}")]],
            lastName: ['', Validators.required],
            DateOfBirth: ['', Validators.required],
            Gender: ['', Validators.required],
            Ethnicity: ['', Validators.required],
            Race: ['', Validators.required],
        });
        this.frmPatientAddress = this._formBuilder.group({
            country: ['', Validators.required],
            street: ['', [Validators.required]],
            postalCode: ['', [Validators.required]],
            city: ['', Validators.required],
            state: ['', Validators.required],
        });
        this.frmInsuranceInfo = this._formBuilder.group({
            PolicyID: ['', [Validators.required]],
            Group: ['', [Validators.required]],
            SFirstName: ['', Validators.required],
            LFirstName: ['', Validators.required],
            Relationship: ['', Validators.required],
            InsuranceOName: ['']
        });
        this.frmValidate = this._formBuilder.group({
            Cnumber: ['null', [Validators.required, Validators.pattern("[0-9 ]{10}")]],
            VEmail: ['']
            // acceptterms: [false, Validators.requiredTrue],
        });
        this.frmTrackRequest = this._formBuilder.group({
            ConfirmationCode: ['', Validators.required],
            TDateOfBirth: ['', Validators.required],
        });
        this.frmUpload = this._formBuilder.group({
            // Finalacceptterms: [false, Validators.requiredTrue],
            DocumentType: ['', Validators.required], document: ['', Validators.required]
        });
        this.frmVerification = this._formBuilder.group({
            verifycode1: ['null', [Validators.required, Validators.pattern("[0-9 ]{1}")]],
            verifycode2: ['null', [Validators.required, Validators.pattern("[0-9 ]{1}")]],
            verifycode3: ['null', [Validators.required, Validators.pattern("[0-9 ]{1}")]],
            verifycode4: ['null', [Validators.required, Validators.pattern("[0-9 ]{1}")]]

        });

        if (localStorage.getItem("PMobileNo") != undefined && localStorage.getItem("PMobileNo") != null &&
            localStorage.getItem("PMobileNo") != "") {
            this.empObject.mobileNo = localStorage.getItem("PMobileNo");
            this.empObject.AltMobile = localStorage.getItem("PMobileNo");
        }

        setTimeout(() => {
            this.lstlatlngs1 = this.lstlatlngs;

        }, 5000);
    }
    clear_signature() { 
        this.sig.clear();
      }
      saveSignPad() {
        const base64ImageData = this.sig.toDataURL();
        let signImage = base64ImageData;
        this.progress = 1;
        const formData = new FormData();
        formData.append("base64Image", signImage.replace('data:image/png;base64,', ''));
        formData.append("Location", this.empObject.SelectedLocation); 
        let APiURL = environment.BaseApiUrl + "Common";
        this.http
            .post(APiURL + '/UploadSignatureFile', formData, {
                reportProgress: true,
                observe: "events"
            })
            .pipe(
                map((event: any) => {
                    if (event.type == HttpEventType.UploadProgress) {
                        this.progress = Math.round((100 / event.total) * event.loaded);
                    } else if (event.type == HttpEventType.Response) {
                        if (event.body.status == 200) {
                            this.empObject.signaturePath = event.body.resultObj;
                            console.log(this.empObject.signaturePath);
                            //this.sig.clear();
                            this._snackbarservice.showSnackbar("Signature Uploaded")
                            this.isUploadPending = false;
                        }
                        else{
                            this._snackbarservice.showSnackbar(event.body.message)
                        }
                        this.progress = null;
                    }
                }),
                catchError((err: any) => {
                    this.progress = null;
                    alert(err.message);
                    return throwError(err.message);
                })
            )
            .toPromise();

      }
    ngAfterViewInit(): void {
        this.getMasterData(23, 0, '');
        this.empObject.country = 231;
        this.getMasterData(2, 231, '');
       
    }
    Slidechanged(ev) {
        this.empObject.IsEnableEmailVerification = ev.checked;
    }

    closeHistory() {
        this.hideHistory = true;
    }
    checkConfirmEmail() {
        if (this.empObject.ConfirmEmailAddress != this.empObject.EmailAddress) {
            return true;
        }
        else {
            return false;
        }
    }
    keytab(event, flg) {
        if (event.keyCode != "") {
            if (flg == 1 && this.empObject.verifycode1 != undefined && this.empObject.verifycode1 != "") {
                this.input1.nativeElement.focus()
            }
            else if (flg == 2 && this.empObject.verifycode2 != undefined && this.empObject.verifycode2 != "") {
                this.input2.nativeElement.focus()
            }
            else if (flg == 3 && this.empObject.verifycode3 != undefined && this.empObject.verifycode3 != "") {
                this.input3.nativeElement.focus()
            }

        }

    }
    setCurrentLocation() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.userlatitude = position.coords.latitude;
                this.userlongitude = position.coords.longitude;
                this.zoom = 8;
                this.getAddress(this.userlatitude, this.userlongitude);
                if (this.QrLocationId == "" || this.QrLocationId == undefined || this.QrLocationId == null) {
                    this.filteredLocations = [];
                    this.empObject.SelectedLocation = '';
                    // this.filteredCourses.push({location_latitude :this.userlatitude ,
                    //     location_longitude :this.userlongitude,icon:'./assets/images/green-dot.png' });
                    this.filteredCourses.forEach(element => {
                        if (this.distance(this.userlatitude, this.userlongitude, element.location_latitude, element.location_longitude, "K") <= 1) {
                            this.filteredLocations.push(element);
                        }
                    });
                }

            });
        }
    }

    getAddress(latitude, longitude) {
        this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    this.zoom = 12;
                    this.useraddress = results[0].formatted_address;
                } else {
                    window.alert('No results found');
                }
            } else {
                window.alert('Geocoder failed due to: ' + status);
            }

        });
    }
    distance(lat1: any, lon1: any, lat2: any, lon2: any, unit) {
        var radlat1 = Math.PI * lat1 / 180
        var radlat2 = Math.PI * lat2 / 180
        var theta = lon1 - lon2
        var radtheta = Math.PI * theta / 180
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist)
        dist = dist * 180 / Math.PI
        dist = dist * 60 * 1.1515
        if (unit == "K") { dist = dist * 1.609344 }
        if (unit == "N") { dist = dist * 0.8684 }
        return dist
    }
    setStreetMapInit() {
        this.mapsAPILoader.load().then(() => { 
            this.geoCoder = new google.maps.Geocoder;
            let autocomplete = new google.maps.places.Autocomplete(this.streetaddressElementRef.nativeElement);
            autocomplete.addListener("place_changed", () => {
                this.ngZone.run(() => {
                    let place = autocomplete.getPlace();

                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }

                    var location = { country: "", postal_code: "", locality: "", State: "", sublocality: "", cookCountry: "" };
                    for (var i = 0; i < place.address_components.length; ++i) {
                        var component = place.address_components[i];
                        if (component.types.indexOf("country") > -1)
                            location.country = component.long_name;
                        else if (component.types.indexOf("postal_code") > -1)
                            location.postal_code = component.long_name;
                        else if (component.types.indexOf("administrative_area_level_2") > -1)
                            location.cookCountry = component.long_name;
                        else if (component.types.indexOf("locality") > -1)
                            location.locality = component.long_name;
                        else if (component.types.indexOf("political") > -1)
                            location.State = component.long_name;
                        else if (component.types.indexOf("sublocality") > -1)
                            location.sublocality = component.long_name;


                    }



                    this.empObject.street = place.formatted_address.split(',')[0];
                    var state = location.State;
                    var city = location.locality;
                    //document.getElementById('txtCountry').value = country;
                    var _stateID = this._states.filter((course) => {
                        return course.state_name == state;
                    });
                    if (_stateID != undefined && _stateID.length > 0) {
                        this.empObject.state = _stateID[0].state_id
                    }
                    this.empObject.City = city;
                    this.empObject.postalCode = location.postal_code;
                    this.empObject.county = location.cookCountry;
                    // this.empObject.postalCode=place.address_components[7].long_name;
                    // this.empObject.City=place.address_components[3].long_name;

                });
            });
        });
    }
    // getLatLog(){
    //     debugger;
    //     this.filteredCourses.forEach(element => {
    //         var address = element.location_propertyName + "," + element.location_city; 
    //     this.geoCoder = new google.maps.Geocoder; 
    //     this.geoCoder.geocode( { 'address': address}, function(results, status) {
    //         debugger;
    //       if (status == 'OK') {
    //         google.maps.setCenter(results[0].geometry.location);
    //         var marker = new google.maps.Marker({
    //             map: map,
    //             position: results[0].geometry.location
    //         });
    //         console.log("update [master.locations] set location_latitude='"+results[0].geometry.location.lat()+"' ,location_longitude='"+results[0].geometry.location.lng()+"'"  );
    //       } else {
    //         alert('Geocode was not successful for the following reason: ' + status);
    //       }
    //     });
    //     });

    // }
    setMapInit() {
        this.mapsAPILoader.load().then(() => {
            this.setCurrentLocation();
            this.geoCoder = new google.maps.Geocoder;
            let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
            autocomplete.addListener("place_changed", () => {
                this.ngZone.run(() => {
                    let place = autocomplete.getPlace();

                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }
                    if (this.QrLocationId == "" || this.QrLocationId == undefined || this.QrLocationId == null) {
                        this.filteredLocations = [];
                        this.userlatitude = place.geometry.location.lat();
                        this.userlongitude = place.geometry.location.lng();
                        this.empObject.SelectedLocation = '';
                        this.filteredCourses.forEach(element => {
                            if (this.distance(this.userlatitude, this.userlongitude, element.location_latitude, element.location_longitude, "K") <= 3) {

                                this.filteredLocations.push(element);
                            }
                        });
                    }
                    else if (this.QrLocationId != undefined && this.QrLocationId != null && this.QrLocationId != "") {
                        //this.openmodal(this.location_confirmation);
                        return
                    }
                    this.zoom = 12;
                });
            });
        });
    }
    valueChange(symptom, $event) {
        this.symptons[symptom].checked = $event.checked;
    }
    selectCard(flg: number) {
        if (flg == 1) {
            this.empObject.IsUSresident = "0";
        }
        else if (flg == 2) {
            this.empObject.IsUSresident = "1";
        }
        else if (flg == 3) {
            this.empObject.IsForSelf = "1";
        }
        else if (flg == 4) {
            this.empObject.IsForSelf = "0";
        }
        else if (flg == 5 || flg==7) { 
if(this.insuranceList.length==0){
    this._snackbarservice.showSnackbar("Currently we are not accepting insurance,please proceed with 'No' insurance")
    this.empObject.Insurance = "0";
    return;
}else{
            this.empObject.Insurance = "1";
}
        }
        else if (flg == 6) {
            this.empObject.Insurance = "0";
            this.viewHide = 8;
            setTimeout(() => {
                this.setMapInit();
            }, 1000);

        }
        else if (flg == 8) {
            this.empObject.Insurance = "0";
            this.viewHide = 16; 

        }
    }

    showNextView(flg: number, IsBack: number) {
        if (IsBack == 1) {
            this.viewHide = flg;
            return;
        }
        if (flg == 0) { //Verification Code
            if (this.empObject.PhoneInput.number.replace(' ', '').length >= 10) {
                this.empObject.mobileno = this.empObject.PhoneInput.e164Number.replace(this.empObject.PhoneInput.dialCode, '');
                this.empObject.dailCode = this.empObject.PhoneInput.dialCode;
                if (!this.empObject.IsEnableEmailVerification &&
                    (this.empObject.vEmailAddress == undefined || this.empObject.vEmailAddress == "")) {
                    this._snackbarservice.showSnackbar("Invalid email address")
                    return;
                }
                this.sendVerification()
            }
            else {
                this._snackbarservice.showSnackbar("Invalid dail code /mobile no")
                return;
            }
        }
        else if (flg == 1) { // OTP Sent
            this.VerifyOTP()
        }
        else if (flg == 2) { //Residence 
            if (this.empObject.IsUSresident != undefined && this.empObject.IsUSresident != null) {
                this.empObject.AltMobile = this.empObject.mobileno.toString();
                if (!this.empObject.IsEnableEmailVerification)
                    this.empObject.EmailAddress = this.empObject.vEmailAddress
                this.viewHide = 3;
            }
            else {
                this._snackbarservice.showSnackbar("Invalid selection");
            }
        }
        else if (flg == 3) { //For him/others
            if (this.empObject.IsForSelf != undefined && this.empObject.IsForSelf != null) {
                this.empObject.AltMobile = this.empObject.mobileno.toString();
                if (!this.empObject.IsEnableEmailVerification)
                    this.empObject.EmailAddress = this.empObject.vEmailAddress
                this.viewHide = 4;
            }
            else {
                this._snackbarservice.showSnackbar("Invalid selection");
            }
        }
        else if (flg == 4) { //Patient Info
            if (this.frmPatientInfo.invalid) {
                return;
            }
            if (this.checkConfirmEmail()) {
                this._snackbarservice.showSnackbar("Confirm email and email didn't match");
                return;
            }
            setTimeout(() => {
                this.setStreetMapInit();
            }, 1000);
            this.viewHide = 5;
        }
        else if (flg == 5) { //Patient Address
            if (this.frmPatientAddress.invalid) {
                return;
            }


            this.viewHide = 6;
        }
        else if (flg == 6) { //Symptons
            if (this.IsSymptonSelected()) {
                this._snackbarservice.showSnackbar("Invalid symptons");
            } else {
                this.viewHide = 7;
            }

        }
        else if (flg == 7 || flg==17) { //Patient Address
            if (this.frmInsuranceInfo.invalid) {
                return;
            }
            if (this.empObject.InsuranceName == undefined || this.empObject.InsuranceName == null || this.empObject.InsuranceName == "") {
                this._snackbarservice.showSnackbar("Invalid insurance selection");
                return;
            }
            else if (this.empObject.InsuranceName.insurance_id == 103 && (this.empObject.InsuranceOName == undefined
                || this.empObject.InsuranceOName == null || this.empObject.InsuranceOName == "")) {
                this._snackbarservice.showSnackbar("Invalid insurance name");
                return;
            }
            if (this.empObject.InsuranceName.insurance_id == undefined || this.empObject.InsuranceName.insurance_id == null || this.empObject.InsuranceName.insurance_id == "") {
                this._snackbarservice.showSnackbar("Invalid insurance selection");
                return;
            }
            this.empObject.InsuranceId = this.empObject.InsuranceName.insurance_id
            if (this.empObject.InsuranceName.insurance_id == 103) {
                this.empObject.OthersInsuranceName = this.empObject.InsuranceOName;
            }
            if(flg==17)
            this.viewHide = 16;
            else{
            this.viewHide = 8;
            setTimeout(() => {
                this.setMapInit();
            }, 1000);
        }
        }
        else if (flg == 8) { //SelectedLocation 
            if (this.empObject.SelectedLocation != undefined && this.empObject.SelectedLocation != null) {

                this.getMasterData(27, this.empObject.SelectedLocation, ''); 
            }
            else {
                this._snackbarservice.showSnackbar("Invalid location selection");
            }
        }
        else if (flg == 9) { //SelectedLocation 
            if (this.empObject.SelectedTest != undefined && this.empObject.SelectedTest != null) {
                this.viewHide = 10;
              setTimeout(() => {
                this.sig = new SignaturePad(this.canvas.nativeElement);  
                console.log("child", this.canvas);
              }, 500);
            }
            else {
                this._snackbarservice.showSnackbar("Invalid selection of test");
            }
        }
        else if (flg == 13) { //Track Request
            if (this.frmTrackRequest.invalid) {
                return;
            }
            
            if (this.empObject.confirmcode != undefined && this.empObject.confirmcode != "") {

                // if (this.empObject.TDOB !== undefined && this.empObject.TDOB !== null) {
                //     this.empObject.TDOB = this.datePipe.transform(this.empObject.TDOB, this.dateFormat);
                // }
                this.getMasterData(43, 0, this.empObject.confirmcode + "~" + this.empObject.TDOB.month() + "~" + this.empObject.TDOB.date() + "~" + this.empObject.TDOB.year());
            }
        }
        else if (flg == 12) {
            this.empObject.confirmcode = "";
            this.viewHide = flg;
        }
        else if (flg == 14) {
            this.viewHide = flg;
            this.getMasterData(41, 0, this.empObject.mobileno.toString())
        }
        else {

            this.viewHide = flg;
        }
    }
    checkDocumentType(){
        if(this.empObject.DocumentType=='NO Identification'){
            this.empObject.imagePath = "NO Identification";
            this.isUploadPending = false; 
           
        }
        else{
            this.empObject.imagePath = "";
            this.isUploadPending = true;  
        }
    }
    // compressFile(image, fileName) {
    //     var orientation = -1;
    //     let sizeOfOriginalImage = this.imageCompress.byteCount(image) / (1024 * 1024);
    //     // console.warn('Size in bytes is now:',  this.sizeOfOriginalImage);
    //     this.imageCompress.compressFile(image, orientation, 50, 50).then(
    //         result => {
    //             debugger;
    //             //  this.imgResultAfterCompress = result;
    //             let localCompressedURl = result;
    //             let sizeOFCompressedImage = this.imageCompress.byteCount(result) / (1024 * 1024)
    //             console.warn('Size in bytes after compression:', sizeOFCompressedImage);
    //             // create file from byte
    //             const imageName = fileName;
    //             // call method that creates a blob from dataUri
    //             // const imageBlob = this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1]);
    //             //imageFile created below is the new compressed file which can be send to API in form data
    //             const imageFile = new File([result], imageName, { type: 'image/jpeg' });
    //         });
    // }

    onFileChanged(event) {
        const file = event.target.files[0];
        if (!file.type.includes("image")) {
            //this.snackBar.showSnackbar("Please select image file only");
            this.fileInput.nativeElement.value = "";
        }
        else {
            var maxfilesize = (1024 * 1024) * 12,  // 12 Mb
                filesize = file.size;
            if (filesize > maxfilesize) {
                this._snackbarservice.showSnackbar("File too large: " + filesize + ". Maximum size: upto 12MB");
                return;
            }
            if (this.empObject.SelectedLocation == undefined || this.empObject.SelectedLocation == "" ||
                this.empObject.SelectedLocation == null) {
                this._snackbarservice.showSnackbar("Invalid location");
                return;
            }
            this.progress = 1;
            const formData = new FormData();
            formData.append("file", file);
            formData.append("Location", this.empObject.SelectedLocation);
            this.selectedFileName = file.name
            let APiURL = environment.BaseApiUrl + "Common";
            this.http
                .post(APiURL + '/UploadRequestFile', formData, {
                    reportProgress: true,
                    observe: "events"
                })
                .pipe(
                    map((event: any) => {
                        if (event.type == HttpEventType.UploadProgress) {
                            this.progress = Math.round((100 / event.total) * event.loaded);
                        } else if (event.type == HttpEventType.Response) {
                            if (event.body.status == 200) {
                                this.empObject.imagePath = event.body.resultObj;
                                this.isUploadPending = false;
                            }
                            this.progress = null;
                        }
                    }),
                    catchError((err: any) => {
                        this.progress = null;
                        alert(err.message);
                        return throwError(err.message);
                    })
                )
                .toPromise();

            if (this.empObject.imagePath == undefined || this.empObject.imagePath == "") {
                this.empObject.profileImage = file;
            }

            var reader = new FileReader();
            reader.onload = (event: any) => {
                if (this.empObject.imagePath == undefined || this.empObject.filePath == "") {
                    this.empObject.imagePath = event.target.result;
                }

            }
            reader.readAsDataURL(event.target.files[0]);

        }

    }
    IsSymptonSelected() {
        var _firstlist = this.symptons.filter((course) => {
            return course.checked == true;
        });
        if (_firstlist != undefined && _firstlist.length > 0) {
            return false;
        }
        return true;
    }
    sendVerification() {
        // if (this.frmValidate.invalid) {
        //     return;
        // }
        if (localStorage.getItem("QrLocation") == undefined || localStorage.getItem("QrLocation") == null ||
            localStorage.getItem("QrLocation") == "") {
            this._snackbarservice.showSnackbar("Invalid qr code");
            this.router.navigate(['/auth/invalidqr']);
            return;

        }
        this.VerificationCode(this.empObject.PhoneInput.e164Number, 0);

    }
    ResendCode() {
        this.VerificationCode(this.empObject.PhoneInput.e164Number, 1);
    }
    VerificationCode(mobileNo: string, flg: number) {

        var clsmodel = {
            MobileNo: mobileNo.toString(),
            EmailAddress: !this.empObject.IsEnableEmailVerification ? this.empObject.vEmailAddress : ""
        }
        this._Registerservice.sendVerificationCode(clsmodel).subscribe(result => {
            var resultObj = result;
            if (resultObj["status"] == 200) {
                if (resultObj["resultObj"] != undefined && resultObj["resultObj"] != "") {
                    this.VCode = resultObj["resultObj"]["sRandomOTP"];
                    localStorage.setItem("PMobileNo", this.empObject.mobileno);
                    this.viewHide = 1;
                    if (flg == 1) {
                        this._snackbarservice.showSnackbar("Successfully resent");
                        this.empObject.verifycode1 = "";
                        this.empObject.verifycode2 = "";
                        this.empObject.verifycode3 = "";
                        this.empObject.verifycode4 = "";
                    }
                }

            }

        }, error => {
            console.log(error);
        });
        return "";
    }
    changeCountry(_obj, flag) {
        this.getMasterData(flag, _obj.value, '');
    }
    closepdfwindow() {
        this.dialog.closeAll();
    }
    openmodal(modal) {
        this.dialog.open(modal, { width: "400px", height: "auto", disableClose: true });
    }
    changeQrLocation() {
        this.QrLocationId = "";
        localStorage.setItem("QrLocation", "");
        this.filteredLocations = [];
        this.dialog.closeAll();
    }
    clickedMarker(_Loc: any, event) {
        if (this.QrLocationId != undefined && this.QrLocationId != null && this.QrLocationId != "") {
            //this.openmodal(this.location_confirmation);
            return
        }
        this.empObject.SelectedLocation = '';
        var iconList = this.filteredCourses.filter((course) => {
            return course.icon != undefined && course.icon.length > 0;
        });
        iconList.forEach(element => {
            element.icon = "";
        });
        _Loc.icon = './assets/images/green-dot.png';
        this.filteredLocations = [];
        this.filteredLocations.push(_Loc);
        this.empObject.SelectedLocation = _Loc.location_Id;
    }
    SelectedCenter(_loc: any) {
        this.empObject.SelectedLocation = _loc.location_Id;
    }
    SelectedTestCenter(_loc: any) { 
        this.empObject.SelectedTest = _loc.test_code;
        this.empObject.PaymentLink = _loc.location_paymentLink;
    }
    getMasterData(flg, filterId, code) {
         
        var clsmodel = {
            Type: flg==72 ? 27 : flg,
            FilterId: filterId,
            Code: code
        }
        this._Registerservice.GetMasterData(clsmodel).subscribe(result => {

            var resultObj = result;
            if (resultObj["status"] == 200) {
                if (flg == 23) {
                    this.symptonsData = resultObj["resultObj"]["employeeDtls"];
                    this.symptons = [];
                    this.symptonsData.forEach(element => {
                        this.symptons.push({
                            symptoms_Name: element.symptoms_Name,
                            symptoms_id: element.symptoms_id, checked: false
                        })
                    });
                    this.insuranceList = resultObj["resultObj"]["insuranceList"];
                    this.filteredOptions = this.myControl.valueChanges
                        .pipe(
                            startWith(''),
                            map(value => typeof value === 'string' ? value : value.insurance_Name),
                            map(insurance_Name => insurance_Name ? this._filter(insurance_Name) : this.insuranceList.slice())
                        );

                    this._countries = resultObj["resultObj"]["countries"];
                    this._states.push({ state_id: 0, state_name: "Others" })
                    this.empObject.stateId = 0;
                    this.dateFormat = resultObj["resultObj"]["dateFormat"];
                    this.filteredCourses = resultObj["resultObj"]["locations"];

                    if (localStorage.getItem("QrLocation") != undefined && localStorage.getItem("QrLocation") != null && localStorage.getItem("QrLocation") != "") {
                        this.QrLocationId = localStorage.getItem("QrLocation"); 
                        this.empObject.SelectedLocation = '';
                        var Qrloc = this.filteredCourses.filter((course) => {
                            return course.location_code == this.QrLocationId;
                        });
                        if (Qrloc != null && Qrloc.length > 0) { 
                            this.filteredLocations = []; 
                            this.locationNm=Qrloc[0].location_accountName
                            this.locationImage = Qrloc[0].locationImage 
                            this.insuranceOption=Qrloc[0].location_paymentOption;
                            if(this.insuranceOption==3){
                                this.empObject.Insurance=1;
                            }
                            else   {
                                this.empObject.Insurance=0;
                            }
                            if(this.locationImage=="../../../../assets/images/no-file.png")
                            this.locationImage="";
                            this.filteredLocations.push(Qrloc[0]);
                            this.empObject.SelectedLocation = Qrloc[0].location_Id;
                            this.filteredCourses = this.filteredLocations;
                            this.lat =Number(Qrloc[0].location_latitude);
                            this.lng =Number( Qrloc[0].location_longitude);

                            this.getMasterData(80, this.empObject.SelectedLocation, '');

                        }
                        else{
                            this._snackbarservice.showSnackbar("Invalid qr code");
                            this.router.navigate(['/auth/invalidqr']);
                        }
                    }
                    
                    this.totalLocations = this.filteredCourses;
                    this.filteredCourses.forEach(element => {

                        this.lstlatlngs.push({
                            lat: element.location_latitude,
                            lon: element.location_longitude
                        });

                    });

                }
                else if (flg == 27) {
                    this.TestList = resultObj["resultObj"];
                    // var test = this.TestList.filter((course) => {
                    //     return course.test_code == '3A5A1D19' || course.test_code == '3A5A1D17'; //only rapid
                    // });
                    // this.TestList = test;

                    this.viewHide = 9;
                }
                else if (flg == 72) {
                    debugger;
                    var _templist = resultObj["resultObj"];
                     var _testli = _templist.filter((course) => {
                        return course.test_code ==   this.empObject.SelectedTest;  
                    });
                    if(_testli!=null && _testli.length>0){
                        this.empObject.PaymentLink = _testli[0].location_paymentLink;
                    }
                    this.viewHide = 17;
                }
                else if (flg == 80) {
                    this.insuranceList = resultObj["resultObj"];
                    this.filteredOptions = this.myControl.valueChanges
                        .pipe(
                            startWith(''),
                            map(value => typeof value === 'string' ? value : value.insurance_Name),
                            map(insurance_Name => insurance_Name ? this._filter(insurance_Name) : this.insuranceList.slice())
                        ); 
                }
                else if (flg == 43) {
                    this.TrackResult = resultObj["resultObj"]
                    this.empObject.TDOB = null;
                    this.empObject.confirmcode = "";
                    if (this.TrackResult != undefined && this.TrackResult.length > 0) {
                        this.TrackResult = this.TrackResult[0]
                        this.viewHide = 13;
                    }
                    else {
                        this._snackbarservice.showSnackbar(resultObj["message"]);
                    }
                }
                else if (flg == 2) {
                    this._states = [];
                    this._states = resultObj["resultObj"];
                    this._states.push({ state_id: 0, state_name: "Others" })
                    this.empObject.stateId = 0;
                }
                else if (flg == 41) {
                    this._previousHistory = resultObj["resultObj"];
                    if (this._previousHistory.length == 0)
                        this.hideHistory = true;
                }

            }


        }, error => {
            console.log(error);
        });
        return "";
    }
    ReTest(_referenceNo,testCode) {
        if (_referenceNo != undefined && _referenceNo != '') {
            this.ReferenceNo = _referenceNo;
            this.empObject.SelectedTest = testCode; 
            if (this.empObject.SelectedLocation != undefined && this.empObject.SelectedLocation != null) {

                this.getMasterData(72, this.empObject.SelectedLocation, ''); 
            }
            
        }
        else {
            this._snackbarservice.showSnackbar("Invalid reference no");
        }
    }
    RetestLocation() {
        if (this.ReferenceNo != undefined && this.ReferenceNo != '') {
            setTimeout(() => {
                this.setMapInit();
            }, 1000);
            this.viewHide = 15;
        }
        else {
            this._snackbarservice.showSnackbar("Invalid reference no");
        }
    }
    SubmitRetest(flg) {
        let selectedsymptons = "";
        this.symptons.forEach(element => {
            if (element.checked) {
                selectedsymptons += element.symptoms_Name + ",";
            }
        });
        this.empObject.selectedsymptons = selectedsymptons;
        var clsmodel = {
            mobileNo: this.empObject.mobileno.toString(),
            ReferenceNo: this.ReferenceNo,
            SelectedLocation: this.empObject.SelectedLocation,
            selectedsymptons: this.empObject.selectedsymptons,
            IpAddress: this.empObject.IpAddress,
            InsuranceId:this.empObject.InsuranceId,
            OthersInsuranceName:this.empObject.OthersInsuranceName,
            PolicyID:this.empObject.PolicyID,
            Group:this.empObject.Group,
            SFirstName:this.empObject.SFirstName,
            LFirstName:this.empObject.LFirstName,
            Relationship:this.empObject.Relationship  
        }
        this.empObject.InsuranceName = '';
        this._splashScreenService.show();
        this._Registerservice.ReTestRequest(clsmodel).subscribe(result => {

            var resultObj = result;
            if (resultObj["status"] == 200) {
                localStorage.setItem("QrLocation", "");
                this._snackbarservice.showSnackbar("Thank you for registering , please note confirmation code:" + resultObj["resultObj"]);
                
                if(flg==1 && this.empObject.PaymentLink!=null && this.empObject.PaymentLink!="" && this.empObject.PaymentLink.length>0){
                    //https://buy.stripe.com/test_eVa3do41l4Ye6KkcMN?prefilled_email=jenny%40example.com&client_reference_id=id_1234
                    window.location.href=this.empObject.PaymentLink+"?prefilled_email="+this.empObject.EmailAddress+"&client_reference_id="+resultObj["resultObj"];
                   }
                   else
                    this.router.navigate(['/auth/thankyou', resultObj["resultObj"]]);
                     
            }
            else {
                this.viewHide = 3;
                this._snackbarservice.showSnackbar(resultObj["message"]);
            }
            this._splashScreenService.hide();


        }, error => {
            console.log(error);
        });
        return "";
    }
    employerSubmit(flg) {
        this.isUploadPending=true;
        if (this.empObject == null || this.empObject == undefined) {
            return;
        }
        if (this.empObject.imagePath == null || this.empObject.imagePath == undefined
            || this.empObject.imagePath == "") {
            this._snackbarservice.showSnackbar("Please upload attachment to proceed");
            return;
        }
        if (this.empObject.signaturePath == null || this.empObject.signaturePath == undefined
            || this.empObject.signaturePath == "") {
            this._snackbarservice.showSnackbar("Please upload signature to proceed");
            return;
        }
        const formData = new FormData();

        if (this.empObject.DOB !== undefined && this.empObject.DOB !== null) {
            this.empObject.DOB = this.datePipe.transform(this.empObject.DOB, this.dateFormat);
        }
        let selectedsymptons = "";
        this.symptons.forEach(element => {
            if (element.checked) {
                selectedsymptons += element.symptoms_Name + ",";
            }
        });
        this.empObject.selectedsymptons = selectedsymptons;

        if (this.empObject.imagePath == undefined || this.empObject.imagePath == "") {
            if (this.empObject.profileImage !== undefined && this.empObject.profileImage != "")
                formData.append('profileImage', this.empObject.profileImage, this.empObject.profileImage.name);

            this.empObject.imagePath = '';
        }
        this.empObject.profileImage = '';

        this.empObject.InsuranceName = '';
        this.empObject.mobileno = this.empObject.mobileno.toString();
        formData.append("UserObject", JSON.stringify(this.empObject));
        this._splashScreenService.show();

        this._Registerservice.SaveRequest(formData).subscribe(result => {
            var resultObj = result;

            if (resultObj["status"] == 200) {
                localStorage.setItem("QrLocation", "");
                this._snackbarservice.showSnackbar("Thank you for registering , please note confirmation code:" + resultObj["resultObj"]);
               if(flg==1 && this.empObject.PaymentLink!=null && this.empObject.PaymentLink!="" && this.empObject.PaymentLink.length>0){
                //https://buy.stripe.com/test_eVa3do41l4Ye6KkcMN?prefilled_email=jenny%40example.com&client_reference_id=id_1234
                window.location.href=this.empObject.PaymentLink+"?prefilled_email="+this.empObject.EmailAddress+"&client_reference_id="+resultObj["resultObj"];
               }
               else
                this.router.navigate(['/auth/thankyou', resultObj["resultObj"]]);
            }
            else {
                this._snackbarservice.showSnackbar(resultObj["message"]);
                this.selectedFileName = "";
                this.isUploadPending=false;
            }
            this._splashScreenService.hide();
        }, error => {
            console.log(error);
            this._splashScreenService.hide();
        });

    }

    VerifyOTP() {
        let otpString = "";
        if (this.frmVerification.invalid) {
            return;
        }
        otpString = this.empObject.verifycode1 + "" + this.empObject.verifycode2 + "" + this.empObject.verifycode3 + "" + this.empObject.verifycode4;

        if (otpString == this.VCode) {
            this.viewHide = 3;
            this.empObject.IsBypass = "0";
            this._snackbarservice.showSnackbar("Successfully verified");
            this.getMasterData(41, 0, this.empObject.mobileno.toString())
        }
        else if (otpString == "1232") {
            this.viewHide = 3;
            this._snackbarservice.showSnackbar("Successfully verified");
            this.getMasterData(41, 0, this.empObject.mobileno.toString())
            this.empObject.IsBypass = "1";
        }
        else {
            this._snackbarservice.showSnackbar("Invalid OTP");
        }

    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if (!control.parent || !control) {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if (!password || !passwordConfirm) {
        return null;
    }

    if (passwordConfirm.value === '') {
        return null;
    }

    if (password.value === passwordConfirm.value) {
        return null;
    }

    return { passwordsNotMatching: true };
};
