import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CommonService } from 'app/common/Service/common.service';
import { MatSelectModule } from '@angular/material/select';
import { Subject } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { SnackbarService } from 'app/common/Service/snackbar.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-covid-upload',
  templateUrl: './covid-upload.component.html',
  styleUrls: ['./covid-upload.component.scss']
})

export class CovidUploadComponent implements OnInit {

  courses: any[];
  coursesFilteredByCategory: any[];
  filteredCourses: any = []; lstlatlngs: any = []; lstlatlngs1: any = [];
  searchTerm: string;
  files: any;
  rows: any = []; categories: any = []; currentCategory: string;

  dataSource = new MatTableDataSource(this.rows);//FilesDataSource | null;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  //  @ViewChild(MatSort) sort:MatSort;
  displayedColumns = ['photograph', 'name', 'employeeId', 'mobileNo', 'emailAddress', 'noOfDoses', 'edit'];
  selected: any;
  empObject: any = {};
  private APiURL = environment.BaseApiUrl;
  private ApplicationUrl = environment.ApplicationUrl;
  storeData: any;
  excelData: any; dashboard: any = [];
  importedRecords: number = 0;
  modalType: number = 0;
  newArray: any = [];
  fileUploaded: File;
  worksheet: any;
  id: string = '';
  _testlist: any = [];
  _vaccineList: any = [];
  _loginDetails: any;
  public UploadFile: File;
  imagePath: string = "assets/images/default.jpg";
  filePath: string = "assets/images/default.jpg";
  referenceNo: number = 0;
  remarks: string = "";
  index: number = 0;
  private _unsubscribeAll: Subject<any>;
  @ViewChild('editcovidtest') editcovidtest: ElementRef;
  invitation1Form: FormGroup;
  constructor(
    private _formBuilder: FormBuilder,
    private _CommonService: CommonService, private activatedRoute: ActivatedRoute,
    private _snackbarservice: SnackbarService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private service: CommonService,


  ) {

    this.currentCategory = 'all';
    this.searchTerm = '';

    // Set the private defaults
    this._unsubscribeAll = new Subject();

  }

  ngOnInit(): void {
    this.employeesbyOrganization();
    this.invitation1Form = this._formBuilder.group({
      remarks: ['', Validators.required],

    });
    //this.dataSource = new Object();//new FilesDataSource(this._fileManagerService);
    if (localStorage != null && localStorage != undefined && localStorage["UserDetails"]) {
      this._loginDetails = JSON.parse(localStorage.getItem('UserDetails') || '{}');

    }

  }
  getMasterData(flg, filterId, code) {

    var sessionobj = JSON.parse(localStorage.UserDetails);
    var clsmodel = {
      Type: flg,
      FilterId: filterId,
      Code: code
    }
    this.service.GetMasterData(clsmodel).subscribe(result => {

      var resultObj = result;
      if (resultObj["status"] == 200) {
        if (flg == 8) {
          if (resultObj["resultObj"] != undefined) {
            this.imagePath = resultObj["resultObj"].imagePath;
            this._testlist = resultObj["resultObj"].clsCovidTests;
            this._vaccineList = resultObj["resultObj"].clsVaccines;
          }
        }
      }

    }, error => {
      console.log(error);
    });
    return "";
  }
  setDataSourceAttributes() {
    if (this.paginator !== undefined && this.paginator !== null) {
      this.dataSource.paginator = this.paginator;
      //        this.dataSource.sort = this.sort;
    }

  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  filteremployeeByID(obj): void {
    // Filter 
    this.getMasterData(8, 0, this.currentCategory);

  }
  openmodal(modal) {
    this.dialog.open(modal, { width: "700px", height: "auto", disableClose: true });
  }
  closewindow() {
    this.referenceNo = 0;
    this.filePath = "";
    this.dialog.closeAll();
  }
  UploadStatus(flg: number) {
    if (this.referenceNo == 0) {
      this._snackbarservice.showSnackbar("Invalid reference no");
      return;
    }
    else if (this.remarks == "" || this.remarks == undefined) {
      this._snackbarservice.showSnackbar("Invalid remarks");
      return;
    }
    else {
      if (this.modalType == 0)
        this.verifyCovidResults(flg)
      else if (this.modalType == 1)
        this.verifyVaccineResults(flg)
    }
  }
  editcovidpop(flg, refno, _filepath) {
    this.modalType = flg;
    this.referenceNo = refno;
    this.filePath = _filepath;
    this.openmodal(this.editcovidtest);
  }

  verifyCovidResults(flg: number) {
    var sessionobj = JSON.parse(localStorage.UserDetails);
    var clsmodel = {
      EmployeeId: sessionobj.users_userid,
      Vaccine: this.remarks,
      NoOfDoses: flg,
      testid: this.referenceNo
    }
    this._CommonService.VerifyCovidResults(clsmodel).subscribe(result => {

      var resultObj = result;
      if (resultObj["status"] == 200) {
        this.referenceNo = 0;
        this.filePath = "";
        this.dialog.closeAll();
        this.getMasterData(8, 0, this.currentCategory);
        this._snackbarservice.showSnackbar(resultObj["message"]);
      }
      else
        this._snackbarservice.showSnackbar(resultObj["message"]);

    }, error => {
      console.log(error);

    });
  }

  verifyVaccineResults(flg: number) {
    var sessionobj = JSON.parse(localStorage.UserDetails);
    var clsmodel = {
      EmployeeId: sessionobj.users_userid,
      Vaccine: this.remarks,
      NoOfDoses: flg,
      testid: this.referenceNo
    }
    this._CommonService.VerifyVaccineResults(clsmodel).subscribe(result => {

      var resultObj = result;
      if (resultObj["status"] == 200) {
        this.referenceNo = 0;
        this.filePath = "";
        this.dialog.closeAll();
        this.getMasterData(8, 0, this.currentCategory);
        this._snackbarservice.showSnackbar(resultObj["message"]);
      }
      else
        this._snackbarservice.showSnackbar(resultObj["message"]);

    }, error => {
      console.log(error);

    });
  }
  employeesbyOrganization() {
    var sessionobj = JSON.parse(localStorage.UserDetails);
    var clsmodel = {
      customerID: sessionobj.users_Customerid
    }
    this._CommonService.employeesbyOrganization(clsmodel).subscribe(result => {

      var resultObj = result;

      if (resultObj["status"] == 200) {
        this.rows = resultObj["resultObj"];
        this.dataSource = new MatTableDataSource(this.rows);
        this.dataSource.paginator = this.paginator;
        //               this.dataSource.sort = this.sort;
      }
      else
        this._snackbarservice.showSnackbar(resultObj["message"]);

    }, error => {
      console.log(error);

    });
  }

}

