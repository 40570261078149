import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import { AgmCoreModule } from '@agm/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CentersComponent } from 'app/main/centers/centers.component';
import { AcademyCoursesComponent } from 'app/main/centers/courses/courses.component';
import { AcademyCourseComponent } from 'app/main/centers/course/course.component';
import { AcademyCoursesService } from 'app/main/centers/courses.service';
import { AcademyCourseService } from 'app/main/centers/course.service';
import { FuseSidebarModule } from '@fuse/components';  

const routes = [
    {
        path     : 'courses',
        component: AcademyCoursesComponent,
        resolve  : {
            academy: AcademyCoursesService
        }
    },
    {
        path     : 'courses/:courseId/:courseSlug',
        component: AcademyCourseComponent,
        resolve  : {
            academy: AcademyCourseService
        }
    } 
];

@NgModule({
    declarations: [
        AcademyCoursesComponent,
        AcademyCourseComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatTooltipModule,
        FuseSharedModule,
        MatCheckboxModule,
        FuseSidebarModule, 
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyCWjB2Aht_Vamw6HfxqA1UkZhXkphNCV24'
        }),
    ],
    providers   : [
        AcademyCoursesService,
        AcademyCourseService ,
        CentersComponent
    ]
})
export class centerModule
{
}
