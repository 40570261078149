<div id="timeline" class="p-24" fxLayout="row wrap">

    <div class="timeline-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="55" fxFlex.gt-md="100">

        <div class="profile-box add-post"> 
            <div class="title1 accent1">Change Password</div> 
            <div class="content p-24">
               
                <form  [formGroup]="formGroup"   >

                    <mat-form-field appearance="fill" fxFlex="33">
                        <mat-label>Old Password</mat-label>
                        <input matInput formControlName="oPassword" [(ngModel)]="screenModel.Password" >
                        
                        <mat-error>
                            Old Password is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex="34" class="px-4">
                        <mat-label>New Password</mat-label>
                        <input matInput formControlName="nPassword" [(ngModel)]="screenModel.new_Password">
                         
                        <mat-error>
                            Current Password is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex="33"> 
                        <mat-label>Confirm Password</mat-label>
                        <input matInput formControlName="conPassword" [(ngModel)]="screenModel.confirm_Password">
                       
                        <mat-error>
                            Confirm Password is required
                        </mat-error>
                    </mat-form-field> 
                   
                </form>

            </div>
           

        </div>
     
        

       
    </div>
    
    <div class="actions1" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button color="warn" aria-label="Follow" routerLink="/dashboard" ><mat-icon>close</mat-icon> Cancel</button>
        <button mat-raised-button color="primary" [disabled]="formGroup.invalid" aria-label="save" (click)="changepassword()"><mat-icon>save</mat-icon> Save</button>
    </div> 
    

</div>

