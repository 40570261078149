import { Component, ElementRef, ViewChild, OnInit, NgModule } from '@angular/core';
import { CommonService } from 'app/common/Service/common.service';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from 'app/common/Service/snackbar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'app-test-list',
  templateUrl: './test-list.component.html',
  styleUrls: ['./test-list.component.scss']
})
export class TestListComponent implements OnInit {
  usersdata: any = [];
  categories: any = []; currentCategory: string;
  storeData: any;
  index: number = 0;
  usercode: string = "";
  empObject: any = {};
  deleteFlg: number = 0;
  typeId: number = 0;
  symptomsList: FormGroup;
  @ViewChild('add_Symptoms') add_Symptoms: ElementRef;
  @ViewChild('alertpop') alertpop: ElementRef;
  constructor(

    private service: CommonService,
    private dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private _snackbarservice: SnackbarService,
    private _splashScreenService: FuseSplashScreenService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    //link : ['', Validators.required],  
    var sessionobj = JSON.parse(localStorage.UserDetails);
    this.getMasterData(75, 0, "");// 

    this.symptomsList = this._formBuilder.group({
      name: ['', Validators.required],

    });
  }

  addSymptoms() {
    this.empObject.Name = ""; this.usercode == "";
    this.openmodal(this.add_Symptoms);
  }
  openmodal(modal) {
    this.dialog.open(modal, { width: "500px", height: "auto", disableClose: true });
  }
  Slidechanged(accessCode) {
    this.usercode = accessCode;

  }
  del_symp(accessCode: string, flg: number) {
    this.openmodal1(this.alertpop);
    this.usercode = accessCode;
    this.deleteFlg = flg;

  }
  openmodal1(modal) {
    this.dialog.open(modal, { width: "400px", height: "auto", disableClose: true });
  }

  confirm_del() {
    this.getMasterData(77, this.deleteFlg, this.usercode.toString());//EmployeerData
  }
  edt_symp(accessCode: string) {
    this.openmodal(this.add_Symptoms);
    this.usercode = accessCode;
    this.getMasterData(76, 0, accessCode.toString());//EmployeerData
  }
  act_symp(accessCode: string, flg: number) {
    this.openmodal1(this.alertpop);
    this.usercode = accessCode;
    this.deleteFlg = flg;
  }
  symptomSubmit() {
    if (this.symptomsList.invalid) {
      return;
    }
    this._splashScreenService.show();
    this.empObject.seqno = this.usercode == "" || this.usercode == "0" ? 0 : this.usercode;
    this.service.SaveTestList(this.empObject).subscribe(result => {

      var resultObj = result;

      if (resultObj["status"] == 200) {
        this.symptomsList.reset();
        this._snackbarservice.showSnackbar(resultObj["message"]);
        this.getMasterData(75, 0, "");//EmployeerData
        this.usercode = "0";
      }
      else
        this._snackbarservice.showSnackbar(resultObj["message"]);
      this._splashScreenService.hide();
    }, error => {
      console.log(error);
      this._splashScreenService.hide();
    });

  }
  getMasterData(flg, filterId, code) {
    var sessionobj = JSON.parse(localStorage.UserDetails);
    var clsmodel = {
      Type: flg,
      FilterId: filterId,
      Code: code
    }
    this.service.GetMasterData(clsmodel).subscribe(result => {
      var resultObj = result;
      if (resultObj["status"] == 200) {
        if (flg == 75) { 
          this.usersdata = resultObj["resultObj"];
        }
        else if (flg == 77) {
          this._snackbarservice.showSnackbar(resultObj["message"]);
          this.getMasterData(75, 0, '');//EmployeerData 
          this.dialog.closeAll();
        }
        else if (flg == 76) {
          var userobj = resultObj["resultObj"];
          this.empObject.Name = userobj[0].test_name;
        }

      }

    }, error => {
      console.log(error);
    });
    return "";

  }

}
const routes: Routes = [
  {
    path: 'test-list',
    component: TestListComponent 

  }
];