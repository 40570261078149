<div id="about" class="p-24" fxLayout="row wrap">

    <div class="about-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="100">

        <div class="profile-box info-box general" fxLayout="column">

            <header class="primary">
                <div class="title">General Information</div>
            </header>

            <div class="content">
                <form class="mat-card mat-elevation-z4 p-24 mr-24" fxLayout="column" fxLayoutAlign="start"
                fxFlex="1 0 auto" name="form" [formGroup]="formGroup"> 
              <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                  <mat-form-field appearance="fill" fxFlex="50" class="pr-4">
                      <mat-label>Company Code</mat-label>
                      <input matInput formControlName="companycode"  readonly  [disabled]="true" [(ngModel)]="_formGroup.companycode"   >
                      <mat-icon matSuffix class="disabled-text">domain</mat-icon>
                  </mat-form-field>
                  <mat-form-field appearance="fill" fxFlex="50" class="pl-4">
                    <mat-label>Company Name</mat-label>
                    <input matInput formControlName="companyname"  readonly  [disabled]="true" [(ngModel)]="_formGroup.companyname"   >
                    <mat-icon matSuffix class="disabled-text">domain</mat-icon>
                </mat-form-field>

              </div>

              <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                  <mat-form-field appearance="fill" fxFlex="50" class="pr-4">
                      <mat-label>Email Address</mat-label>
                      <input matInput formControlName="emailAddress" [(ngModel)]="_formGroup.emailAddress" readonly  [disabled]="true"   >
                      <mat-icon matSuffix class="secondary-text">email</mat-icon>
                      <mat-error>Email Address is required!</mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="50" class="pl-4">
                      <mat-label>Mobile No</mat-label>
                      <input matInput formControlName="mobileNo" [(ngModel)]="_formGroup.mobileNo" readonly  [disabled]="true" >
                      <mat-icon matSuffix class="secondary-text">contact_phone</mat-icon>
                      <mat-error>Mobile No is required!</mat-error>
                  </mat-form-field>

              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                <mat-form-field appearance="fill" fxFlex="50" class="pr-4">
                    <mat-label>FirstName </mat-label>
                    <input matInput formControlName="firstName" required [(ngModel)]="_formGroup.firstName"   >
                    <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    <mat-error>first name is required!</mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="50" class="pl-4">
                    <mat-label>LastName </mat-label>
                    <input matInput formControlName="lastName" required [(ngModel)]="_formGroup.lastName"   >
                    <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    <mat-error>last name is required!</mat-error>
                </mat-form-field>

            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                <mat-form-field appearance="fill" fxFlex="50" class="pr-4">
                    <mat-label>Alternate MobileNo </mat-label>
                    <input matInput formControlName="altmobileNumber" [(ngModel)]="_formGroup.alternateMobile"   >
                    <mat-icon matSuffix class="secondary-text">contact_phone</mat-icon> 
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="50" class="pl-4">
                    <mat-label>Confirm Email </mat-label>
                    <input matInput formControlName="pEmailAddress" [(ngModel)]="_formGroup.pEmailAddress"   >
                    <mat-icon matSuffix class="secondary-text">email</mat-icon> 
                </mat-form-field>

            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                <mat-form-field appearance="fill" fxFlex="50" class="pr-4">
                    <mat-label>Sex </mat-label>
                    <mat-select formControlName="gender"   [(ngModel)]="_formGroup.gender" >
                        <mat-option  [value]="'M'">
                            Male
                        </mat-option> 
                        <mat-option  [value]="'F'">
                            Female
                        </mat-option> 
                        <mat-option  [value]="'U'">
                            Undisclosed
                        </mat-option> 
                        <mat-option  [value]="'O'">
                            Others
                        </mat-option> 
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text">supervised_user_circle</mat-icon>
                    
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="50" class="pr-4">
                    <mat-label>DOB </mat-label>
                    <input matInput [matDatepicker]="picker" [max]="currentDate" formControlName="dateOfBirth" required [(ngModel)]="_formGroup.dateOfBirth">
                    <mat-datepicker-toggle matSuffix [for]="picker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker disabled="false"></mat-datepicker> 
                    <mat-error>DOB is required!</mat-error>
                </mat-form-field>

            </div>
          
              <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">

                  <mat-form-field appearance="fill" fxFlex="100">
                      <mat-label>Street</mat-label>
                      <textarea matInput formControlName="address"  [(ngModel)]="_formGroup.address" required></textarea>
                      <mat-error>Street is required!</mat-error>
                  </mat-form-field> 

              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <mat-form-field appearance="fill" fxFlex="33">
                    <mat-label>Ethnicity</mat-label>
                    <mat-select formControlName="Ethinicity" required [(ngModel)]="_formGroup.Ethinicity">
                        <mat-option [value]="'Hispanic/Latino'">
                            Hispanic/Latino
                        </mat-option>
                        <mat-option [value]="'Not Hispanic/Latino'">
                            Not Hispanic / Latino
                        </mat-option>
                        <mat-option [value]="'Undisclosed'">
                            Undisclosed
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        Ethnicity is required
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" class="px-8" fxFlex="33">
                    <mat-label>Race</mat-label>
                    <mat-select formControlName="Race" required [(ngModel)]="_formGroup.Race">
                        <mat-option [value]="'White'">
                            White
                        </mat-option>
                        <mat-option [value]="'Asian'">
                            Asian
                        </mat-option>
                        <mat-option [value]="'American Indian/Native Alaskan'">
                            American Indian / Native Alaskan
                        </mat-option>
                        <mat-option [value]="'Black/African American'">
                            Black / African American
                        </mat-option>
                        <mat-option [value]="'Native Hawaiian/Pacific Islander '">
                            Native Hawaiian / Pacific Islander
                        </mat-option>
                        <mat-option [value]="'Undisclosed'">
                            Undisclosed
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        Race is required
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" fxFlex="33" class="pl-4">
                    <mat-label>Insurance</mat-label>
                    <mat-select formControlName="insuranceId"   [(ngModel)]="_formGroup.insuranceName"   >
                        <mat-option *ngFor="let insurance of insuranceList" [value]="insurance.insurance_id">
                            {{insurance.insurance_Name}}
                        </mat-option> 
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text">outlined_flag</mat-icon> 
                </mat-form-field> 
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <mat-form-field appearance="fill" fxFlex="33" >
                    <mat-label>Country</mat-label>
                    <mat-select formControlName="country" (selectionChange)="changeCountry($event,2)" [(ngModel)]="_formGroup.countryId"  required>
                        <mat-option *ngFor="let country of _countries" [value]="country.country_id">
                            {{country.country_name}}
                        </mat-option> 
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text">outlined_flag</mat-icon>
                    <mat-error>Country is required!</mat-error>
                </mat-form-field> 

                  <mat-form-field appearance="fill" fxFlex="34" class="px-8">
                      <mat-label>State</mat-label>
                      <mat-select formControlName="state" (selectionChange)="changeCountry($event,3)" [(ngModel)]="_formGroup.stateId"  >
                        <mat-option *ngFor="let state of _states" [value]="state.state_id">
                            {{state.state_name}}
                        </mat-option> 
                    </mat-select>
                      <mat-icon matSuffix class="secondary-text">location_city</mat-icon>
                      <mat-error>State is required!</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="fill" fxFlex="33">
                    <mat-label>City</mat-label>
                    <input matInput formControlName="city" required [(ngModel)]="_formGroup.city"> 
                    <mat-icon matSuffix class="secondary-text">location_city</mat-icon>
                    <mat-error>City is required!</mat-error>
                </mat-form-field>
               
              </div>

              <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <mat-form-field appearance="fill" fxFlex="33" class="pr-4">
                    <mat-label>Postal Code</mat-label>
                    <input matInput #postalCode value="94043"  [(ngModel)]="_formGroup.zipCode"
                           formControlName="postalCode" maxlength="6" required>
                    <mat-icon matSuffix class="secondary-text">markunread_mailbox</mat-icon>
                    <mat-hint align="end">{{postalCode.value.length}} / 6</mat-hint>
                    <mat-error>Postal Code is required!</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" fxFlex="33" class="pr-4">
                    <mat-label>County</mat-label>
                    <input matInput   [(ngModel)]="_formGroup.County"
                           formControlName="County"   required>
                    <mat-icon matSuffix class="secondary-text">markunread_mailbox</mat-icon> 
                    <mat-error>county is required!</mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="33" class="pl-4">
                    <mat-label>Department</mat-label>
                    <mat-select formControlName="department"   [(ngModel)]="_formGroup.departmentId"   >
                        <mat-option *ngFor="let department of _departments" [value]="department.department_id">
                            {{department.department_name}}
                        </mat-option> 
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text">outlined_flag</mat-icon> 
                </mat-form-field> 

              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">  
                <mat-form-field appearance="fill" fxFlex="33">
                    <mat-label>Document No.</mat-label>
                    <input matInput formControlName="documentNo" required [(ngModel)]="_formGroup.documentNo"> 
                    <mat-icon matSuffix class="secondary-text">outlined_flag</mat-icon> 
                </mat-form-field>
               
                </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">  
                  <a href="{{_formGroup.empDocument}}" *ngIf="_formGroup.empDocument!='' && _formGroup.empDocument!=null && _formGroup.empDocument!=undefined" target="_blank" >View Document</a>
                <div class="file-uploader">
                    <input hidden type="file"  accept="image/*,application/pdf,application/msword,
                    application/vnd.openxmlformats-officedocument.wordprocessingml.document"  (change)="onFileChanged($event)" #fileInput />
                    <button mat-raised-button color="primary" class="add-file-button" (click)="fileInput.click()"
                        aria-label="Upload file" [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
                        <mat-icon>cloud_upload</mat-icon> Upload Document
                    </button>
                </div>
               
              
              </div>
          </form>
            </div>

        </div> 

    </div>

     
    <div class="actions1" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button color="warn" aria-label="Follow" (click)="goToPrevious()"><mat-icon>close</mat-icon> Cancel</button>
        <button mat-raised-button color="primary"   (click)="SubmitEmpForm()" aria-label="save"><mat-icon>save</mat-icon> Save</button>
    </div> 
</div>
