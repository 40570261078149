import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators'; 
import { FuseNavigationItem } from '@fuse/types';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

@Component({
    selector   : 'fuse-nav-horizontal-item',
    templateUrl: './item.component.html',
    styleUrls  : ['./item.component.scss']
})
export class FuseNavHorizontalItemComponent
{
    @HostBinding('class')
    classes = 'nav-item';

    @Input()
    item: any;
    RouterLink(url){
        window.location.href=url;
    }
    checkAccess(_pageID: string) {       
        return this._fuseNavigationService.checkAccess(_pageID);
       }
    /**
     * Constructor
     */
    constructor( private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService)
    {

    }
}
