import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { EmployeeProfileService } from 'app/main/pages/employee-edit/employee-edit.service';
import { SnackbarService } from 'app/common/Service/snackbar.service'; 
import { FileManagerService } from 'app/main/apps/file-manager/file-manager.service';
import { CommonService } from 'app/common/Service/common.service'; 
import { MatTableDataSource } from '@angular/material/table';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';
//import { MatSort } from '@angular/material/sort';

@Component({
    selector     : 'employee-list',
    templateUrl  : './employees-list.component.html',
    styleUrls    : ['./employees-list.component.scss'],
    encapsulation: ViewEncapsulation.None, 
})
export class EmployeeListComponent implements OnInit, OnDestroy
{
    files: any; 
    rows:any=[];categories:any=[];currentCategory:string;
    searchTerm:string;
    dataSource = new MatTableDataSource(this.rows);//FilesDataSource | null;
    @ViewChild(MatPaginator) paginator:MatPaginator;
    @ViewChild(MatPaginator) set matPaginator(mp:MatPaginator){
        this.paginator = mp;
        this.setDataSourceAttributes();
    }
    
  //  @ViewChild(MatSort) sort:MatSort;
    displayedColumns = ['photograph', 'name', 'employeeId', 'mobileNo', 'emailAddress', 'noOfDoses',];
    selected: any;
    empObject: any = {}; 
    private APiURL = environment.BaseApiUrl; 
    private ApplicationUrl = environment.ApplicationUrl; 
    storeData: any;
    excelData: any;dashboard: any=[];
    importedRecords:number=0;
    FailedRecords:number=0;
    newArray: any = [];
    fileUploaded: File;
    worksheet: any;
    
    index:number=0;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * 
     */
    constructor( 
        private _snackbarservice: SnackbarService,  
        private _CommonService:CommonService,
        private datePipe : DatePipe, private service: CommonService,
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    filteremployees(){
        const searchTerm = this.searchTerm.toLowerCase();

        // Search
        if (searchTerm === '') {
            this.dataSource = this.rows;
        }
        else {
            this.dataSource = this.rows.filter((course) => {
            return course.name.toLowerCase().includes(searchTerm);
          });
        }
    }
    /**
     * On init
     */
    ngOnInit(): void
    {
        var sessionobj = JSON.parse(localStorage.UserDetails);
         this.employeesbyOrganization();

        //this.dataSource = new Object();//new FilesDataSource(this._fileManagerService);
        this.getMasterData(6, sessionobj.users_Customerid);//EmployeerData
       
            
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();

 
    }
    getMasterData(flg, filterId) {
       
        var clsmodel = {
            Type: flg,
            FilterId: filterId
        }
        this.service.GetMasterData(clsmodel).subscribe(result => {

            var resultObj = result;
            if (resultObj["status"] == 200) {
                 if (flg == 6) { 
                    this.dashboard = resultObj["resultObj"];  
                }
                
            }

        }, error => {
            console.log(error);
        });
        return "";
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

   

    setDataSourceAttributes() {
        if(this.paginator !== undefined && this.paginator !== null) {
            this.dataSource.paginator = this.paginator;
    //        this.dataSource.sort = this.sort;
        }

    }

     
    employeesbyOrganization(){
        var sessionobj= JSON.parse(localStorage.UserDetails);
        var clsmodel={
            customerID:sessionobj.users_Customerid
        }
         this._CommonService.employeesbyOrganization(clsmodel).subscribe(result => {
            
            var resultObj = result;

            if(resultObj["status"] == 200) {
                this.rows = resultObj["resultObj"];
                this.dataSource = new MatTableDataSource(this.rows);
                this.dataSource.paginator = this.paginator;
 //               this.dataSource.sort = this.sort;
            }
            else
            this._snackbarservice.showSnackbar(resultObj["message"]);
            
        }, error => {
            console.log(error);
            
        });
    }
}
 
 

