import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CommonService } from 'app/common/Service/common.service';
import { SnackbarService } from 'app/common/Service/snackbar.service';
import { MatTableDataSource } from '@angular/material/table';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';

@Component({
  selector: 'app-vendor-workflow',
  templateUrl: './vendor-workflow.component.html',
  styleUrls: ['./vendor-workflow.component.scss']
})
export class VendorWorkflowComponent implements OnInit {
  vendorWorkflow: FormGroup;
  displayedColumns: string[] = ['SNo', 'Duration', 'EmailID', 'Message'];
  dataSource = new MatTableDataSource();
  items: Array<any> = [];
  newItem: any = {};
  empObject: any = {};
  listpref: any = [];
  filtermvendor:any=[];
  constructor(
    private _formBuilder: FormBuilder,
    private service: CommonService,
    private _snackbarservice: SnackbarService,
    private _splashScreenService: FuseSplashScreenService,

  ) {



  }

  ngOnInit(): void {
    var sessionobj = JSON.parse(localStorage.UserDetails);
    this.getMasterData(60, 0, '');//getLocationWiseUsers
    this.vendorWorkflow = this._formBuilder.group({
      duration: ['', Validators.required],
      emailAddress: ['', [Validators.required, Validators.email]],
      message: ['', [Validators.required]],
      selftestpref_id: [''],

    });
  }

  addItem() {
    this.items.push(this.newItem);
    console.log(this.items);
    this.newItem = {};
  }
  getMasterData(flg, filterId, _code) {


    var sessionobj = JSON.parse(localStorage.UserDetails);
    var clsmodel = {
      Type: flg,
      FilterId: filterId,
      Code: _code
    }
    this.service.GetMasterData(clsmodel).subscribe(result => {
      this._splashScreenService.hide();
      var resultObj = result;
      if (resultObj["status"] == 200) {
        if (flg == 60) {
          
          if (result["resultObj"].length > 0) {
            this.listpref = result["resultObj"];

            let filtervendorList: any = this.listpref.filter(f => f.selftestpref_id == 1);
            this.items = this.listpref.filter(f => f.selftestpref_id!= 1);
            if (filtervendorList != undefined && filtervendorList != null && filtervendorList.length > 0) {
                this.empObject.selftestpref_duration = filtervendorList[0].selftestpref_duration;
                this.empObject.selftestpref_email = this.listpref[0].selftestpref_email;
                this.empObject.selftestpref_msg = this.listpref[0].selftestpref_msg;
              }
            } 
        }
      }

    }, error => {
      console.log(error);
    });
    return "";
  }
  vendorwfSubmit() {

  }
}

