import { Component, OnDestroy, OnInit, ViewEncapsulation, NgZone, Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { RouterModule, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { NgModule, ViewChild, ElementRef } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { ActivatedRoute } from '@angular/router'; 
@Component({
    selector: 'auth/invalidqr',
    templateUrl: './invalidqr.component.html',
    styleUrls: ['./invalidqr.component.scss'],
    encapsulation: ViewEncapsulation.None, 
})
export class InvalidQrComponent implements OnInit, OnDestroy {
confirmationCode:string;
       
    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private activatedRoute:ActivatedRoute, 
        private ngZone: NgZone,
        private router: Router, 
    ) {


        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
        this.activatedRoute.params.subscribe(param => this.confirmationCode = param.id);
        localStorage.setItem("QrLocation","");
    }
    ngOnInit(): void {

    }
    ngOnDestroy(): void {
       
    }
}