import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { FuseSharedModule } from '@fuse/shared.module'; 
import { RegisterComponent } from 'app/main/pages/authentication/register/register.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete'; 
const routes = [
    {
        path     : 'auth/register/:id',
        component: RegisterComponent
    }
];

@NgModule({
    declarations: [
        RegisterComponent 
    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule, 
        FuseSharedModule,MatAutocompleteModule,
        MatSelectModule,
        MatDatepickerModule,
    ]
})
export class RegisterModule
{
}
