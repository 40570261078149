<div id="profile" class="page-layout simple tabbed">

    <!-- HEADER -->
    <div class="header p-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
        fxLayoutAlign.gt-sm="space-between end">

        <div class="user-info" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="start center">
            <img class="profile-image avatar huge" src="{{this.imagePath}}"
                [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
            <div class="name" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                {{_loginDetails.users_Fullname}}
            </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between">
            <div class="actions" fxLayout="row" style="display: none;">
                <button mat-raised-button color="accent" style="display: none;" aria-label="Follow">
                    <mat-icon>edit</mat-icon> Edit
                </button>
            </div>
            <div class="file-uploader">
                <input hidden type="file" (change)="onFileChanged($event,_loginDetails.users_AccessToken)" #fileInput />
                <button mat-raised-button color="primary" class="add-file-button" (click)="fileInput.click()"
                    aria-label="Upload file" [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
                    <mat-icon>cloud_upload</mat-icon> Upload Photo
                </button>
            </div>
        </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content">

        <mat-tab-group dynamicHeight="true">

            <mat-tab label="About">
                <profile-about></profile-about>
            </mat-tab>

            <mat-tab label="Security" *ngIf="showSecuTab">
                <profile-timeline></profile-timeline>
            </mat-tab>
        </mat-tab-group>

    </div>
    <!-- / CONTENT -->

</div>