import { Component, Inject, OnDestroy, ElementRef, ViewChild, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { environment } from '../../environments/environment';
import { navigation } from 'app/navigation/navigation';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';
import { MatDialog } from '@angular/material/dialog';
import { LoaderService } from './common/Service/loader.service';
import { ActivatedRoute, Router, Params, ParamMap } from '@angular/router';
import { CommonService } from 'app/common/Service/common.service';
@Component({
    moduleId: module.id,
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    display: string = "";
    Intimer: any;
    // Private
    private _unsubscribeAll: Subject<any>;
    userActivity;
    @ViewChild('sessionpopup') sessionInputRef: ElementRef;
    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    locatonCode: string = "";
    hideStaging: boolean = false;
    loader = false;
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        private loaderService: LoaderService, 
        private service: CommonService,
    ) {

        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');

        // Add languages
        this._translateService.addLangs(['en', 'tr']);

        // Set the default language
        this._translateService.setDefaultLang('en');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

        // Use a language
        this._translateService.use('en');
        // if(this.router.location._platformLocation.location.pathname=="/auth/register"){

        // }

        if (environment.production) {
            this.hideStaging = true;
        }
        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         */

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         * setTimeout(() => {
         * this._translateService.setDefaultLang('en');
         * this._translateService.setDefaultLang('tr');
         * });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void { 
        if (window.location.pathname == "/auth/request") {
            localStorage.setItem("IsRefreshed", "/auth/request");
        }
        else if (window.location.pathname == "/registered-list") {
            localStorage.setItem("IsRRefreshed", "/registered-list");
        }
        else if (window.location.pathname == "/auth/self-test") {
            localStorage.setItem("IsSRefreshed", "/auth/self-test");
        } 
        else {
            localStorage.setItem("IsRefreshed", "")
            localStorage.setItem("IsSRefreshed", "")
            localStorage.setItem("IsRRefreshed", "")
            
        }
        this.loaderService.loader.subscribe(res => {
            setTimeout(() => {
                this.loader = res;
            }, 0);
        });
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => { 
                this.fuseConfig = config;

                // Boxed
                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                }
                else {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });
        setTimeout(() => { 
            if (localStorage.getItem("IsRefreshed") != undefined && localStorage.getItem("IsRefreshed") != "") {
                this.router.navigateByUrl('/auth/request');
            }
            else if (localStorage.getItem("IsRRefreshed") != undefined && localStorage.getItem("IsRRefreshed") != "") {
                this.router.navigateByUrl('/registered-list');
            }
            else if (localStorage.getItem("IsSRefreshed") != undefined && localStorage.getItem("IsSRefreshed") != "") {
                this.router.navigateByUrl('/auth/self-test');
            };
        }, 100);
        // setTimeout(() => {
        //     console.clear();
        // }, 200);
        // setTimeout(() => {
        //     var chatWindow = document.getElementById('chat-widget-container');
        //     if (chatWindow) {
        //         if (localStorage.getItem("UserDetails") == undefined || localStorage.getItem("UserDetails") == "" ||
        //             localStorage.getItem("UserDetails") == null) {
        //             chatWindow.style.display = 'none';
        //         }
        //     }
        // }, 500);

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
    setTimeout() {
        this.userActivity = setTimeout(() => this.sessionPopup(), 900000);//15 mins 900000
    }

    @HostListener('window:mousemove') refreshUserState() {
        clearTimeout(this.userActivity);
        this.setTimeout();
    }
    sessionModelPopup(model) {
        this.dialog.open(model, {
            height: '200px',
            width: '500px',
            disableClose: true,
        });
    }
    logOut() {
       
        this.saveAuditLog();
         localStorage.clear();
        var chatWindow = document.getElementById('chat-widget-container');
        if (chatWindow) {
            chatWindow.style.display = 'none';
        }
        this.router.navigateByUrl("auth/login");
        
    }
    saveAuditLog() { 
        var currentdate = new Date(); 
if(localStorage.length==0)
return;
        var sessionobj = JSON.parse(localStorage.UserDetails);
         
        var clsmodel = {
          actionName: "Inserted",
          refNo:  sessionobj.users_Username + " Logged Out :" +  currentdate.getDate() + "/"
          + (currentdate.getMonth()+1)  + "/" 
          + currentdate.getFullYear() + " @ "  
          + currentdate.getHours() + ":"  
          + currentdate.getMinutes() + ":" 
          + currentdate.getSeconds(),
          seqno: sessionobj.users_userid,
          location: "0",
          msg: sessionobj.users_Emailaddress,
          user: sessionobj.users_userid.toString(),
          type: "Info", 
        }
        this.service.saveAuditTrail(clsmodel).subscribe(result => {
           console.log("Audit Trail Saved Successfully")
        }, error => {
          console.log(error);
        });
        return "";
    
    
      }
    sessionPopup() {
        clearInterval(this.Intimer);
        this.dialog.closeAll();
        if (localStorage.getItem("UserDetails") != undefined && localStorage.getItem("UserDetails") != "" && localStorage.getItem("UserDetails") != null) {
            this.sessionModelPopup(this.sessionInputRef);
            let seconds: number = 60;
            let textSec: any = "0";
            let statSec: number = 60;
            const prefix = 60 <= 10 ? "0" : "";
            this.Intimer = setInterval(() => {
                seconds--;
                if (statSec != 0) statSec--;
                else statSec = 60;

                if (statSec < 10) {
                    textSec = "0" + statSec;
                } else textSec = statSec; 
                if (seconds == 0) {
                    console.log("finished");
                    clearInterval(this.Intimer);
                    this.dialog.closeAll();
                    this.logOut();
                }

                this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`

            }, 1000);
            
        }
    }

    cancelConfirm() {
        this.dialog.closeAll();
        localStorage.removeItem("UserDetails");
        this.router.navigate(['auth/login']);
    }

    confirm() {
        if (localStorage.getItem("UserDetails") != undefined && localStorage.getItem("UserDetails") != "" && localStorage.getItem("UserDetails") != null) {
            var sessionobj = JSON.parse(localStorage.UserDetails);
            if (sessionobj.users_Usertype == "S") {
                this.router.navigateByUrl('/request-list');
            }
            else
                this.router.navigateByUrl('/request-list');
                clearInterval(this.Intimer);
            this.dialog.closeAll();
        }
    }
}

