import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from '../../../../../common/Service/common.service';
import { fuseAnimations } from '@fuse/animations';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from 'app/common/Service/snackbar.service';
import { EmployeeProfileService } from 'app/main/pages/employee-edit/employee-edit.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DatePipe, Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { de } from 'date-fns/locale';
// export const MY_DATE_FORMATS = {
//     parse: {
//         dateInput: 'DD/MM/YYYY',
//     },
//     display: {
//         dateInput: 'DD/MM/YYYY',
//         monthYearLabel: 'MMMM YYYY',
//         dateA11yLabel: 'LL',
//         monthYearA11yLabel: 'MMMM YYYY'
//     },
// };
@Component({
    moduleId: module.id,
    selector: 'employee-edit-about',
    templateUrl: './emp.about.component.html',
    styleUrls: ['./emp.about.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class EmpProfileAboutComponent implements OnInit, OnDestroy {
    formGroup: FormGroup;
    about: any;
    currentDate = new Date();
    id: string = '';
    _loginDetails: any;
    _countries: any = [];insuranceList: any = [];
    _states: any = [];
    _cities: any = [];
    _industries: any = [];
    _departments: any = [];
    _formGroup: clsScreen = new clsScreen();
    public UploadFile: File;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ProfileService} _profileService
     */
    constructor(
        private _formBuilder: FormBuilder, private _profileService: EmployeeProfileService,
        private service: CommonService,
        private _snackbarservice: SnackbarService,
        private datePipe: DatePipe,
        private activatedRoute: ActivatedRoute,
        private _splashScreenService: FuseSplashScreenService,
        private _location: Location,
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.formGroup = this._formBuilder.group({
            companycode: ['', Validators.required],
            companyname: ['', Validators.required],
            emailAddress: ['', Validators.required],
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            mobileNo: ['', Validators.required],
            address: [''],
            pEmailAddress: [''],
            altmobileNumber: [''],
            gender: [''], Race: [''],Ethinicity: [''],County: [''],
            dateOfBirth: [''], 
            address1: [''],
            city: [''], department: [''],
            state: [''],
            postalCode: [''], documentNo: [''],
            insuranceId: [''],
            country: ['', Validators.required],
        });

        this._profileService.aboutOnChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(about => {
                this.about = about;
            });
        if (localStorage != null && localStorage != undefined && localStorage["UserDetails"]) {
            this._loginDetails = JSON.parse(localStorage.getItem('UserDetails') || '{}');
            if (this._loginDetails.employeeDtls != undefined) {
                this._formGroup.companycode = this._loginDetails.employeeDtls.companyCode;
                this._formGroup.companyname = this._loginDetails.employeeDtls.companyName;
                this._formGroup.employeerID = this._loginDetails.employeeDtls.employeerID;
            }

        }
        this.activatedRoute.params.subscribe(param => this.id = param.id);
        this.getMasterData(1, 0, '');//Countries 
        this.getMasterData(5, this._formGroup.employeerID, '');//EmployeerData
        this.getMasterData(9, 0, '');//Departments 
        this.getMasterData(8, 0, this.id);//Employee data
        this.getMasterData(70, 0, "");//Insurance Data
    }
    goToPrevious() {
        this._location.back();
    }
    getMasterData(flg, filterId, code) {

        var sessionobj = JSON.parse(localStorage.UserDetails);
        var clsmodel = {
            Type: flg,
            FilterId: filterId,
            Code: code
        }
        this.service.GetMasterData(clsmodel).subscribe(result => {
            var resultObj = result;
            if (resultObj["status"] == 200) {
                if (flg == 1) {
                    this._countries = resultObj["resultObj"];
                    this._states.push({ state_id: 0, state_name: "Others" })
                    this._formGroup.stateId = 0;
                    this._cities.push({ city_Id: 0, city_name: "Others" })
                    this._formGroup.cityId = 0;
                }
                else if (flg == 70) { 
                    this.insuranceList = resultObj["resultObj"];
                }
                else if (flg == 2) {
                    this._states = resultObj["resultObj"];
                    this._states.push({ state_id: 0, state_name: "Others" })
                    this._formGroup.stateId = 0;
                }
                else if (flg == 3) {
                    this._cities = resultObj["resultObj"];
                    this._cities.push({ city_Id: 0, city_name: "Others" })
                    this._formGroup.cityId = 0;
                }
                else if (flg == 4) {
                    this._industries = resultObj["resultObj"];
                }
                else if (flg == 9) {
                    this._departments = resultObj["resultObj"];
                }
                else if (flg == 8) { 
                    if (resultObj["resultObj"] != undefined) {  
                        this._formGroup.companycode = resultObj["resultObj"].clsEmployeerModel.clientCode;
                        this._formGroup.companyname =  resultObj["resultObj"].clsEmployeerModel.orgName;
                        this._formGroup.employeerID = resultObj["resultObj"].clsEmployeerModel.employerId;
                        this._formGroup.address = resultObj["resultObj"].street; 
                        this._formGroup.lastName = resultObj["resultObj"].lastName;
                        this._formGroup.firstName = resultObj["resultObj"].firstName;
                        this._formGroup.insuranceName = resultObj["resultObj"].insuranceName;
                        this._formGroup.empDocument=resultObj["resultObj"].documentTypeText; 
                        //this._formGroup.documentType = resultObj["resultObj"].documentType;
                     //   this._formGroup.documentNo = resultObj["resultObj"].documentNo;
                        this._formGroup.departmentId = resultObj["resultObj"].departmentId;
                        this._formGroup.gender = resultObj["resultObj"].gender;
                        this._formGroup.County = resultObj["resultObj"].county;
                        this._formGroup.Ethinicity = resultObj["resultObj"].ethinicity;
                        this._formGroup.Race = resultObj["resultObj"].race;
                        this._formGroup.city = resultObj["resultObj"].city;
                         this._formGroup.documentNo = resultObj["resultObj"].documentNo;
                        this._formGroup.dateOfBirth = new Date(resultObj["resultObj"].dateOfBirth);
                        this._formGroup.alternateMobile = resultObj["resultObj"].alternateMobile;
                        this._formGroup.emailAddress = resultObj["resultObj"].emailAddress;
                        this._formGroup.mobileNo = resultObj["resultObj"].mobileNumber;
                        this._formGroup.pEmailAddress = resultObj["resultObj"].emailAddress;
                        this._formGroup.zipCode = resultObj["resultObj"].zipCode;
                        this._formGroup.countryId = resultObj["resultObj"].countryId;
                        if (resultObj["resultObj"].stateId != null && resultObj["resultObj"].stateId != 0)
                            this.getMasterData(2, this._formGroup.countryId, '');
                            setTimeout(() => {
                                this._formGroup.stateId = resultObj["resultObj"].stateId;
                            }, 2000);
                       
                        
                    }
                }
            }

        }, error => {
            console.log(error);
        });
        return "";
    }
    changeCountry(_obj, flag) {
        this.getMasterData(flag, _obj.value, '');
    }
    UploadDocument() { 
        const formData = new FormData();
        formData.append('employerCode',this.id);
        if(this.UploadFile !== undefined)
            formData.append('profileImage', this.UploadFile, this.UploadFile.name);
        this.service.UploadEmployeeDocument(formData).subscribe(result => {

            var resultObj = result;

            if (resultObj["status"] == 200) {
                this._snackbarservice.showSnackbar(resultObj["message"]);
            }
            else
                this._snackbarservice.showSnackbar(resultObj["message"]);
            
        }, error => {
            console.log(error);
             
        });
         
    }
    onFileChanged(event) { 
        const file = event.target.files[0]; 
          this.UploadFile = file;
        var reader = new FileReader();
        reader.onload = (event: any) => {
           
            //this.imagePath = event.target.result;
            this.UploadDocument();
        }
        reader.readAsDataURL(event.target.files[0]);
    
      }
    SubmitEmpForm() {
        // const invalid = [];
        // const controls = this.formGroup.controls;
        // for (const name in controls) {
        //     if (controls[name].invalid) {
        //         invalid.push(name);
        //     }
        // }

        // if(this.formGroup.invalid)
        // return;
        if (this._formGroup.emailAddress != this._formGroup.pEmailAddress) {
            this._snackbarservice.showSnackbar("Confirm email and email didn't match");
            return;
        }

        var _dateFormat = 'dd/MM/yyyy';
        this._formGroup.dob = this.datePipe.transform(this._formGroup.dateOfBirth, _dateFormat);
        this._splashScreenService.show();
        this._formGroup.employerCode = this.id;
        this.service.UpdateEmployee(this._formGroup).subscribe(result => {

            var resultObj = result;

            if (resultObj["status"] == 200) {
                this._snackbarservice.showSnackbar(resultObj["message"]);
            }
            else
                this._snackbarservice.showSnackbar(resultObj["message"]);
            this._splashScreenService.hide();
        }, error => {
            console.log(error);
            this._splashScreenService.hide();
        });

    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
export class clsScreen {
    countryId: number;
    stateId: number;
    cityId: number;
    city:string;
    employerCode: string;
    businessType: number;
    industry: number;
    companycode: string; emailAddress: string;
    companyname: string; mobileNo: string;
    firstName: string; lastName: string;
    pEmailAddress: string;
    alternateMobile: string;
    documentNo: string;
    documentType: number;
    departmentId: string;
    insuranceName: number;
    zipCode: string;
    dob: string;
    dateOfBirth:Date;
    gender: string;
    Race: string; empDocument: string;
    Ethinicity: string;
    County: string;
    address: string;
    address1: string;
    employeerID: number;
    logo: string;
}