import { Component, ElementRef, ViewChild, OnInit, OnDestroy,ViewEncapsulation, AfterViewInit, NgModule } from '@angular/core';
import { CommonService } from 'app/common/Service/common.service';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from 'app/common/Service/snackbar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators, FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatPaginator } from '@angular/material/paginator';
import { TableUtil } from "../../../helpers/tableUtil";
import { DatePipe } from '@angular/common';
import { CircleTimerComponent } from '@flxng/circle-timer';
import * as XLSX from "xlsx";
@Component({
  selector: 'selftest-list',
  templateUrl: './selftest-list.html', 
  styleUrls: ['./selftest-list.component.scss']
})
export class selfTestListComponent implements OnInit, AfterViewInit {
  rows: any = []; rowslist: any = [];
  timerlist: any = [];
  toxilabrows: any = [];
  requestform: FormGroup;
  TransferForm: FormGroup;
  result: any = {};
  testList: any = [];
  selfTestPref: any = [];
  resultLength: number = 0;
  categories: any = []; currentCategory: string;
  storeData: any;
  filteredOptions1: Observable<string[]>;
  index: number = 0;
  searchKey: string = "";
  dataSource: any = [];
  usercode: string = "";
  empObject: any = {};
  clsmodel: any = {};
  test_type: string = "all";
  selectedList: any = [];
  EmailList: any = [];
  buttonDisabled: boolean;
  ids: any[] = [];
  emailStatus_div: boolean = false;
  divbackgroundClass = "";
  searchTerm: string = "";
  counter: number = 0;
  myControl = new FormControl();
  myControl1 = new FormControl();
  minDate: Date;
  usersdata: any = [];
  preferences: any = [];
  maxDate: Date;
  startDate: string;
  endDate: string;
  filteredOptions: Observable<string[]>;
  dateFormat: string;
  selectedLocation: any = [];
  _referenceNo: string = "";
  FromLocation: any = [];
  ToLocation: any = [];
  ScreenActions: any = { IsEdit: false, IsPrint: false,Download:false, Document: false, IsSendNotification: false, IsTransfer: false, IsPDF: false, IsDelete: false }
  @ViewChild('view_Msg') view_Msg: ElementRef;
  @ViewChild('send_notification') send_notification: ElementRef;
  @ViewChild('pdf_confirmation') pdf_confirmation: ElementRef;
  @ViewChild(MatPaginator, { read: true }) paginator: MatPaginator;

  timerstartDate = Date.now() - (15 * 1000); // current time minus 15 seconds
  duration = 15 * 1000; // 15 minute
  //@ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
  //    this.paginator = mp;
  //    this.setDataSourceAttributes();
  //}

  MsgResult: any = { 'msgType': "", 'actionType': "", 'result': "" };

  range = new FormGroup({
    start: new FormControl(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1)),
    end: new FormControl(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))
  });
  reference_no: string = "";
  showfilter: boolean = false;
  filtertxt: string = "Show Filter";
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['isSelected', 'code', 'mobileNo','dob', 'fullname', 'createdDate', 'status', 'counter', 'seqno'];
  constructor(

    private service: CommonService,
    private dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private _snackbarservice: SnackbarService,
    private _splashScreenService: FuseSplashScreenService,
    private router: Router,
  ) {


    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 100, 0, 1);
    this.maxDate = new Date(currentYear, new Date().getMonth(), new Date().getDay());
    this.startDate = this.datePipe.transform(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1), "yyyy-MM-dd");
    this.endDate = this.datePipe.transform(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), "yyyy-MM-dd");

  }

  ngOnInit(): void {

    this.requestform = this._formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobileNo: ['', Validators.required],
      emailAddress: ['', Validators.required],
      gender: ['', Validators.required],
      DateOfBirth: ['', Validators.required],

    });

    var sessionObject = JSON.parse(localStorage.UserDetails)
    this.ScreenActions.IsEdit = this.service.getActionPermissions("SelfTestList", "Edit");
    this.ScreenActions.IsTransfer = this.service.getActionPermissions("SelfTestList", "Transfer");
    this.ScreenActions.IsPDF = this.service.getActionPermissions("SelfTestList", "SendPDF");
    this.ScreenActions.IsPrint = this.service.getActionPermissions("SelfTestList", "Print");
    this.ScreenActions.Download = this.service.getActionPermissions("SelfTestList", "Download");
    this.ScreenActions.Document = this.service.getActionPermissions("SelfTestList", "Document");
    this.ScreenActions.IsSendNotification = this.service.getActionPermissions("SelfTestList", "SendNotification");
    this.getMasterData(38, 0, "");//TestList  
    this.empObject.test_type = "0"
    this.empObject.result = "0";
    this.empObject.reportType = "Pending";
    this.test_type = "all";
    this.empObject.reportType = "0";
    if (sessionObject.users_Usertype == "A")
      this.getMasterData(10, sessionObject.users_userid, "");//LocationUsers
    else
      this.getMasterData(30, sessionObject.users_userid, "");//LocationUsers

    this.searchlist();
  }
  closepdfwindow() {
    this.dialog.closeAll();
    this.reference_no = "";
  }
  onTimer3Complete(): void {
    console.log('timer completed!');
  }
  sendEmailInAsync() {
    var seqnos = "";
    this.EmailList = [];
    this.selectedList.forEach(element => {
      this.EmailList.push({ refNo: element.element.referenceNo, seqno: element.element.seqno, Status: "Pending" })
    });
    let counter = 0;
    this.EmailList.forEach(element => {
      var sessionobj = JSON.parse(localStorage.UserDetails);
      var clsmodel = {
        TestResult: this.empObject.result,
        requestIDs: element.refNo,
        UserId: sessionobj.users_userid

      }
      this.service.UpdateSelfTestResultsAsync(clsmodel).subscribe(result => {
        var resultObj = result;
        if (resultObj["status"] == 200) {
          element.Status = "Sent";
          counter++;
          if (counter == this.EmailList.length) {
            this.emailStatus_div = false;
            this.empObject.result = "0";
            this.EmailList = [];
            this.selectedList = [];
            this.searchlist();
          }
        }
        else {
          element.Status = resultObj["message"];
        }


      }, error => {
        console.log(error);
      });
    });

  }
  sendEmailBackground() {
    this.divbackgroundClass = "email_status";
  }
  OpenStatus(ref) {
    this.emailStatus_div = true;
  }
  closeemailsendingdiv(){
    this.emailStatus_div = false;
  }
  submitnotification() {
    if (this.selectedList.length > 0) {
      this.emailStatus_div = true;
      this.sendEmailInAsync();

    } else {
      this._snackbarservice.showSnackbar("No record[s] are selected");
    }
  }

  startTimers() {
    if (this.timerlist.length > 0) {

      this.timerlist.forEach(element => {
        this.timer(element.duration, element.refNo);
      });

    }
  }
  display: string = '';
  clearTimerList:any=[];
  timer(minute, refNo) {

    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = minute <= 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;



      if (seconds == 0) {
        console.log("finished");
        clearInterval(timer);
      }
      var currentObject = this.timerlist.filter((course) => {
        return course.refNo == refNo;
      });
      if (currentObject != null && currentObject.length > 0) {
        currentObject[0].className = Math.floor(seconds / 60) >= 3 && Math.floor(seconds / 60) <=5? "stage_2" : Math.floor(seconds / 60) <= 2 ?
          "stage_1 blinking" : "stage_3";
        currentObject[0].display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;
      }

    }, 1000);
this.clearTimerList.push(timer);
  }
  GetLatestDuration(ref) {
    let dis = '<span class="bg-green mb-8 timer_button stage_3"> ';
    var currentObject = this.timerlist.filter((course) => {
      return course.refNo == ref;
    });
    if (currentObject != null && currentObject.length > 0) {
      dis = dis.replace("stage_3", currentObject[0].className);
      dis += currentObject[0].display;
    }
    else {
      dis = dis.replace("stage_3", "");
      dis += "N/A";
    }
    dis += "</span>";
    return dis;
  }
  ngAfterViewInit(): void {
    //this.dataSource.sort = this.sort;

    if (this.paginator !== undefined)
      this.dataSource.paginator = this.paginator;

    this.range.valueChanges.pipe(

    ).subscribe(event => {
      if (event.end) {
        this.onDateChanged(event);
      }
    });


  }
  filterdiv() {
    if (this.showfilter) {
      this.filtertxt = "Show Filter";
      this.showfilter = false;
    }
    else {
      this.showfilter = true;
      this.filtertxt = "Hide Filter";
    }
  }
  onsearchEnter(_key) {
    if (_key.keyCode === 13) {
      this.searchlist();
    }
  }
  searchlist() {
    this.searchKey = this.searchTerm
    this.getdatawithcommonpaging(0, 50, 'createdDate', false, null, null);
  }
  view_document(_referenceNo) { // get s3 signed url
    this.getMasterData(59, 0, _referenceNo);

  }
  download_pdf(_referenceNo) {
    this.getMasterData(62, 0, _referenceNo);

  }
  sendPDF(referenceno) {
    this.reference_no = referenceno;
    if (this.reference_no != undefined && this.reference_no != "") {
      this.openmodal(this.pdf_confirmation);
    } else {
      this._snackbarservice.showSnackbar("No record[s] are selected");
    }
  }
  sendPDFConfirmation() {
    var sessionobj = JSON.parse(localStorage.UserDetails);
    if (this.reference_no != undefined && this.reference_no != "") {
      this._splashScreenService.show();
      this.getMasterData(63, sessionobj.users_userid, this.reference_no);//send pdf  
    } else {
      this._snackbarservice.showSnackbar("Invalid reference no");
    }
  }
  sendNotication() {
    if (this.selectedList.length > 0) {
      this.openmodal(this.send_notification);
    } else {
      this._snackbarservice.showSnackbar("No record[s] are selected");
    }
  }

  OnStatusClick(_referenceNo) {
    this.reference_no = _referenceNo;
    var selectedIds = this.rows.filter((course) => {
      return course.referenceNo == _referenceNo;
    });
    if (selectedIds != undefined && selectedIds.length > 0) {
      selectedIds.forEach(element => {
        this.selectedList.push({ element });
      });

    }

  }
  onCheckboxChangeFn(event, seqno) {
    this.selectedList = [];
    var selectedIds = this.rows.filter((course) => {
      return course.isSelected == true;
    });
    if (selectedIds != undefined && selectedIds.length > 0) {
      selectedIds.forEach(element => {
        this.selectedList.push({ element });
      });

    }
  }

  generateReport() {
    this.getdatawithcommonpaging(0, 50, 'createdDate', false, this.startDate, this.endDate);
  }
  flg = 0;
  onDateChanged(ev) {
    if (this.flg == 1) {
      this.startDate = this.datePipe.transform(ev.start._d, "yyyy-MM-dd");
      this.endDate = this.datePipe.transform(ev.end._d, "yyyy-MM-dd");
      this.getdatawithcommonpaging(0, 50, 'createdDate', false, this.startDate, this.endDate);
      this.flg = 0;
    }
    else {
      this.flg++;
    }
  }

  viewmsg(msg, action) {
    this.MsgResult.msgType = msg.split('Body:')[0];
    this.MsgResult.actionType = action;
    this.MsgResult.result = msg.split('Body:')[1];
    this.openmodal(this.view_Msg);
    var ele = '';

  }
  exportExcel() {
    TableUtil.exportTableToExcel("tableData", "AuditReport");
  }
  exportToxilabExcel() {
    TableUtil.exportTableToExcel("tblToxiLabRpt", "AuditReport");
  }
  displayFn(selectedoption) {

    return selectedoption ? selectedoption.location_propertyName : undefined;
  }
  openmodal(modal) {
    this.dialog.open(modal, { width: "700px", height: "auto", disableClose: true });
  }
  openlocationmodal(modal) {
    this.dialog.open(modal, { width: "400px", height: "auto", disableClose: true });
  }


  onToSelectionChange(_ev) {
    if (_ev.option.value != null) {
      this.ToLocation = _ev.option.value;

    }
  }
  onFromSelectionChange(_ev) {
    if (_ev.option.value != null) {
      this.FromLocation = _ev.option.value;

    }
  }
  getTotalRecords(testCode: string) {
    let _tot = 0;
    this.rows.forEach(element => {
      var _s = element.clsTestWiseCnts.filter((course) => {
        return course.testName == testCode;
      });
      if (_s != undefined && _s.length > 0) {
        _tot += _s[0].cnt
      }
    });
    return _tot;

  }

  reloadRoute() {
    window.location.reload();
  }
  onPageFired(event) {
    let pgIndex = (event.length / event.pageSize) - 1 == event.pageIndex ? event.pageIndex : event.pageIndex;
    this.getdatawithcommonpaging(pgIndex, event.pageSize, this.clsmodel.sortKey, this.clsmodel.ordering, null, null);
  }
  clearTimers(){
    this.clearTimerList.forEach(element => {
      clearInterval(element);
    });
    this.timerlist=[];
  }
  getdatawithcommonpaging(page, pageSize, sortKey, ordering, stDate, endDate) {
    if (this.startDate == null || this.endDate == null)
      return;
    this.clearTimers();
    this._splashScreenService.show();
    var sessionobj = JSON.parse(localStorage.UserDetails);
    this.clsmodel.pageNumber = page;
    this.clsmodel.pageSize = pageSize;
    this.clsmodel.sortKey = sortKey;
    this.clsmodel.ordering = ordering;
    this.clsmodel.featureId = 100;
    this.clsmodel.startDate = this.startDate; this.clsmodel.endDate = this.endDate;
    this.clsmodel.searchKey = this.searchKey;
    this.clsmodel.categoryId = Number(this.empObject.reportType);
    this.clsmodel.accountId = sessionobj.users_userid;
    this.clsmodel.UserType = sessionobj.users_Usertype;
    this.service.getSelfTestByPaging(this.clsmodel).subscribe((data) => {
      if (data["status"] == "200") {
        // then you can assign data to your dataSource like so
        this.result = data["resultObj"]["metadata"];
        this.resultLength = this.result.totalRecords;
        this.rows = data["resultObj"]["data"];
        this.rowslist = this.rows;
        let _prefObject = data["resultObj"]["metadata"]["selfTestPreferences"];
        if (_prefObject != null && _prefObject.length > 0) {
          var _list = _prefObject.filter((course) => {
            return course.selftestpref_priority == 1;
          });
          if (_list != null && _list.length > 0) {
            this.selfTestPref = _list;
          }
        }
        let prefDuration = this.selfTestPref == null || this.selfTestPref.length == 0 ? 10 :
          this.selfTestPref[0].selftestpref_duration;

        this.rows.forEach(element => {
          if (element.testResult == 'Pending' && element.noOfMinutes <= prefDuration) {
            this.timerlist.push({
              'refNo': element.referenceNo, 'time': element.createdtime, 'date':
                element.dtCreated, 'duration': prefDuration - element.noOfMinutes, 'totalDuration': prefDuration,
              'display': '00:00', 'className': 'stage_3'
            });
          }
        });
        this.startTimers();
        this.dataSource = new MatTableDataSource(this.rows);
        this.dataSource.sort = this.sort;

        const sortState: Sort = { active: 'createdDate', direction: 'desc' };
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);

        var positive = this.rows.filter((course) => {
          return course.status == "Detected";
        });
        var negative = this.rows.filter((course) => {
          return course.status == "Not Detected";
        });
        var Unsent = this.rows.filter((course) => {
          return (course.status == "Not Detected" || course.status == "Detected") && course.emailCounter == 0;
        });

        this._splashScreenService.hide();
      }
      else {
        this._snackbarservice.showSnackbar(data["message"]);
        this._splashScreenService.hide();
      }
    });

  }
  _filter(value: string) {
    return this.usersdata.filter(option => option.location_propertyName.toLowerCase().includes(value.toLowerCase()));
  }
  getMasterData(flg, filterId, code) {
    var sessionobj = JSON.parse(localStorage.UserDetails);
    var clsmodel = {
      Type: flg,
      FilterId: filterId,
      Code: code
    }
    this.service.GetMasterData(clsmodel).subscribe(result => {
      var resultObj = result;
      if (resultObj["status"] == 200) { 
        if (flg == 34) {
          this.dateFormat = resultObj["resultObj"];
        }
        else if (flg == 59) {
          let link = document.createElement('a');
          link.setAttribute('type', 'hidden');
          link.download = "ViewDocument";
          link.target = "_blank"
          link.href = resultObj["resultObj"];
          document.body.appendChild(link);
          link.click();
          link.remove();

        }
        else if (flg == 63) {
          this._snackbarservice.showSnackbar(resultObj["message"]);
          this.reference_no = "";
          this.dialog.closeAll();
          this._splashScreenService.hide();
        }
        else if (flg == 62) {
          let link = document.createElement('a');
          link.setAttribute('type', 'hidden');
          link.download = "DownloadPdf";
          link.target = "_blank"
          link.href = resultObj["message"];
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
        else if (flg == 30 || flg == 10) {
          this.usersdata = resultObj["resultObj"];
          this.filteredOptions1 = this.myControl1.valueChanges
            .pipe(
              startWith(''),
              map(value => typeof value === 'string' ? value : value.location_propertyName),
              map(location_propertyName => location_propertyName ? this._filter(location_propertyName) : this.usersdata.slice())
            );
        }
        else if (flg == 38) {
          this.testList = resultObj["resultObj"];
        }


      }

    }, error => {
      console.log(error);
    });
    return "";

  }

  filterCoursesByCategory(_filterid): void {
    // Filter 
    if (_filterid.value == "all")
      this.getMasterData(10, 0, "");//Countries  
    else
      this.getMasterData(30, _filterid.value, "");//Countries  
  }
  filterCoursesByTerm() {

  }


}
const routes: Routes = [
  {
    path: 'self-test-list',
    component: selfTestListComponent

  }
];