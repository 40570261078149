<div id="register" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">

    <div id="register-intro1"  fxShow.gt-xs>

        <div class="logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
            <img src="assets/images/logos/pdp-logo.svg">
        </div>

        <div class="title" [@animate]="{value:'*',params:{delay:'50ms',y:'25px'}}">
            <span *ngIf="!isValid">  QR Code, please try again</span> 
            <span *ngIf="isValid">  Valid QR Code</span> 
        </div> 
        <div class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
            <a class="link" [routerLink]="'/auth/request'">Request form</a>
        </div>

    </div>
    <div class="overlay"></div>
    </div>