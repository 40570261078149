import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonService } from 'app/common/Service/common.service';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from 'app/common/Service/snackbar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { DatePipe } from '@angular/common';
import { HttpClient,  HttpEventType,  HttpErrorResponse} from "@angular/common/http";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';
import { Observable, throwError } from 'rxjs';
import { takeUntil, catchError } from 'rxjs/operators';
@Component({
  selector: 'app-vendor-list',
  templateUrl: './vendor-list.component.html',
  styleUrls: ['./vendor-list.component.scss']
})
export class VendorListComponent implements OnInit {

  rows: any = [];
  listpref:any=[];
  _loginDetails: any;
  usersdata: any = [];
  locationWiseUsers: any = [];
  usersdataList: any = [];
  coursesFilteredByCategory: any[];
  modalType: number = 0;
  vendorType:number = 58;
  usersearchTerm: string;
  currentCategory: string;
  btnText: string = "Save";
  searchTerm: string;
  usercode:string="";
  dateFormat: string;
  step1Img:any=[];
  filteredCourses: any = []; lstlatlngs: any = []; lstlatlngs1: any = [];
  alternateColor: any[] = ["android", "web", "cloud", "firebase"];
  empCode: string = "";
  counter: number = 0;
  modalTitle: string = "approve vendor?";
  selected: any;
  selLocation: number = 0;
  CntList: any = {};
  empObject: any = {};
  viewHide: number = 1;
  preferenceForm:FormGroup;
  progress: number;
  selectedFileName: string;
  vendorDetails: FormGroup;
  @ViewChild('confirmpopup') invitation_link: ElementRef;
  @ViewChild('assignuserpop') assignuserpop: ElementRef;
  @ViewChild('add_Vendor') add_Vendor: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;

  private _unsubscribeAll: Subject<any>;


  constructor(
    private _CommonService: CommonService,
    private _matIcon: MatIconModule,
    private _snackbarservice: SnackbarService,
    private _splashScreenService: FuseSplashScreenService,
    private service: CommonService, private dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private router: Router,
    private datePipe: DatePipe,
    private http: HttpClient,
  ) {
    // Set the defaults
    this.currentCategory = 'all';
    this.searchTerm = '';
    this.preferenceForm= this._formBuilder.group({
      vendor_code: [''], 
      Description:[''],
  });
  }
  getAlternateColor() {
    if (this.counter == 3)
      this.counter = 0;
    else
      this.counter++;
    return this.alternateColor[this.counter] + "-bg";

  }
  SaveUsermapping() {

    if (this.selLocation == undefined || this.selLocation == 0)
      return;
    var selectedUsers = "";
    this.locationWiseUsers.forEach(element => {
      if (element.isChecked) {
        selectedUsers += element.users_userid + ",";
      }
    });
    this.getMasterData(44, this.selLocation, selectedUsers);//Countries
  }
  filterCoursesByCategory(_filterid): void {
    // Filter  
    this._splashScreenService.show();
    if (_filterid.value == "all")

      this.getMasterData(13, 0, '');//Countries  
    else
      this.getMasterData(46, _filterid.value, '');//Countries  
    this._splashScreenService.hide();

  }
  ngOnInit(): void {
    var sessionobj = JSON.parse(localStorage.UserDetails);
    //this.dataSource = new Object();//new FilesDataSource(this._fileManagerService);
    this.getMasterData(57, sessionobj.users_Customerid, '');//EmployeerData 
    this.getMasterData(18, 0, '');//getLocationWiseUsers
    this.vendorDetails = this._formBuilder.group({
      name: ['', Validators.required],
      emailAddress: ['', [Validators.required, Validators.email]],
      contactno: ['null', [Validators.required, Validators.pattern("[0-9 ]{10}")]],
      address: ['', Validators.required],
      website: [''],
      manufacturer: [''],
    });
  }
  invitationPop(_selectedCode, flg) {
    this.modalType = flg;
    if (flg == 1)
      this.modalTitle = "Active Vendor?"
    else if (flg == 0)
      this.modalTitle = "de-active Vendor?"
    this.empCode = _selectedCode;
    this.openmodal(this.invitation_link);
  }
  uservalueChange(loc, $event) {
    this.locationWiseUsers[loc].isChecked = $event.checked;
  }

  addVendor() {

    this.empObject.name = "";
    this.empObject.emailAddress = "";
    this.empObject.contactno = "";
    this.empObject.address = "";
    this.empObject.manufacturer = "";
    this.empObject.website = "";
    this.btnText = "Save";
    this.openmodal(this.add_Vendor);
  }
  vendorSubmit() {
    if (this.vendorDetails.invalid) {
      return;
    }
    
    this._splashScreenService.show();
    this.empObject.code=this.usercode;
    this.service.VendorData(this.empObject).subscribe(result => {

      var resultObj = result;
      if (resultObj["status"] == 200) {
        this.vendorDetails.reset();
        this._snackbarservice.showSnackbar(resultObj["message"]);
        this.getMasterData(57, 0, "");//EmployeerData
        this.dialog.closeAll();
      }
       
      else
        this._snackbarservice.showSnackbar(resultObj["message"]); 
        
      this._splashScreenService.hide();
    }, error => {
      console.log(error);
      this._splashScreenService.hide();
    });
  }
  openmodal(modal) {
    this.dialog.open(modal, { width: "700px", height: "auto", disableClose: true });
  }
  assignuser(emp_id) { 
    this.empObject.code=emp_id;
    this.getMasterData(61, 0, emp_id);//getLocationWiseUsers 
    

  }
  UploadImage(file: any) {
    this.progress = 1;
    const formData = new FormData();
    formData.append("file", file); 
    let APiURL = environment.BaseApiUrl + "Common";
    this.http
        .post(APiURL + '/UploadSelfTestImage', formData, {
            reportProgress: true,
            observe: "events"
        })
        .pipe(
            map((event: any) => {
                if (event.type == HttpEventType.UploadProgress) {
                    this.progress = Math.round((100 / event.total) * event.loaded);
                } else if (event.type == HttpEventType.Response) {
                    if (event.body.status == 200) {
                        this.empObject.UploadedimagePath = event.body.resultObj;
                    }
                    this.progress = null;
                }
            }),
            catchError((err: any) => {
                this.progress = null;
                alert(err.message);
                return throwError(err.message);
            })
        )
        .toPromise();
}
  onFileChanged(event) {
    const file = event.target.files[0];
    
    if (!file.type.includes("image")) {
        //this.snackBar.showSnackbar("Please select image file only");
        this.fileInput.nativeElement.value = "";
    }
    else {
        var maxfilesize = (1024 * 1024) * 12,  // 12 Mb
            filesize = file.size;
        if (filesize > maxfilesize) {
            this._snackbarservice.showSnackbar("File too large: " + filesize + ". Maximum size: upto 12MB");
            return;
        }
        this.UploadImage(file);
        this.empObject.profileImage = file;
        this.selectedFileName = this.empObject.profileImage.name
        var reader = new FileReader();

        reader.onload = (event: any) => {

            this.empObject.imagePath = event.target.result;
        }



        reader.readAsDataURL(event.target.files[0]);
    }

}
  closewindow() {
    this.dialog.closeAll();
  }
  ApproveClient() { 
      this.getMasterData(58,this.modalType, this.empCode);    
  }
  edit_user(accessCode: string) {

    this.openmodal(this.add_Vendor);

    this.usercode = accessCode;

    var _firstlist = this.rows.filter((row) => {
      return row.vendor_code === this.usercode;
    });
    if (_firstlist != undefined && _firstlist.length > 0) {
      this.empObject.name = _firstlist[0].vendor_name,
        this.empObject.contactno = _firstlist[0].vendor_contactNo,
        this.empObject.emailAddress = _firstlist[0].vendor_emailAddress,
        this.empObject.website = _firstlist[0].vendor_website,
        this.empObject.manufacturer = _firstlist[0].vendor_manufacturer,
        this.empObject.address = _firstlist[0].vendor_address
    }

    this.btnText = "Update";
  }
  getMasterData(flg, filterId, _code) {


    var sessionobj = JSON.parse(localStorage.UserDetails);
    var clsmodel = {
      Type: flg,
      FilterId: filterId,
      Code: _code
    }
    this.service.GetMasterData(clsmodel).subscribe(result => {
      this._splashScreenService.hide();
      var resultObj = result;
      if (resultObj["status"] == 200) {
        if (flg == 34) {
          this.dateFormat = resultObj["resultObj"];
        }
        else if (flg == 57) {
          this.rows = resultObj["resultObj"];
          this.rows.forEach(element => {
            element.location_className = this.getAlternateColor();
          });

          var _ActList = this.rows.filter((row) => {
            return row.vendor_isActive == 1;
          });
          var _InActList = this.rows.filter((row) => {
            return row.vendor_isActive == 0;
          }); 
          this.CntList.TotalClients = this.rows.length;
          this.CntList.ActiveList = _ActList.length;
          this.CntList.InActiveList = _InActList.length;
          this.coursesFilteredByCategory = this.rows;
        } 
         
        else if (flg == 58) {
          this._snackbarservice.showSnackbar(resultObj["message"]);
          this.getMasterData(57,0,"");
          this.dialog.closeAll();
        } 
        else if (flg == 61) { 
          this.listpref=resultObj["resultObj"];
           this.getperfdetails(1);
          this.openmodal(this.assignuserpop);
        } 
      }

    }, error => {
      console.log(error);
    });
    return "";
  }
  getperfdetails(step){
    let step1_img = this.listpref.filter((row) => {
      return row.vendorpref_step == step;
    }); 
    if(step1_img!=null && step1_img.length>0){
      this.step1Img.push({'imagepath':step1_img[0].vendorpref_imagePath,'description':step1_img[0].vendorpref_desc});
    }
  }
  valueChange(locationID, testid) {
    this.getMasterData(26, testid, locationID);//Countries  
  }
  searchlist(): void {
    const searchTerm = this.usersearchTerm.toLowerCase();
    if (searchTerm === '') {

    }
    else {
      this.locationWiseUsers = this.usersdataList.filter((row) => {
        return row.users_Username.toLowerCase().includes(searchTerm);
      });
    }
  }
  filterCoursesByTerm(): void {
    const searchTerm = this.searchTerm.toLowerCase();

    // Search
    if (searchTerm === '') {
      this.rows = this.coursesFilteredByCategory;
    }
    else {
      this.rows = this.coursesFilteredByCategory.filter((row) => {
        return row.employeer_orgName.toLowerCase().includes(searchTerm);
      });
    }
  }
  validateNumber(event) {
    const keyCode = event.keyCode;

    const excludedKeys = [8, 37, 39, 46];
    if (keyCode == 9 || keyCode == 13)
      return;
    if (!((keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) ||
      (excludedKeys.includes(keyCode)))) {
      event.preventDefault();
    }
  }
}
