import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { de } from 'date-fns/locale';
 
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    _loginDetails:any;
    constructor(
        private router: Router 
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {         
        if (localStorage != null && localStorage != undefined && localStorage["UserDetails"]) { 
            this._loginDetails = JSON.parse(localStorage.getItem('UserDetails') || '{}');
            
        }       
        const user = this._loginDetails;
        if (user) {
          
            // check if route is restricted by role
            if (user.users_Usertype!="A" && user.users_Usertype!="S") {
                // role not authorised so redirect to home page                
                this.router.navigate(['/auth/login']);
                return false;
            }

            // authorised so return true
            return true;
        } 
        // not logged in so redirect to login page with the return url 
        this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}