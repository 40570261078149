<div id="academy-courses" class="page-layout simple">

    <div class="center">
        <div class="header1 accent p-24" fxLayout="row" fxLayoutAlign="start center">

            <div class="breadcrumb text-truncate h1" fxLayout="row" fxLayoutAlign="start center"
                [@animate]="{value:'*',params:{x:'50px'}}">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <span>List of Centers</span>
                </div>
            </div>
            <!-- <div class="clsCaption"> Displaying records between {{startDate}} to {{endDate}}</div> -->
        </div>
    </div>
    <div class="header accent " fxLayout="column" fxLayoutAlign="center center">
        <agm-map [zoom]="10" [latitude]="lat" [longitude]="lng">
            <agm-marker *ngFor="let post of lstlatlngs" [iconUrl]="icon" [latitude]="post.lat" [longitude]="post.lon">
            </agm-marker>
        </agm-map>


    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content p-24">

        <div fxLayout="column" fxLayoutAlign="center">

            <div fxLayout="column" fxLayoutAlign="center">
                <fieldset>
                    <legend>
                        <h3>Filters</h3>
                    </legend>
                    <div class="filters" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
                        fxLayoutAlign.gt-xs="start center">
                        <mat-form-field appearance="fill" floatLabel="always" fxFlex="33" class="course-search">
                            <mat-label>Search for a center</mat-label>
                            <input matInput placeholder="Enter a keyword..." [(ngModel)]="searchTerm"
                                (input)="filterCoursesByTerm()">
                        </mat-form-field>
        
                        <mat-form-field appearance="fill" fxFlex="33" class="category-selector">
                            <mat-label>Users</mat-label>
                            <mat-select [(ngModel)]="currentCategory" (selectionChange)="filterCoursesByCategory($event)">
                                <mat-option [value]="'all'">
                                    All
                                </mat-option>
                                <mat-option *ngFor="let row of usersdata" [value]="row.users_userid">
                                    {{ row.users_Fullname}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        
                
                    </div>
                </fieldset>
                
                </div>
            
            <div class="filters" fxLayout="column" fxLayoutAlign="end center" fxLayout.gt-xs="row"
                fxLayoutAlign.gt-xs="end">
                <div class="captions">

                    <span class="dosespan mat-raised-button">Total Open - &nbsp;<label
                            class="text-green">{{TotalActive}}</label></span>

                    <span class="dosespan mat-raised-button">Total Closed - &nbsp;<label
                            class="text-warn">{{TotalInActive}}</label> </span>

                    <span class="dosespan mat-raised-button">Total centers - &nbsp;<label
                            class="text-accent">{{filteredCourses.length}}</label> </span>
                </div>

                <button mat-raised-button class="primary mb-8 right" (click)="addLocation()"
                    [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
                    <mat-icon style="font-size: 20px;">location_on</mat-icon>
                    Add Location
                </button>
            </div>
            <div class="courses" fxLayout="row wrap" fxLayoutAlign="center">

                <div class="course" *ngFor="let course of displaypagedata" fxFlex="100" fxFlex.gt-xs="50"
                    fxFlex.gt-sm="33" fxFlex.gt-md="25" [ngClass]="course.category">

                    <div class="course-content" fxLayout="column" fxFlex="1 1 auto">

                        <div class="header fx-lt-grey" fxLayout="row" fxLayoutAlign="center center"
                            [ngClass]="course.location_className">

                            <div class="category" fxFlex>
                                {{course.location_accountName}}
                                <span>{{course.location_code}}</span>
                            </div>

                            <div class="length" fxLayout="row" fxLayoutAlign="center center">
                                <div class="min">{{course.location_status}} </div>
                            </div>

                        </div>

                        <div class="content pos-rel" fxLayout="column" fxLayoutAlign="start" fxFlex="1 1 auto">
                            <div class="h1">{{course.location_propertyName}}</div>
                            <div class="updated">
                                <mat-icon class="length-icon s-20 mr-4">location_city</mat-icon>{{course.location_city}}
                            </div>
                            <!-- <div class="updated"><span class="">Total Requests: {{course.totalRequests}}</span></div>
                            <div class="chk" fxLayout="row" fxLayoutAlign="start" *ngFor="let test of course.tests">
                                <mat-checkbox name="{{test.test_id}}" [checked]="test.isAvailable"
                                    (change)="valueChange(course.location_code,test.test_id)">
                                    {{test.test_name}}
                                </mat-checkbox>
                            </div> -->
                            <div class="chk" fxLayout="row" fxLayoutAlign="start">
                                <mat-checkbox name="1" [checked]="course.location_paymentOption==1"
                                    (change)="valueChange(course.location_code,1)">
                                   Pay at Center
                                </mat-checkbox>
                            </div>
                            <div class="chk" fxLayout="row" fxLayoutAlign="start">
                                <mat-checkbox name="2" [checked]="course.location_paymentOption==2"
                                    (change)="valueChange(course.location_code,2)">
                                   Free Testing
                                </mat-checkbox>
                            </div>
                            <div class="chk" fxLayout="row" fxLayoutAlign="start">
                                <mat-checkbox name="3" [checked]="course.location_paymentOption==3"
                                    (change)="valueChange(course.location_code,3)">
                                   Insurance Mandatory
                                </mat-checkbox>
                            </div>
                            <div class="location_img">
                                <img src="{{course.locationImage}}"
                                    [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                           
                            <div class="file-uploader">
                                <input hidden type="file" formControlName="document" #fileInput accept="image/*"
                                    (change)="onFileChanged($event,course.location_code)" />
                                <button mat-raised-button class="add-file-button" (click)="fileInput.click()"
                                    aria-label="UPLOAD FILE"
                                    [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
                                    <mat-icon>cloud_upload</mat-icon>
                                </button>
                                

                            </div>
                        </div>
 </div>
                        <div class="footer" fxLayout="row" fxLayoutAlign="center center">
                            <button mat-button matTooltip="Edit" matTooltipClass="tooltip-blue"
                                (click)="edit_location(course.location_code)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-button matTooltip="Delete" matTooltipClass="tooltip-blue"
                                *ngIf="course.location_status=='Open'" (click)="del_location(course.location_code,0)">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button mat-button matTooltip="Active" matTooltipClass="tooltip-blue"
                                *ngIf="course.location_status=='Closed'" (click)="del_location(course.location_code,1)">
                                <mat-icon>check_circle</mat-icon>
                            </button>
                            <button mat-button matTooltip="Assign User" matTooltipClass="tooltip-blue"
                                (click)="assignuser(course.location_Id)">
                                <mat-icon>assignment_ind</mat-icon>
                            </button>
                             <button mat-button matTooltip="Insurance List" matTooltipClass="tooltip-blue"
                                (click)="insuranceList(course.location_Id)">
                                <mat-icon>file_copy</mat-icon>
                            </button>
                            <!-- <button mat-button matTooltip="Referral" matTooltipClass="tooltip-blue"
                                (click)="Referral(course.location_code)">
                                <mat-icon>device_hub</mat-icon>
                            </button> -->
                            <button mat-button matTooltip="Tests" matTooltipClass="tooltip-blue"
                            (click)="Testlist(course.location_Id,course.tests)">
                            <mat-icon>local_pharmacy</mat-icon>
                        </button>
                        </div>

                    </div>

                </div>
                <div style="width: 100%;padding: 10px 0; text-align: center;">
                    <button mat-flat-button color="primary" (click)="showMore()" *ngIf="showsLoadMore">
                        <mat-icon>refresh</mat-icon> Show More
                    </button>
                </div>
                <div class="no-courses" *ngIf="filteredCourses.length == 0">
                    No centers found!
                </div>

            </div>

        </div>

    </div>
    <!-- / CONTENT -->

</div>
<ng-template #insuranceListpopup>
    <div class="content"> 
        <div style="height: 400px;overflow:auto;">
            <table width="100%" class="simple invoice-table">
                <thead>
                    <tr class="accent1 mat-header-row bdr-radius">
                        <th>S No.</th>
                        <th>Insurance Code</th>
                        <th>Insurance Name</th> 
                </thead>
                <tbody>
                    <tr class="mat-header-row" *ngFor="let row of locationWiseInsurances; let i=index">
                        <td>{{i+1}}</td>
                        <td> {{row.insurance_code}}</td>
                        <td>{{row.insurance_Name}}</td> 
                      
                    </tr>

                </tbody>
            </table>
        </div>
        <div class="mr-t-30">

            <button mat-raised-button class="warn mr-4 " (click)="closewindow()" mat-dialog-close>
                <mat-icon>close</mat-icon>Cancel
            </button> 
        </div>
    </div>
</ng-template>
<ng-template #add_Location>
    <form fxLayout="column" fxFlex="1 0 auto" name="addlocation" [formGroup]="addlocation">
        <h1 mat-dialog-title>Location Details</h1>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" fxFlex="50" class="mr-4">
                <mat-label>Location Name</mat-label>
                <input matInput formControlName="locationName" [(ngModel)]="empObject.location_accountName" required>
                <mat-error>
                    Location Name is required
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="50">
                <mat-label>Location Address</mat-label>
                <input matInput formControlName="locationAddress" [(ngModel)]="empObject.location_propertyName"
                    required>
                <mat-error>Location Address is required!</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" fxFlex="50" class="mr-4">
                <mat-label>Country</mat-label>
                <mat-select formControlName="country" (selectionChange)="changeCountry($event,2)"
                    [(ngModel)]="empObject.countryId" required>
                    <mat-option *ngFor="let country of _countries" [value]="country.country_id">
                        {{country.country_name}}
                    </mat-option>
                </mat-select>
                <mat-error>Country is required!</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="33" class="px-4">
                <mat-label>State</mat-label>
                <input matInput formControlName="state" [(ngModel)]="empObject.location_state" required>
                <mat-error>state is required!</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="34" class="px-4">
                <mat-label>City</mat-label>
                <input matInput formControlName="city" [(ngModel)]="empObject.location_city" required>
                <mat-error>City is required!</mat-error>
            </mat-form-field>
        

        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" fxFlex="33">
                <mat-label>Postal Code</mat-label>
                <input matInput #postalCode value="94043" [(ngModel)]="empObject.zipCode" formControlName="postalCode"
                    maxlength="6" required>
                <mat-hint align="end">{{postalCode.value.length}} / 6</mat-hint>
                <mat-error>Postal Code is required!</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="34" class="px-4">
                <mat-label>Latitude</mat-label>
                <input matInput [(ngModel)]="empObject.location_latitude" formControlName="latitude" required>
                <mat-error>Latitude is required!</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="33">
                <mat-label>Longitude</mat-label>
                <input matInput [(ngModel)]="empObject.location_longitude" formControlName="longitude" required>
                <mat-error>Longitude is required!</mat-error>
            </mat-form-field>



        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" fxFlex="33" class="mr-4">
                <mat-label>Site Owner</mat-label>
                <input matInput formControlName="siteOwner" [(ngModel)]="empObject.location_siteOwner" required>
                <mat-error>
                    Site Owner is required
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="33" class="mr-4">
                <mat-label>Manager Name</mat-label>
                <input matInput formControlName="managerName" [(ngModel)]="empObject.location_managerName" required>
                <mat-error>
                    Manager Name is required
                </mat-error>
            </mat-form-field> 
            <mat-form-field appearance="fill" fxFlex="33">
                <mat-label>Customer Service Number</mat-label>
                <input matInput formControlName="managernumber" maxlength="10"
                    [(ngModel)]="empObject.location_managernumber" required>
                <mat-error>Customer Service Number is required!</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" fxFlex="50" class="mr-4">
                <mat-label>Laboratory Director</mat-label>
                <input matInput formControlName="labDirector" [(ngModel)]="empObject.location_labDirector" required>
                <mat-error>
                    Laboratory Director is required
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="33">
                <mat-label>Property Type</mat-label>
                <mat-select formControlName="prtype" [(ngModel)]="empObject.propertytype" required>
                    <mat-option value="'Mainlocation'">
                        Main Location
                    </mat-option>
                    <mat-option value="'MobileUnit'">
                        Mobile Unit
                    </mat-option>
                    <mat-option value="'StoreFront'">
                        Store Front
                    </mat-option>
                    <mat-option value="'ParkingLotUnit'">
                        Parking Lot Unit
                    </mat-option>
                </mat-select>
                <mat-error>Property is required!</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
          
            <mat-form-field appearance="fill" fxFlex="33">
                <mat-label>NPI</mat-label>
                <input matInput formControlName="npi" [(ngModel)]="empObject.npi" required>
                <mat-error>NPI is required!</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="33">
                <mat-label>CLIA ID Number</mat-label>
                <input matInput formControlName="cliaIdnumber" [(ngModel)]="empObject.location_cliaIdnumber" required>
                <mat-error>CLIA ID Number is required!</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="33">
                <mat-label>Device/Method</mat-label>
                <input matInput formControlName="deviceMethod" [(ngModel)]="empObject.location_device" required>
                <mat-error>Device/Method!</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" fxFlex="100" class="mr-4">
                <mat-label>PDF Address</mat-label>
                <input matInput formControlName="pdfAddress" [(ngModel)]="empObject.location_pdfAddress" required>
                <mat-error>
                    Address is required
                </mat-error>
            </mat-form-field>

        </div>


        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <button mat-raised-button class="warn mr-4 " (click)="closewindow()" mat-dialog-close>
                <mat-icon>close</mat-icon>Cancel
            </button>
            <button mat-raised-button [disabled]="addlocation.invalid" (click)="locationSubmit()" class="primary mr-4"
               >
                <mat-icon>save</mat-icon>{{btnText}}
            </button>
        </div>
    </form>

</ng-template>
<ng-template #assignTestList>
    <div class="content"> 
        <div style="height: 400px;overflow:auto;">
            <table width="100%" class="simple invoice-table">
                <thead>
                    <tr class="accent1 mat-header-row bdr-radius">
                        <th>Assign</th> 
                        <th>Test Name</th>
                        <th>Description</th>
                        <th>Amount($)</th>  
                        <th>Payment Link</th> 
                </thead>
                <tbody>
                    <tr class="mat-header-row" *ngFor="let row of locationWiseTests; let i=index">
                        <td>
                            <mat-checkbox [name]="row.test_id" [ngModelOptions]="{standalone: true}"
                                (change)="testvalueChange(i,$event)" [id]="row.test_id"
                                [(ngModel)]="row.isAvailable"> 
                            </mat-checkbox>
                        </td> 
                        <td>{{row.test_name}}</td>
                        <td><input type="text" id="{{row.test_id}}" [(ngModel)]="row.desc"  value="{{row.desc}}" /> </td>
                       
                        <td><input type="number" id="{{row.test_id}}" [(ngModel)]="row.amount"  value="{{row.amount}}" /> </td>
                       
                        <td><input type="text"  id="{{row.test_id}}" [(ngModel)]="row.paymentLink"  value="{{row.paymentLink}}" /> </td>
                    </tr>

                </tbody>
            </table>
        </div>
        <div class="mr-t-30">

            <button mat-raised-button class="warn mr-4 " (click)="closewindow()" mat-dialog-close>
                <mat-icon>close</mat-icon>Cancel
            </button>
            <button mat-raised-button class="primary mr-4" (click)="SaveTestmapping()">
                <mat-icon>save</mat-icon>Update
            </button>

        </div>
    </div>
</ng-template>
<ng-template #assignuserpop>
    <div class="content">
        <mat-form-field appearance="fill" floatLabel="always" class="course-search">
            <mat-label>Search for a user</mat-label>
            <input matInput placeholder="Enter a keyword..." [(ngModel)]="usersearchTerm" (input)="searchlist()">
        </mat-form-field>

        <div style="height: 400px;overflow:auto;">
            <table width="100%" class="simple invoice-table">
                <thead>
                    <tr class="accent1 mat-header-row bdr-radius">
                        <th>S No.</th>
                        <th>User Code</th>
                        <th>Name</th>
                        <th>Email Address</th>
                        <th>Assign</th>
                </thead>
                <tbody>
                    <tr class="mat-header-row" *ngFor="let row of locationWiseUsers; let i=index">
                        <td>{{i+1}}</td>
                        <td> {{row.users_AccessToken}}</td>
                        <td>{{row.users_Fullname}}</td>
                        <td>{{row.users_Username}}</td>
                        <td>
                            <mat-checkbox [name]="row.users_userid" [ngModelOptions]="{standalone: true}"
                                (change)="uservalueChange(i,$event)" [id]="row.users_userid"
                                [(ngModel)]="row.isChecked">

                            </mat-checkbox>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
        <div class="mr-t-30">

            <button mat-raised-button class="warn mr-4 " (click)="closewindow()" mat-dialog-close>
                <mat-icon>close</mat-icon>Cancel
            </button>
            <button mat-raised-button class="primary mr-4" (click)="SaveUsermapping()">
                <mat-icon>save</mat-icon>Update
            </button>

        </div>
    </div>
</ng-template>
<ng-template #referralpop>
    <form fxLayout="column" fxFlex="1 0 auto" name="addreferral" [formGroup]="addreferral">
        <h1 mat-dialog-title>Add Referral</h1>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" fxFlex="50" class="mr-4">
                <mat-label>Referral Name</mat-label>
                <input matInput formControlName="referralName" [(ngModel)]="empObject.referralName" required>
                <mat-error>
                    Referral Name is required
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="50">
                <mat-label>Percentage</mat-label>
                <input matInput formControlName="percentage" type="number" [(ngModel)]="empObject.referralCommission" required>
                <mat-error>Percenrage</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" fxFlex="100">
                <mat-label>Remarks</mat-label>
                <textarea matInput cols="80" rows="4" placeholder="Remarks" [(ngModel)]="empObject.referralRemarks"
                    formControlName="remarks"></textarea>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <button mat-raised-button class="warn mr-4 " (click)="closewindow()" mat-dialog-close>
                <mat-icon>close</mat-icon>Cancel
            </button>
            <button mat-raised-button [disabled]="addreferral.invalid" (click)="referralSubmit()" class="primary mr-4"
                mat-dialog-close>
                <mat-icon>save</mat-icon>Update
            </button>
        </div>
    </form>
</ng-template>
<ng-template #alertpop>


    <form fxLayout="column" fxFlex="1 0 auto" name="specimenDetails" [formGroup]="specimenDetails">
        <h1 mat-dialog-title>Confirmation</h1>
        <div fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">
            <h4>Are you sure you want to{{this.deleteFlg==1 ? "'Active'" : "'Suspend'"}} the Location?</h4>

        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <button mat-raised-button class="warn mr-4 " (click)="closewindow()" mat-dialog-close>
                <mat-icon>close</mat-icon>Cancel
            </button>
            <button mat-raised-button (click)="confirm_del(0)" class="primary mr-4">
                <mat-icon>{{this.deleteFlg==1 ? "save" : "delete"}}</mat-icon>Save
            </button>
        </div>
    </form>

</ng-template>