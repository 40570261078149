//import { environment } from '../../../environments/environment';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private APiURL = environment.BaseApiUrl + "Common";
  constructor(
    private http: HttpClient
  ) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  CheckLogin(formData: any) { 
    return this.http.post(this.APiURL + '/ValidateUser', formData);
  }
  ForgotPassword(formData: any) { 
    return this.http.post(this.APiURL + '/ForgotPassword', formData);
  }

  GetUserDataByID( UserId) {     
    return this.http.post(this.APiURL + '/GetUserDataById', UserId );
  }

  SaveUserMaster (UserData) {
    return this.http.post(this.APiURL + '/SaveUserMaster', UserData );
  }



}
