<div id="dashboard-analytics" class="page-layout blank">


    <div class="center">
        <div class="header accent p-24" fxLayout="row" fxLayoutAlign="start start">

            <div class="breadcrumb text-truncate h1" fxLayout="row" fxLayoutAlign="start center"
                [@animate]="{value:'*',params:{x:'50px'}}">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <span>Request Dashboard</span>
                </div>
            </div>
            <!-- <div class="clsCaption"> Displaying records between {{startDate}} to {{endDate}}</div> -->
        </div>
    </div>
    <div class="content p-24">
        <div fxLayout="column" fxLayoutAlign="center">
            <fieldset>
                <legend>
                    <h3>Filters</h3>
                </legend>
                <div class="filters" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
                    fxLayoutAlign.gt-xs="start center">
                    <mat-form-field appearance="fill" fxFlex="30" class="date-picker mr-4">
                        <mat-label>Enter a date range</mat-label>
                        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                            <input matStartDate formControlName="start" placeholder="Start date">
                            <input matEndDate formControlName="end" placeholder="End date">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
        
                        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date
                        </mat-error>
                        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex="30" class="category-selector">
        
                        <mat-label>Centers</mat-label>
                        <input type="text" placeholder="Pick one" aria-label="Number" matInput [formControl]="myControl"
                            [matAutocomplete]="auto">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                            (optionSelected)="onSelectionChange($event)" [displayWith]="displayFn">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                {{option.location_accountName + " - " + option.location_propertyName}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex="30" class="result-course-search">
                        <mat-label>Status</mat-label>
                        <mat-select [(ngModel)]="result" (selectionChange)="LoadData()">
                            <mat-option value="0">
                                Select Result
                            </mat-option>
                            <mat-option value="Registered">
                                Registered
                            </mat-option>
                            <mat-option value="Pending">
                                Pending
                            </mat-option>
                            <mat-option value="Detected">
                                Detected
                            </mat-option>
                            <mat-option value="Not Detected">
                                Not Detected
                            </mat-option>
                            <mat-option value="Inconclusive">
                                Inconclusive
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button mat-raised-button class="primary button-export" (click)="exportExcel()">
                        <mat-icon style="font-size: 20px;">cloud_download</mat-icon> Export
                    </button>
                    
            
                </div>
            </fieldset>
            
            </div>
        
    </div>
    <mat-tab-group dynamicHeight>

        <mat-tab label="General">
            <div class="content">

                <div class="widget-group" fxLayout="row wrap" fxFlex="100" *fuseIfOnDom
                    [@animateStagger]="{value:'100'}">

                    <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
                        fxFlex="100" fxFlex.gt-sm="100">
                        <div class="fuse-widget-front">
                            <div class="h3 px-16 py-24 border-bottom ">Center wise requests
                                <div class="divcaptions">
                                    <ul>
                                        <li><b>P :</b> Pending</li>
                                        <li><b>D :</b> Detected</li>
                                        <li><b>N :</b> Not Detected</li>
                                        <li><b>I :</b> Inconclusive</li>
                                        <li><b>R :</b> Registered</li>
                                    </ul>
                                </div>

                            </div>
                            <div [innerHtml]="dashboard.clsLocationWise" id="_divL" style="height:500px;overflow:auto;">
                            </div>
                        </div>
                    </fuse-widget>

                </div>
            </div>
            <div class="content">
                <div class="widget-group" fxLayout="row wrap" fxFlex="100" *fuseIfOnDom
                    [@animateStagger]="{value:'50'}">
                    <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
                        fxFlex="100" fxFlex.gt-sm="50">
                        <div class="fuse-widget-front">
                            <div class="h3 px-16 py-24 border-bottom ">Insurance wise requests</div>
                            <table width="100%" class="simple invoice-table h-400 tab-h">
                                <thead>
                                    <tr class="mat-header-row">
                                        <th>Insurance</th>
                                        <th>Count</th>
                                    </tr>
                                </thead>
                                <tbody class="mat-header-row">
                                    <tr *ngFor="let row of dashboard.clsInsuranceWise">
                                        <td>{{row.name}}</td>
                                        <td>{{row.value}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </fuse-widget>
                    <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
                    fxFlex="100" fxFlex.gt-sm="50">
                    <div class="fuse-widget-front">
                        <div class="h3 px-16 py-24 border-bottom ">Payment Option requests</div>
                        <table width="100%" class="simple invoice-table h-400 tab-h">
                            <thead>
                                <tr class="mat-header-row">
                                    <th>Payment Type</th>
                                    <th>Count</th>
                                </tr>
                            </thead>
                            <tbody class="mat-header-row">
                                <tr *ngFor="let row of dashboard.clsPaymentOption">
                                    <td>{{row.name}}</td>
                                    <td>{{row.value}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </fuse-widget>
                </div>

            </div>
            <div class="content">
                <div class="widget-group" fxLayout="row wrap" fxFlex="100" *fuseIfOnDom
                    [@animateStagger]="{value:'50'}">
                    <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
                        fxFlex="100" fxFlex.gt-sm="33">
                        <div class="fuse-widget-front">
                            <div class="h3 px-16 py-24 border-bottom ">Test wise requests</div>
                            <table class="simple invoice-table h-400 tab-h">
                                <thead>
                                    <tr class="mat-header-row">
                                        <th>Test</th>
                                        <th>Count</th>
                                    </tr>
                                </thead>
                                <tbody class="mat-header-row">
                                    <tr *ngFor="let row of dashboard.clsStatusWise">
                                        <td>{{row.name}}</td>
                                        <td>{{row.value}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </fuse-widget>
                    <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
                        fxFlex="100" fxFlex.gt-sm="33">
                        <div class="fuse-widget-front">
                            <div class="h3 px-16 py-24 border-bottom ">Status wise requests</div>
                            <table class="simple invoice-table h-400 tab-h">
                                <thead>
                                    <tr class="mat-header-row">
                                        <th>Status</th>
                                        <th>Count</th>
                                    </tr>
                                </thead>
                                <tbody class="mat-header-row">
                                    <tr *ngFor="let row of dashboard.clsTestWise">
                                        <td>{{row.name}}</td>
                                        <td>{{row.value}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </fuse-widget>
                    <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
                        fxFlex="100" fxFlex.gt-sm="33">
                        <div class="fuse-widget-front">
                            <div class="h3 px-16 py-24 border-bottom ">Gender wise requests</div>
                            <table class="simple invoice-table h-400 tab-h">
                                <thead>
                                    <tr class="mat-header-row">
                                        <th>Gender</th>
                                        <th>Count</th>
                                    </tr>
                                </thead>
                                <tbody class="mat-header-row">
                                    <tr *ngFor="let row of dashboard.clsGenderWise">
                                        <td>{{row.name}}</td>
                                        <td>{{row.value}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </fuse-widget>

                </div>
            </div>
            <div class="content">
                <div class="widget-group" fxLayout="row wrap" fxFlex="100" *fuseIfOnDom
                    [@animateStagger]="{value:'50'}">

                    <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
                        fxFlex="100" fxFlex.gt-sm="33">
                        <div class="fuse-widget-front">
                            <div class="h3 px-16 py-24 border-bottom ">City wise requests</div>
                            <table width="100%" class="simple invoice-table h-400 tab-h">
                                <thead>
                                    <tr class="mat-header-row">
                                        <th>City</th>
                                        <th>Count</th>
                                    </tr>
                                </thead>
                                <tbody class="mat-header-row">
                                    <tr *ngFor="let row of dashboard.clsCityWise">
                                        <td>{{row.name}}</td>
                                        <td>{{row.value}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </fuse-widget>
                    <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
                        fxFlex="100" fxFlex.gt-sm="33">
                        <div class="fuse-widget-front">
                            <div class="h3 px-16 py-24 border-bottom ">Ethnicity wise requests</div>
                            <table class="simple invoice-table h-400 tab-h">
                                <thead>
                                    <tr class="mat-header-row">
                                        <th>Type</th>
                                        <th>Count</th>
                                    </tr>
                                </thead>
                                <tbody class="mat-header-row">
                                    <tr *ngFor="let row of dashboard.clsEthinicty">
                                        <td>{{row.name}}</td>
                                        <td>{{row.value}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </fuse-widget>
                    <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
                        fxFlex="100" fxFlex.gt-sm="33">
                        <div class="fuse-widget-front">
                            <div class="h3 px-16 py-24 border-bottom ">Race wise requests</div>
                            <table class="simple invoice-table h-400 tab-h">
                                <thead>
                                    <tr class="mat-header-row">
                                        <th>Type</th>
                                        <th>Count</th>
                                    </tr>
                                </thead>
                                <tbody class="mat-header-row">
                                    <tr *ngFor="let row of dashboard.clsRaceWise">
                                        <td>{{row.name}}</td>
                                        <td>{{row.value}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </fuse-widget>
                </div>

            </div>
         
        </mat-tab>
        <mat-tab label="Charts">
            <div class="content">
                <div class="widget-group" fxLayout="row wrap" fxFlex="100" *fuseIfOnDom
                    [@animateStagger]="{value:'50'}">

                    <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
                        fxFlex="100" fxFlex.gt-sm="33">
                        <div class="fuse-widget-front">
                            <div class="h3 px-16 py-24 border-bottom ">City wise requests</div>
                            <div class="h-400">
                                <ngx-charts-pie-chart *fuseIfOnDom [scheme]="scheme"
                                    [results]="this.dashboard.clsCityWise" [legend]="false" [explodeSlices]="false"
                                    [labels]="true" [doughnut]="true" [gradient]="false" (select)="onSelect($event)">
                                </ngx-charts-pie-chart>
                            </div>
                        </div>
                    </fuse-widget>
                    <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
                        fxFlex="100" fxFlex.gt-sm="33">
                        <div class="fuse-widget-front">
                            <div class="h3 px-16 py-24 border-bottom ">Ethnicity wise requests</div>
                            <div class="h-400">
                                <ngx-charts-pie-chart *fuseIfOnDom [scheme]="colorScheme"
                                    [results]="this.dashboard.clsEthinicty" [legend]="false" [explodeSlices]="false"
                                    [labels]="true" [doughnut]="true" [gradient]="true" (select)="onSelect($event)">
                                </ngx-charts-pie-chart>
                            </div>
                        </div>
                    </fuse-widget>
                    <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
                        fxFlex="100" fxFlex.gt-sm="33">
                        <div class="fuse-widget-front">
                            <div class="h3 px-16 py-24 border-bottom ">Race wise requests</div>
                            <div class="h-400">
                                <ngx-charts-pie-chart *fuseIfOnDom [scheme]="colorScheme"
                                    [results]="this.dashboard.clsRaceWise" [legend]="false" [explodeSlices]="false"
                                    [labels]="true" [doughnut]="true" [gradient]="true" (select)="onSelect($event)">
                                </ngx-charts-pie-chart>
                            </div>
                        </div>
                    </fuse-widget>
                </div> 
            </div>

            <div class="content">
                <div class="widget-group" fxLayout="row wrap" fxFlex="100" *fuseIfOnDom
                    [@animateStagger]="{value:'50'}">
                    <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
                        fxFlex="100" fxFlex.gt-sm="33">
                        <div class="fuse-widget-front">
                            <div class="h3 px-16 py-24 border-bottom">Test wise requests</div>

                            <div class="h-400">
                                <ngx-charts-pie-chart *fuseIfOnDom [scheme]="scheme"
                                    [results]="this.dashboard.clsTestWise" [legend]="false" [explodeSlices]="false"
                                    [labels]="true" [doughnut]="true" [gradient]="false" (select)="onSelect($event)">
                                </ngx-charts-pie-chart>
                            </div>
                        </div>



                    </fuse-widget>

                    <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
                        fxFlex="100" fxFlex.gt-sm="33">
                        <div class="fuse-widget-front">
                            <div class="h3 px-16 py-24 border-bottom">Gender wise requests</div>

                            <div class="h-400">
                                <ngx-charts-pie-chart *fuseIfOnDom [scheme]="colorScheme"
                                    [results]="this.dashboard.clsGenderWise" [legend]="false" [explodeSlices]="false"
                                    [labels]="true" [doughnut]="true" [gradient]="false" (select)="onSelect($event)">
                                </ngx-charts-pie-chart>
                            </div>
                        </div>



                    </fuse-widget>
                    <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
                        fxFlex="100" fxFlex.gt-sm="33">
                        <div class="fuse-widget-front">
                            <div class="h3 px-16 py-24 border-bottom">Status wise requests</div>

                            <div class="h-400">
                                <ngx-charts-pie-chart *fuseIfOnDom [scheme]="scheme"
                                    [results]="this.dashboard.clsStatusWise" [legend]="false" [explodeSlices]="false"
                                    [labels]="true" [doughnut]="true" [gradient]="false" (select)="onSelect($event)">
                                </ngx-charts-pie-chart>
                            </div>
                        </div>



                    </fuse-widget>
                </div>
            </div>

        </mat-tab>
    </mat-tab-group>

</div>