import { Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation, NgZone, ViewChild, AfterViewInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { RequestService } from '../request/request.service';
import { SnackbarService } from 'app/common/Service/snackbar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { MatDialog } from '@angular/material/dialog';
import * as $ from 'jquery';
import { takeUntil, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { CircleTimerComponent } from '@flxng/circle-timer';
import { environment } from '../../../../../../environments/environment';
import {
    HttpClient,
    HttpEventType,
    HttpErrorResponse
} from "@angular/common/http";
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
@Component({
    moduleId: module.id,
    selector: 'auth/self-test',
    templateUrl: './self-test.component.html',
    styleUrls: ['./self-test.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class SelfTestComponent implements OnInit, OnDestroy {

    @ViewChild('timer', { static: false }) timer: CircleTimerComponent;
    @ViewChild('fileInput') fileInput: ElementRef;
    startDate = Date.now() - (15 * 1000); // current time minus 15 seconds
    duration = 15 * 1000; // 15 minute
    duration1 = 600 * 1000; // 10 minute
    startDate1 = Date.now() - (600 * 1000); // current time minus 10 mins

    duration2 = 300 * 1000; // 5 minute
    startDate2 = Date.now() - (300 * 1000); // current time minus 5 mins
    IsResultGenerated: number = 0;
    viewHide: number = 0;
    frmValidate: FormGroup;
    frmUpload: FormGroup;
    empObject: any = {};
    referenceNo: string = "";
    selfTestPref: any = [];
    display: string = "";
    progress: number = 0;
    selectedFileName: string = "";
    Intimer: any;
    dateFormat: string;
    // Private
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
    myControl = new FormControl();
    filteredOptions: Observable<string[]>;
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _Registerservice: RequestService,
        private datePipe: DatePipe,
        private _snackbarservice: SnackbarService,
        private _splashScreenService: FuseSplashScreenService,
        private router: Router,
        private ngZone: NgZone,
        private dialog: MatDialog, private http: HttpClient,
        private activatedRoute: ActivatedRoute,

    ) {


        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.empObject.IsStep3Completed = 0;
        this.empObject.IsStep6Completed = 0;
        this.empObject.IsStep11Completed = 0;
        this._Registerservice.getUserIPDetails().subscribe(result => {
            this.empObject.IpAddress = JSON.stringify(result);
        });
    }

    ngOnInit(): void {
        this.frmUpload = this._formBuilder.group({
            Cnumber: ['null', [Validators.required]],
            VEmail: ['', Validators.required],
            fullname: ['', Validators.required], document: ['', Validators.required],
            DateOfBirth: ['', Validators.required]
        });
        this.getMasterData(34, 0, '');
        this.getMasterData(60, 0, '');
    }
    getMasterData(flg, filterId, code) {
        var clsmodel = {
            Type: flg,
            FilterId: filterId,
            Code: code
        }
        this._Registerservice.GetMasterData(clsmodel).subscribe(result => {

            var resultObj = result;
            if (resultObj["status"] == 200) {
                if (flg == 34) {
                    this.dateFormat = resultObj["resultObj"];
                }
                else if (flg == 54) {
                    clearInterval(this.Intimer);
                    window.location.href = resultObj["resultObj"];

                }
                else if (flg == 60) {
                    let _prefObject = resultObj["resultObj"];
                    if (_prefObject != null && _prefObject.length > 0) {
                        var _list = _prefObject.filter((course) => {
                            return course.selftestpref_priority == 1;
                        });
                        if (_list != null && _list.length > 0) {
                            this.selfTestPref = _list;
                        }
                    }
                    // let prefDuration = this.selfTestPref == null || this.selfTestPref.length == 0 ? 10 :
                    //     this.selfTestPref[0].selftestpref_duration;
                }
            }


        }, error => {
            console.log(error);
        });
        return "";
    }
    employerSubmit() {
        if (this.frmUpload.invalid)
            return;
        if (this.empObject == null || this.empObject == undefined) {
            return;
        }
        if (this.empObject.UploadedimagePath == null || this.empObject.UploadedimagePath == undefined
            || this.empObject.UploadedimagePath == "") {
            this._snackbarservice.showSnackbar("Please upload attachment to proceed");
            return;
        }
        if (this.empObject.DOB !== undefined && this.empObject.DOB !== null) {
            this.empObject.DOB = this.datePipe.transform(this.empObject.DOB, this.dateFormat);
        }
        this.empObject.MobileNo = this.empObject.PhoneInput.e164Number.replace(this.empObject.PhoneInput.dialCode, '');
        this.empObject.dailCode = this.empObject.PhoneInput.dialCode;
        this.empObject.imagePath = this.empObject.UploadedimagePath;
        this.empObject.profileImage = '';
        this._splashScreenService.show();

        this._Registerservice.SelfTestRequest(this.empObject).subscribe(result => {
            var resultObj = result;

            if (resultObj["status"] == 200) {
                this.referenceNo = resultObj["resultObj"];
                this.showNextView(13, 0);
                //this.timer.start(this.startDate2);
                // this._snackbarservice.showSnackbar("Thank you for registering , please note confirmation code:" + resultObj["resultObj"]);
                // this.router.navigate(['/auth/thankyou', resultObj["resultObj"]]);
            }
            else {
                this._snackbarservice.showSnackbar(resultObj["message"]);
                this.selectedFileName = "";
            }
            this._splashScreenService.hide();
        }, error => {
            console.log(error);
            this._splashScreenService.hide();
        });

    }
    UploadImage(file: any) {
        this.progress = 1;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("Location", this.empObject.SelectedLocation);
        let APiURL = environment.BaseApiUrl + "Common";
        this.http
            .post(APiURL + '/UploadSelfTestImage', formData, {
                reportProgress: true,
                observe: "events"
            })
            .pipe(
                map((event: any) => {
                    if (event.type == HttpEventType.UploadProgress) {
                        this.progress = Math.round((100 / event.total) * event.loaded);
                    } else if (event.type == HttpEventType.Response) {
                        if (event.body.status == 200) {
                            this.empObject.UploadedimagePath = event.body.resultObj;
                        }
                        this.progress = null;
                    }
                }),
                catchError((err: any) => {
                    this.progress = null;
                    alert(err.message);
                    return throwError(err.message);
                })
            )
            .toPromise();
    }
    onFileChanged(event) {
        const file = event.target.files[0];
        if (!file.type.includes("image")) {
            //this.snackBar.showSnackbar("Please select image file only");
            this.fileInput.nativeElement.value = "";
        }
        else {
            var maxfilesize = (1024 * 1024) * 12,  // 12 Mb
                filesize = file.size;
            if (filesize > maxfilesize) {
                this._snackbarservice.showSnackbar("File too large: " + filesize + ". Maximum size: upto 12MB");
                return;
            }
            this.UploadImage(file);
            this.empObject.profileImage = file;
            this.selectedFileName = this.empObject.profileImage.name
            var reader = new FileReader();

            reader.onload = (event: any) => {

                this.empObject.imagePath = event.target.result;
            }



            reader.readAsDataURL(event.target.files[0]);
        }

    }

    ngAfterViewInit(): void {

    }
    onTimerComplete(): void {
        this.empObject.IsStep3Completed = 1;
        console.log('timer completed!');
    }
    onTimer1Complete(): void {
        this.empObject.IsStep6Completed = 1;
        console.log('timer completed!');
    }
    onTimer2Complete(): void {
        this.empObject.IsStep11Completed = 1;
        console.log('timer completed!');
    }
    onTimer3Complete(): void {
        console.log('timer completed!');
    }
    showNextView(flg: number, IsBack: number) {

        if (IsBack == 1) {
            this.viewHide = flg;
            return;
        }
        if (flg == 0) {
            this.viewHide = flg;
        }
        else if (flg == 1) {
            this.viewHide = flg;
        }
        else if (flg == 2) {
            this.viewHide = flg;
        }
        else if (flg == 3) {
            this.viewHide = flg;
        }
        else if (flg == 4) {
            this.viewHide = flg;
        }
        else if (flg == 5) {
            this.empObject.IsStep3Completed = 1;
            this.viewHide = flg;
        }
        else if (flg == 6) {
            this.viewHide = flg;
        }
        else if (flg == 7) {
            this.empObject.IsStep6Completed = 1;
            this.viewHide = flg;
        }
        else if (flg == 8) {

            this.viewHide = flg;
        }
        else if (flg == 9) {
            this.viewHide = flg;
        }
        else if (flg == 10) {
            this.viewHide = flg;
        }
        else if (flg == 11) {
            this.viewHide = flg;
        }
        else if (flg == 12) {
            this.viewHide = flg;
        }
        else if (flg == 13) {
            let prefDuration = this.selfTestPref == null || this.selfTestPref.length == 0 ? 10 :
                this.selfTestPref[0].selftestpref_duration;
            let seconds: number = prefDuration * 60;
            let textSec: any = "0";
            let statSec: number = 60;
            let chkStatusinSeconds = 0;
            const prefix = prefDuration <= 10 ? "0" : "";
            this.Intimer = setInterval(() => {
                seconds--;
                if (statSec != 0) statSec--;
                else statSec = 59;

                if (statSec < 10) {
                    textSec = "0" + statSec;
                } else textSec = statSec;

                if (chkStatusinSeconds == 10) {
                    this.checkResultStatus();
                    chkStatusinSeconds = 0;
                }
                else
                    chkStatusinSeconds++;

                if (seconds == 0) {
                    console.log("finished");
                    clearInterval(this.Intimer);
                }

                this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`

            }, 1000);
            this.viewHide = flg;
        }
    }
    checkResultStatus() {
        this.getMasterData(54, 0, this.referenceNo);
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */

