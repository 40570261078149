import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'app/common/Service/common.service';
import { SnackbarService } from 'app/common/Service/snackbar.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute } from '@angular/router';
import { de } from 'date-fns/locale';

@Component({
    selector: 'profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ProfileComponent {
    /**
     * Constructor
     */
    _loginDetails: any;
    employeeid: any;
    public UploadFile: File;
    imagePath: string = "assets/images/avatars/Velazquez.jpg";
    @ViewChild('fileInput') fileInput: ElementRef;
    constructor(
        private service: CommonService,
        private acctivelink: ActivatedRoute,
        private _snackbarservice: SnackbarService,) {


    }
    id: number = 0;
    showSecuTab: boolean = false;
    ngOnInit(): void {

        this.acctivelink.params.subscribe(p => {
            this.id = p["id"];
            console.log("id", this.id);
        })


        if (this.id == undefined || this.id == null || this.id == 0) {
            this.showSecuTab = true;
        }
        else {
            this.showSecuTab = false;
        } 
        if (localStorage != null && localStorage != undefined && localStorage["UserDetails"]) {
            this._loginDetails = JSON.parse(localStorage.getItem('UserDetails') || '{}');
            this.imagePath = this._loginDetails.users_Profilepic
        }
    }
    SubmitForm() {
        const formData = new FormData();
        formData.append('ClientCode', this.employeeid);
        if (this.UploadFile !== undefined)
            formData.append('UploadFile', this.UploadFile, this.UploadFile.name);
        this.service.UploadEmployeerLogo(formData).subscribe(result => {

            var resultObj = result;

            if (resultObj["status"] == 200) {
                var filter = this._loginDetails.filter((_loginDetails) => {
                    return _loginDetails.users_AccessToken == this.employeeid;
                });
                if (filter != undefined && filter.length > 0) {
                    filter[0].locationImage = resultObj["resultObj"]
                }
                this._snackbarservice.showSnackbar(resultObj["message"]);
            }
            else
                this._snackbarservice.showSnackbar(resultObj["message"]);

        }, error => {
            console.log(error);

        });

    }
    onFileChanged(event, accessCode) {
        
        this.employeeid = accessCode;
        const file = event.target.files[0];
        if (!file.type.includes("image")) {
            alert("Please select image file only");

            return false;
        }
        this.UploadFile = file;
        var reader = new FileReader();
        reader.onload = (event: any) => {

            this.imagePath = event.target.result;
            this.SubmitForm();
        }
        reader.readAsDataURL(event.target.files[0]);

    }
}
