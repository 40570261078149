import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from '../../../../../common/Service/common.service';
import { fuseAnimations } from '@fuse/animations';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from 'app/common/Service/snackbar.service';
import { ProfileService } from 'app/main/pages/profile/profile.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { de } from 'date-fns/locale';
import { ActivatedRoute } from '@angular/router';

@Component({
    moduleId: module.id,
    selector: 'profile-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ProfileAboutComponent implements OnInit, OnDestroy {
    formGroup: FormGroup;
    about: any;
    _loginDetails: any;
    _countries: any = [];
    _states: any = [];
    _cities: any = [];
    _industries: any = [];
    employerid:number=0;
    id:string;
    _formGroup: clsScreen = new clsScreen();

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ProfileService} _profileService
     */
    constructor(
        private _formBuilder: FormBuilder, private _profileService: ProfileService,
        private service: CommonService,
        private _snackbarservice: SnackbarService,
        private _splashScreenService: FuseSplashScreenService,
        private activatedRoute: ActivatedRoute,
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.formGroup = this._formBuilder.group({
            companycode: ['', Validators.required],
            companyname: ['', Validators.required],
            emailAddress: ['', Validators.required],
            mobileNo: ['', Validators.required], industry: ['', Validators.required],
            address: [''],
            address2: [''],
            city: [''], businesstype: [''],
            state: [''],
            postalCode: [''],
            country: ['', Validators.required],
        });

        this._profileService.aboutOnChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(about => {
                this.about = about;
            });
        if (localStorage != null && localStorage != undefined && localStorage["UserDetails"]) {
            this._loginDetails = JSON.parse(localStorage.getItem('UserDetails') || '{}');
            if(this._loginDetails.employeeDtls!=null){
                this._formGroup.companycode = this._loginDetails.employeeDtls.companyCode;
                this._formGroup.companyname = this._loginDetails.employeeDtls.companyName;
                this._formGroup.employeerID = this._loginDetails.employeeDtls.employeerID;
            }
            
            this._formGroup.emailAddress = this._loginDetails.users_Emailaddress;
            this._formGroup.mobileNo = this._loginDetails.users_Mobile;
        }
        this.activatedRoute.params.subscribe(param => this.id = param.id);
        this.getMasterData(1, 0,'');//Countries
        this.getMasterData(4, 0,'');//Industries 
        if(this.id!=undefined && this.id!=null && this.id!=""){
            this.getMasterData(14,0, this.id);//EmployeerData
        }
        else{
            this.getMasterData(14,0, this._formGroup.companycode);//EmployeerData
        }
    }
    getMasterData(flg, filterId, code) {

        var sessionobj = JSON.parse(localStorage.UserDetails);
        var clsmodel = {
            Type: flg,
            FilterId: filterId,
            Code:code
        }
        this.service.GetMasterData(clsmodel).subscribe(result => {

            var resultObj = result;
            if (resultObj["status"] == 200) {
                if (flg == 1) {
                    this._countries = resultObj["resultObj"];
                    this._states.push({ state_id: 0, state_name: "Others" })
                    this._formGroup.stateId = 0;
                    this._cities.push({ city_Id: 0, city_name: "Others" })
                    this._formGroup.cityId = 0;
                }
                else if (flg == 2) {
                    this._states = resultObj["resultObj"];
                    this._states.push({ state_id: 0, state_name: "Others" })
                    this._formGroup.stateId = 0;
                }
                else if (flg == 3) {
                    this._cities = resultObj["resultObj"];
                    this._cities.push({ city_Id: 0, city_name: "Others" })
                    this._formGroup.cityId = 0;
                }
                else if (flg == 4) {
                    this._industries = resultObj["resultObj"];  
                }
                else if (flg == 14) {
                    if(resultObj["resultObj"].length>0){
                    this._formGroup.address=resultObj["resultObj"][0].employeer_address;
                    this._formGroup.countryId=resultObj["resultObj"][0].employeer_country;
                    this._formGroup.stateId=resultObj["resultObj"][0].employeer_state;
                    this._formGroup.cityId=resultObj["resultObj"][0].employeer_city;  
                    this._formGroup.industry=resultObj["resultObj"][0].employeer_industry;  
                    this._formGroup.businessType=resultObj["resultObj"][0].employeer_businessType;  
                    this._formGroup.zipCode=resultObj["resultObj"][0].employeer_zipCode;  
                    this._formGroup.address2=resultObj["resultObj"][0].employeer_buffer1;
                    this._formGroup.companycode = resultObj["resultObj"][0].employeer_code;
            this._formGroup.companyname = resultObj["resultObj"][0].employeer_orgName;
            this._formGroup.emailAddress = resultObj["resultObj"][0].employeer_emailAddress;
            this._formGroup.mobileNo = resultObj["resultObj"][0].employeer_mobileNo; 
            this.employerid = resultObj["resultObj"][0].employeer_id; 
                    }
                }
            }

        }, error => {
            console.log(error);
        });
        return "";
    }
    changeCountry(_obj, flag) {
        this.getMasterData(flag, _obj.value,'');
    }
    
    SubmitForm() { 
        this._splashScreenService.show();
        this._formGroup.employeerID= this.employerid;
        this.service.employerForm(this._formGroup).subscribe(result => {

            var resultObj = result;

            if (resultObj["status"] == 200) {
                this._snackbarservice.showSnackbar(resultObj["message"]);
            }
            else
                this._snackbarservice.showSnackbar(resultObj["message"]);
            this._splashScreenService.hide();
        }, error => {
            console.log(error);
            this._splashScreenService.hide();
        });
         
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
export class clsScreen {
    countryId: number;
    stateId: number;
    cityId: number;
    businessType: number;
    industry: number; 
    companycode: string;emailAddress: string;
    companyname: string;mobileNo: string;
    zipCode: string;
    address: string;
    address2: string;
    employeerID: number;
    logo: string;
}