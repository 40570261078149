import { Component, ElementRef, ViewChild, OnInit, NgModule } from '@angular/core';
import { CommonService } from 'app/common/Service/common.service';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from 'app/common/Service/snackbar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AnalyticsDashboardService } from 'app/main/apps/dashboards/analytics/analytics.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss']
})
export class RoleListComponent implements OnInit {
  usersdata: any = [];
  categories: any = []; currentCategory: string;
  storeData: any;
  index: number = 0; counter: number = 0;
  DefaultMenu: any = [];
  AdminActions: any = [];
  usercode: number = 0;
  ModuleDefaultMenu: any = [];
  isEdit: boolean = false;
  alternateColor: any[] = ["android", "web", "cloud", "firebase"];
  btnText: string = "Save";
  specimenDetails: FormGroup;
  empObject: any = {};
  @ViewChild('add_User') add_User: ElementRef;
  @ViewChild('alertpop') alertpop: ElementRef;
  constructor(
    private _analyticsDashboardService: AnalyticsDashboardService,
    private service: CommonService,
    private dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private _snackbarservice: SnackbarService,
    private _splashScreenService: FuseSplashScreenService,
    private _fuseNavigationService: FuseNavigationService,
    private router: Router,

  ) { }

  ngOnInit(): void {
    let isaccess=this._fuseNavigationService.checkAccess("UserList")
    if(!isaccess){
      this._snackbarservice.showSnackbar("Unauthorised Access to the page");
      this.router.navigateByUrl('/auth/login'); 
    }
    //link : ['', Validators.required], 
    this.specimenDetails = this._formBuilder.group({
      name: ['', Validators.required],
      prtype: ['', [Validators.required]],

    });
    this.getRolesJson();
    var sessionobj = JSON.parse(localStorage.UserDetails);
    this.getMasterData(35, 0, "");
    this.AdminActions = JSON.parse(sessionobj.users_Responsibilities);
    this.DefaultMenu.forEach(element => {
      element.Icon = this.getAlternateColor();
    });

  }
  getAlternateColor() {
    if (this.counter == 3)
      this.counter = 0;
    else
      this.counter++;
    return this.alternateColor[this.counter] + "-bg";

  }
  addUser() {
    this.btnText = "Save";
    this.isEdit = true;
    this.empObject.Name = "";
    this.empObject.propertytype = "A";
    this.getRolesJson();
  }
  valueChange(screenId: string, action: string, $event) {
    var _filterActions = this.DefaultMenu.filter((item) => {
      return item.ScreenId === screenId;
    });
    if (action == "All") {
      if (_filterActions != undefined && _filterActions.length > 0) {
        _filterActions[0].clsActions.forEach(element => {
          element.Value = $event.checked;
        });
        _filterActions[0].IsAllow = $event.checked;
      }
    }
    else {

      if (_filterActions != undefined && _filterActions.length > 0) {
        var _actionItem = _filterActions[0].clsActions.filter((item) => {
          return item.ActionName === action;
        });
        _filterActions[0].IsAllow = $event.checked;
        _actionItem[0].Value = $event.checked
        //Check parent module if any item is checked
        _filterActions[0].clsActions.forEach(element => {
          if (element.Value) {
            _filterActions[0].IsAllow = true;
          }
        });
      }
    }
  }
  getRolesJson() {  
    this.DefaultMenu = this._analyticsDashboardService.projects;

    this.DefaultMenu.forEach(element => {
      let moduleNm=element.Module;
      var _actionItem = this.ModuleDefaultMenu.filter((item) => {
        return item.Module === moduleNm;
      });
      if (_actionItem == undefined || _actionItem.length == 0) {
        var _subItems = this.DefaultMenu.filter((item) => {
          return item.Module === moduleNm;
        });
        this.ModuleDefaultMenu.push({Module:moduleNm,Menu:_subItems });
      }
    });
  }
  openmodal(modal) {
    this.dialog.open(modal, { width: "700px", height: "auto", disableClose: true });
  }
  openmodal1(modal) {
    this.dialog.open(modal, { width: "400px", height: "auto", disableClose: true });
  }
  closewindow() {
    this.dialog.closeAll();
    this.getMasterData(18, 0, "");
  }
  back() {
    this.isEdit = false;
  }
  edit_user(accessCode: number) {
    this.usercode = accessCode;
    this.btnText = "Update";
    this.isEdit = true;
    var _selectedRecord = this.usersdata.filter((item) => {
      return item.role_id === accessCode;
    });
    if (_selectedRecord != undefined) { 
      this.empObject.Name = _selectedRecord[0].role_name;
      this.empObject.propertytype = _selectedRecord[0].role_type;
      this.AdminActions = JSON.parse(_selectedRecord[0].role_permissions); 
      this.DefaultMenu.forEach(element => {
        var _filterActions = this.AdminActions.filter((item) => {
          return item.ScreenId === element.ScreenId;
        });
        if (_filterActions != undefined && _filterActions.length > 0) {
          _filterActions[0].clsActions.forEach(childelement => {
            var _childItem = element.clsActions.filter((_item) => {
              return _item.ActionName === childelement.ActionName;
            }); 
            _childItem[0].Value=!_filterActions[0].IsAllow ? false : childelement.Value;
          });
          element.IsAllow = false;
          _filterActions[0].clsActions.forEach(Lelement => {
            if (Lelement.Value) {
              element.IsAllow = true;
            }
          });
        }
        element.Icon = this.getAlternateColor();
      });
    }
  }
  del_user(accessCode: number) {
    this.openmodal1(this.alertpop);
    this.usercode = accessCode;
  }
  act_user(accessCode: number) {
    this.usercode = accessCode;
    this.getMasterData(37, 0, this.usercode);
  }
  confirm_del() {
    this.getMasterData(36, 0, this.usercode);
  }
  getMasterData(flg, filterId, code) {

    var clsmodel = {
      Type: flg,
      FilterId: filterId,
      Code: code
    }
    this.service.GetMasterData(clsmodel).subscribe(result => {

      var resultObj = result;
      if (resultObj["status"] == 200) {
        if (flg == 35) {
          this.usersdata = resultObj["resultObj"];
        }
        else if (flg == 20) {
          var userobj = resultObj["resultObj"];
          this.empObject.Name = userobj[0].users_Fullname;
          this.empObject.EmailAddress = userobj[0].users_Emailaddress;
          this.empObject.MobileNo = userobj[0].users_Mobile;
        }
        else if (flg == 36) {
          this._snackbarservice.showSnackbar(resultObj["message"]);
          this.getMasterData(35, 0, "");//EmployeerData
        }
        else if (flg == 37) {
          this._snackbarservice.showSnackbar(resultObj["message"]);
          this.getMasterData(35, 0, "");//EmployeerData
        }

      }

    }, error => {
      console.log(error);
    });

  }
  specimenSubmit() {
    if (this.specimenDetails.invalid) {
      return;
    } 
    this._splashScreenService.show();
    this.empObject.RoleId = this.usercode;
    this.empObject.RoleName = this.empObject.Name;
    this.empObject.RoleType = this.empObject.propertytype;
    this.empObject.RoleJson = JSON.stringify(this.DefaultMenu);
    this.service.SaveRole(this.empObject).subscribe(result => {

      var resultObj = result;

      if (resultObj["status"] == 200) {
        this.specimenDetails.reset();
        this._snackbarservice.showSnackbar(resultObj["message"]);
        this.getMasterData(35, 0, "");//EmployeerData
        this.isEdit = false;
      }
      else
        this._snackbarservice.showSnackbar(resultObj["message"]);
      this._splashScreenService.hide();
    }, error => {
      console.log(error);
      this._splashScreenService.hide();
    });

  }
  validateNumber(event) {
    const keyCode = event.keyCode;

    const excludedKeys = [8, 37, 39, 46];
    if (keyCode == 9 || keyCode == 13)
      return;
    if (!((keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) ||
      (excludedKeys.includes(keyCode)))) {
      event.preventDefault();
    }
  }
}
const routes: Routes = [
  {
    path: 'role-list',
    component: RoleListComponent

  }
];