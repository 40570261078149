import { Component, ElementRef, OnDestroy, OnInit, ViewChild,NgZone, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { SnackbarService } from 'app/common/Service/snackbar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { RegisterService } from './register.service';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { map, startWith } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestService } from '../request/request.service';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { environment } from '../../../../../../environments/environment';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import {
    HttpClient,
    HttpEventType,
    HttpErrorResponse
} from "@angular/common/http";
export const MY_DATE_FORMATS = {
    parse: {
        dateInput: 'MM/DD/YYYY',
    },
    display: {
        dateInput: 'MM/DD/YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    },
};
import SignaturePad from "signature_pad"; 
@Component({
    moduleId: module.id,
    selector: 'register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
    ],
    host: {
        '(keyup.ctrl.k)': 'clear()'
      }
})
export class RegisterComponent implements OnInit, OnDestroy {
    registerForm: FormGroup; registerForm2: FormGroup;frmPatientAddress: FormGroup;
    empObject: any = {};
    currentDate = new Date();
    insuranceList: any = [];
    viewHide = 1;
    myControl = new FormControl();
    selectedFileName: string = "";
    filteredOptions: Observable<string[]>;
    birthDate: Date;
    minDate: Date;
    _countries: any = [];
    _states: any = [];
    maxDate: Date;
    userlatitude: number;
    clientLogo:string="";
    userlongitude: number;
    zoom: number;
    filteredCourses: any = [];
    filteredLocations: any = [];
    QrLocationId: string = ""; 
    useraddress: string;
    geoCoder: any;
    lstlatlngs: any = [];
    totalLocations: any = [];
    id: string = ''; 
    progress: number;
    isUploadPending: boolean = true;
    sig: SignaturePad;
    @ViewChild('search') public searchElementRef: ElementRef;
    @ViewChild('streetaddress') public streetaddressElementRef: ElementRef;
    @ViewChild('fileInput') fileInput: ElementRef;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
    @ViewChild("canvas", { static: false }) public canvas: ElementRef; 
    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private registerService: RegisterService,
        private datePipe: DatePipe,
        private router: Router,
        private ngZone: NgZone,
        private http: HttpClient,
        private mapsAPILoader: MapsAPILoader,
        private activatedRoute: ActivatedRoute,
        private _Registerservice: RequestService,
        private _snackbarservice: SnackbarService,
        private _splashScreenService: FuseSplashScreenService,
    ) {

        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this._Registerservice.getUserIPDetails().subscribe(result => {
            this.empObject.IpAddress = JSON.stringify(result);
        });
        this.empObject.imagePath = 'assets/images/avatars/Velazquez.jpg';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.registerForm = this._formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            CemailAddress: ['', [Validators.required, Validators.email]],
            mobileNumber: ['null', [Validators.required, Validators.pattern("[0-9 ]{10}")]],
            DOB: ['', Validators.required],
            gender: [''],
            Ethnicity: ['', Validators.required],
            Race: ['', Validators.required],
            address: [''],
        });
        this.registerForm2 = this._formBuilder.group({
            Department: [''],
            acceptterms: [false, Validators.requiredTrue],
            Supervisor: [''],
            InsuranceOName: [''],
            PolicyID: [''],
            DocumentType: ['', Validators.required],
            _insurance:['',Validators.required],
            Group: [''],
            SubScriberFName: [''],
            SubScriberLName: [''],
            Relationship: [''],
        });
        this.frmPatientAddress = this._formBuilder.group({
            country: ['', Validators.required],
            street: ['', [Validators.required]],
            postalCode: ['', [Validators.required]],
            city: ['', Validators.required],
            state: ['', Validators.required],
        });
        this.getMasterData(24, 0, ''); 
        this.empObject.IsInsurance = 0;
        this.empObject.PolicyID = "";
        this.empObject.Group = "";
        this.empObject.SubScriberFName = "";
        this.empObject.SubScriberLName = "";
        this.empObject.Relationship = "";
        this.empObject.Ethnicity = "Undisclosed";
        this.empObject.Race = "Undisclosed";
        this.activatedRoute.params.subscribe(param => this.id = param.id);
        this.getMasterData(71, 0, this.id);
        const currentYear = new Date().getFullYear();
        this.minDate = new Date(currentYear - 100, 0, 1);
        this.maxDate = new Date(currentYear, new Date().getMonth(), new Date().getDate());
    }
    changeCountry(_obj, flag) {
        this.getMasterData(flag, _obj.value, '');
    }
    clear_signature() { 
        this.sig.clear();
      }
      saveSignPad() {
        const base64ImageData = this.sig.toDataURL();
        let signImage = base64ImageData;
        this.progress = 1;
        const formData = new FormData();
        formData.append("base64Image", signImage.replace('data:image/png;base64,', ''));
        formData.append("Location", this.id); 
        let APiURL = environment.BaseApiUrl + "Common";
        this.http
            .post(APiURL + '/UploadEmpSignatureFile', formData, {
                reportProgress: true,
                observe: "events"
            })
            .pipe(
                map((event: any) => {
                    if (event.type == HttpEventType.UploadProgress) {
                        this.progress = Math.round((100 / event.total) * event.loaded);
                    } else if (event.type == HttpEventType.Response) {
                        if (event.body.status == 200) {
                            this.empObject.signaturePath = event.body.resultObj;
                            console.log(this.empObject.signaturePath);
                            //this.sig.clear();
                            this._snackbarservice.showSnackbar("Signature Uploaded")
                            this.isUploadPending = false;
                        }
                        else{
                            this._snackbarservice.showSnackbar(event.body.message)
                        }
                        this.progress = null;
                    }
                }),
                catchError((err: any) => {
                    this.progress = null;
                    alert(err.message);
                    return throwError(err.message);
                })
            )
            .toPromise();

      }
    checkConfirmEmail() {
        if (this.empObject.emailAddress != this.empObject.CemailAddress) {
            return true;
        }
        else {
            return false;
        }
    }
    showNextView(flg: number, IsBack: number) { 
        if (IsBack == 1) {
            this.viewHide = flg;
            return;
        }
        if (flg == 2) {
            if (this.frmPatientAddress.invalid) {
                return;
            }  
            this.viewHide = flg;
            setTimeout(() => {
                this.sig = new SignaturePad(this.canvas.nativeElement);  
                console.log("child", this.canvas);
              }, 500);
        }
       if (flg == 3) {
        if (this.registerForm.invalid)
        return;
        if (this.checkConfirmEmail()) {
            this._snackbarservice.showSnackbar("Confirm email and email didn't match");
            return;
        }
        setTimeout(() => {
            this.setStreetMapInit();
        }, 1000);
        this.viewHide = flg;
           
    }
}
ngAfterViewInit(): void {
    this.getMasterData(23, 0, '');
    this.empObject.country = 231;
    this.getMasterData(2, 231, ''); 
}
setCurrentLocation() {
    if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
            this.userlatitude = position.coords.latitude;
            this.userlongitude = position.coords.longitude;
            this.zoom = 8;
            this.getAddress(this.userlatitude, this.userlongitude);
            if (this.QrLocationId == "" || this.QrLocationId == undefined || this.QrLocationId == null) {
                this.filteredLocations = [];
                this.empObject.SelectedLocation = '';
                // this.filteredCourses.push({location_latitude :this.userlatitude ,
                //     location_longitude :this.userlongitude,icon:'./assets/images/green-dot.png' });
                this.filteredCourses.forEach(element => {
                    if (this.distance(this.userlatitude, this.userlongitude, element.location_latitude, element.location_longitude, "K") <= 1) {
                        this.filteredLocations.push(element);
                    }
                });
            }

        });
    }
}

getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
        if (status === 'OK') {
            if (results[0]) {
                this.zoom = 12;
                this.useraddress = results[0].formatted_address;
            } else {
                window.alert('No results found');
            }
        } else {
            window.alert('Geocoder failed due to: ' + status);
        }

    });
}
distance(lat1: any, lon1: any, lat2: any, lon2: any, unit) {
    var radlat1 = Math.PI * lat1 / 180
    var radlat2 = Math.PI * lat2 / 180
    var theta = lon1 - lon2
    var radtheta = Math.PI * theta / 180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
        dist = 1;
    }
    dist = Math.acos(dist)
    dist = dist * 180 / Math.PI
    dist = dist * 60 * 1.1515
    if (unit == "K") { dist = dist * 1.609344 }
    if (unit == "N") { dist = dist * 0.8684 }
    return dist
}
setStreetMapInit() {
    this.mapsAPILoader.load().then(() => {
        this.geoCoder = new google.maps.Geocoder; 
        let autocomplete = new google.maps.places.Autocomplete(this.streetaddressElementRef.nativeElement);
        autocomplete.addListener("place_changed", () => {
            this.ngZone.run(() => {
                let place = autocomplete.getPlace();

                if (place.geometry === undefined || place.geometry === null) {
                    return;
                }

                var location = { country: "", postal_code: "", locality: "", State: "", sublocality: "", cookCountry: "" };
                for (var i = 0; i < place.address_components.length; ++i) {
                    var component = place.address_components[i];
                    if (component.types.indexOf("country") > -1)
                        location.country = component.long_name;
                    else if (component.types.indexOf("postal_code") > -1)
                        location.postal_code = component.long_name;
                    else if (component.types.indexOf("administrative_area_level_2") > -1)
                        location.cookCountry = component.long_name;
                    else if (component.types.indexOf("locality") > -1)
                        location.locality = component.long_name;
                    else if (component.types.indexOf("political") > -1)
                        location.State = component.long_name;
                    else if (component.types.indexOf("sublocality") > -1)
                        location.sublocality = component.long_name;


                } 

                this.empObject.street = place.formatted_address.split(',')[0];
                var state = location.State;
                var city = location.locality;
                //document.getElementById('txtCountry').value = country;
                var _stateID = this._states.filter((course) => {
                    return course.state_name == state;
                });
                if (_stateID != undefined && _stateID.length > 0) {
                    this.empObject.state = _stateID[0].state_id
                }
                this.empObject.City = city;
                this.empObject.postalCode = location.postal_code;
                this.empObject.county = location.cookCountry;
                // this.empObject.postalCode=place.address_components[7].long_name;
                // this.empObject.City=place.address_components[3].long_name;

            });
        });
    });
}
setMapInit() {
    this.mapsAPILoader.load().then(() => {
        this.setCurrentLocation();
        this.geoCoder = new google.maps.Geocoder;
        let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
        autocomplete.addListener("place_changed", () => {
            this.ngZone.run(() => {
                let place = autocomplete.getPlace();

                if (place.geometry === undefined || place.geometry === null) {
                    return;
                }
                if (this.QrLocationId == "" || this.QrLocationId == undefined || this.QrLocationId == null) {
                    this.filteredLocations = [];
                    this.userlatitude = place.geometry.location.lat();
                    this.userlongitude = place.geometry.location.lng();
                    this.empObject.SelectedLocation = '';
                    this.filteredCourses.forEach(element => {
                        if (this.distance(this.userlatitude, this.userlongitude, element.location_latitude, element.location_longitude, "K") <= 3) {

                            this.filteredLocations.push(element);
                        }
                    });
                }
                else if (this.QrLocationId != undefined && this.QrLocationId != null && this.QrLocationId != "") {
                    //this.openmodal(this.location_confirmation);
                    return
                }
                this.zoom = 12;
            });
        });
    });
}
 
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    saveEmpDetails() {
        if (this.empObject.IsInsurance == 1 && (this.empObject.InsuranceName == "" || this.empObject.InsuranceName == null ||
            this.empObject.InsuranceName == undefined)) {
            this._snackbarservice.showSnackbar("Invalid insurance");
            return;
        }
        else if (this.empObject.IsInsurance == 1 && (this.empObject.PolicyID == "" || this.empObject.PolicyID == null ||
            this.empObject.PolicyID == undefined)) {
            this._snackbarservice.showSnackbar("Invalid policy");
            return;
        }
        else if (this.empObject.IsInsurance == 1 && (this.empObject.Group == "" || this.empObject.Group == null ||
            this.empObject.Group == undefined)) {
            this._snackbarservice.showSnackbar("Invalid policy group");
            return;
        }
        else if (this.empObject.IsInsurance == 1 && (this.empObject.SubScriberFName == "" || this.empObject.SubScriberFName == null ||
            this.empObject.SubScriberFName == undefined)) {
            this._snackbarservice.showSnackbar("Invalid subScriber first name");
            return;
        }
        else if (this.empObject.IsInsurance == 1 && (this.empObject.SubScriberLName == "" || this.empObject.SubScriberLName == null ||
            this.empObject.SubScriberLName == undefined)) {
            this._snackbarservice.showSnackbar("Invalid subScriber last name");
            return;
        }
        else if (this.empObject.IsInsurance == 1 && (this.empObject.Relationship == "" || this.empObject.Relationship == null ||
            this.empObject.Relationship == undefined)) {
            this._snackbarservice.showSnackbar("Invalid relationship");
            return;
        }
        else if (this.empObject.IsInsurance == 1 && this.empObject.InsuranceName.insurance_id == 103 && (this.empObject.InsuranceOName == undefined
            || this.empObject.InsuranceOName == null || this.empObject.InsuranceOName == "")) {
            this._snackbarservice.showSnackbar("Invalid insurance name");
            return;
        }
        else  if (this.empObject.signaturePath == null || this.empObject.signaturePath == undefined
            || this.empObject.signaturePath == "") {
            this._snackbarservice.showSnackbar("Please upload signature to proceed");
            return;
        }
        this._splashScreenService.show();
        var _dateFormat = 'MM/dd/yyyy';
        if (this.birthDate !== undefined && this.birthDate !== null) {
            if (this.birthDate["_d"] !== undefined)
                this.birthDate = this.birthDate["_d"];
            this.birthDate = new Date(this.birthDate);
            this.empObject.dob = this.datePipe.transform(this.birthDate, _dateFormat);
        }

        const formData = new FormData();
        formData.append('employeeType', 'Employee');
        formData.append('employeeID', '');
        formData.append('firstName', this.empObject.firstName);
        formData.append('lastName', this.empObject.lastName);
        formData.append('emailAddress', this.empObject.emailAddress);
        formData.append('mobileNumber', this.empObject.mobileNumber);
        formData.append('pEmailAddress', '');
        formData.append('alternateMobile', '');
        formData.append('location', '');
        formData.append('documentType', this.empObject.DocumentType); 
        formData.append('dob', this.empObject.dob);
        formData.append('gender', this.empObject.gender);
        formData.append('department', '');
        formData.append('supervisor', '');
        formData.append('Ethinicity', this.empObject.Ethnicity);
        formData.append('Race', this.empObject.Race);
        formData.append('address', this.empObject.address);
        formData.append('countryId', this.empObject.country);
        formData.append('postalCode', this.empObject.postalCode);
        formData.append('county', this.empObject.county);
        formData.append('stateId', this.empObject.state);
        formData.append('city', this.empObject.City);
        formData.append('street', this.empObject.street); 
        formData.append('IpAddress', this.empObject.IpAddress);
        formData.append('acceptTerms', this.empObject.acceptTerms);
        formData.append('OthersInsuranceName', this.empObject.InsuranceOName); 
        formData.append('InsuranceName', this.empObject.InsuranceName == "" || this.empObject.InsuranceName == undefined ||
            this.empObject.InsuranceName == null ? 0 : this.empObject.InsuranceName.insurance_id);
        formData.append('PolicyID', this.empObject.PolicyID);
        formData.append('Group', this.empObject.Group);
        formData.append('SFirstName', this.empObject.SubScriberFName);
        formData.append('LFirstName', this.empObject.SubScriberLName);  
        formData.append('signaturePath', this.empObject.signaturePath);
        formData.append('Relationship', this.empObject.Relationship);
        formData.append('employerCode', this.id);
        if (this.empObject.profileImage !== undefined)
            formData.append('profileImage', this.empObject.profileImage, this.empObject.profileImage.name);

        this.registerService.SaveEmpDetails(formData).subscribe(result => {

            var resultObj = result;

            if (resultObj["status"] == 200) {
                this.registerForm.reset();
                this._snackbarservice.showSnackbar(resultObj["message"]);
                this.router.navigate(['/auth/login']);
            }
            else
                this._snackbarservice.showSnackbar(resultObj["message"]);
            this._splashScreenService.hide();
        }, error => {
            console.log(error);
            this._splashScreenService.hide();
        });

    }
    _filter(value: string) {
        return this.insuranceList.filter(option => option.insurance_Name.toLowerCase().includes(value.toLowerCase()));
    }
    displayFn(selectedoption) {

        return selectedoption ? selectedoption.insurance_Name : undefined;
    }
    onSelectionChange(_ev) {
        if (_ev.option.value != null) {
            this.empObject.InsuranceName = _ev.option.value;
        }
    }
    EnabledInsuranceFields(ev) {
        this.empObject.IsInsurance = ev.value;
    }
    getMasterData(flg, filterId, code) {
        var clsmodel = {
            Type: flg,
            FilterId: filterId,
            Code: code
        }
        this._Registerservice.GetMasterData(clsmodel).subscribe(result => {

            var resultObj = result;
            if (resultObj["status"] == 200) {
                if (flg == 23) { 
                    this.insuranceList = resultObj["resultObj"]["insuranceList"];
                    this.filteredOptions = this.myControl.valueChanges
                        .pipe(
                            startWith(''),
                            map(value => typeof value === 'string' ? value : value.insurance_Name),
                            map(insurance_Name => insurance_Name ? this._filter(insurance_Name) : this.insuranceList.slice())
                        );

                    this._countries = resultObj["resultObj"]["countries"];
                    this._states.push({ state_id: 0, state_name: "Others" })
                    this.empObject.stateId = 0; 
                    this.filteredCourses = resultObj["resultObj"]["locations"];

                    if (localStorage.getItem("QrLocation") != undefined && localStorage.getItem("QrLocation") != null && localStorage.getItem("QrLocation") != "") {
                        this.QrLocationId = localStorage.getItem("QrLocation");
                        this.empObject.SelectedLocation = '';
                        var Qrloc = this.filteredCourses.filter((course) => {
                            return course.location_code == this.QrLocationId;
                        }); 
                    }
                    
                    this.totalLocations = this.filteredCourses;
                    this.filteredCourses.forEach(element => {

                        this.lstlatlngs.push({
                            lat: element.location_latitude,
                            lon: element.location_longitude
                        });

                    });

                }
                 
                else if (flg == 71) { 
                    this.clientLogo=resultObj["resultObj"];
                }
                else if (flg == 2) {
                    this._states = [];
                    this._states = resultObj["resultObj"];
                    this._states.push({ state_id: 0, state_name: "Others" })
                    this.empObject.stateId = 0;
                }
                

            }


        }, error => {
            console.log(error);
        });
        return "";
    }
    onFileChanged(event) {
        const file = event.target.files[0];
        if (!file.type.includes("image")) {
            //this.snackBar.showSnackbar("Please select image file only");
            this.fileInput.nativeElement.value = "";
        }
        else {
            var maxfilesize = (1024 * 1024) * 12,  // 12 Mb
                filesize = file.size;
            if (filesize > maxfilesize) {
                this._snackbarservice.showSnackbar("File too large: " + filesize + ". Maximum size: upto 12MB");
                return;
            }

            this.empObject.profileImage = file;
            this.selectedFileName = this.empObject.profileImage.name
            var reader = new FileReader();

            reader.onload = (event: any) => {
                this.empObject.imagePath = event.target.result;
            }



            reader.readAsDataURL(event.target.files[0]);
        }

    }


}


/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if (!control.parent || !control) {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if (!password || !passwordConfirm) {
        return null;
    }

    if (passwordConfirm.value === '') {
        return null;
    }

    if (password.value === passwordConfirm.value) {
        return null;
    }

    return { passwordsNotMatching: true };
};
