<div
    id="forgot-password"
    class="inner-scroll"
    fxLayout="row"
    fxLayoutAlign="start"
>
    <div
        id="forgot-password-form-wrapper"
        fusePerfectScrollbar
        [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }"
    >
        <div id="forgot-password-form">
            <div class="logo" fxHide.gt-xs>
                <img src="assets/images/logos/logo.png" />
            </div>

            <div class="title">RECOVER YOUR PASSWORD</div>

            <form
                name="forgoPasswordForm"
                [formGroup]="forgotPasswordForm"
                novalidate
            >
                <mat-form-field appearance="fill">
                    <mat-label>Email</mat-label>
                    <input
                        matInput
                        [(ngModel)]="emailAddress"
                        formControlName="email"
                    />
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error
                        *ngIf="
                            forgotPasswordForm.get('email').hasError('required')
                        "
                    >
                        Email is required
                    </mat-error>
                    <mat-error
                        *ngIf="
                            forgotPasswordForm.get('email').hasError('email')
                        "
                    >
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>

                <button
                    mat-raised-button
                    class="submit-button"
                    color="accent"
                    aria-label="SEND RESET LINK"
                    (click)="sendPassword()"
                    [disabled]="forgotPasswordForm.invalid"
                >
                    <mat-icon>send</mat-icon> SEND RESET LINK
                </button>
            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <button
                    mat-raised-button
                    [routerLink]="'/auth/login'"
                    class="link"
                >
                    <mat-icon>keyboard_arrow_left</mat-icon> GO BACK TO LOGIN
                </button>
            </div>
        </div>
    </div>
</div>
