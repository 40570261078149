<div id="dashboard-analytics" class="page-layout blank">


    <div class="center">
        <div class="header accent p-24" fxLayout="row" fxLayoutAlign="start start">

            <div class="breadcrumb text-truncate h1" fxLayout="row" fxLayoutAlign="start center"
                [@animate]="{value:'*',params:{x:'50px'}}">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <span>Check-In List</span>
                </div>
            </div>
            <!-- <div class="clsCaption"> Displaying records between {{startDate}} to {{endDate}}</div> -->
        </div>
    </div>
    <div class="content p-24"> 
        <div fxLayout="column" fxLayoutAlign="center">
            <fieldset>
                <legend>
                    <h3>Filters</h3>
                </legend>
                <div class="filters" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
                    fxLayoutAlign.gt-xs="start center">
                    <mat-form-field appearance="fill" fxFlex="33" floatLabel="always" class="course-search  mr-4">
                        <mat-label>Search for a list</mat-label>
                        <input matInput placeholder="Enter a keyword..." [(ngModel)]="searchTerm"
                            (input)="filterCoursesByTerm()">
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex="33" class="date-picker mr-4">
                        <mat-label>Enter a date range</mat-label>
                        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                            <input matStartDate formControlName="start" placeholder="Start date">
                            <input matEndDate formControlName="end" placeholder="End date">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
        
                        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date
                        </mat-error>
                        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex="33" class="category-selector">
        
                        <mat-label>Centers</mat-label>
                        <input type="text" placeholder="Pick one" aria-label="Number" matInput [formControl]="myControl"
                            [matAutocomplete]="auto">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                            (optionSelected)="onSelectionChange($event)" [displayWith]="displayFn">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                {{option.location_propertyName}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
        
                    
            
                </div>
            </fieldset>
            
            </div>  
    </div>
    <div class="content">
        <div class="widget-group" fxLayout="row wrap" fxFlex="100">
            <div class="fuse-widget-front">
                <div class="courses" fxLayout="row wrap" [@animateStagger]="{value:'50'}">

                    <div class="course" *ngFor="let row of dashboard.clsRequestLists" fxFlex="100" fxFlex.gt-xs="25"
                        fxFlex.gt-sm="25" [@animate]="{value:'*',params:{y:'100%'}}">

                        <div class="course-content" fxLayout="column" fxFlex="1 1 auto">

                            <div [ngClass]="row.onlinePayment!=null && row.onlinePayment!='' && row.onlinePayment.length>0 ? 'header fx-lt-green'
                            :row.location_paymentType=='1' && (row.insuranceName==null || row.insuranceName=='') ? 'header fx-lt-red' : 'header fx-lt-grey'" fxLayout="row" fxLayoutAlign="center center">

                                <div class="headerh1" fxFlex>
                                    {{row.name}}   <a href="javascript::" title="Location : {{row.location}} &#13; &#13;Symptoms : {{row.selectedsymptons}} &#13; &#13;Country:{{row.countryName}}
                                    &#13;Ethnicity: {{row.ethnicity}}&#13;&#13;Race: {{row.race}} &#13; &#13;Mobileno: {{row.mobileNo}} &#13; &#13;Address:{{row.street}}
                                    &#13; &#13;Insurance:{{row.insuranceName}}&#13; &#13;PolicyID:{{row.policyID}}&#13; &#13;S Firstname:{{row.sFirstName}}
                                    &#13; &#13;Relationship:{{row.relationship}}&#13; &#13;Group:{{row.group}}" class="request_info"> 
                                         <mat-icon>information</mat-icon></a>
                                </div>

                            </div>

                            <div class="content1" fxLayout="row" fxLayoutAlign="center left" fxFlex="0 0 auto">
                                <div class="updated">{{row.referenceNo}}</div>
                                <div class="updated">{{row.requestDate}} {{row.createdTime}}</div>
                            </div>

                            <div class="footer" fxLayout="row" fxLayoutAlign="center center">
                                <button mat-button (click)="AcceptRequest(row.referenceNo)">
                                    <mat-icon>check_circle</mat-icon> Accept
                                </button>
                                <button mat-button (click)="GeneratePaymentQR(row.referenceNo,row.emailAddress)" *ngIf="(row.onlinePayment==null 
                                || row.onlinePayment=='' || row.onlinePayment.length==0) && row.location_paymentType=='1' && (row.insuranceName==null || row.insuranceName=='') " >
                                    <mat-icon>center_focus_strong</mat-icon> Payment QR
                                </button>
                            </div>

                        </div>

                    </div>
                    <div *ngIf="dashboard==null || dashboard.clsRequestLists ==null || dashboard.clsRequestLists.length <= 0" align="center" class="p-12" fxflex="" ng-reflect-flex=""
                        style="flex: 1 1 0%; box-sizing: border-box;">
                        <h3>No record[s] found,try changing the date range filter</h3>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>