import { Component, ElementRef, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'app/common/Service/common.service';
import { de } from 'date-fns/locale';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { SnackbarService } from 'app/common/Service/snackbar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { DatePipe } from '@angular/common';
import { TableUtil } from "../../../helpers/tableUtil";
import * as XLSX from 'xlsx';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators, FormControl } from '@angular/forms';
@Component({
    selector: 'registered-list',
    templateUrl: './registered-list.html',
    styleUrls: ['./registered-component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class RegisteredListComponent implements OnInit {
    widgets: any;
    widget1SelectedYear = '2016';
    widget5SelectedDay = 'today';
    dosageWiseEmps: any = [];
    result: string;
    widget7: any = [];
    selectedLocation: any = [];
    projects: any[];
    scheme: any = []; filteredOptions: Observable<string[]>;
    usersdata: any = [];
    colorScheme: any = [];
    searchTerm: string = "";
    rows: any = [];
    dashboard: any = [];
    widget6: any = {};
    firstdosePercentage: number = 0;
    seconddosePercentage: number = 0;
    thirdosePercentage: number = 0;
    myControl = new FormControl();
    ExpiresList: any = [];
    range = new FormGroup({
        start: new FormControl(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1)),
        end: new FormControl(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))
    });
    dateNow = Date.now();
    minDate: Date;
    maxDate: Date;
    startDate: string;
    view: any[] = [600, 300];
    endDate: string;
    constructor(

        private dialog: MatDialog,
        private service: CommonService,
        private _fuseSidebarService: FuseSidebarService,
        private _snackbarservice: SnackbarService,
        private _splashScreenService: FuseSplashScreenService,
        private datePipe: DatePipe,
    ) {
        const currentYear = new Date().getFullYear();
        this.minDate = new Date(currentYear - 100, 0, 1);
        this.maxDate = new Date(currentYear, new Date().getMonth(), new Date().getDate());

        this.scheme = {
            domain: ['#f44336', '#9c27b0', '#03a9f4', '#e91e63']
        };
        this.colorScheme = {
            domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
        };
        this.startDate = this.datePipe.transform(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1), "yyyy-MM-dd");
        this.endDate = this.datePipe.transform(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), "yyyy-MM-dd");

        setInterval(() => {
            this.dateNow = Date.now();
        }, 1000);
        setInterval(() => {
            this.generateReport(this.startDate, this.endDate);
        }, 30000);  
        this.range.valueChanges.pipe(
        ).subscribe(event => {
            if (event.end) {
                this.onDateChanged(event);
            }
        });
    }
    displayFn(selectedoption) {

        return selectedoption ? selectedoption.location_propertyName : undefined;
    }
    _filter(value: string) {
        return this.usersdata.filter(option => option.location_accountName.toLowerCase().includes(value.toLowerCase()) ||
        option.location_propertyName.toLowerCase().includes(value.toLowerCase()));
    }
    onSelectionChange(_ev) {
        if (_ev.option.value != null) {
            this.selectedLocation = _ev.option.value;
            this.generateReport(this.startDate, this.endDate);
        }

    }
    LoadData() {
        this.generateReport(this.startDate, this.endDate);
    }
    flg = 0;
    onDateChanged(ev) {
        if (this.flg == 1) {
            this.startDate = this.datePipe.transform(ev.start._d, "yyyy-MM-dd");
            this.endDate = this.datePipe.transform(ev.end._d, "yyyy-MM-dd");
            this.generateReport(this.startDate, this.endDate);
            //this.getdatawithcommonpaging(0, 50, 'createdDate', false, this.selectedLocation.location_Id, this.startDate, this.endDate);
            this.flg = 0;
        }
        else {
            this.flg++;
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    onSelect(ev) {

    }

    /**
     * On init
     */
    ngOnInit(): void {
        // Get the widgets from the service         
        if (localStorage.getItem("isRefresh") != undefined && localStorage.getItem("isRefresh") != ""
            && localStorage.getItem("isRefresh") == "1") {
            localStorage.setItem("isRefresh", "0");
            window.location.reload();
        }

        var sessionobj = JSON.parse(localStorage.UserDetails); 
        this.getMasterData(7, sessionobj.users_Customerid, '');//EmployeerData
        if (sessionobj.users_Usertype == "A")
            this.getMasterData(10, sessionobj.users_userid, '');//LocationUsers
        else
            this.getMasterData(30, sessionobj.users_userid, '');//LocationUsers

    }
    AcceptRequest(_referenceNo) {
        var sessionobj = JSON.parse(localStorage.UserDetails);
        this.getMasterData(55, sessionobj.users_userid, _referenceNo);
    }
    GeneratePaymentQR(_referenceNo,_emailID) {
        this._splashScreenService.show();
        var sessionobj = JSON.parse(localStorage.UserDetails);
        this.getMasterData(81, sessionobj.users_userid, _referenceNo+"$"+_emailID);
    }
    filterCoursesByTerm(): void {
        const searchTerm = this.searchTerm.toLowerCase();

        // Search
        if (searchTerm === '') {
            this.dashboard.clsRequestLists = this.rows.clsRequestLists;
        }
        else {
            this.dashboard.clsRequestLists = this.dashboard.clsRequestLists.filter((row) => {
                return row.name.toLowerCase().includes(searchTerm) ||
                    row.referenceNo.toLowerCase().includes(searchTerm) ||
                    row.mobileNo.toLowerCase().includes(searchTerm);
            });
        }
    }
    generateReport(stDate, endDate) {
        this.rows=[];
        this.dashboard.clsRequestLists=[];
        if (stDate == null || endDate == null)
            return;
        var sessionobj = JSON.parse(localStorage.UserDetails);
        if (sessionobj.users_Usertype == "S") {
            if (this.selectedLocation == null || this.selectedLocation.location_Id == undefined) {
                return;
            }
        }

        this._splashScreenService.show();

        var clsmodel = {
            startDate: stDate,
            endDate: endDate,
            FilterId: this.selectedLocation.location_Id,
            flg: 0,
            locationId: this.result
        }
        this.service.RegisteredList(clsmodel).subscribe(result => { 
            var resultObj = result;
            this._splashScreenService.hide();
            if (resultObj["status"] == 200) {
                this.rows = resultObj["resultObj"];
                this.dashboard.clsRequestLists = this.rows.clsRequestLists;
            }
            else {
                this._snackbarservice.showSnackbar(resultObj["message"]);
            }


        }, error => {
            console.log(error);
        });
    }
    getMasterData(flg, filterId, code) {
        var sessionobj = JSON.parse(localStorage.UserDetails); 
        var clsmodel = {
            Type: flg,
            FilterId: filterId,
            Code: code
        }
        this.service.GetMasterData(clsmodel).subscribe(result => {
            var resultObj = result;
            this._splashScreenService.hide();
            if (resultObj["status"] == 200) {
                if (flg == 55) { 
                    this._snackbarservice.showSnackbar("Successfully Accepted");
                    if (sessionobj.users_Usertype == "S") {
                        this.selectedLocation = this.usersdata[0];
                        this.generateReport(this.startDate, this.endDate)
                    }
                    else{
                        this.generateReport(this.startDate, this.endDate)
                    }
                }
                else if(flg==81){ 
                    var ele = '<div style="width:100%"><center> <br/> <br/>';
                    ele += '<img style="width:800px;height:600px" src="' + resultObj["resultObj"]  + '" />   </center></div>';
                    let printContents, popupWin;
                    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
                    popupWin.document.open();
                    popupWin.document.write('<html><head><title>Payment Qr </title> \
                    \ <style></style> \
                   </head>');
                    popupWin.document.write('<body><div>' + ele + '</div>');
                    popupWin.document.write('</body></html>');  
                    popupWin.document.close();
                }
                else if (flg == 30 || flg == 10) {
                    this.usersdata = resultObj["resultObj"];
                    this.filteredOptions = this.myControl.valueChanges
                        .pipe(
                            startWith(''),
                            map(value => typeof value === 'string' ? value : value.location_propertyName),
                            map(location_propertyName => location_propertyName ? this._filter(location_propertyName) : this.usersdata.slice())
                        );
                  
                    if (sessionobj.users_Usertype == "S") {
                        this.selectedLocation = this.usersdata[0];
                        this.generateReport(this.startDate, this.endDate)
                    }
                }

            }
            else{
                this._snackbarservice.showSnackbar(resultObj["message"]);
            }

        }, error => {
            console.log(error);
        });
        return "";
    }

    openmodal(modal) {
        this.dialog.open(modal, { width: "700px", height: "auto", disableClose: true });
    }
    sendNotification() {

    }



}

