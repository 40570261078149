// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
//npm run-script build --prod

 
export const environment = {
  production: true,
  BaseApiUrl:"https://api.clinify.io/api/",
  ApplicationUrl:"https://app.clinify.io/",
  AttachmentUrl:"https://api.clinify.io/", //don't put '/' at the end
  ApiKey:"hL4bA4nB4yI0vI0fC8fH7eT6"
};
// export const environment = {
//  production: false,
//  BaseApiUrl:"http://stageapi.clinify.io/api/",
//  ApplicationUrl:"http://stageapp.clinify.io/",
//  AttachmentUrl:"http://stageapi.clinify.io/", //don't put '/' at the end
//  ApiKey:"hL4bA4nB4yI0vI0fC8fH7eT6",
//  ReportsUrl:"http://stagereports.clinify.io/", 
// };

//  export const environment = {  
//    production: false,
//    BaseApiUrl:"http://localhost:19301/api/",
//    ApplicationUrl:"http://localhost:4850/",
//    AttachmentUrl:"http://localhost:19301/", //don't put '/' at the end
//    ApiKey:"hL4bA4nB4yI0vI0fC8fH7eT6",
//    ReportsUrl:"http://localhost:19301/",
//    hmr: false

//  };