<div id="file-list" class="page-layout simple">
    <div class="center">
        <div class="header accent p-24" fxLayout="row" fxLayoutAlign="space-between start">

            <div class="breadcrumb text-truncate h1" fxLayout="row" fxLayoutAlign="start center"
                [@animate]="{value:'*',params:{x:'50px'}}">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <span>Users List</span>
                </div>

            </div>

        </div>
    </div>
    <div class="content p-24">
        <div fxLayout="column" fxLayoutAlign="center">
            <fieldset>
                <legend>
                    <h3>Filters</h3>
                </legend>
                <div class="filters" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
                    fxLayoutAlign.gt-xs="start center">
                    <mat-form-field appearance="fill" fxFlex="30" class="category-selector mr-4">
                        <mat-label>Location List</mat-label>
                        <mat-select [(ngModel)]="currentCategory"
                        (selectionChange)="filterCoursesByCategory($event)">
                            <mat-option [value]="'all'">
                                All
                            </mat-option>
                            <mat-option *ngFor="let row of locationList" [value]="row.location_Id">
                                {{ row.location_propertyName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex="30" class="category-selector mr-4">
                        <mat-label>User Type</mat-label>
                        <mat-select [(ngModel)]="currentUserType"
                        (selectionChange)="filterUsersByType($event)">
                            <mat-option [value]="'all'">
                                Select
                            </mat-option>
                            <mat-option value="A">
                                Administrator
                            </mat-option>
                            <mat-option value="S">
                                Staff
                            </mat-option>
                            <mat-option value="O">
                                Batch
                            </mat-option>
                            <mat-option value="U">
                                Member/Patient
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex="30" floatLabel="always" class="course-search mar-left-4">
                        <mat-label>Search for a user</mat-label>
                        <input matInput placeholder="Enter a keyword..." (input)="searchlist()" [(ngModel)]="searchTerm">
                    </mat-form-field>
                    <button mat-raised-button class="primary right mar-left-5 button1" (click)="addUser()"
                        [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
                        <mat-icon style="font-size: 20px;">supervisor_account</mat-icon>
                        Add User
                    </button>
                    
            
                </div>
            </fieldset>
            
            </div>  
      

        <div fxLayout="column" fxLayoutAlign="center">
            <div id="about" fxLayout="row wrap">
                <div class="about-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="100">
                    <div class="profile-box info-box general" fxLayout="column">
                        <div class="content">
                            <div class="table-responsive">
                                <table width="100%" mat-table [dataSource]="dataSource" class="simple invoice-table">
                                    <ng-container matColumnDef="SNo">
                                        <th class="accent1 mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef> S No. </th>
                                        <td mat-cell *matCellDef="let element"> {{element.Sno}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="UserCode">
                                        <th class="accent1 mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef> User Code</th>
                                        <td mat-cell *matCellDef="let element"> <a href="javascript::"
                                                (click)="edit_user(element.users_AccessToken)"> {{element.users_AccessToken}}</a></td>
                                    </ng-container>
                                    <ng-container matColumnDef="Password">
                                        <th class="accent1 mat-header-row bdr-radius"  mat-header-cell *matHeaderCellDef> Password</th>
                                        <td mat-cell *matCellDef="let element" ><span [ngClass]="pwvis==element.users_AccessToken? 'visshow' : 'vishid'" > {{element.users_Password}}</span>
                                            <label [ngClass]="pwvis==element.users_AccessToken ? 'vishid' : 'visshow'">*********</label>
                                            <button mat-button
                                            class="button-min-width view" (click)="showpw(element.users_AccessToken,1)">
                                            <mat-icon class="ft-18">visibility</mat-icon>
                                        </button> 
                                       
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="UserType">
                                        <th class="accent1 mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef> User Type</th>
                                        <td mat-cell *matCellDef="let element"> {{element.users_Usertype}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="Name">
                                        <th class="accent1 mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef>Name</th>
                                        <td mat-cell *matCellDef="let element"> {{element.users_Fullname}}</td>
                                    </ng-container> 
                                    <ng-container matColumnDef="EmailID">
                                        <th class="accent1 mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef> Email ID </th>
                                        <td mat-cell *matCellDef="let element"> {{element.users_Emailaddress}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="Mobile">
                                        <th class="accent1 mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef> Mobile </th>
                                        <td mat-cell *matCellDef="let element"> {{element.users_Mobile}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="Status">
                                        <th class="accent1 mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef> Status </th>
                                        <td mat-cell *matCellDef="let element">{{element.users_Isstatus==1 ? "Active" :
                                            "InActive"}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="Edit">
                                        <th class="accent1 mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef> Edit </th>
                                        <td mat-cell *matCellDef="let element"> <button mat-button
                                                class="button-min-width" matTooltip="Edit" (click)="edit_user(element.users_AccessToken)">
                                                <mat-icon class="ft-18">edit</mat-icon>
                                            </button></td>
                                    </ng-container>
                                    <ng-container matColumnDef="Action">
                                        <th class="accent1 mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef> Action </th>
                                        <td mat-cell *matCellDef="let element"> <button mat-button
                                                class="button-min-width" matTooltip="Delete" *ngIf="element.users_Isstatus==1"
                                                (click)="del_user(element.users_AccessToken)">
                                                <mat-icon class="ft-18">delete</mat-icon>
                                            </button>
                                            <button mat-button matTooltip="Activate" class="button-min-width" *ngIf="element.users_Isstatus==0"
                                                (click)="act_user(element.users_AccessToken)">
                                                <mat-icon class="ft-18">check</mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                </table>
                                <mat-paginator  [pageSizeOptions]="[50, 100, 200]" showFirstLastButtons
                                    aria-label="Select page of usersdata">
                                </mat-paginator>


                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </div>
    </div>
</div>
<ng-template #add_User>


    <form fxLayout="column" fxFlex="1 0 auto" name="specimenDetails" [formGroup]="specimenDetails">
        <h1 mat-dialog-title>User Details</h1>

        <div fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" fxFlex="100">
                <mat-label>Name</mat-label>
                <input matInput formControlName="name" [(ngModel)]="empObject.Name">
                <mat-error>
                    Name is required
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="100">
                <mat-label>Email Address</mat-label>
                <input matInput formControlName="email" type="email" [(ngModel)]="empObject.EmailAddress">
                <mat-error>
                    Email is required
                </mat-error>
                <mat-error>
                    Please enter a valid email address
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="100">
                <mat-label>Mobile Number</mat-label>
                <input matInput formControlName="Mnumber" [(ngModel)]="empObject.MobileNo" maxlength="10"
                    (keydown)="validateNumber($event)">

                <mat-error>
                    Contact Number is required
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="33">
                <mat-label>Role</mat-label>
                <mat-select formControlName="Role" [(ngModel)]="empObject.role_id" required>
                    <mat-option *ngFor="let role of rolesdata" [value]="role.role_id">
                        {{role.role_name}}
                    </mat-option>

                </mat-select>
                <mat-error>Role is required!</mat-error>
            </mat-form-field>
            <mat-checkbox [(ngModel)]="empObject.changePassword" [ngModelOptions]="{standalone: true}">
                Change password for every 90 days
            </mat-checkbox>
            <mat-checkbox [(ngModel)]="empObject.passwordExpiry" [ngModelOptions]="{standalone: true}">
                User can change password on first login
            </mat-checkbox>
            <mat-checkbox [(ngModel)]="empObject.wrongAttempts" [ngModelOptions]="{standalone: true}">
                Block account on 3 wrong attempts password
            </mat-checkbox>
        </div>
        <div fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">


        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="mt-20" fxFlex="1 0 auto">
            <button mat-raised-button class="warn mr-4 " (click)="closewindow()" mat-dialog-close>
                <mat-icon>close</mat-icon>Cancel
            </button>
            <button mat-raised-button [disabled]="specimenDetails.invalid" (click)="specimenSubmit()"
                class="primary mr-4" mat-dialog-close>
                <mat-icon>save</mat-icon>{{btnText}}
            </button>
        </div>
    </form>

</ng-template>

<ng-template #alertpop>


    <form fxLayout="column" fxFlex="1 0 auto" name="specimenDetails" [formGroup]="specimenDetails">
        <h1 mat-dialog-title>Confirmation</h1>
        <div fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">
            <h4>Are you sure you want to suspended the user?</h4>

        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <button mat-raised-button class="warn mr-4 " (click)="closewindow()" mat-dialog-close>
                <mat-icon>close</mat-icon>Cancel
            </button>
            <button mat-raised-button (click)="confirm_del()" class="primary mr-4" mat-dialog-close>
                <mat-icon>delete</mat-icon>Delete
            </button>
        </div>
    </form>

</ng-template>