<div id="file-list" class="page-layout simple">
    <div class="center">
        <div
            class="header accent p-24"
            fxLayout="row"
            fxLayoutAlign="space-between start"
        >
            <div
                class="breadcrumb text-truncate h1"
                fxLayout="row"
                fxLayoutAlign="start center"
                [@animate]="{value:'*',params:{x:'50px'}}"
            >
                <div fxLayout="row" fxLayoutAlign="start center">
                    <span>Test Report</span>
                </div>
            </div>
        </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content p-24">
        <div fxLayout="column" fxLayoutAlign="center">
            <fieldset>
                <legend>
                    <h3>Filters</h3>
                </legend>
                <div
                    class="filters"
                    fxLayout="column"
                    fxLayoutAlign="center center"
                    fxLayout.gt-xs="row"
                    fxLayoutAlign.gt-xs="start center"
                >
                    <mat-form-field
                        appearance="fill"
                        fxFlex="25"
                        class="mr-4 category-selector"
                    >
                        <mat-label>Enter a date range</mat-label>
                        <mat-date-range-input
                            [formGroup]="range"
                            [rangePicker]="picker"
                        >
                            <input
                                matStartDate
                                formControlName="start"
                                placeholder="Start date"
                            />
                            <input
                                matEndDate
                                formControlName="end"
                                placeholder="End date"
                            />
                        </mat-date-range-input>
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="picker"
                        ></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>

                        <mat-error
                            *ngIf="range.controls.start.hasError('matStartDateInvalid')"
                            >Invalid start date
                        </mat-error>
                        <mat-error
                            *ngIf="range.controls.end.hasError('matEndDateInvalid')"
                            >Invalid end date</mat-error
                        >
                    </mat-form-field>
                    <mat-form-field
                        appearance="fill"
                        fxFlex="25"
                        class="mr-4 category-selector"
                    >
                        <mat-label>Test Type</mat-label>
                        <mat-select [(ngModel)]="empObject.test_type" required>
                            <mat-option value="0"> All </mat-option>
                            <mat-option
                                *ngFor="let role of testList"
                                [value]="role.test_id"
                            >
                                {{role.test_name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field
                        appearance="fill"
                        fxFlex="25"
                        class="mr-4 category-selector"
                    >
                        <mat-label>Report Type</mat-label>
                        <mat-select
                            [(ngModel)]="empObject.reportType"
                            [disabled]
                            (selectionChange)="generateReport()"
                            required
                        >
                            <mat-option value="1"> Detailed </mat-option>
                            <!-- <mat-option value="0">
                                    Summary
                                </mat-option>  -->
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field
                        appearance="fill"
                        fxFlex="25"
                        class="category-selector"
                    >
                        <mat-label>Center</mat-label>
                        <input
                            type="text"
                            placeholder="Pick one"
                            aria-label="Number"
                            matInput
                            [formControl]="myControl1"
                            [matAutocomplete]="auto"
                        />
                        <mat-autocomplete
                            autoActiveFirstOption
                            #auto="matAutocomplete"
                            (optionSelected)="onToSelectionChange($event)"
                            [displayWith]="displayFn"
                        >
                            <mat-option
                                *ngFor="let _option of filteredOptions1 | async"
                                [value]="_option"
                            >
                                {{_option.location_accountName + " - " +
                                _option.location_propertyName}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </fieldset>

            <div
                class="filters"
                fxLayout="column"
                fxLayoutAlign="end center"
                fxLayout.gt-xs="row"
                fxLayoutAlign.gt-xs="end"
            >
                <mat-checkbox
                    [(ngModel)]="empObject.wrongAttempts"
                    (change)="onCheckboxChangeFn($event)"
                    style="display: none"
                    [ngModelOptions]="{standalone: true}"
                >
                    Remove zero data record[s]
                </mat-checkbox>
                <button
                    mat-raised-button
                    class="blue_btn mb-8 right"
                    (click)="generateReport()"
                    [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}"
                >
                    <mat-icon style="font-size: 20px">settings</mat-icon>
                    Generate
                </button>
                <!-- <button mat-raised-button class="primary mb-8 right"  *ngIf="empObject.reportType=='1'" (click)="exportToxilabExcel()"
                [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
                <mat-icon style="font-size: 20px;">cloud_download</mat-icon>
                ToxiLab Export List
            </button> -->
                <button
                    mat-raised-button
                    class="primary mb-8 right"
                    (click)="exportExcel()"
                    [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}"
                >
                    <mat-icon style="font-size: 20px">cloud_download</mat-icon>
                    Export List
                </button>
                <button
                    mat-raised-button
                    class="warn mb-8 right"
                    (click)="reloadRoute()"
                    [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}"
                >
                    <mat-icon style="font-size: 20px">refresh</mat-icon>
                    Clear Filters
                </button>
            </div>
            <div fxLayout="column" fxLayoutAlign="center">
                <div class="content">
                    <div
                        *ngIf="rows!=undefined && rows.length <= 0"
                        align="center"
                        class="p-12"
                        fxflex=""
                        ng-reflect-flex=""
                        style="flex: 1 1 0%; box-sizing: border-box"
                    >
                        <h3>No record[s] found</h3>
                    </div>
                    <div style="width: 100%; height: 600px; overflow: auto">
                        <table
                            id="tableData"
                            width="100%"
                            *ngIf="empObject.reportType=='1' && rows.length > 0"
                            class="simple invoice-table"
                        >
                            <thead>
                                <tr class="accent1 mat-header-row bdr-radius">
                                    <th>S No.</th>
                                    <th>Clinify No.</th>
                                    <th>Name</th>
                                    <th>Mobile No</th>
                                    <th>Email ID</th>
                                    <th>DOB</th>
                                    <th>Gender</th>
                                    <th>Req. Tests</th>
                                    <th>LOINC</th>
                                    <th>Location</th>
                                    <th>Requested Date</th>
                                    <th>Submitted By</th>
                                    <th>Status</th>
                                    <th>Ethnicity</th>
                                    <th>Race</th>
                                    <th>Street</th>
                                    <th>City</th>
                                    <th>County</th>
                                    <th>State</th>
                                    <th>PostalCode</th>
                                    <th>Country</th>
                                    <th>Insurance Name</th>
                                    <th>Payer Code</th>
                                    <th>Policy Id</th>
                                    <th>Group</th>
                                    <th>Subscriber FName</th>
                                    <th>Subscriber LName</th>
                                    <th>Relationship</th>
                                    <th>Payment Type</th>
                                    <th>IP Address</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    class="mat-header-row"
                                    *ngFor="let row of rows; let i=index"
                                >
                                    <td>{{i+1}}</td>
                                    <td>{{row.request_code}}</td>
                                    <td>{{row.name}}</td>
                                    <td>{{row.request_mobileNo}}</td>
                                    <td>{{row.request_email}}</td>
                                    <td>{{row.request_dob}}</td>
                                    <td>{{row.request_gender}}</td>
                                    <td>{{row.testname}}</td>
                                    <td>
                                        {{row.testname=='RAPID' ? '94558-4'
                                        :'94500-6'}}
                                    </td>
                                    <td>{{row.location}}</td>
                                    <td>{{row.createddate}}</td>
                                    <td>{{row.updatedBy}}</td>
                                    <td>{{row.status}}</td>
                                    <td>{{row.request_ethnicity}}</td>
                                    <td>{{row.request_race}}</td>
                                    <td>{{row.request_street1}}</td>
                                    <td>{{row.request_city}}</td>
                                    <td>{{row.request_county}}</td>
                                    <td>{{row.request_state}}</td>
                                    <td>{{row.request_zipcode}}</td>
                                    <td>{{row.request_country}}</td>
                                    <td>{{row.request_payerName}}</td>
                                    <td>{{row.request_payerCode}}</td>
                                    <td>{{row.request_policyID}}</td>
                                    <td>{{row.request_group}}</td>
                                    <td>{{row.request_subscribe_fname}}</td>
                                    <td>{{row.request_subscribe_lname}}</td>
                                    <td>{{row.request_subscribe_relation}}</td>
                                    <td>{{row.paymentOption}}</td>
                                    <td>{{row.ipAddress}}</td>
                                </tr>
                            </tbody>
                        </table>

                        <table
                            id="tableData"
                            width="100%"
                            *ngIf="empObject.reportType=='0' && rows.length > 0"
                            class="simple invoice-table"
                        >
                            <thead>
                                <tr class="accent mat-header-row bdr-radius">
                                    <th>S No.</th>
                                    <th>Requested Date</th>
                                    <th
                                        *ngFor="let header of rows[0].clsTestWiseCnts; let i=index"
                                    >
                                        {{header.testName}}
                                    </th>
                                    <th>Total</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    class="mat-header-row"
                                    *ngFor="let row of rows; let i=index"
                                >
                                    <td>{{i+1}}</td>
                                    <td>{{row.requestdate}}</td>
                                    <td *ngFor="let cnt of row.clsTestWiseCnts">
                                        {{cnt.cnt}}
                                    </td>
                                    <td class="text-warn">{{row.cnt}}</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td class="text-warn">Total</td>
                                    <td
                                        *ngFor="let footer of rows[0].clsTestWiseCnts; let i=index"
                                        class="text-warn"
                                    >
                                        {{getTotalRecords(footer.testName)}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table
                            id="tblToxiLabRpt"
                            width="100%"
                            style="display: none"
                            class="simple invoice-table"
                        >
                            <thead>
                                <tr class="accent mat-header-row bdr-radius">
                                    <th>AGENCY_CODE</th>
                                    <th>AP_MRN</th>
                                    <th>GROUP_CODE</th>
                                    <th>PATIENT_LNAME</th>
                                    <th>PATIENT_FNAME</th>
                                    <th>PATIENT_DOB</th>
                                    <th>PATIENT_GENDER</th>
                                    <th>PATIENT_GENDENTITY</th>
                                    <th>PATIENT_RACE</th>
                                    <th>PATIENT_ETHNICITY</th>
                                    <th>PATIENT_STREET1</th>
                                    <th>PATIENT_STREET2</th>
                                    <th>PATIENT_CITY</th>
                                    <th>PATIENT_STATE</th>
                                    <th>PATIENT_PROVINCE</th>
                                    <th>PATIENT_POSTAL_CODE</th>
                                    <th>PATIENT_COUNTRY</th>
                                    <th>PATIENT_PHONE</th>
                                    <th>PATIENT_EMAIL</th>
                                    <th>PATIENT_DX_LIST</th>
                                    <th>PATIENT_RX_LIST</th>
                                    <th>PAYER_CODE</th>
                                    <th>PAYER_SUB_IDNUMBER</th>
                                    <th>PAYER_GROUP</th>
                                    <th>PAYER_SUB_FNAME</th>
                                    <th>PAYER_SUB_LNAME</th>
                                    <th>PAYER_SUB_STREET1</th>
                                    <th>PAYER_SUB_STREET2</th>
                                    <th>PAYER_SUB_CITY</th>
                                    <th>PAYER_SUB_STATE</th>
                                    <th>PAYER_SUB_POSTAL_CODE</th>
                                    <th>PAYER_SUB_REL</th>
                                    <th>Specimen_Collected</th>
                                    <th>Family_Key</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    class="mat-header-row"
                                    *ngFor="let toxirow of toxilabrows; let i=index"
                                >
                                    <td>{{toxirow.location}}</td>
                                    <td>{{toxirow.request_code}}</td>
                                    <td>&nbsp;</td>
                                    <td>{{toxirow.lName}}</td>
                                    <td>{{toxirow.fName}}</td>
                                    <td>{{toxirow.request_dob}}</td>
                                    <td>{{toxirow.request_gender}}</td>
                                    <td>&nbsp;</td>
                                    <td>{{toxirow.request_race}}</td>
                                    <td>{{toxirow.request_ethnicity}}</td>
                                    <td>{{toxirow.request_street1}}</td>
                                    <td>&nbsp;</td>
                                    <td>{{toxirow.request_city}}</td>
                                    <td>{{toxirow.request_state}}</td>
                                    <td>&nbsp;</td>
                                    <td>{{toxirow.request_zipcode}}</td>
                                    <td>{{toxirow.request_country}}</td>
                                    <td>{{toxirow.request_mobileNo}}</td>
                                    <td>{{toxirow.request_email}}</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>
                                        {{toxirow.request_payerCode =="" &&
                                        toxirow.request_policyID!="" ? "95964" :
                                        toxirow.request_payerCode}}
                                    </td>
                                    <td>{{toxirow.request_policyID}}</td>
                                    <td>{{toxirow.Group}}</td>
                                    <td>{{toxirow.request_subscribe_fname}}</td>
                                    <td>{{toxirow.request_subscribe_lname}}</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>
                                        {{toxirow.request_subscribe_relation}}
                                    </td>
                                    <td>
                                        {{toxirow.createddate + ' '
                                        +toxirow.createdTime}}
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <!-- / CONTENT -->
        </div>
    </div>
</div>

<ng-template #update_request>
    <form
        fxLayout="column"
        fxFlex="1 0 auto"
        name="requestform"
        [formGroup]="requestform"
    >
        <h1 mat-dialog-title>Request Details</h1>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" fxFlex="50" class="mr-4">
                <mat-label>First Name</mat-label>
                <input
                    matInput
                    formControlName="firstName"
                    [(ngModel)]="empObject.request_name"
                    required
                />
                <mat-error> Name is required </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="50">
                <mat-label>Last Name</mat-label>
                <input
                    matInput
                    formControlName="lastName"
                    [(ngModel)]="empObject.request_lastName"
                    required
                />
                <mat-error>LastName is required!</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" fxFlex="50" class="mr-4">
                <mat-label>Mobile No</mat-label>
                <input
                    matInput
                    formControlName="mobileNo"
                    [(ngModel)]="empObject.request_mobileNo"
                    required
                />
                <mat-error> Mobile No is required </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="50">
                <mat-label>Email Address</mat-label>
                <input
                    matInput
                    formControlName="emailAddress"
                    [(ngModel)]="empObject.request_email"
                    required
                />
                <mat-error>Email address is required!</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" fxFlex="33">
                <mat-label>Gender</mat-label>
                <mat-select
                    formControlName="gender"
                    [(ngModel)]="empObject.request_gender"
                    required
                >
                    <mat-option value="M"> Male </mat-option>
                    <mat-option value="F"> Female </mat-option>
                    <mat-option value="U"> Undisclosed </mat-option>
                </mat-select>
                <mat-error>Gender is required!</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="34" class="px-4">
                <mat-label>Date of Birth</mat-label>
                <input
                    matInput
                    [min]="minDate"
                    placeholder="MM/DD/YYYY"
                    [max]="maxDate"
                    [matDatepicker]="picker"
                    [(ngModel)]="empObject.request_DOB"
                    formControlName="DateOfBirth"
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>

                <mat-error> Date of Birth is required </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <button
                mat-raised-button
                class="warn mr-4"
                (click)="closewindow()"
                mat-dialog-close
            >
                <mat-icon>close</mat-icon>Cancel
            </button>
            <button
                mat-raised-button
                [disabled]="requestform.invalid"
                (click)="requestSubmit()"
                class="primary mr-4"
                mat-dialog-close
            >
                <mat-icon>save</mat-icon>Update
            </button>
            <button
                mat-raised-button
                [disabled]="requestform.invalid"
                (click)="requestSubmit()"
                class="primary mr-4"
                mat-dialog-close
            >
                <mat-icon>save</mat-icon>Update & Send to Ovation
            </button>
        </div>
    </form>
</ng-template>

<ng-template #transfer_request>
    <form
        fxLayout="column"
        fxFlex="1 0 auto"
        name="TransferForm"
        [formGroup]="TransferForm"
    >
        <h1 mat-dialog-title>Transfer</h1>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" class="category-selector">
                <mat-label>From Center</mat-label>
                <input
                    type="text"
                    placeholder="Pick one"
                    aria-label="Number"
                    matInput
                    formControlName="fromcenter"
                    readonly
                    [formControl]="myControl"
                    [matAutocomplete]="auto"
                />
                <mat-autocomplete
                    autoActiveFirstOption
                    #auto="matAutocomplete"
                    (optionSelected)="onFromSelectionChange($event)"
                    [displayWith]="empObject.location"
                >
                    <mat-option
                        *ngFor="let option of filteredOptions | async"
                        [value]="option"
                    >
                        {{option.location_propertyName}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" class="category-selector">
                <mat-label>To Center</mat-label>
                <input
                    type="text"
                    placeholder="Pick one"
                    aria-label="Number"
                    matInput
                    formControlName="tocenter"
                    [formControl]="myControl"
                    [matAutocomplete]="auto"
                />
                <mat-autocomplete
                    autoActiveFirstOption
                    #auto="matAutocomplete"
                    (optionSelected)="onToSelectionChange($event)"
                    [displayWith]="displayFn"
                >
                    <mat-option
                        *ngFor="let option of filteredOptions | async"
                        [value]="option"
                    >
                        {{option.location_propertyName}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" fxFlex="100" class="mr-4">
                <mat-label>Reason</mat-label>
                <input
                    matInput
                    formControlName="remarks"
                    [(ngModel)]="empObject.remarks"
                    required
                />
                <mat-error> Reason is required </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <button
                mat-raised-button
                class="warn mr-4"
                (click)="closewindow()"
                mat-dialog-close
            >
                <mat-icon>close</mat-icon>Cancel
            </button>
            <button
                mat-raised-button
                [disabled]="TransferForm.invalid"
                (click)="requestSubmit()"
                class="primary mr-4"
                mat-dialog-close
            >
                <mat-icon>save</mat-icon>Update
            </button>
        </div>
    </form>
</ng-template>
