import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonService } from 'app/common/Service/common.service';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarService } from 'app/common/Service/snackbar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent implements OnInit {

  rows: any = [];
  _loginDetails: any;
  usersdata: any = [];
  locationWiseUsers: any = [];
  usersdataList:any=[];
  coursesFilteredByCategory: any[];
  modalType: number = 15;
   usersearchTerm:string;
   currentCategory: string;
   searchTerm: string;
  filteredCourses: any = []; lstlatlngs: any = []; lstlatlngs1: any = [];
  alternateColor: any[] = ["android", "web", "cloud", "firebase"];
  empCode: string = "";
  counter: number = 0;
  modalTitle: string = "approve employeer?";
  selected: any;
  selLocation: number = 0;
  CntList:any={};
  empObject: any = {};
  @ViewChild('confirmpopup') invitation_link: ElementRef;
  @ViewChild('assignuserpop') assignuserpop: ElementRef;

  private _unsubscribeAll: Subject<any>;


  constructor(
    private _CommonService: CommonService,
    private _matIcon: MatIconModule,
    private _snackbarservice: SnackbarService,
    private _splashScreenService: FuseSplashScreenService,
    private service: CommonService, private dialog: MatDialog,
    private _fuseNavigationService: FuseNavigationService,
    private router: Router,
  ) {
    let isaccess=this._fuseNavigationService.checkAccess("ClientsList")
    if(!isaccess){
      this._snackbarservice.showSnackbar("Unauthorised Access to the page");
      this.router.navigateByUrl('/auth/login'); 
    }
    // Set the defaults
    this.currentCategory = 'all';
    this.searchTerm = '';

   }
  getAlternateColor() {
    if (this.counter == 3)
      this.counter = 0;
    else
      this.counter++;
    return this.alternateColor[this.counter] + "-bg";

  }
  SaveUsermapping() {

    if (this.selLocation == undefined || this.selLocation == 0)
      return;
    var selectedUsers = "";
    this.locationWiseUsers.forEach(element => {
      if (element.isChecked) {
        selectedUsers += element.users_userid + ",";
      }
    });
    this.getMasterData(44, this.selLocation, selectedUsers);//Countries
  }
  filterCoursesByCategory(_filterid): void { 
    // Filter  
    this._splashScreenService.show();
    if (_filterid.value == "all") 
   
      this.getMasterData(13, 0, '');//Countries  
    else
      this.getMasterData(46, _filterid.value, '');//Countries  
      this._splashScreenService.hide();
      
  }
  ngOnInit(): void {
    var sessionobj = JSON.parse(localStorage.UserDetails);
    //this.dataSource = new Object();//new FilesDataSource(this._fileManagerService);
    this.getMasterData(13, sessionobj.users_Customerid, '');//EmployeerData
    this.getMasterData(18, 0, '');//getLocationWiseUsers
  }
  invitationPop(_selectedCode, flg) {
    this.modalType = flg;
    if (flg == 15)
      this.modalTitle = "approve employeer?"
    else if (flg == 16)
      this.modalTitle = "de-active all employees from selected employeer?"
    this.empCode = _selectedCode;
    this.openmodal(this.invitation_link);
  }
  uservalueChange(loc, $event) {
    this.locationWiseUsers[loc].isChecked = $event.checked;
  }
  openmodal(modal) {
    this.dialog.open(modal, { width: "700px", height: "auto", disableClose: true });
  }
  assignuser(_id) {
    this.selLocation=_id;
    this.getMasterData(45, _id, '');//getLocationWiseUsers
    this.openmodal(this.assignuserpop);

  }
  closewindow() {
    this.dialog.closeAll();
  }
  ApproveClient() {
    if (this.empCode != "" && this.empCode != undefined) {
      this._splashScreenService.show(); 
      var sessionobj = JSON.parse(localStorage.UserDetails);
      this.getMasterData(this.modalType, sessionobj.users_userid, this.empCode);
    }
  }
  getMasterData(flg, filterId, _code) {

    
    var sessionobj = JSON.parse(localStorage.UserDetails);
    var clsmodel = {
      Type: flg,
      FilterId: filterId,
      Code: _code
    }
    this.service.GetMasterData(clsmodel).subscribe(result => {
      this._splashScreenService.hide(); 
      var resultObj = result;
      if (resultObj["status"] == 200) {
        if (flg == 13 || flg==46) {
          this.rows = resultObj["resultObj"];
          this.rows.forEach(element => {
            element.location_className = this.getAlternateColor();
          });
          
          var _ActList=this.rows.filter((course) => {
            return course.employeer_status == 1;
          });
          var _InActList=this.rows.filter((course) => {
            return course.employeer_status == 0;
          });
          this.CntList.TotalClients=this.rows.length;
          this.CntList.ActiveList=_ActList.length;
          this.CntList.InActiveList=_InActList.length;
          this.coursesFilteredByCategory=this.rows;
        }
        else if (flg == this.modalType) {
          this._snackbarservice.showSnackbar(resultObj["message"]);
          this.dialog.closeAll();
          var sessionobj = JSON.parse(localStorage.UserDetails);
          this.getMasterData(13, sessionobj.users_Customerid, '');//EmployeerData
        } 
        else if (flg == 18) {
          this.usersdata = resultObj["resultObj"];
          this.locationWiseUsers = [];  
          this.usersdata.forEach(element => {
            let checked = false; 
            this.locationWiseUsers.push({
              users_AccessToken: element.users_AccessToken,
              users_Username: element.users_Username, isChecked: checked,
              users_userid: element.users_userid
            }) 
          this.usersdataList=this.locationWiseUsers;
          });
        }
        else if(flg==44){
          this._snackbarservice.showSnackbar(resultObj["message"]);
          this.dialog.closeAll();
        }
        else if (flg == 45) { 
      
          let locationUsers = resultObj["resultObj"];
          this.locationWiseUsers = [];   
          this.usersdata.forEach(element => {
            let checked = false;
            if (locationUsers != null) {
              var filter = locationUsers.filter((course) => {
                return course.client_userId == element.users_userid;
              });
              if (filter != undefined && filter.length > 0) {
                checked = true;
              } 
            }
            this.locationWiseUsers.push({
              users_AccessToken: element.users_AccessToken,
              users_Username: element.users_Username, isChecked: checked,
              users_userid: element.users_userid
            }) 
          this.usersdataList=this.locationWiseUsers;
          });
        }
        
      }

    }, error => {
      console.log(error);
    });
    return "";
  }
  valueChange(locationID, testid) {
    this.getMasterData(26, testid, locationID);//Countries  
  }
  searchlist(): void { 
    const searchTerm = this.usersearchTerm.toLowerCase();  
    if (searchTerm === '') {
      
    }
    else {
      this.locationWiseUsers = this.usersdataList.filter((row) => {
        return row.users_Username.toLowerCase().includes(searchTerm);
      });
    }
  }
  filterCoursesByTerm(): void {  
    const searchTerm = this.searchTerm.toLowerCase();

    // Search
    if (searchTerm === '') {
      this.rows = this.coursesFilteredByCategory;
    }
    else {
      this.rows = this.coursesFilteredByCategory.filter((row) => {
        return row.employeer_orgName.toLowerCase().includes(searchTerm);
      });
    }
  }
}
