<div id="file-list" class="page-layout simple">
    <div class="center">
        <div class="header accent p-24" fxLayout="row" fxLayoutAlign="space-between start">
          
        <div class="breadcrumb text-truncate h1" fxLayout="row" fxLayoutAlign="start center"
        [@animate]="{value:'*',params:{x:'50px'}}">
       <div fxLayout="row" fxLayoutAlign="start center">
           <span>Insurance List</span> 
       </div>
       
   </div>
    
</div>  
</div>
<div class="content p-24">  
   
    <div class="filters p-15" fxLayout="column" fxLayoutAlign="end center" fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="end"> 
    <mat-form-field appearance="fill" floatLabel="always" class="course-search mar-right-5">
        <mat-label>Search for a insurance</mat-label>
        <input matInput placeholder="Enter a keyword..." [(ngModel)]="insurancesearchTerm" (input)="searchInsurancelist()">
    </mat-form-field>
    <button mat-raised-button class="primary right mar-left-5 button1" (click)="addInsurance()"
        [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
        <mat-icon style="font-size: 20px;">supervisor_account</mat-icon>
        Add Insurance
    </button>
</div>
    <div fxLayout="column" fxLayoutAlign="center"> 
                <div id="about" fxLayout="row wrap"> 
                    <div class="about-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="100"> 
                        <div class="profile-box info-box general" fxLayout="column"> 
                            <div class="content">
                                <table width="100%" class="simple invoice-table">
                                    <thead>
                                        <tr class="accent1 mat-header-row bdr-radius">
                                            <th>S No.</th>
                                            <th>Code</th>
                                           <th>Name</th>
                                           <th>Edit</th>
                                           <th>Assign</th>
                                            <th>Actions</th>  
                                    </thead>
                                    <tbody>
                                        <tr class="mat-header-row" *ngFor="let row of usersdata; let i=index">
                                            <td>{{i+1}}</td> 
                                            <td>{{row.insurance_code}}</td>
                                            <td>{{row.insurance_Name}}</td>
                                            <td><button mat-button
                                                class="button-min-width" matTooltip="Edit" (click)="edt_ins(row.insurance_id)">
                                                <mat-icon class="ft-18">edit</mat-icon>
                                            </button></td>
                                            <td> <button mat-button class="button-min-width" matTooltip="Assign" 
                                                (click)="showAssignLocations(row.insurance_id,1)">
                                                <mat-icon class="ft-18">local_pharmacy</mat-icon>
                                            </button></td>
                                            <td>
                                                <button mat-button
                                                class="button-min-width" matTooltip="Delete" *ngIf="row.insurance_isActive==1"
                                                (click)="del_ins(row.insurance_id,0)">
                                                <mat-icon class="ft-18">delete</mat-icon>
                                            </button>
                                            <button mat-button class="button-min-width" matTooltip="Activate" *ngIf="row.insurance_isActive==0"
                                                (click)="act_ins(row.insurance_id,1)">
                                                <mat-icon class="ft-18">check</mat-icon>
                                            </button> 
                                               </td> 
                                            
                                        </tr>
                                        
                                    </tbody>
                                </table>
                            </div>
                           
                        </div>
                    </div>
                </div>
            
        
            </div>
    </div>  
</div>
<ng-template #assignuserpop>
    <div class="content">
        <mat-form-field appearance="fill" floatLabel="always" class="course-search">
            <mat-label>Search for a location</mat-label>
            <input matInput placeholder="Enter a keyword..." [(ngModel)]="usersearchTerm" (input)="searchlist()">
        </mat-form-field>

        <div style="height: 400px;overflow:auto;">
            <table width="100%" class="simple invoice-table">
                <thead>
                    <tr class="accent1 mat-header-row bdr-radius">
                        <th>S No.</th>
                        <th>Location Code</th>
                        <th>Location Name</th>
                        <th>Location Address</th>
                        <th>Assign</th>
                </thead>
                <tbody>
                    <tr class="mat-header-row" *ngFor="let row of locations; let i=index">
                        <td>{{i+1}}</td>
                        <td> {{row.location_code}}</td>
                        <td>{{row.location_accountName}}</td>
                        <td>{{row.location_propertyName}}</td>
                        <td>
                            <mat-checkbox [name]="row.location_Id" [ngModelOptions]="{standalone: true}"
                                (change)="uservalueChange(i,$event)" [id]="row.location_Id"
                                [(ngModel)]="row.isChecked">

                            </mat-checkbox>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
        <div class="mr-t-30">

            <button mat-raised-button class="warn mr-4 " (click)="closewindow()" mat-dialog-close>
                <mat-icon>close</mat-icon>Cancel
            </button>
            <button mat-raised-button class="primary mr-4" (click)="SaveUsermapping()">
                <mat-icon>save</mat-icon>Update
            </button>

        </div>
    </div>
</ng-template>
<ng-template #add_Insurance>


    <form fxLayout="column" fxFlex="1 0 auto" name="insuranceList" [formGroup]="insuranceList">
        <h1 mat-dialog-title>Insurance Details</h1>

        <div fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">
            <mat-form-field appearance="fill" fxFlex="100">
                <mat-label>Insurance Provider Name</mat-label>
                <input matInput formControlName="name" [(ngModel)]="empObject.Name">
                <mat-error>
                    Name is required
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="100">
                <mat-label>Code</mat-label>
                <input matInput formControlName="code" [(ngModel)]="empObject.code">
                <mat-error>
                    Code is required
                </mat-error>
                
            </mat-form-field> 
        </div>
        <div fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">


        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <button mat-raised-button class="warn mr-4 " (click)="closewindow()" mat-dialog-close>
                <mat-icon>close</mat-icon>Cancel
            </button>
            <button mat-raised-button [disabled]="insuranceList.invalid" (click)="insuranceSubmit()"
                class="primary mr-4" mat-dialog-close>
                <mat-icon>save</mat-icon>Save
            </button>
        </div>
    </form>

</ng-template>

<ng-template #alertpop>


    <form fxLayout="column" fxFlex="1 0 auto" name="specimenDetails" [formGroup]="specimenDetails">
        <h1 mat-dialog-title>Confirmation</h1>
        <div fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">
            <h4>Are you sure you want to{{this.deleteFlg==1 ? "'Active'" : "'Suspend'"}} the Symptom?</h4>

        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <button mat-raised-button class="warn mr-4 " (click)="closewindow()" mat-dialog-close>
                <mat-icon>close</mat-icon>Cancel
            </button>
            <button mat-raised-button (click)="confirm_del(0)" class="primary mr-4">
                <mat-icon>{{this.deleteFlg==1 ? "save" : "delete"}}</mat-icon>Save
            </button>
        </div>
    </form>

</ng-template>