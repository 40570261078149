
<div id="file-list" class="page-layout simple">
    <div class="center">
        <div class="header accent p-24" fxLayout="row" fxLayoutAlign="space-between start">
          
        <div class="breadcrumb text-truncate h1" fxLayout="row" fxLayoutAlign="start center"
        [@animate]="{value:'*',params:{x:'50px'}}">
       <div fxLayout="row" fxLayoutAlign="start center">
           <span>Employee List</span> 
       </div>
       
   </div>
    
</div>  
</div>
<div class="content p-24">
    <div fxLayout="column" fxLayoutAlign="start"
    fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start start">
    
    <!-- Widget 1 -->
    <div class="widget" fxFlex="100" fxFlex.gt-sm="33">
    
       <div class="fuse-card primary auto-width mb-32" [ngClass.gt-sm]="'mb-0 mr-32'">
    
           <div class="p-16 pb-0" fxLayout="row wrap" fxLayoutAlign="start end">
    
               <div class="pr-16">
                   <div class="h3 secondary-text">Registered Employees</div>
                   <div class="font-size-54 font-weight-300 line-height-1 mt-8">
                       <span>{{dashboard.empcount}}</span>
                   </div>
               </div> 
    
           </div> 
       </div> 
    </div>
    <!-- / Widget 1 -->
    
    <!-- Widget 2 -->
    <div class="widget" fxFlex="100" fxFlex.gt-sm="34">
    
       <div class="fuse-card accent auto-width mb-32" [ngClass.gt-sm]="'mb-0 mr-32'">
    
           <div class="p-16 pb-0" >
    
               <div class="pr-16">
                   <div class="h3 secondary-text">No. of Employees Vacinated</div>
                   <div   class="font-size-54 font-weight-300 line-height-1 mt-8">
                       <div class="captions">
                     <span class="dosespan mat-raised-button"><label class="text-warn">Dose one:&nbsp;{{dashboard.secondDose}}</label> </span>  
                        <span class="dosespan mat-raised-button"><label class="text-accent">Dose two:&nbsp;{{dashboard.firstDose}} </label> </span> 
                     <span class="dosespan   mat-raised-button"><label class="text-primary">Dose three:&nbsp;{{dashboard.thirdDose}}</label> </span> </div>
                   </div>
               </div>  
           </div> 
       </div> 
    </div>
    <!-- / Widget 2 -->
    
    <!-- Widget 3 -->
    <div class="widget" fxFlex="100" fxFlex.gt-sm="33">
    
       <div class="fuse-card warn auto-width bg-warn" [ngClass.gt-sm]="'mb-0'">
    
           <div class="p-16 pb-0" fxLayout="row wrap" fxLayoutAlign="start end">
    
               <div class="pr-16">
                   <div class="h3 secondary-text">Employees Not Vaccinated</div>
                   <div class="font-size-54 font-weight-300 line-height-1 mt-8">
                    <span>{{dashboard.unVaccinated}}</span>
                   </div>
               </div> 
           </div>
    
           
    
       </div>
    
    </div>
    <!-- / Widget 3 -->
    
    </div>

    <div class="filters" fxLayout="column" fxLayoutAlign="center center"
    fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">

    <mat-form-field appearance="fill" floatLabel="always" class="course-search">
        <mat-label>Search for a employee</mat-label>
        <input matInput placeholder="Enter a keyword..."
               [(ngModel)]="searchTerm"
               (input)="filteremployees()">
    </mat-form-field>

  

</div>
   
    <div fxLayout="column" fxLayoutAlign="center">
<mat-table #table [dataSource]="dataSource" >

    <!-- Type Column -->
    <ng-container matColumnDef="photograph">
        <mat-header-cell *matHeaderCellDef fxFlex="64px"></mat-header-cell>
        <mat-cell *matCellDef="let row" fxFlex="64px">
           <img class="photo" src="{{row.photograph}}" /> 
        </mat-cell>
    </ng-container>
 <!-- Name Column -->
 <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.name}}</mat-cell>
</ng-container>
 <!-- Owner Column -->
 <ng-container matColumnDef="employeeId">
    <mat-header-cell *matHeaderCellDef fxHide.xs>Employee ID</mat-header-cell>
    <mat-cell *matCellDef="let row" fxHide.xs><a  [routerLink]="['/employee-edit/',row.employeeCode ]"
        routerLinkActive="router-link-active">{{row.employeeCode}}</a> </mat-cell>
</ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="mobileNo">
        <mat-header-cell *matHeaderCellDef>Mobile Number</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.mobileNo}}</mat-cell>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="emailAddress">
        <mat-header-cell *matHeaderCellDef fxHide fxShow.gt-md>Email Address</mat-header-cell>
        <mat-cell *matCellDef="let row" fxHide fxShow.gt-md> {{row.emailAddress}}</mat-cell>
    </ng-container>

   

    <!-- Size Column -->
    <ng-container matColumnDef="noOfDoses">
        <mat-header-cell *matHeaderCellDef fxHide.xs>No. of Doses</mat-header-cell>
        <mat-cell *matCellDef="let row" fxHide.xs>  
            <button mat-mini-fab style="margin-left:10px" color="{{row.doseColor}}" >
            {{row.noOfDoses}}
        </button></mat-cell>
    </ng-container>
 
 

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"
             (click)="onSelect(row)"
             [ngClass]="{'selected' : row == selected}"
             [@animate]="{value:'*',params:{y:'100%'}}"
    >
    </mat-row>
</mat-table>
    </div>
    </div>  
</div>