<div id="about" class="p-24" fxLayout="row wrap">

    <div class="about-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="100">

        <div class="profile-box info-box general" fxLayout="column">

            <header class="accent1">
                <div class="title">General Information</div>
            </header>

            <div class="content">
                <form class="mat-card mat-elevation-z4 p-24 mr-24" fxLayout="column" fxLayoutAlign="start"
                fxFlex="1 0 auto" name="form" [formGroup]="formGroup"> 
              <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                  <mat-form-field appearance="fill" fxFlex="50" class="pr-4">
                      <mat-label>Company Code</mat-label>
                      <input matInput formControlName="companycode"  readonly  [disabled]="true" [(ngModel)]="_formGroup.companycode"   >
                      <mat-icon matSuffix class="disabled-text">domain</mat-icon>
                  </mat-form-field>
                  <mat-form-field appearance="fill" fxFlex="50" class="pl-4">
                    <mat-label>Company Name</mat-label>
                    <input matInput formControlName="companyname"  readonly  [disabled]="true" [(ngModel)]="_formGroup.companyname"   >
                    <mat-icon matSuffix class="disabled-text">domain</mat-icon>
                </mat-form-field>

              </div>

              <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                  <mat-form-field appearance="fill" fxFlex="50" class="pr-4">
                      <mat-label>Email Address</mat-label>
                      <input matInput formControlName="emailAddress" [(ngModel)]="_formGroup.emailAddress" readonly  [disabled]="true"   >
                      <mat-icon matSuffix class="secondary-text">email</mat-icon>
                      <mat-error>Email Address is required!</mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="50" class="pl-4">
                      <mat-label>Mobile No</mat-label>
                      <input matInput formControlName="mobileNo" [(ngModel)]="_formGroup.mobileNo" readonly  [disabled]="true" >
                      <mat-icon matSuffix class="secondary-text">contact_phone</mat-icon>
                      <mat-error>Mobile No is required!</mat-error>
                  </mat-form-field>

              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

                <mat-form-field appearance="fill" fxFlex="50" class="pr-4">
                    <mat-label>Industry </mat-label>
                    <mat-select formControlName="industry"  [(ngModel)]="_formGroup.industry"  required>
                        <mat-option *ngFor="let industr of _industries" [value]="industr.industry_id">
                            {{industr.industry_name}}
                        </mat-option> 
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    <mat-error>Industry is required!</mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="50" class="pl-4">
                    <mat-label>Business Type</mat-label>
                    <mat-select formControlName="businesstype"   [(ngModel)]="_formGroup.businessType"  required>
                        <mat-option  [value]="'N/A'">
                            N/A
                        </mat-option> 
                        <mat-option  [value]="'Partnership'">
                            Partner ship
                        </mat-option> 
                        <mat-option  [value]="'Self'">
                            Self
                        </mat-option> 
                        <mat-option  [value]="'Others'">
                            Others
                        </mat-option> 
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                   
                </mat-form-field>

            </div>
              <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">

                  <mat-form-field appearance="fill" fxFlex="100">
                      <mat-label>Address</mat-label>
                      <textarea matInput formControlName="address"  [(ngModel)]="_formGroup.address" required></textarea>
                      <mat-error>Address is required!</mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="fill" fxFlex="100">
                      <mat-label>Address 2</mat-label>
                      <textarea matInput formControlName="address2" [(ngModel)]="_formGroup.address2"></textarea>
                      <mat-error>Address 2 is required!</mat-error>
                  </mat-form-field>

              </div>

              <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <mat-form-field appearance="fill" fxFlex="33">
                    <mat-label>Country</mat-label>
                    <mat-select formControlName="country" (selectionChange)="changeCountry($event,2)" [(ngModel)]="_formGroup.countryId"  required>
                        <mat-option *ngFor="let country of _countries" [value]="country.country_id">
                            {{country.country_name}}
                        </mat-option> 
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text">outlined_flag</mat-icon>
                    <mat-error>Country is required!</mat-error>
                </mat-form-field> 

                  <mat-form-field appearance="fill" fxFlex="34" class="px-8">
                      <mat-label>State</mat-label>
                      <mat-select formControlName="state" (selectionChange)="changeCountry($event,3)" [(ngModel)]="_formGroup.stateId"  required>
                        <mat-option *ngFor="let state of _states" [value]="state.state_id">
                            {{state.state_name}}
                        </mat-option> 
                    </mat-select>
                      <mat-icon matSuffix class="secondary-text">location_city</mat-icon>
                      <mat-error>State is required!</mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="fill" fxFlex="33">
                    <mat-label>City</mat-label>
                    <mat-select formControlName="city" [(ngModel)]="_formGroup.cityId" required >
                        <mat-option *ngFor="let city of _cities" [value]="city.city_Id">
                            {{city.city_name}}
                        </mat-option> 
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text">location_city</mat-icon>
                    <mat-error>City is required!</mat-error>
                </mat-form-field>
               
              </div>

              <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <mat-form-field appearance="fill" fxFlex="33">
                    <mat-label>Postal Code</mat-label>
                    <input matInput #postalCode value="94043"  [(ngModel)]="_formGroup.zipCode"
                           formControlName="postalCode" maxlength="6" required>
                    <mat-icon matSuffix class="secondary-text">markunread_mailbox</mat-icon>
                    <mat-hint align="end">{{postalCode.value.length}} / 6</mat-hint>
                    <mat-error>Postal Code is required!</mat-error>
                </mat-form-field>

                

              </div>

          </form>
            </div>

        </div> 

    </div>

     
    <div class="actions1" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button color="warn" aria-label="Follow" routerLink="/dashboard" ><mat-icon>close</mat-icon> Cancel</button>
        <button mat-raised-button color="primary" [disabled]="formGroup.invalid" (click)="SubmitForm()" aria-label="save"><mat-icon>save</mat-icon> Save</button>
    </div> 
</div>


