import { Component, OnDestroy, OnInit, ViewEncapsulation, NgZone, Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { RouterModule, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { RequestService } from '../request/request.service';
import { NgModule, ViewChild, ElementRef } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { ActivatedRoute } from '@angular/router'; 
@Component({
    selector: 'register-4',
    templateUrl: './register-4.html' ,    
    styleUrls: ['../request/thankyou/thankyou.component.scss'],
    encapsulation: ViewEncapsulation.None, 
})
export class PresignedUrlComponent implements OnInit, OnDestroy {
confirmationCode:string;
       
    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private activatedRoute:ActivatedRoute,         
        private _Registerservice: RequestService,
        private ngZone: NgZone,
        private router: Router, 
    ) {


        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
        this.activatedRoute.params.subscribe(param => this.confirmationCode = param.id); 
        this.getMasterData(54,0,this.confirmationCode);
        setTimeout(() => {
            window.location.href='/auth/login'
        }, 60000);
    }
    getMasterData(flg, filterId, code) {
        var clsmodel = {
            Type: flg,
            FilterId: filterId,
            Code: code
        }
        this._Registerservice.GetMasterData(clsmodel).subscribe(result => {

            var resultObj = result;
            if (resultObj["status"] == 200) {
                
                  if (flg == 54) { 
                   window.location.href=resultObj["resultObj"];
          
                  }
               

            }
            else{
                alert("Invalid reference no, please try after some time")
                window.location.href='/auth/request';
            }


        }, error => {
            console.log(error);
        });
        return "";
    }
    ngOnInit(): void {

    }
    ngOnDestroy(): void {
       
    }
}