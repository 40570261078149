<div id="file-list" class="page-layout simple">
    <div class="center">
        <div
            class="header accent p-24"
            fxLayout="row"
            fxLayoutAlign="space-between start"
        >
            <div
                class="breadcrumb text-truncate h1"
                fxLayout="row"
                fxLayoutAlign="start center"
                [@animate]="{value:'*',params:{x:'50px'}}"
            >
                <div fxLayout="row" fxLayoutAlign="start center">
                    <span>Audit Logs</span>
                </div>
            </div>
        </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content p-24">
        <div fxLayout="column" fxLayoutAlign="center">
            <fieldset>
                <legend>
                    <h3>Filters</h3>
                </legend>
                <div
                    class="filters"
                    fxLayout="column"
                    fxLayoutAlign="center center"
                    fxLayout.gt-xs="row"
                    fxLayoutAlign.gt-xs="start center"
                >
                    <mat-form-field
                        appearance="fill"
                        fxFlex="33"
                        floatLabel="always"
                        class="course-search mr-4"
                    >
                        <mat-label>Search</mat-label>
                        <input
                            matInput
                            placeholder="Enter a keyword..."
                            (keyup)="onsearchEnter($event)"
                            [(ngModel)]="searchTerm"
                        />
                        <button
                            mat-raised-button
                            class="primary mb-8 right btn_search"
                            (click)="searchlist()"
                            [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}"
                        >
                            <mat-icon matSuffix class="secondary-text"
                                >search</mat-icon
                            >
                            Search
                        </button>
                    </mat-form-field>
                    <mat-form-field
                        appearance="fill"
                        fxFlex="33"
                        class="date-picker mr-4"
                    >
                        <mat-label>Enter a date range</mat-label>
                        <mat-date-range-input
                            [formGroup]="range"
                            [rangePicker]="picker"
                        >
                            <input
                                matStartDate
                                formControlName="start"
                                placeholder="Start date"
                            />
                            <input
                                matEndDate
                                formControlName="end"
                                placeholder="End date"
                            />
                        </mat-date-range-input>
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="picker"
                        ></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>

                        <mat-error
                            *ngIf="range.controls.start.hasError('matStartDateInvalid')"
                            >Invalid start date
                        </mat-error>
                        <mat-error
                            *ngIf="range.controls.end.hasError('matEndDateInvalid')"
                            >Invalid end date</mat-error
                        >
                    </mat-form-field>

                    <mat-form-field
                        appearance="fill"
                        fxFlex="33"
                        class="category-selector"
                    >
                        <mat-label>Transaction Type</mat-label>
                        <mat-select
                            [(ngModel)]="empObject.reportType"
                            [disabled]
                            (selectionChange)="generateReport()"
                            required
                        >
                            <mat-option value="0"> ALL </mat-option>
                            <mat-option value="1"> Inserted </mat-option>
                            <mat-option value="2"> Updated </mat-option>
                            <mat-option value="3"> Email </mat-option>
                            <mat-option value="4"> SMS </mat-option>
                            <mat-option value="5"> Transferred </mat-option>
                            <mat-option value="6"> Clone </mat-option>
                            <!-- <mat-option value="0">
                                Summary
                            </mat-option>  -->
                        </mat-select>
                    </mat-form-field>
                </div>
            </fieldset>

            <div
                class="filters"
                fxLayout="column"
                fxLayoutAlign="end center"
                fxLayout.gt-xs="row"
                fxLayoutAlign.gt-xs="end"
            >
                <button
                    mat-raised-button
                    class="blue_btn mb-8 right"
                    (click)="generateReport()"
                    [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}"
                >
                    <mat-icon style="font-size: 20px">settings</mat-icon>
                    Generate
                </button>
                <button
                    mat-raised-button
                    class="primary mb-8 right"
                    *ngIf="empObject.reportType=='1'"
                    (click)="exportToxilabExcel()"
                    [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}"
                >
                    <mat-icon style="font-size: 20px">cloud_download</mat-icon>
                    ToxiLab Export List
                </button>
                <button
                    mat-raised-button
                    class="primary mb-8 right"
                    (click)="exportExcel()"
                    [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}"
                >
                    <mat-icon style="font-size: 20px">cloud_download</mat-icon>
                    Export List
                </button>
                <button
                    mat-raised-button
                    class="warn mb-8 right"
                    (click)="reloadRoute()"
                    [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}"
                >
                    <mat-icon style="font-size: 20px">refresh</mat-icon>
                    Clear Filters
                </button>
            </div>
            <div fxLayout="column" fxLayoutAlign="center">
                <div class="content">
                    <div class="table-responsive">
                        <table
                            class="table"
                            mat-table
                            id="tableData"
                            [dataSource]="dataSource"
                            matSort
                        >
                            <ng-container matColumnDef="action">
                                <th
                                    class="accent1 mat-header-row bdr-radius"
                                    mat-header-cell
                                    *matHeaderCellDef
                                >
                                    Action
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.actionName}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="type">
                                <th
                                    class="accent1 mat-header-row bdr-radius"
                                    mat-header-cell
                                    *matHeaderCellDef
                                >
                                    Action Status
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.type}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="ref">
                                <th
                                    class="accent1 mat-header-row bdr-radius"
                                    mat-header-cell
                                    *matHeaderCellDef
                                    mat-sort-header="name"
                                >
                                    Reference
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.refNo}}
                                </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="txnDate">
                                <th
                                    class="accent1 mat-header-row bdr-radius"
                                    mat-header-cell
                                    *matHeaderCellDef
                                    mat-sort-header="name"
                                >
                                    Txn Date
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.createdate + ' ' +
                                    element.createdtime}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="user">
                                <th
                                    class="accent1 mat-header-row bdr-radius"
                                    mat-header-cell
                                    *matHeaderCellDef
                                >
                                    User
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.user}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="location">
                                <th
                                    class="accent1 mat-header-row bdr-radius"
                                    mat-header-cell
                                    *matHeaderCellDef
                                >
                                    Location
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.location}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="seqno">
                                <th
                                    class="accent1 mat-header-row bdr-radius"
                                    mat-header-cell
                                    *matHeaderCellDef
                                >
                                    &nbsp; #
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <a
                                        mat-button
                                        matTooltip="View Document"
                                        *ngIf="element.actionName=='Email' || element.actionName=='SMS'"
                                        class="button-min-width"
                                        target="_blank"
                                        (click)="viewmsg(element.msg,element.actionName)"
                                    >
                                        <mat-icon class="ft-18"
                                            >remove_red_eye</mat-icon
                                        >
                                    </a>
                                    <!-- <button mat-button matTooltip="Print" *ngIf="ScreenActions.IsPrint"
                                        class="button-min-width" (click)="OpenStatus(element.referenceNo)">
                                        <mat-icon class="ft-18">print</mat-icon>
                                    </button> -->
                                </td>
                            </ng-container>

                            <tr
                                mat-header-row
                                *matHeaderRowDef="displayedColumns"
                            ></tr>
                            <tr
                                mat-row
                                *matRowDef="let row; columns: displayedColumns;"
                                matTooltipClass="toolTipClass"
                                [ngClass]="{'red-100-bg': row.type === 'Error' }"
                            ></tr>
                        </table>
                    </div>

                    <div
                        *ngIf="rows.length <= 0"
                        align="center"
                        class="p-12"
                        fxflex=""
                        ng-reflect-flex=""
                        style="flex: 1 1 0%; box-sizing: border-box"
                    >
                        <h3>
                            No record[s] found,try changing the date range
                            filter
                        </h3>
                    </div>

                    <mat-paginator
                        [length]="resultLength"
                        [pageSize]="clsmodel.pageSize"
                        [pageSizeOptions]="[5,10,20,50,100]"
                        (page)="onPageFired($event)"
                        showFirstLastButtons
                        #paginator
                    ></mat-paginator>
                </div>
            </div>
            <!-- / CONTENT -->
        </div>
    </div>
</div>

<ng-template #view_Msg>
    <form
        fxLayout="column"
        fxFlex="1 0 auto"
        name="TransferForm"
        [formGroup]="TransferForm"
        style="align-items: center; width: 100%"
    >
        <h1>Info :</h1>
        <table class="simple table" id="tblInfo" width="100%">
            <tr>
                <td class="accent mat-header-row bdr-radius">Type:</td>
                <td>{{MsgResult.actionType}}</td>
            </tr>
            <tr>
                <td class="accent mat-header-row bdr-radius">Status:</td>
                <td>{{MsgResult.msgType}}</td>
            </tr>
            <tr>
                <td class="accent mat-header-row bdr-radius">Body:</td>
                <td>
                    <div
                        [innerHtml]="MsgResult.result"
                        id="_divL"
                        style="height: 300px; overflow: auto"
                    ></div>
                </td>
            </tr>
        </table>

        <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxFlex="1 0 auto"
            class="mar-t-30"
        >
            <button
                mat-raised-button
                class="warn mr-4"
                (click)="closepdfwindow()"
                mat-dialog-close
            >
                <mat-icon>close</mat-icon> Close
            </button>
        </div>
    </form>
</ng-template>
