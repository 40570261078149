import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import { RegisterModule } from './main/pages/authentication/register/register.module';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';
import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';
import { Login2Module } from './main/pages/authentication/login-2/login-2.module';
import { AuthGuardService } from './common/authGuard';
import { AppsModule } from './main/apps/apps.module';
import { AnalyticsDashboardModule } from './main/apps/dashboards/analytics/analytics.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { profile } from 'console';
import { MatSelectModule } from '@angular/material/select';
import { ProfileModule } from './main/pages/profile/profile.module';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { ProfileComponent } from './main/pages/profile/profile.component';
import { EmployeesProfileModule } from './main/pages/employees-list/employees-list.module';
import { EmpProfileModule } from './main/pages/employee-edit/employee-edit.module';
import { EmpProfileComponent } from './main/pages/employee-edit/employee-edit';
import { Register2Module } from './main/pages/authentication/register-2/register-2.module';
import { RequestModule } from './main/pages/authentication/request/request.module'; 
import { ForgotPassword2Module } from './main/pages/authentication/forgot-password-2/forgot-password-2.module';
import { CentersComponent } from './main/centers/centers.component';
import { EmployeeListComponent } from './main/pages/employees-list/employees-list.component';
import { CenterWiseEmployeesComponent } from './main/pages/employees-list/centerwise-employees/centerwise-employees';
import { centerModule } from './main/centers/centers.module';
import { GeocodeService } from './main/centers/geocode.service';
import { DatePipe } from '@angular/common';
import { SnackbarService } from './common/Service/snackbar.service';
import { LoaderService } from './common/Service/loader.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialog } from '@angular/material/dialog';
import { AgmCoreModule } from '@agm/core';
import { MatTableModule } from '@angular/material/table';
import { MatTableDataSource } from '@angular/material/table';
import { MatTable } from '@angular/material/table';
import { DataSource } from '@angular/cdk/collections';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import {MatRadioModule} from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { FileManagerService } from 'app/main/apps/file-manager/file-manager.service';
import { CovidUploadComponent } from './main/apps/covid-upload/covid-upload.component';
import { AuthGuard } from '../app/helpers/auth.guard';
import { ClientListComponent } from './main/apps/client-list/client-list.component';
import { VendorListComponent } from './main/apps/vendor-list/vendor-list.component';
import { RequestListComponent } from './main/apps/request-list/request-list.component';
import { CenterReportComponent } from './main/reports/center-report/center-report.component'; 
import { EmpRequestListComponent } from './main/pages/employees-list/emp-requests/emp-requests-component';
import { AuditLogComponent } from './main/reports/auditlogs/auditlogs.component';
import { selfTestListComponent } from './main/apps/selftest-list/selftest-list.component';
import { TestReportComponent } from './main/reports/testreport/testreport.component';
import { BarcodeGenerator } from './main/apps/request-list/barcode/barcodegenerator';
import { UserListComponent } from './main/apps/user-list/user-list.component';
import { RoleListComponent } from './main/apps/role-list/role-list.component';
import { RequestDashboardComponent } from './main/apps/request-dashboard/request-dashboard.component';
import { InsuranceListComponent } from './main/apps/insurance-list/insurance-list.component';
import { SymptomsListComponent } from './main/apps/symptoms-list/symptoms-list.component';
import { TestListComponent } from './main/apps/test-list/test-list.component';
import { BatchreportComponent } from './main/reports/batchreport/batchreport.component';
import { LocationQrComponent } from './main/apps/location-qr/location-qr.component'; 
import { MatCheckboxModule } from '@angular/material/checkbox'; 
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { InterceptorService } from './common/interceptor.service'; 
import { CircleTimerModule } from '@flxng/circle-timer';    
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { NgxBarcodeModule } from 'ngx-barcode';
import { SelfTestComponent } from './main/pages/authentication/self-test/self-test.component'; 
import { RequestComponent } from './main/pages/authentication/request/request.component';
import { RegisteredListComponent } from './main/apps/registered-list/registered-component';
import {MatExpansionModule} from '@angular/material/expansion';
import{VendorWorkflowComponent} from './main/apps/vendor-workflow/vendor-workflow.component';
import { InvalidQrComponent } from './main/pages/authentication/request/invalidqrcode/invalidqr.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { VendorReportComponent } from './main/reports/vendorreport/vendorreport.component';
//import { VerifyRapidComponent } from './main/pages/authentication/verify-rapid/verify-rapid.component';

const appRoutes: Routes = [
    // {
    //     path        : 'apps',
    //     loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule)
    // },
    // {
    //     path        : 'pages',
    //     loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
    // },
    // {
    //     path        : 'ui',
    //     loadChildren: () => import('./main/ui/ui.module').then(m => m.UIModule)
    // },
    // {
    //     path        : 'documentation',
    //     loadChildren: () => import('./main/documentation/documentation.module').then(m => m.DocumentationModule)
    // },
    { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
    {
        path: 'dashboard',
        redirectTo: 'apps/dashboards/analytics'
    },       
    {
        path     : 'center-employees',
        component: CenterWiseEmployeesComponent,
    
    },
    {
        path: 'profile',
        component: ProfileComponent
    },
    {
        path: 'auth/invalidqr' ,
        component: InvalidQrComponent
    },
    {
        path: 'employee-edit',
        component: EmpProfileComponent
    },  
    {
        path: 'location-qr',
        component: LocationQrComponent,
        canActivate: [AuthGuard]
    },  
    {
        path: 'request-list',
        component: RequestListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'emp-request-list',
        component: EmpRequestListComponent, 
    },
    {
        path: 'test-report',
        component: TestReportComponent,
        canActivate: [AuthGuard]
    },  
    {
        path: 'batch-report',
        component: BatchreportComponent,
        canActivate: [AuthGuard]
    },  
    {
        path: 'vendor-report',
        component: VendorReportComponent,
        canActivate: [AuthGuard]
    },  
    {
        path: 'audit-logs',
        component: AuditLogComponent,
        canActivate: [AuthGuard]
    },
     {
        path: 'self-test-list',
        component: selfTestListComponent,
        canActivate: [AuthGuard]
    }, 
     {
        path: 'center-report',
        component: CenterReportComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'barcode-generator',
        component: BarcodeGenerator  
    },
    {
        path: 'barcode-generator/:id',
        component: BarcodeGenerator  
    },
    {
        path: 'centers',
        component: CentersComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'employee-list',
        component: EmployeeListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'covid-upload',
        component: CovidUploadComponent
    },
    {
        path: 'client-list',
        component: ClientListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'vendor-list',
        component: VendorListComponent,
        canActivate: [AuthGuard]
    }
    , {
        path: 'role-list',
        component: RoleListComponent,
        canActivate: [AuthGuard]
    },
     {
        path: 'user-list',
        component: UserListComponent,
        canActivate: [AuthGuard]
    }, {
        path: 'insurance-list',
        component: InsuranceListComponent,
        canActivate: [AuthGuard]
    }
    , {
        path: 'request-dashboard',
        component: RequestDashboardComponent,
        canActivate: [AuthGuard]
    } 
    , {
        path: 'registered-list',
        component: RegisteredListComponent,
        canActivate: [AuthGuard]
    }
    , {
        path: 'symptoms-list',
        component: SymptomsListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'test-list',
        component: TestListComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'vendor-workflow',
        component: VendorWorkflowComponent,
        canActivate: [AuthGuard]
    },
    
    // {
    //     path: 'verify-rapid',
    //     component: VerifyRapidComponent,
    //     canActivate: [AuthGuard]
    // },

];

@NgModule({
    declarations: [
        AppComponent,
        CentersComponent,
        EmployeeListComponent,
        CovidUploadComponent,
        RequestListComponent,EmpRequestListComponent,AuditLogComponent,selfTestListComponent,
        TestReportComponent,CenterReportComponent,VendorReportComponent,BatchreportComponent,
        RoleListComponent,BarcodeGenerator,CenterWiseEmployeesComponent,
        UserListComponent,LocationQrComponent ,VendorListComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,

        RouterModule.forRoot(appRoutes),//, { relativeLinkResolution: 'legacy' }

        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),

        // Material moment date module
        MatMomentDateModule,
        // Material
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MatSelectModule, 
        MatFormFieldModule,MatExpansionModule,
        MatTableModule, MatTabsModule,MatRadioModule,
        MatAutocompleteModule,CircleTimerModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        AppStoreModule,
        RegisterModule,
        Login2Module,
        AppsModule,
        AnalyticsDashboardModule,
        ProfileModule,
        MatTooltipModule,
        EmpProfileModule, EmployeesProfileModule,
        Register2Module,
        RequestModule, 
        ForgotPassword2Module, 
        centerModule,
        MatSnackBarModule,
        MatDialogModule, MatCheckboxModule,
        MatProgressSpinnerModule, AgmCoreModule ,
        MatPaginatorModule,MatSortModule,
        NgxBarcodeModule,
        MatSlideToggleModule,
        MatCarouselModule.forRoot(),
    ],
    
    providers: [AuthGuardService, DatePipe, SnackbarService, LoaderService,RegisteredListComponent, CentersComponent, EmployeeListComponent,
        GeocodeService, FileManagerService,
        { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
