<div id="register" fxLayout="column">
    <div
        id="register-form-wrapper"
        fusePerfectScrollbar
        [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }"
    >
        <div
            id="register-form"
            fxLayout="column"
            [@animate]="{
                value: '*',
                params: { duration: '300ms', y: '100px' }
            }"
        >
            <!-- <div class="logo">
            <img src="assets/images/logos/logo.png">
        </div> -->
            <div class="title">CREATE AN ACCOUNT</div>

            <form name="registerForm" [formGroup]="registerForm" novalidate>
                <mat-form-field appearance="fill">
                    <mat-label>Organization Name</mat-label>
                    <input
                        matInput
                        formControlName="organizationName"
                        [(ngModel)]="empObject.OrgName"
                    />
                    <mat-icon matSuffix class="secondary-text"
                        >account_circle</mat-icon
                    >
                    <mat-error> Organization Name is required </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Email</mat-label>
                    <input
                        matInput
                        formControlName="email"
                        pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                        type="email"
                        [(ngModel)]="empObject.emailAddress"
                    />
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error
                        *ngIf="registerForm.get('email').hasError('required')"
                    >
                        Email is required
                    </mat-error>
                    <mat-error
                        *ngIf="registerForm.get('email').hasError('email')"
                    >
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Contact Number</mat-label>
                    <input
                        matInput
                        formControlName="Cnumber"
                        maxlength="10"
                        [(ngModel)]="empObject.mobileno"
                        (keydown)="validateNumber($event)"
                    />
                    <mat-icon matSuffix class="secondary-text"
                        >phone_iphone</mat-icon
                    >
                    <mat-error> Contact Number is required </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Address</mat-label>
                    <textarea
                        matInput
                        formControlName="address"
                        [(ngModel)]="empObject.address"
                    >
                    </textarea>
                </mat-form-field>

                <div class="terms" fxLayout="row" fxLayoutAlign="center center">
                    <mat-checkbox
                        name="terms"
                        formControlName="acceptterms"
                        aria-label="Accept"
                        required
                    >
                        <span>Accept</span>
                    </mat-checkbox>
                    <a href="javascript::()" target="_blank"
                        >&nbsp;terms and conditions</a
                    >
                </div>

                <button
                    mat-raised-button
                    color="accent"
                    class="submit-button"
                    aria-label="Sign Up"
                    [disabled]="registerForm.invalid"
                    (click)="employerSubmit()"
                >
                    Sign Up
                </button>
            </form>

            <div
                class="register"
                fxLayout="column"
                fxLayoutAlign="center center"
            >
                <span class="text">Already have an account?</span>
                <a class="link" [routerLink]="'/auth/login'">Login</a>
            </div>
        </div>
    </div>
</div>
