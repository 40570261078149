<div id="file-list" class="page-layout simple">
    <div class="center">
        <div class="header accent p-24" fxLayout="row" fxLayoutAlign="space-between start">

            <div class="breadcrumb text-truncate h1" fxLayout="row" fxLayoutAlign="start center"
                [@animate]="{value:'*',params:{x:'50px'}}">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <span>Members List</span>
                </div>
            </div>

        </div>
    </div>
    <div class="content p-24">

        <div fxLayout="column" fxLayoutAlign="center">
            <fieldset>
                <legend>
                    <h3>Filters</h3>
                </legend>
                <div class="filters" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
                    fxLayoutAlign.gt-xs="start center">
                    <mat-form-field appearance="fill" floatLabel="always" fxFlex="33" class="course-search">
                        <mat-label>Search for a member</mat-label>
                        <input matInput placeholder="Enter a keyword..." [(ngModel)]="searchTerm" (input)="filteremployees()">
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="course-search mr-4" fxFlex="33">
                        <mat-label>Clients</mat-label>
                        <input type="text" placeholder="Pick one" aria-label="Number" matInput [formControl]="myControl2"
                            [matAutocomplete]="autoclient">
                        <mat-autocomplete autoActiveFirstOption #autoclient="matAutocomplete"
                            (optionSelected)="onClientSelectionChange($event)" [displayWith]="displayFn">
                            <mat-option *ngFor="let option2 of filteredOptions2 | async" [value]="option2">
                                {{option2.employeer_orgName}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                   
                </div>
            </fieldset>
            
            </div>
            <div class="load-more-div">
                <button mat-flat-button color="warn" style="margin-right: 10px;" *ngIf="IsSelected==1"  (click)="openprint_bulk()">
                    <mat-icon style="font-size:20px">print</mat-icon> Bulk Print
               </button>  
            <button mat-flat-button color="primary" *ngIf="IsSelected==1"  (click)="openreq_bulk()">
               Bulk Raise Request
            </button> </div> 

        <div fxLayout="column" fxLayoutAlign="center">
            <div class="table-responsive">
                <table width="100%" mat-table [dataSource]="dataSource" class="simple invoice-table">
 <!-- Checkbox Column -->
 <ng-container matColumnDef="emp_select">
    <th class="accent1 mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="empall_valueChange($event)">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row;let i = index">
      <mat-checkbox  
       [name]="row.employeeCode" [ngModelOptions]="{standalone: true}"
      (change)="emp_valueChange(i,$event)" [id]="row.employeeCode" [(ngModel)]="row.emp_select"
                     >
      </mat-checkbox>
    </td>
  </ng-container>

                    <!-- Type Column -->
                    <ng-container matColumnDef="photograph">
                        <th class="accent1 mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let row" style="width: 64px;"> 
                            <img class="photo" src="{{row.photograph}}" />
                        </td>
                    </ng-container>
                    <!-- Name Column -->
                    <ng-container matColumnDef="name">
                        <th class="accent1 mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef>Name</th>
                        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                    </ng-container>
                    <!-- Owner Column -->
                    <ng-container matColumnDef="employeeId">
                        <th class="accent1 mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef>Member ID</th>
                        <td mat-cell *matCellDef="let row"> <a
                                [routerLink]="['/employee-edit/',row.employeeCode ]"
                                routerLinkActive="router-link-active">{{row.employeeCode}}</a> </td>
                    </ng-container>
                    <!-- Name Column -->
                    <ng-container matColumnDef="mobileNo">
                        <th class="accent1 mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef>Mobile Number</th>
                        <td mat-cell *matCellDef="let row"> {{row.mobileNo}} </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th class="accent1 mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef>Latest Status</th>
                        <td mat-cell *matCellDef="let row"> {{row.latestStatus}} </td>
 
                    </ng-container>
                    <ng-container matColumnDef="lastUpdated">
                        <th class="accent1 mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef>Last Updated</th>
                        <td mat-cell *matCellDef="let row"> {{row.lastUpdated}} </td> 
                    </ng-container>
                    <!-- Type Column -->
                    <ng-container matColumnDef="emailAddress">
                        <th class="accent1 mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef>Email Address</th>
                        <td mat-cell *matCellDef="let row">  {{row.emailAddress}} </td>
                    </ng-container>



                    <!-- Size Column -->
                    <!-- <ng-container matColumnDef="noOfDoses">
                        <th class="accent1 mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef>No. of Doses</th>
                            <td mat-cell *matCellDef="let row"> 
                            <button mat-mini-fab style="margin-left:10px" color="{{row.doseColor}}">
                                {{row.noOfDoses}}
                            </button>
                        </td>
                    </ng-container> -->
                    <ng-container matColumnDef="noOfRequests">
                        <th class="accent1 mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef>No. of Requests</th>
                        <td mat-cell *matCellDef="let row"> 
                            <button mat-mini-fab style="margin-left:10px">
                                {{row.noOfRequests}}
                            </button>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="raiseReq">
                        <th class="accent1 mat-header-row bdr-radius" mat-header-cell *matHeaderCellDef>Action</th>
                        <td mat-cell *matCellDef="let row"> 
                            <button mat-flat-button color="primary" (click)="openreq(row.employeeId)">
                                Raise Request
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"  
                    [ngClass]="{'selected' : row == selected}" [@animate]="{value:'*',params:{y:'100%'}}"></tr>
                     
                </table>
<div class="load-more-div">
                <button mat-flat-button color="warn" *ngIf="norecords==1"  (click)="LoadMoreRecords()">
                    Load More
                </button></div>
            </div>
            <div class="load-more-div space-between">
                <button mat-flat-button color="warn" style="margin-right: 10px;" *ngIf="IsSelected==1"  (click)="openprint_bulk()">
                    <mat-icon style="font-size:20px">print</mat-icon> Bulk Print
               </button>  
            <button mat-flat-button color="primary" *ngIf="IsSelected==1"  (click)="openreq_bulk()">
               Bulk Raise Request
            </button> </div> 
        </div>
    </div>
</div>
<ng-template #openreqpop>

    <div id="register-form" *ngIf="viewHide==1">
        <!--Testing Symptons for only Retest -->

        <div class="title">Are you currently experiencing any of these symptoms? </div>
        <form name="symptonsForm" [formGroup]="symptonsForm" novalidate>

            <ul class="list">
                <li *ngFor="let sympton of symptons;let i=index">{{sympton.symptoms_Name}} <mat-checkbox
                        [name]="sympton.symptoms_id" [ngModelOptions]="{standalone: true}"
                        (change)="valueChange(i,$event)" [id]="sympton.symptoms_id" [(ngModel)]="sympton.checked"
                        class="list-chk"></mat-checkbox>
                </li>
            </ul>

            <div class="fix-btn">
                <label class="emp-count">{{empCount}}</label>
                <button mat-raised-button color="warn" class="submit-button" aria-label="Sign Up"
                    (click)="closewindow()">
                    Cancel
                </button>
                <button mat-raised-button color="primary" class="submit-button" aria-label="Sign Up"
                    [disabled]="IsSymptonSelected()" (click)="nextview(2,0)">
                    Next
                </button>
            </div>

        </form>
    </div>
    <div id="register-form" *ngIf="viewHide==2">
        <!--Testing Symptons for only Retest -->

        <div class="title">Please choose Covid-19 test type </div>
        <form class="flx-dir" name="symptonsForm" [formGroup]="symptonsForm" novalidate>
            <div class="rapid">
                <div (click)="SelectedTestCenter(testresult)"
                    [ngClass]="(this.empObject.SelectedTest!='' 
        && this.empObject.SelectedTest!=null && this.empObject.SelectedTest==testresult.test_code) ? 'shadow rounded-2xl col-md-12 card-in cardselected' : 'shadow rounded-2xl col-md-12 card-in' "
                    *ngFor="let testresult of TestList ">
                    <div class="text-lgr">{{testresult.test_name}}

                        <span>{{testresult.test_price}} </span>

                        <span>{{testresult.test_description}}

                        </span>
                    </div>
                </div>
            </div> 
            <mat-form-field appearance="fill"   fxFlex="100">
                <mat-label>Location</mat-label>
                <!-- <input type="text" placeholder="Pick one"   aria-label="Number" matInput [formControl]="myControl1"
                [matAutocomplete]="autoclient">
            <mat-autocomplete autoActiveFirstOption #autoclient="matAutocomplete"
                (optionSelected)="SelectedLocationCenter($event)" [displayWith]="displayClientFn">
                <mat-option *ngFor="let option2 of filteredOptions1 | async" [value]="option2">
                    {{option2.location_propertyName}}
                </mat-option>
            </mat-autocomplete>         -->
                <mat-select formControlName="location_Id" (selectionChange)="SelectedLocationCenter($event)"
                    [(ngModel)]="empObject.location_Id" required>
                    <mat-option *ngFor="let user of usersData" [value]="user.location_Id">
                        {{user.location_propertyName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="fix-btn">
                <button mat-raised-button color="warn" class="submit-button" aria-label="Sign Up"
                    (click)="nextview(1,0)">
                    Back
                </button>
                <button mat-raised-button color="primary" class="submit-button" aria-label="Sign Up" [disabled]="(this.empObject.SelectedTest=='' || this.empObject.SelectedTest==undefined ||
                this.empObject.SelectedTest==null)" (click)="submitreq()">
                    Submit
                </button>

            </div>

        </form>
    </div>
</ng-template>