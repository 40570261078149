//import { siteVerification_v1 } from "googleapis"


export class UserModel {
    public usersUserid: number = 0;
    public usersFullname: string="";
    public usersUsername: string="";
    public usersEmailaddress: string="";
    public usersPassword: string="";
    public usersLanguage:number = 0;
    public usersIsstatus: number=0;
    public usersMobile: string="";
    public userCountryId:number=0;
    public userStateId:number=0;
    public userCityId:number=0;
    public userRoleId:number=0;
    public userProfilePic: string="";
    public ImageFileObj:File;
 }
 
 export class ChkLogin {
   
     public password: string = "";
     public username: string = "";
 };
 
 
 export class ForgotPasswordModel{
     public forgot_UserEmailid:string="";
 }
 
 
 
