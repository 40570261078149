<div id="register" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">


    <div id="register-form-wrapper" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">
       
        <div id="register-form" *ngIf="viewHide==1">
            <div class="logo">
                <img src="{{this.clientLogo}}">
            </div>
            <div class="title">Register Member</div>
            <form fxLayout="column" fxLayoutAlign="start" name="registerForm" [formGroup]="registerForm">

                <mat-form-field appearance="fill" fxFlex="100" class="pr-4">
                    <mat-label>First name</mat-label>
                    <input matInput formControlName="firstName" [(ngModel)]="empObject.firstName" required>
                    <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    <mat-error>First Name is required!</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" fxFlex="100" class="pr-4">
                    <mat-label>Last name</mat-label>
                    <input matInput formControlName="lastName" [(ngModel)]="empObject.lastName" required>
                    <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    <mat-error>Last Name is required!</mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" fxFlex="100" class="pr-4">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" [(ngModel)]="empObject.emailAddress" required>
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error>
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill" fxFlex="100" class="pr-4">
                    <mat-label>Confirm Email</mat-label>
                    <input matInput formControlName="CemailAddress" [(ngModel)]="empObject.CemailAddress" required>
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error>
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="100" class="pr-4">
                    <mat-label>Mobile Number</mat-label>
                    <input matInput type="number"
                        onKeyDown="if(this.value.length==10 && event.keyCode!=8) return false;"
                        formControlName="mobileNumber" maxlength="10" [(ngModel)]="empObject.mobileNumber" required>
                    <mat-icon matSuffix class="secondary-text">phone_iphone</mat-icon>
                    <mat-error>Mobile Number required!</mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlex="100" class="pr-4">
                    <mat-label>Date of Birth </mat-label>
                    <input matInput [min]="minDate" required placeholder="MM/DD/YYYY" [max]="maxDate"
                        [matDatepicker]="picker" [(ngModel)]="birthDate" formControlName="DOB">
                    <mat-datepicker-toggle matSuffix [for]="picker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                    <!-- <mat-icon matSuffix class="secondary-text">date_range</mat-icon>  -->
                    <mat-error>DOB required!</mat-error>
                </mat-form-field>


                <mat-form-field appearance="fill" fxFlex="100" class="pr-4">
                    <mat-label>Sex</mat-label>
                    <mat-select formControlName="gender" required [(ngModel)]="empObject.gender">
                        <mat-option [value]="'M'">
                            Male
                        </mat-option>
                        <mat-option [value]="'F'">
                            Female
                        </mat-option>
                        <mat-option [value]="'U'">
                            Undisclosed
                        </mat-option>
                        <mat-option [value]="'O'">
                            Others
                        </mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text">group</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Ethnicity</mat-label>
                    <mat-select formControlName="Ethnicity" required [(ngModel)]="empObject.Ethnicity">
                        <mat-option [value]="'Hispanic/Latino'">
                            Hispanic/Latino
                        </mat-option>
                        <mat-option [value]="'Not Hispanic/Latino'">
                            Not Hispanic / Latino
                        </mat-option>
                        <mat-option [value]="'Undisclosed'">
                            Undisclosed
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        Ethnicity is required
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Race</mat-label>
                    <mat-select formControlName="Race" required [(ngModel)]="empObject.Race">
                        <mat-option [value]="'White'">
                            White
                        </mat-option>
                        <mat-option [value]="'Asian'">
                            Asian
                        </mat-option>
                        <mat-option [value]="'American Indian/Native Alaskan'">
                            American Indian / Native Alaskan
                        </mat-option>
                        <mat-option [value]="'Black/African American'">
                            Black / African American
                        </mat-option>
                        <mat-option [value]="'Native Hawaiian/Pacific Islander '">
                            Native Hawaiian / Pacific Islander
                        </mat-option>
                        <mat-option [value]="'Undisclosed'">
                            Undisclosed
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        Race is required
                    </mat-error>
                </mat-form-field>
                <button mat-raised-button color="accent" class="submit-button" aria-label="SubmitRequest"
                    [disabled]="registerForm.invalid" (click)="showNextView(3,0)">
                    Next
                </button>
            </form>
        </div>
        <div id="register-form" *ngIf="viewHide==3">
            <a class="back_button" href="javacript::" aria-label="Sign Up" (click)="showNextView(1,1)">
                <mat-icon class="back-icon">arrow_back</mat-icon> BACK
                </a>
                <div class="title pt-50">Address</div>
                <form name="frmPatientAddress" [formGroup]="frmPatientAddress" novalidate>
                    <mat-form-field appearance="fill">
                        <mat-label>Country</mat-label>
                        <mat-select formControlName="country" (selectionChange)="changeCountry($event,2)"
                            [(ngModel)]="empObject.country" required>
                            <mat-option *ngFor="let country of _countries" [value]="country.country_id">
                                {{country.country_name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Street</mat-label>
                        <input matInput formControlName="street" required [(ngModel)]="empObject.street"
                            id="txtstreetsearch" (keydown.enter)="$event.preventDefault()" autocorrect="off"
                            autocapitalize="off" spellcheck="off" type="text" #streetaddress>

                        <mat-error>
                            Street is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Zip/Postal Code</mat-label>
                        <input matInput [(ngModel)]="empObject.postalCode" required formControlName="postalCode">
                        <mat-error>
                            Zip/Postal Code is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>City</mat-label>
                        <input matInput [(ngModel)]="empObject.City" required formControlName="city">
                        <mat-error>
                            City is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>State</mat-label>
                        <mat-select formControlName="state" required [(ngModel)]="empObject.state">
                            <mat-option *ngFor="let state of _states" [value]="state.state_id">
                                {{state.state_name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>


                    <div class="fix-btn">
                        <button mat-raised-button color="accent" class="submit-button" aria-label="Sign Up"
                            [disabled]="frmPatientAddress.invalid" (click)="showNextView(2,0)">
                            NEXT
                        </button>
                    </div>

                </form>
        </div>
        <div id="register-form" *ngIf="viewHide==2">
            <a class="back_button" href="javacript::" aria-label="Sign Up" (click)="showNextView(3,1)">
                <mat-icon class="back-icon">arrow_back</mat-icon> BACK
            </a>

            <form fxLayout="column" class="pt-50" fxLayoutAlign="start" fxFlex="1 0 auto" name="registerForm2"
                [formGroup]="registerForm2">

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="fill" fxFlex="100" class="pr-4">
                        <mat-label>Do you have Insurance? </mat-label>
                        <mat-select formControlName="_insurance" (selectionChange)="EnabledInsuranceFields($event)" required
                            [(ngModel)]="empObject.IsInsurance">
                            <mat-option [value]="1">
                                Yes
                            </mat-option>
                            <mat-option [value]="0">
                                No
                            </mat-option>
                        </mat-select> 
                    </mat-form-field>

                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="empObject.IsInsurance==1">

                    <mat-form-field appearance="fill" fxFlex="100" class="pr-4">
                        <mat-label>Insurance Name </mat-label>
                        <input type="text" placeholder="Pick one" aria-label="Number" matInput
                            [(ngModel)]="empObject.InsuranceName" [formControl]="myControl" [matAutocomplete]="auto">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                            (optionSelected)="onSelectionChange($event)" [displayWith]="displayFn">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                {{option.insurance_Name}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-icon matSuffix class="secondary-text">file_copy</mat-icon>
                    </mat-form-field>
                </div>
                <mat-form-field appearance="fill" *ngIf="empObject.InsuranceName!=undefined &&
                empObject.InsuranceName!='' && empObject.InsuranceName.insurance_id==103">
                    <mat-label>Insurance Name</mat-label>
                    <input matInput [(ngModel)]="empObject.InsuranceOName" formControlName="InsuranceOName">
                    <mat-error>
                        Insurance Name is required
                    </mat-error>
                </mat-form-field>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="empObject.IsInsurance==1">
                    <mat-form-field appearance="fill" fxFlex="100" class="pr-4">
                        <mat-label>Policy ID</mat-label>
                        <input matInput formControlName="PolicyID" [(ngModel)]="empObject.PolicyID">
                        <mat-icon matSuffix class="secondary-text">featured_play_list</mat-icon>
                    </mat-form-field>

                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="empObject.IsInsurance==1">
                    <mat-form-field appearance="fill" fxFlex="100" class="pr-4">
                        <mat-label>Group</mat-label>
                        <input matInput formControlName="Group" [(ngModel)]="empObject.Group"
                            [readonly]="empObject.IsInsurance==0">
                        <mat-icon matSuffix class="secondary-text">group</mat-icon>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="empObject.IsInsurance==1">
                    <mat-form-field appearance="fill" fxFlex="100" class="pr-4">
                        <mat-label>Subscriber First Name</mat-label>
                        <input matInput formControlName="SubScriberFName" [(ngModel)]="empObject.SubScriberFName"
                            [readonly]="empObject.IsInsurance==0">
                        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    </mat-form-field>

                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="empObject.IsInsurance==1">

                    <mat-form-field appearance="fill" fxFlex="100" class="pr-4">
                        <mat-label>Subscriber Last Name</mat-label>
                        <input matInput formControlName="SubScriberLName" [(ngModel)]="empObject.SubScriberLName"
                            [readonly]="empObject.IsInsurance==0">
                        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    </mat-form-field>

                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="empObject.IsInsurance==1">

                    <mat-form-field appearance="fill" fxFlex="100" class="pr-4">
                        <mat-label>Subscriber Relationship</mat-label>
                        <mat-select formControlName="Relationship" [(ngModel)]="empObject.Relationship"
                            [disabled]="empObject.IsInsurance==0">
                            <mat-option [value]="'Self'">
                                Self
                            </mat-option>
                            <mat-option [value]="'Spouse'">
                                Spouse
                            </mat-option>
                            <mat-option [value]="'Child'">
                                Child
                            </mat-option>
                            <mat-option [value]="'Other'">
                                Other
                            </mat-option>
                        </mat-select>
                        <mat-icon matSuffix class="secondary-text">featured_play_list</mat-icon>
                    </mat-form-field>
                </div>

                <mat-form-field appearance="fill">
                    <mat-label>Document Type</mat-label>
                    <mat-select formControlName="DocumentType" [(ngModel)]="empObject.DocumentType">
                        <mat-option [value]="'0'">
                            Drivers Licence
                        </mat-option>
                        <mat-option [value]="'1'">
                            Passport
                        </mat-option>
                        <mat-option [value]="'2'">
                            State ID
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        DocumentType is required
                    </mat-error>
                </mat-form-field>
                <div class="file-uploader">
                    <input hidden type="file" formControlName="document" #fileInput accept="image/*"
                        (change)="onFileChanged($event)" />
                    <button mat-raised-button class="add-file-button" (click)="fileInput.click()"
                        aria-label="UPLOAD FILE" [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
                        <mat-icon>cloud_upload</mat-icon>
                        UPLOAD FILE <br />
                        (only images allowed upto 12 MB)
                    </button>
                    <span *ngIf="selectedFileName">{{selectedFileName}}</span>
                </div>
                <div class="sig_pad">
                    <label>Signature</label>
                     <canvas #canvas width="300" height="100"></canvas>
                     <button
                     class="clear_button" 
                     aria-label="Clear"
                     (click)="clear_signature()">
                     Clear </button>
                     <button
                     class="upload_sig_button" 
                     aria-label="Upload"
                     (click)="saveSignPad()">
                     Upload </button>
                     <div class="progress" *ngIf="progress">
                         <div
                             class="progress-bar"
                             [style.width]="progress + '%'"
                         >
                             {{ progress }}%
                         </div>
                     </div>
                 </div>
                 
                <div class="terms" fxLayout="row" fxLayoutAlign="center center">
                    <mat-checkbox name="terms" aria-label="I read and accept" required formControlName="acceptterms"
                        [(ngModel)]="empObject.acceptTerms">
                        <span>I accept</span>
                    </mat-checkbox>
                    <a href="https://Clinify.io/">terms and conditions</a>
                </div>
                <button mat-raised-button color="accent" class="submit-button" aria-label="SUBMIT"
                    [disabled]="registerForm2.invalid" (click)="saveEmpDetails()">
                    <mat-icon>check_circle</mat-icon> SUBMIT
                </button>

            </form>
        </div>
    </div>

</div>