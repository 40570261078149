<div id="register" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">
    <div
        id="register-form-wrapper"
        fusePerfectScrollbar
        [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }"
    >
        <div id="register-form" *ngIf="viewHide == 20">
            <div class="logo">
                <img src="assets/images/logos/logo.png" />
            </div>
            <div class="logo" style="width: 300px">
                <img src="assets/images/Registration-Closed.png" />
            </div>
        </div>
        <div id="register-form" *ngIf="viewHide == 0">
            <div class="logo">
                <img src="{{ locationImage }}" />
            </div>

            <div class="title p-t-15">
                Get started by entering your cell phone number.
            </div>
            <p>
                We'll use this number to communicate with you and give you
                access to your records.
            </p>
            <form
                name="registerForm"
                class="pos-rel"
                [formGroup]="frmValidate"
                novalidate
            >
                <!-- <mat-form-field appearance="fill">
                    <mat-label>Contact Number</mat-label>
                    <input matInput formControlName="Cnumber" type="number" maxlength="10"
                        [(ngModel)]="empObject.mobileno"
                        onKeyDown="if(this.value.length==10 && event.keyCode!=8) return false;">
                    <mat-icon matSuffix class="secondary-text">phone_iphone</mat-icon> 
                    <mat-error>
                        Contact Number is required
                    </mat-error>
                </mat-form-field>   -->
                <div class="tel-input">
                    <fieldset>
                        <legend>Contact Number</legend>
                        <ngx-intl-tel-input
                            matinput
                            [cssClass]="'custom'"
                            [(ngModel)]="empObject.PhoneInput"
                            [preferredCountries]="preferredCountries"
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="true"
                            [searchCountryFlag]="true"
                            [searchCountryField]="[
                                SearchCountryField.Iso2,
                                SearchCountryField.Name
                            ]"
                            [selectFirstCountry]="false"
                            [selectedCountryISO]="CountryISO.UnitedStates"
                            [maxLength]="10"
                            [phoneValidation]="true"
                            [separateDialCode]="true"
                            [numberFormat]="PhoneNumberFormat.National"
                            name="phone"
                            formControlName="Cnumber"
                        >
                        </ngx-intl-tel-input>
                    </fieldset>
                </div>
                <mat-form-field
                    appearance="fill"
                    class="width-300 mr-t-10"
                    *ngIf="!this.empObject.IsEnableEmailVerification"
                >
                    <mat-label>Email Address</mat-label>
                    <input
                        matInput
                        formControlName="VEmail"
                        type="text"
                        [(ngModel)]="empObject.vEmailAddress"
                    />
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error> Email Address is required </mat-error>
                </mat-form-field>

                <div class="terms" fxLayout="row">
                    <section class="example-section">
                        <mat-slide-toggle
                            class="example-margin"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="Slidechanged($event)"
                            [checked]="this.empObject.IsEnableEmailVerification"
                            [(ngModel)]="
                                this.empObject.IsEnableEmailVerification
                            "
                            [color]="color"
                        >
                            Can this phone accept SMS?
                        </mat-slide-toggle>
                    </section>
                </div>

                <!-- <div class="terms" fxLayout="row" fxLayoutAlign="center center">
                    <mat-checkbox name="terms" formControlName="acceptterms" aria-label="Accept" required>
                        <span>Accept</span>
                    </mat-checkbox>
                    <a href="https://centerforcovidcontrol.org/about/" target="_blank">terms and conditions</a>
                </div> [disabled]="frmValidate.invalid"-->

                <button
                    mat-raised-button
                    color="accent"
                    class="submit-button"
                    aria-label="Sign Up"
                    [disabled]="
                        this.empObject.PhoneInput == undefined ||
                        this.empObject.PhoneInput == null ||
                        this.empObject.PhoneInput.number.replace(' ', '')
                            .length < 10
                    "
                    (click)="showNextView(0, 0)"
                >
                    Send Code
                </button>
            </form>
            <div class="separator">
                <span class="text warn">OR</span>
            </div>
            <div
                class="register"
                fxLayout="column"
                fxLayoutAlign="center center"
            >
                <button
                    mat-raised-button
                    color="accent"
                    class="submit-button"
                    aria-label="SubmitRequest"
                    (click)="showNextView(12, 0)"
                >
                    Track Request
                </button>
            </div>
            <div class="poweredby">
                <span
                    >Technology Powered by<br />
                    <b class="cl-primary">Clinify</b></span
                >
            </div>
        </div>
        <div id="register-form" *ngIf="viewHide == 12">
            <a
                class="back_button"
                href="javacript::"
                aria-label="Sign Up"
                (click)="showNextView(0, 1)"
            >
                <mat-icon class="back-icon">arrow_back</mat-icon> BACK
            </a>
            <!-- <div class="logo">
                <img src="assets/images/logos/logo.png">
            </div> -->

            <div class="title">Please enter confirmation code.</div>

            <form
                name="frmTrackRequest"
                [formGroup]="frmTrackRequest"
                novalidate
            >
                <mat-form-field appearance="fill">
                    <mat-label>Confirmation Code</mat-label>
                    <input
                        matInput
                        formControlName="ConfirmationCode"
                        [(ngModel)]="empObject.confirmcode"
                    />
                    <mat-icon matSuffix class="secondary-text">code</mat-icon>
                    <mat-error> Confirmation Code is required </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Date of Birth</mat-label>
                    <input
                        matInput
                        [min]="minDate"
                        placeholder="MM/DD/YYYY"
                        [max]="maxDate"
                        [matDatepicker]="picker"
                        [(ngModel)]="empObject.TDOB"
                        formControlName="TDateOfBirth"
                    />
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>

                    <mat-error> Date of Birth is required </mat-error>
                </mat-form-field>
                <button
                    mat-raised-button
                    color="accent"
                    class="submit-button"
                    aria-label="SubmitRequest"
                    [disabled]="frmTrackRequest.invalid"
                    (click)="showNextView(13, 0)"
                >
                    Get Results
                </button>
            </form>
            <div class="poweredby">
                <span
                    >Technology Powered by<br />
                    <b class="cl-primary">Clinify</b></span
                >
            </div>
        </div>
        <div id="register-form" *ngIf="viewHide == 13">
            <a
                class="back_button"
                href="javacript::"
                aria-label="Sign Up"
                (click)="showNextView(0, 1)"
            >
                <mat-icon class="back-icon">arrow_back</mat-icon> BACK
            </a>
            <div class="logo">
                <img src="assets/images/logos/logo.png" />
            </div>
            <div class="title">Request ID : {{ empObject.confirmcode }}</div>
            <table width="100%" class="simple invoice-table">
                <tbody>
                    <tr class="mat-header-row">
                        <td>Request Date</td>
                        <td>
                            {{ TrackResult.createdDate | date : "MM/dd/yyyy" }}
                        </td>
                    </tr>
                    <tr class="mat-header-row">
                        <td>Request ID</td>
                        <td>{{ TrackResult.referenceNo }}</td>
                    </tr>
                    <tr class="mat-header-row">
                        <td>Full Name</td>
                        <td>{{ TrackResult.name }}</td>
                    </tr>
                    <tr class="mat-header-row">
                        <td>Mobile No.</td>
                        <td>{{ TrackResult.mobileNo }}</td>
                    </tr>
                    <tr class="mat-header-row">
                        <td>Email Address</td>
                        <td>{{ TrackResult.emailAddress }}</td>
                    </tr>
                    <tr class="mat-header-row">
                        <td>Symptoms</td>
                        <td>{{ TrackResult.selectedsymptons }}</td>
                    </tr>
                    <tr class="mat-header-row">
                        <td>Test</td>
                        <td>{{ TrackResult.selectedTest }}</td>
                    </tr>
                    <tr class="mat-header-row">
                        <td>Test Result</td>
                        <td>{{ TrackResult.testResult }}</td>
                    </tr>
                    <tr
                        class="mat-header-row"
                        *ngIf="
                            TrackResult.testResult == 'Detected' ||
                            TrackResult.testResult == 'Not Detected' ||
                            TrackResult.testResult == 'Inconclusive'
                        "
                    >
                        <td></td>
                        <td>
                            <a target="_blank" href="{{ TrackResult.filepath }}"
                                >Download pdf</a
                            >
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div id="register-form" fxLayout="column" *ngIf="viewHide == 1">
            <div class="logo">
                <img src="{{ locationImage }}" />
            </div>

            <div class="title">ENTER VERIFICATION CODE</div>

            <form
                name="frmVerification"
                fxLayout="column"
                fxLayoutAlign="start"
                fxFlex="1 0 auto"
                [formGroup]="frmVerification"
                novalidate
            >
                <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-form-field appearance="fill" fxFlex="25" class="mr-4">
                        <input
                            matInput
                            autofocus
                            type="number"
                            onPaste="return false"
                            formControlName="verifycode1"
                            class="Otpclass"
                            [maxLength]="1"
                            #verifycode1
                            onKeyDown="if(this.value.length==1 && event.keyCode!=8) return false;"
                            (keyup)="keytab($event, 1)"
                            [(ngModel)]="empObject.verifycode1"
                        />
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex="25" class="mr-4">
                        <input
                            matInput
                            formControlName="verifycode2"
                            type="number"
                            onPaste="return false"
                            class="Otpclass"
                            [maxLength]="1"
                            #verifycode2
                            onKeyDown="if(this.value.length==1 && event.keyCode!=8) return false;"
                            (keyup)="keytab($event, 2)"
                            [(ngModel)]="empObject.verifycode2"
                        />
                    </mat-form-field>

                    <mat-form-field appearance="fill" fxFlex="25" class="mr-4">
                        <input
                            matInput
                            formControlName="verifycode3"
                            type="number"
                            onPaste="return false"
                            class="Otpclass"
                            maxlength="1"
                            #verifycode3
                            onKeyDown="if(this.value.length==1 && event.keyCode!=8) return false;"
                            (keyup)="keytab($event, 3)"
                            [(ngModel)]="empObject.verifycode3"
                        />
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex="25" class="mr-4">
                        <input
                            matInput
                            formControlName="verifycode4"
                            type="number"
                            onPaste="return false"
                            class="Otpclass"
                            maxlength="1"
                            #verifycode4
                            onKeyDown="if(this.value.length==1 && event.keyCode!=8) return false;"
                            [(ngModel)]="empObject.verifycode4"
                        />
                    </mat-form-field>
                </div>

                <div class="terms" fxLayout="row" fxLayoutAlign="center center">
                    <a href="javascript::" (click)="ResendCode()"
                        >Request Another Code</a
                    >
                </div>
                <!-- <div class="terms1" fxLayout="row" fxLayoutAlign="center center">

                    <a href="javascript::" (click)="showNextView(14,0)">Haven't received a code yet? Click here</a>
                </div> -->

                <button
                    mat-raised-button
                    color="accent"
                    class="submit-button mr-t-30"
                    aria-label="Sign Up"
                    [disabled]="frmVerification.invalid"
                    (click)="showNextView(1, 0)"
                >
                    VERIFY
                </button>
            </form>
            <div class="poweredby mr-30">
                <span
                    >Technology Powered by<br />
                    <b class="cl-primary">Clinify</b></span
                >
            </div>
        </div>
        <div id="register-form" *ngIf="viewHide == 14">
            <a
                class="back_button"
                href="javacript::"
                aria-label="Sign Up"
                (click)="showNextView(0, 1)"
            >
                <mat-icon class="back-icon">arrow_back</mat-icon> BACK </a
            ><label class="stepper">5/6 Steps</label>
            <div class="logo">
                <img src="assets/images/logos/logo.png" />
            </div>
            <form name="frmtest" [formGroup]="frmtest" novalidate>
                <div class="title">
                    <mat-icon>warning</mat-icon>Proceed without verification you
                    may not be able to retrieve results at a later time without
                    a valid phone number
                </div>
                <button
                    mat-raised-button
                    color="accent"
                    class="submit-button mr-t-30"
                    aria-label="Sign Up"
                    (click)="showNextView(2, 0)"
                >
                    Proceed
                </button>
            </form>
        </div>

        <div id="register-form" *ngIf="viewHide == 2">
            <div class="title">Are you a US Resident</div>

            <form name="registerForm" [formGroup]="registerForm" novalidate>
                <div
                    (click)="selectCard(2)"
                    [ngClass]="
                        this.empObject.IsUSresident != '' &&
                        this.empObject.IsUSresident == 1
                            ? 'shadow rounded-2xl col-md-6 card-in cardselected'
                            : 'shadow rounded-2xl col-md-6 card-in'
                    "
                >
                    <div class="text-lg">
                        <mat-icon class="gr">check_circle</mat-icon> YES
                    </div>
                </div>
                <div
                    (click)="selectCard(1)"
                    [ngClass]="
                        this.empObject.IsUSresident != '' &&
                        this.empObject.IsUSresident == 0
                            ? 'shadow rounded-2xl col-md-6 card-in cardselected'
                            : 'shadow rounded-2xl col-md-6 card-in'
                    "
                >
                    <div class="text-lg">
                        <mat-icon class="re">cancel</mat-icon> NO
                    </div>
                </div>

                <button
                    mat-raised-button
                    color="accent"
                    class="submit-button mr-t-30"
                    aria-label="Sign Up"
                    [disabled]="
                        this.empObject.IsUSresident == '' ||
                        this.empObject.IsUSresident == undefined ||
                        this.empObject.IsUSresident == null
                    "
                    (click)="showNextView(2, 0)"
                >
                    NEXT
                </button>
            </form>
        </div>

        <div id="register-form" class="pos-un" *ngIf="viewHide == 3">
            <div class="title">Who is this for?</div>

            <form name="registerForm" [formGroup]="registerForm" novalidate>
                <div
                    (click)="selectCard(3)"
                    [ngClass]="
                        this.empObject.IsForSelf != '' &&
                        this.empObject.IsForSelf == 1
                            ? 'shadow rounded-2xl col-md-6 card-in cardselected'
                            : 'shadow rounded-2xl col-md-6 card-in'
                    "
                >
                    <div class="text-lg">FOR ME</div>
                </div>
                <div
                    (click)="selectCard(4)"
                    [ngClass]="
                        this.empObject.IsForSelf != '' &&
                        this.empObject.IsForSelf == 0
                            ? 'shadow rounded-2xl col-md-6 card-in cardselected'
                            : 'shadow rounded-2xl col-md-6 card-in'
                    "
                >
                    <div class="text-lg font-14">FOR SOMEONE ELSE</div>
                </div>
                <div
                    *ngIf="_previousHistory.length > 0 && !hideHistory"
                    class="title dis-cen"
                >
                    Previous History
                    <mat-icon (click)="closeHistory()" class="close_history"
                        >close</mat-icon
                    >
                </div>
                <div
                    *ngIf="_previousHistory.length > 0 && !hideHistory"
                    style="
                        height: 300px;
                        overflow: auto;
                        width: 100%;
                        margin-top: 15px;
                        display: inline-block;
                    "
                >
                    <div
                        class="shadow rounded-2xl col-md-12 card-in txt-left mb-bt-5"
                        *ngFor="let prevlist of _previousHistory"
                    >
                        <div class="text-prev-lgr">
                            <label class="name">
                                Name : {{ prevlist.name }}</label
                            >
                            <span>Service: {{ prevlist.selectedTest }} </span>
                            <span
                                [ngClass]="
                                    prevlist.status == 'Inconclusive'
                                        ? 'orange-100-bg'
                                        : prevlist.status == 'Detected'
                                        ? 'red-100-bg'
                                        : 'green-100-bg'
                                "
                                ><strong>Status: {{ prevlist.status }} </strong>
                            </span>
                            <span class="wid-100"
                                >Requested Date: {{ prevlist.requestDate }}
                            </span>

                            <button
                                mat-raised-button
                                class="btn-grey submit-button mr-t-30 mr-auto"
                                (click)="
                                    ReTest(
                                        prevlist.referenceNo,
                                        prevlist.testCode
                                    )
                                "
                                aria-label="re-test"
                            >
                                Re-Test
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="_previousHistory.length == 0 && !hideHistory">
                    <span class="his"
                        >Loading previous history <br /><img
                            src="../../../../../assets/images/loading.gif"
                    /></span>
                </div>
                <div class="fix-btn">
                    <button
                        mat-raised-button
                        color="accent"
                        class="submit-button mr-t-30"
                        aria-label="Sign Up"
                        [disabled]="
                            this.empObject.IsForSelf == '' ||
                            this.empObject.IsForSelf == undefined ||
                            this.empObject.IsForSelf == null
                        "
                        (click)="showNextView(3, 0)"
                    >
                        NEXT
                    </button>
                </div>
            </form>
        </div>
        <div id="register-form" *ngIf="viewHide == 17">
            <a
                class="back_button"
                href="javacript::"
                aria-label="Sign Up"
                (click)="showNextView(3, 1)"
            >
                <mat-icon class="back-icon">arrow_back</mat-icon> BACK </a
            ><label class="stepper">1/3 Steps</label>
            <div class="title" *ngIf="this.insuranceOption != 3">
                Do you have Insurance?
            </div>
            <div class="title" *ngIf="this.insuranceOption == 3">
                Enter Insurance Information
            </div>
            <form name="registerForm" [formGroup]="registerForm" novalidate>
                <div
                    (click)="selectCard(7)"
                    *ngIf="this.insuranceOption != 3"
                    [ngClass]="
                        this.empObject.Insurance != '' &&
                        this.empObject.Insurance == 1
                            ? 'shadow rounded-2xl col-md-6 card-in cardselected'
                            : 'shadow rounded-2xl col-md-6 card-in'
                    "
                >
                    <div class="text-lg">
                        <mat-icon class="gr">check_circle</mat-icon> Yes
                    </div>
                </div>
                <div
                    (click)="selectCard(8)"
                    *ngIf="this.insuranceOption != 3"
                    [ngClass]="
                        this.empObject.Insurance != '' &&
                        this.empObject.Insurance == 0
                            ? 'shadow rounded-2xl col-md-6 card-in cardselected'
                            : 'shadow rounded-2xl col-md-6 card-in'
                    "
                >
                    <div class="text-lg">
                        <mat-icon class="re">cancel</mat-icon> NO
                    </div>
                </div>
                <span
                    *ngIf="
                        this.insuranceOption == 1 &&
                        this.empObject.Insurance == 0
                    "
                    class="shadow rounded-2xl col-md-12 card-in cardselected"
                >
                    Note: If you select "No" you will have to pay for the test
                </span>
                <form
                    name="frmInsuranceInfo"
                    class="p-b-60"
                    *ngIf="
                        this.empObject.Insurance != '' &&
                        this.empObject.Insurance == 1
                    "
                    [formGroup]="frmInsuranceInfo"
                    novalidate
                >
                    <mat-form-field appearance="fill">
                        <mat-label>Insurance</mat-label>
                        <!-- <mat-select formControlName="InsuranceName" [(ngModel)]="empObject.InsuranceName" required>
                            <mat-option *ngFor="let insurance of insuranceList" [value]="insurance.insurance_id">
                                {{insurance.insurance_Name}}
                            </mat-option>
                        </mat-select> -->
                        <input
                            type="text"
                            placeholder="Pick one"
                            aria-label="Number"
                            matInput
                            [(ngModel)]="empObject.InsuranceName"
                            [formControl]="myControl"
                            [matAutocomplete]="auto"
                        />
                        <mat-autocomplete
                            autoActiveFirstOption
                            #auto="matAutocomplete"
                            (optionSelected)="onSelectionChange($event)"
                            [displayWith]="displayFn"
                        >
                            <mat-option
                                *ngFor="let option of filteredOptions | async"
                                [value]="option"
                            >
                                {{ option.insurance_Name }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field
                        appearance="fill"
                        *ngIf="
                            empObject.InsuranceName != undefined &&
                            empObject.InsuranceName != '' &&
                            empObject.InsuranceName.insurance_id == 103
                        "
                    >
                        <mat-label>Insurance Name</mat-label>
                        <input
                            matInput
                            [(ngModel)]="empObject.InsuranceOName"
                            formControlName="InsuranceOName"
                        />
                        <mat-error> Insurance Name is required </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Policy ID</mat-label>
                        <input
                            matInput
                            [(ngModel)]="empObject.PolicyID"
                            formControlName="PolicyID"
                        />
                        <mat-error> Policy Id is required </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Group</mat-label>
                        <input
                            matInput
                            [(ngModel)]="empObject.Group"
                            formControlName="Group"
                        />
                        <mat-error> Group is required </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Subscriber First Name</mat-label>
                        <input
                            matInput
                            [(ngModel)]="empObject.SFirstName"
                            formControlName="SFirstName"
                        />
                        <mat-error>
                            Subscriber First Name is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Subscriber Last Name</mat-label>
                        <input
                            matInput
                            [(ngModel)]="empObject.LFirstName"
                            formControlName="LFirstName"
                        />
                        <mat-error>
                            Subscriber Last Name is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Relationship</mat-label>
                        <mat-select
                            formControlName="Relationship"
                            [(ngModel)]="empObject.Relationship"
                        >
                            <mat-option [value]="'Self'"> Self </mat-option>
                            <mat-option [value]="'Spouse'"> Spouse </mat-option>
                            <mat-option [value]="'Child'"> Child </mat-option>
                            <mat-option [value]="'Other'"> Other </mat-option>
                        </mat-select>
                        <mat-error> Relationship is required </mat-error>
                    </mat-form-field>
                    <div class="fix-btn">
                        <button
                            mat-raised-button
                            color="accent"
                            class="submit-button"
                            aria-label="Sign Up"
                            [disabled]="frmInsuranceInfo.invalid"
                            (click)="showNextView(17, 0)"
                        >
                            NEXT
                        </button>
                    </div>
                </form>
            </form>
        </div>
        <div id="register-form" *ngIf="viewHide == 16">
            <!--Testing Symptons for only Retest -->
            <a
                class="back_button"
                href="javacript::"
                aria-label="Sign Up"
                (click)="showNextView(17, 1)"
            >
                <mat-icon class="back-icon">arrow_back</mat-icon> BACK </a
            ><label class="stepper">2/3 Steps</label>
            <div class="title">
                Are you currently experiencing any of these symptoms?
            </div>
            <form name="symptonsForm" [formGroup]="symptonsForm" novalidate>
                <ul
                    class="list"
                    *ngFor="let sympton of symptons; let i = index"
                >
                    <li>
                        {{ sympton.symptoms_Name }}
                        <mat-checkbox
                            [name]="sympton.symptoms_id"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="valueChange(i, $event)"
                            [id]="sympton.symptoms_id"
                            [(ngModel)]="sympton.checked"
                            class="list-chk"
                        ></mat-checkbox>
                    </li>
                </ul>

                <div class="fix-btn">
                    <button
                        mat-raised-button
                        color="accent"
                        class="submit-button"
                        aria-label="Sign Up"
                        [disabled]="IsSymptonSelected()"
                        (click)="RetestLocation()"
                    >
                        Next
                    </button>
                </div>
            </form>
        </div>
        <div id="register-form" *ngIf="viewHide == 15">
            <!--Testing Center for only center selection -->
            <a
                class="back_button"
                href="javacript::"
                aria-label="Sign Up"
                (click)="showNextView(16, 1)"
            >
                <mat-icon class="back-icon">arrow_back</mat-icon> BACK </a
            ><label class="stepper">3/3 Steps</label>
            <div class="title">Site Address</div>
            <form name="registerForm" [formGroup]="registerForm" novalidate>
                <mat-form-field appearance="fill" style="display: none">
                    <mat-label>Enter address</mat-label>
                    <input
                        matInput
                        id="txtSearchMap"
                        (keydown.enter)="$event.preventDefault()"
                        autocorrect="off"
                        autocapitalize="off"
                        spellcheck="off"
                        type="text"
                        #search
                    />
                </mat-form-field>
                <ol class="site-list mr-t-30">
                    <li
                        *ngFor="let location of filteredLocations"
                        (click)="SelectedCenter(location)"
                        [ngClass]="
                            this.empObject.SelectedLocation != '' &&
                            this.empObject.SelectedLocation != null &&
                            this.empObject.SelectedLocation ==
                                location.location_Id
                                ? 'cardselected'
                                : ''
                        "
                    >
                        {{ location.location_propertyName }}
                        <span
                            >{{ location.location_zipCode }}
                            {{ location.location_city }}</span
                        >
                    </li>
                    <li *ngIf="filteredLocations.length == 0">
                        No nearest centers found between radius 1 mile
                    </li>
                </ol>
                <div class="map">
                    <agm-map [latitude]="lat" [zoom]="10" [longitude]="lng">
                        <agm-marker
                            *ngFor="let post of filteredCourses"
                            [markerDraggable]="false"
                            [iconUrl]="
                                post.icon == undefined || post.icon == ''
                                    ? icon
                                    : post.icon
                            "
                            [latitude]="post.location_latitude"
                            [longitude]="post.location_longitude"
                            (markerClick)="clickedMarker(post, $event)"
                        ></agm-marker>
                    </agm-map>
                </div>

                <div
                    class="fix-btn"
                    *ngIf="
                        this.empObject.PaymentLink == null ||
                        this.empObject.PaymentLink == '' ||
                        this.empObject.Insurance == 1
                    "
                >
                    <button
                        mat-raised-button
                        color="accent"
                        class="submit-button mr-t-30"
                        aria-label="Sign Up"
                        [disabled]="
                            this.empObject.SelectedLocation == '' ||
                            this.empObject.SelectedLocation == undefined ||
                            this.empObject.SelectedLocation == null
                        "
                        (click)="SubmitRetest(0)"
                    >
                        Submit
                    </button>
                </div>
                <div
                    class="fix-btn1"
                    *ngIf="
                        this.empObject.PaymentLink != null &&
                        this.empObject.PaymentLink != '' &&
                        this.empObject.PaymentLink.length > 0 &&
                        this.empObject.Insurance == 0
                    "
                >
                    <button
                        mat-raised-button
                        color="accent"
                        class="submit-button mr-t-30"
                        aria-label="Sign Up"
                        [disabled]="
                            this.empObject.SelectedLocation == '' ||
                            this.empObject.SelectedLocation == undefined ||
                            this.empObject.SelectedLocation == null
                        "
                        (click)="SubmitRetest(1)"
                    >
                        PAY ONLINE
                    </button>
                    <button
                        mat-raised-button
                        color="accent"
                        class="submit-button mr-t-30"
                        aria-label="Sign Up"
                        [disabled]="
                            this.empObject.SelectedLocation == '' ||
                            this.empObject.SelectedLocation == undefined ||
                            this.empObject.SelectedLocation == null
                        "
                        (click)="SubmitRetest(0)"
                    >
                        PAY OFFLINE
                    </button>
                </div>
            </form>
        </div>
        <div id="register-form" *ngIf="viewHide == 4">
            <a
                class="back_button"
                href="javacript::"
                aria-label="Sign Up"
                (click)="showNextView(3, 1)"
            >
                <mat-icon class="back-icon">arrow_back</mat-icon> BACK
            </a>
            <label class="stepper">1/7 Steps</label>
            <div class="title">Patient Information</div>
            <form
                name="frmPatientInfo"
                class="p-b-60"
                [formGroup]="frmPatientInfo"
                novalidate
            >
                <mat-form-field appearance="fill">
                    <mat-label>First Name</mat-label>
                    <input
                        matInput
                        formControlName="Name"
                        required
                        autofocus
                        [(ngModel)]="empObject.Name"
                    />
                    <mat-error> First Name is required </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Last Name</mat-label>
                    <input
                        matInput
                        formControlName="lastName"
                        required
                        [(ngModel)]="empObject.LastName"
                    />
                    <mat-error> Last Name is required </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Date of Birth</mat-label>
                    <input
                        matInput
                        [min]="minDate"
                        required
                        placeholder="MM/DD/YYYY"
                        [max]="maxDate"
                        [matDatepicker]="picker"
                        [(ngModel)]="empObject.DOB"
                        formControlName="DateOfBirth"
                    />
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>

                    <mat-error> Date of Birth is required </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Sex</mat-label>
                    <mat-select
                        formControlName="Gender"
                        required
                        [(ngModel)]="empObject.Gender"
                    >
                        <mat-option [value]="'M'"> Male </mat-option>
                        <mat-option [value]="'F'"> Female </mat-option>
                        <!-- <mat-option [value]="'O'">
                            Others
                        </mat-option> -->
                        <mat-option [value]="'U'"> Undisclosed </mat-option>
                    </mat-select>
                    <mat-error> Gender is required </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Ethnicity</mat-label>
                    <mat-select
                        formControlName="Ethnicity"
                        required
                        [(ngModel)]="empObject.Ethnicity"
                    >
                        <mat-option [value]="'Hispanic/Latino'">
                            Hispanic/Latino
                        </mat-option>
                        <mat-option [value]="'Not Hispanic/Latino'">
                            Not Hispanic / Latino
                        </mat-option>
                        <!-- <mat-option [value]="'Undisclosed'">
                            Undisclosed
                        </mat-option> -->
                    </mat-select>
                    <mat-error> Ethnicity is required </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Race</mat-label>
                    <mat-select
                        formControlName="Race"
                        required
                        [(ngModel)]="empObject.Race"
                    >
                        <mat-option [value]="'White'"> White </mat-option>
                        <mat-option [value]="'Asian'"> Asian </mat-option>
                        <mat-option [value]="'American Indian/Native Alaskan'">
                            American Indian / Native Alaskan
                        </mat-option>
                        <mat-option [value]="'Black/African American'">
                            Black / African American
                        </mat-option>
                        <mat-option
                            [value]="'Native Hawaiian/Pacific Islander '"
                        >
                            Native Hawaiian / Pacific Islander
                        </mat-option>
                        <!-- <mat-option [value]="'Undisclosed'">
                            Undisclosed
                        </mat-option> -->
                    </mat-select>
                    <mat-error> Race is required </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Phone Number</mat-label>
                    <input
                        matInput
                        required
                        formControlName="phoneNumber"
                        [(ngModel)]="empObject.AltMobile"
                    />
                    <mat-error> Contact Number is required </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Email</mat-label>
                    <input
                        type="email"
                        required
                        formControlName="Email"
                        required
                        [(ngModel)]="empObject.EmailAddress"
                        matInput
                    />
                    <mat-error> Please enter a valid email address </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Confirm Email</mat-label>
                    <input
                        type="email"
                        required
                        formControlName="Confirmemail"
                        (ngModelChange)="checkConfirmEmail($event)"
                        [(ngModel)]="empObject.ConfirmEmailAddress"
                        matInput
                    />
                    <mat-error> Please enter a valid email address </mat-error>
                </mat-form-field>
                <div class="fix-btn">
                    <button
                        mat-raised-button
                        color="accent"
                        class="submit-button"
                        aria-label="Sign Up"
                        [disabled]="frmPatientInfo.invalid"
                        (click)="showNextView(4, 0)"
                    >
                        NEXT
                    </button>
                </div>
            </form>
        </div>

        <div id="register-form" *ngIf="viewHide == 5">
            <a
                class="back_button"
                href="javacript::"
                aria-label="Sign Up"
                (click)="showNextView(4, 1)"
            >
                <mat-icon class="back-icon">arrow_back</mat-icon> BACK </a
            ><label class="stepper">2/7 Steps</label>
            <div class="title">Patient Address</div>
            <form
                name="frmPatientAddress"
                [formGroup]="frmPatientAddress"
                novalidate
            >
                <mat-form-field appearance="fill">
                    <mat-label>Country</mat-label>
                    <mat-select
                        formControlName="country"
                        (selectionChange)="changeCountry($event, 2)"
                        [(ngModel)]="empObject.country"
                        required
                    >
                        <mat-option
                            *ngFor="let country of _countries"
                            [value]="country.country_id"
                        >
                            {{ country.country_name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Street</mat-label>
                    <input
                        matInput
                        formControlName="street"
                        required
                        [(ngModel)]="empObject.street"
                        id="txtstreetsearch"
                        (keydown.enter)="$event.preventDefault()"
                        autocorrect="off"
                        autocapitalize="off"
                        spellcheck="off"
                        type="text"
                        #streetaddress
                    />

                    <mat-error> Street is required </mat-error>
                </mat-form-field>
                <!-- <mat-form-field appearance="fill">
                    <mat-label>House no</mat-label>
                    <input matInput [(ngModel)]="empObject.Houseno" formControlName="houseno">
                    <mat-error>
                        House no is required
                    </mat-error>
                </mat-form-field> -->
                <mat-form-field appearance="fill">
                    <mat-label>Zip/Postal Code</mat-label>
                    <input
                        matInput
                        [(ngModel)]="empObject.postalCode"
                        required
                        readonly
                        formControlName="postalCode"
                    />
                    <mat-error> Zip/Postal Code is required </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>City</mat-label>
                    <input
                        matInput
                        [(ngModel)]="empObject.City"
                        required
                        readonly
                        formControlName="city"
                    />
                    <mat-error> City is required </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>State</mat-label>
                    <mat-select
                        formControlName="state"
                        required
                        [(ngModel)]="empObject.state"
                    >
                        <mat-option
                            *ngFor="let state of _states"
                            [value]="state.state_id"
                        >
                            {{ state.state_name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="fix-btn">
                    <button
                        mat-raised-button
                        color="accent"
                        class="submit-button"
                        aria-label="Sign Up"
                        [disabled]="frmPatientAddress.invalid"
                        (click)="showNextView(5, 0)"
                    >
                        NEXT
                    </button>
                </div>
            </form>
        </div>

        <div id="register-form" *ngIf="viewHide == 6">
            <a
                class="back_button"
                href="javacript::"
                aria-label="Sign Up"
                (click)="showNextView(5, 1)"
            >
                <mat-icon class="back-icon">arrow_back</mat-icon> BACK </a
            ><label class="stepper">3/7 Steps</label>
            <div class="title">
                Are you currently experiencing any of these symptoms?
            </div>
            <form
                name="symptonsForm"
                class="p-b-60"
                [formGroup]="symptonsForm"
                novalidate
            >
                <ul
                    class="list"
                    *ngFor="let sympton of symptons; let i = index"
                >
                    <li>
                        {{ sympton.symptoms_Name }}
                        <mat-checkbox
                            [name]="sympton.symptoms_id"
                            [ngModelOptions]="{ standalone: true }"
                            (change)="valueChange(i, $event)"
                            [id]="sympton.symptoms_id"
                            [(ngModel)]="sympton.checked"
                            class="list-chk"
                        ></mat-checkbox>
                    </li>
                </ul>

                <div class="fix-btn">
                    <button
                        mat-raised-button
                        color="accent"
                        class="submit-button"
                        aria-label="Sign Up"
                        [disabled]="IsSymptonSelected()"
                        (click)="showNextView(6, 0)"
                    >
                        NEXT
                    </button>
                </div>
            </form>
        </div>

        <div id="register-form" *ngIf="viewHide == 7">
            <a
                class="back_button"
                href="javacript::"
                aria-label="Sign Up"
                (click)="showNextView(6, 1)"
            >
                <mat-icon class="back-icon">arrow_back</mat-icon> BACK </a
            ><label class="stepper">4/7 Steps</label>
            <div class="title" *ngIf="this.insuranceOption != 3">
                Do you have Insurance?
            </div>
            <div class="title" *ngIf="this.insuranceOption == 3">
                Enter Insurance Information
            </div>
            <form name="registerForm" [formGroup]="registerForm" novalidate>
                <div
                    (click)="selectCard(5)"
                    *ngIf="this.insuranceOption != 3"
                    [ngClass]="
                        this.empObject.Insurance != '' &&
                        this.empObject.Insurance == 1
                            ? 'shadow rounded-2xl col-md-6 card-in cardselected'
                            : 'shadow rounded-2xl col-md-6 card-in'
                    "
                >
                    <div class="text-lg">
                        <mat-icon class="gr">check_circle</mat-icon> Yes
                    </div>
                </div>
                <div
                    (click)="selectCard(6)"
                    *ngIf="this.insuranceOption != 3"
                    [ngClass]="
                        this.empObject.Insurance != '' &&
                        this.empObject.Insurance == 0
                            ? 'shadow rounded-2xl col-md-6 card-in cardselected'
                            : 'shadow rounded-2xl col-md-6 card-in'
                    "
                >
                    <div class="text-lg">
                        <mat-icon class="re">cancel</mat-icon> NO
                    </div>
                </div>
                <span
                    *ngIf="
                        this.insuranceOption == 1 &&
                        this.empObject.Insurance == 0
                    "
                    class="shadow rounded-2xl col-md-12 card-in cardselected"
                >
                    Note: If you select "No" you will have to pay for the test
                </span>
                <form
                    name="frmInsuranceInfo"
                    class="p-b-60"
                    *ngIf="
                        this.empObject.Insurance != '' &&
                        this.empObject.Insurance == 1
                    "
                    [formGroup]="frmInsuranceInfo"
                    novalidate
                >
                    <mat-form-field appearance="fill">
                        <mat-label>Insurance</mat-label>
                        <!-- <mat-select formControlName="InsuranceName" [(ngModel)]="empObject.InsuranceName" required>
                            <mat-option *ngFor="let insurance of insuranceList" [value]="insurance.insurance_id">
                                {{insurance.insurance_Name}}
                            </mat-option>
                        </mat-select> -->
                        <input
                            type="text"
                            placeholder="Pick one"
                            aria-label="Number"
                            matInput
                            [(ngModel)]="empObject.InsuranceName"
                            [formControl]="myControl"
                            [matAutocomplete]="auto"
                        />
                        <mat-autocomplete
                            autoActiveFirstOption
                            #auto="matAutocomplete"
                            (optionSelected)="onSelectionChange($event)"
                            [displayWith]="displayFn"
                        >
                            <mat-option
                                *ngFor="let option of filteredOptions | async"
                                [value]="option"
                            >
                                {{ option.insurance_Name }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field
                        appearance="fill"
                        *ngIf="
                            empObject.InsuranceName != undefined &&
                            empObject.InsuranceName != '' &&
                            empObject.InsuranceName.insurance_id == 103
                        "
                    >
                        <mat-label>Insurance Name</mat-label>
                        <input
                            matInput
                            [(ngModel)]="empObject.InsuranceOName"
                            formControlName="InsuranceOName"
                        />
                        <mat-error> Insurance Name is required </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Policy ID</mat-label>
                        <input
                            matInput
                            [(ngModel)]="empObject.PolicyID"
                            formControlName="PolicyID"
                        />
                        <mat-error> Policy Id is required </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Group</mat-label>
                        <input
                            matInput
                            [(ngModel)]="empObject.Group"
                            formControlName="Group"
                        />
                        <mat-error> Group is required </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Subscriber First Name</mat-label>
                        <input
                            matInput
                            [(ngModel)]="empObject.SFirstName"
                            formControlName="SFirstName"
                        />
                        <mat-error>
                            Subscriber First Name is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Subscriber Last Name</mat-label>
                        <input
                            matInput
                            [(ngModel)]="empObject.LFirstName"
                            formControlName="LFirstName"
                        />
                        <mat-error>
                            Subscriber Last Name is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Relationship</mat-label>
                        <mat-select
                            formControlName="Relationship"
                            [(ngModel)]="empObject.Relationship"
                        >
                            <mat-option [value]="'Self'"> Self </mat-option>
                            <mat-option [value]="'Spouse'"> Spouse </mat-option>
                            <mat-option [value]="'Child'"> Child </mat-option>
                            <mat-option [value]="'Other'"> Other </mat-option>
                        </mat-select>
                        <mat-error> Relationship is required </mat-error>
                    </mat-form-field>
                    <div class="fix-btn">
                        <button
                            mat-raised-button
                            color="accent"
                            class="submit-button"
                            aria-label="Sign Up"
                            [disabled]="frmInsuranceInfo.invalid"
                            (click)="showNextView(7, 0)"
                        >
                            NEXT
                        </button>
                    </div>
                </form>
            </form>
        </div>

        <div id="register-form" *ngIf="viewHide == 8">
            <a
                class="back_button"
                href="javacript::"
                aria-label="Sign Up"
                (click)="showNextView(7, 1)"
            >
                <mat-icon class="back-icon">arrow_back</mat-icon> BACK </a
            ><label class="stepper">5/7 Steps</label>
            <div class="title">Site Address</div>
            <form name="registerForm" [formGroup]="registerForm" novalidate>
                <mat-form-field appearance="fill" style="display: none">
                    <mat-label>Enter address</mat-label>
                    <input
                        matInput
                        id="txtSearchMap"
                        (keydown.enter)="$event.preventDefault()"
                        autocorrect="off"
                        autocapitalize="off"
                        spellcheck="off"
                        type="text"
                        #search
                    />
                </mat-form-field>
                <ol class="site-list mr-t-30">
                    <li
                        *ngFor="let location of filteredLocations"
                        (click)="SelectedCenter(location)"
                        [ngClass]="
                            this.empObject.SelectedLocation != '' &&
                            this.empObject.SelectedLocation != null &&
                            this.empObject.SelectedLocation ==
                                location.location_Id
                                ? 'cardselected'
                                : ''
                        "
                    >
                        {{ location.location_propertyName }}
                        <span
                            >{{ location.location_zipCode }}
                            {{ location.location_city }}</span
                        >
                    </li>
                    <li *ngIf="filteredLocations.length == 0">
                        No nearest centers found between radius 1 mile
                    </li>
                </ol>
                <div class="map">
                    <agm-map [latitude]="lat" [zoom]="10" [longitude]="lng">
                        <agm-marker
                            *ngFor="let post of filteredCourses"
                            [markerDraggable]="false"
                            [iconUrl]="
                                post.icon == undefined || post.icon == ''
                                    ? icon
                                    : post.icon
                            "
                            [latitude]="post.location_latitude"
                            [longitude]="post.location_longitude"
                            (markerClick)="clickedMarker(post, $event)"
                        ></agm-marker>
                    </agm-map>
                </div>

                <div class="fix-btn">
                    <button
                        mat-raised-button
                        color="accent"
                        class="submit-button mr-t-30"
                        aria-label="Sign Up"
                        [disabled]="
                            this.empObject.SelectedLocation == '' ||
                            this.empObject.SelectedLocation == undefined ||
                            this.empObject.SelectedLocation == null
                        "
                        (click)="showNextView(8, 0)"
                    >
                        NEXT
                    </button>
                </div>
            </form>
        </div>

        <div id="register-form" *ngIf="viewHide == 9">
            <a
                class="back_button"
                href="javacript::"
                aria-label="Sign Up"
                (click)="showNextView(8, 1)"
            >
                <mat-icon class="back-icon">arrow_back</mat-icon> BACK </a
            ><label class="stepper">6/7 Steps</label>
            <div class="title">Please choose test type</div>

            <form name="registerForm" class="form-bottom-padding" [formGroup]="registerForm" novalidate>
                <div
                    (click)="SelectedTestCenter(testresult)"
                    [ngClass]="
                        this.empObject.SelectedTest != '' &&
                        this.empObject.SelectedTest != null &&
                        this.empObject.SelectedTest == testresult.test_code
                            ? 'shadow rounded-2xl col-md-12 card-in cardselected'
                            : 'shadow rounded-2xl col-md-12 card-in'
                    "
                    *ngFor="let testresult of TestList"
                >
                    <div class="text-lgr">
                        {{ testresult.test_name }}

                        <span
                            *ngIf="
                                this.insuranceOption == 1 &&
                                this.empObject.Insurance == 0
                            "
                            >${{ testresult.location_amount }}
                        </span>

                        <span
                            >{{
                                testresult.location_desc == null ||
                                testresult.location_desc == ""
                                    ? testresult.test_description
                                    : testresult.location_desc
                            }}
                        </span>
                    </div>
                </div>
                <div class="fix-btn">
                    <button
                        mat-raised-button
                        color="accent"
                        class="submit-button mr-t-30"
                        aria-label="Sign Up"
                        [disabled]="
                            this.empObject.SelectedTest == '' ||
                            this.empObject.SelectedTest == undefined ||
                            this.empObject.SelectedTest == null
                        "
                        (click)="showNextView(9, 0)"
                    >
                        NEXT
                    </button>
                </div>
            </form>
        </div>

        <div id="register-form" *ngIf="viewHide == 10">
            <a
                class="back_button"
                href="javacript::"
                aria-label="Sign Up"
                (click)="showNextView(9, 1)"
            >
                <mat-icon class="back-icon">arrow_back</mat-icon> BACK </a
            ><label class="stepper">7/7 Steps</label>
            <div class="title">Agreement</div>

            <form name="frmUpload" [formGroup]="frmUpload" novalidate>
                <span class="ht-300">
                    In consideration for participating in COVID-19 testing
                    (hereinafter "Testing"). which is provided by
                    <span class="locationNm">{{ locationNm }}</span> (the
                    "Company"), I hereby release, waive, discharge, covenant not
                    to sue, and to hold harmless for any and all purposes
                    Company and their healthcare staff, members, shareholders,
                    officers, servants, agents, volunteers, contractors, or
                    employees (herein referred to as "indemnitees") from any and
                    all liabilities, claims, demands, injuries (including
                    death), or damages, including court costs and attorney fees
                    and expenses, that may be sustained by me while
                    participating in Testing, while traveling to and from the
                    Testing, or while on the premises owned, leased or occupied
                    by indemnitees. <br />
                    <br />

                    Due to the emergency nature of the COVID-19 pandemic, I am
                    fully aware that the Testing provided by Company may involve
                    COVID-19 tests that have not gone through a full FDA
                    approval process and instead obtained emergency use
                    authorization (EUA) or registered and are pending such
                    processing and that the results could produce false
                    positives or false negatives or be administered in a way
                    that otherwise produces inaccurate results, or they are
                    laboratory developed and shown similar performance to EUA
                    authorized reagents. This test has been validated in
                    accordance with the FDA guidance document (policy for
                    diagnostics testing in laboratories certified to perform
                    high complexity testing under EUA prior to emergency use
                    authorization for coronavirus disease-2019 during the public
                    health emergency) issued on February 29th, 2020. I am also
                    fully aware that the Company is not providing medical care
                    or giving a medical diagnosis with Testing and that I should
                    consult my doctor as to the results of Testing.
                    <br />
                    <br />
                    I hereby waive my rights regarding protected health
                    information under HIPAA, to the extent necessary to complete
                    the Testing and to allow Company to provide the results
                    (whether positive or negative) of Testing to (1) the
                    organization which has arranged for the testing, and/or (2)
                    local and state public health authorities (which may result
                    in further direct communication from those entities to me
                    for further followup and contact tracing). Protected heath
                    information will not be reused or disclosed by Company to
                    any person or entity other than the above, except as
                    required by law. I authorize Company to release to
                    <span class="locationNm">{{ locationNm }}</span> the results
                    of my COVID-19 test so that they can determine whether or
                    not I have tested positive for COVID-19. <br />
                    <br />
                    I hereby authorize Company to conduct collection and testing
                    for COVID-19 through a nasopharyngeal swab (a swab inserted
                    into my nose) and/or Serology antibody test (blood draw).
                    <br />
                    <br />
                    I understand that I am not creating a patient/provider
                    relationship with Company or any member of the Company by
                    receiving a COVID-19 test. I understand that Company is not
                    acting as my medical provider and that testing does not
                    replace treatment by my medical provider. I take full
                    responsibility to take appropriate action with regards to my
                    test results when I receive them. I agree I will seek
                    medical advice, care and treatment from my medical provider
                    if I have questions or concerns after I receive the test, or
                    if my condition worsens. I authorize my test results to be
                    disclosed to the county, state, or to any other governmental
                    entity as may be required by law. If applicable, I authorize
                    my test results to be disclosed to a third party. I
                    acknowledge that a positive test result is an indication
                    that I must self-isolate in an effort to avoid infecting
                    others. I understand that there is the potential for false
                    positive or false negative test results. I, the undersigned,
                    have been informed about the COVID-19 test purpose,
                    procedures, possible benefits and risks. I have been given
                    the opportunity to ask questions before I sign, and I have
                    been told that I can ask other questions at any time.
                </span>

                <div class="title mr-t-30">Photo Identification</div>
                <mat-form-field appearance="fill">
                    <mat-label>Document Type</mat-label>
                    <mat-select
                        formControlName="DocumentType"
                        (selectionChange)="checkDocumentType()"
                        [(ngModel)]="empObject.DocumentType"
                    >
                        <mat-option [value]="'Drivinglicence'">
                            Drivers license
                        </mat-option>
                        <mat-option [value]="'Passport'"> Passport </mat-option>
                        <mat-option [value]="'State ID'"> State ID </mat-option>
                        <mat-option [value]="'NO Identification'">
                            NO Identification
                        </mat-option>
                    </mat-select>
                    <mat-error> DocumentType is required </mat-error>
                </mat-form-field>
                <!-- <div class="paymentmsg mr-t-30">Note: You will be redirecting to payment gateway after submit to
                    complete the payment process.</div> -->
                 <div class="sig_pad">
                   <label>Signature</label>
                    <canvas #canvas width="300" height="100"></canvas>
                    <button
                    class="clear_button" 
                    aria-label="Clear"
                    (click)="clear_signature()">
                    Clear </button>
                    <button
                    class="upload_sig_button" 
                    aria-label="Upload"
                    (click)="saveSignPad()">
                    Upload </button>
                    <div class="progress" *ngIf="progress">
                        <div
                            class="progress-bar"
                            [style.width]="progress + '%'"
                        >
                            {{ progress }}%
                        </div>
                    </div>
                </div>
                <div
                    class="file-uploader"
                    *ngIf="this.empObject.DocumentType != 'NO Identification'"
                >
                    <input
                        hidden
                        type="file"
                        formControlName="document"
                        #fileInput
                        accept="image/*"
                        (change)="onFileChanged($event)"
                    />
                    <button
                        mat-raised-button
                        class="add-file-button"
                        (click)="fileInput.click()"
                        aria-label="UPLOAD FILE"
                        
                    >
                        <mat-icon>cloud_upload</mat-icon>
                        UPLOAD FILE <br />
                        (only images allowed upto 12 MB)
                    </button>
                    <span *ngIf="selectedFileName">{{ selectedFileName }}</span>

                    <div class="progress" *ngIf="progress">
                        <div
                            class="progress-bar"
                            [style.width]="progress + '%'"
                        >
                            {{ progress }}%
                        </div>
                    </div>
                </div>
                <div
                    class="fix-btn"
                    *ngIf="
                        this.empObject.PaymentLink == null ||
                        this.empObject.PaymentLink == '' ||
                        this.empObject.Insurance == 1
                    "
                >
                    <button
                        mat-raised-button
                        color="accent"
                        class="submit-button mr-t-30"
                        aria-label="Sign Up"
                        *ngIf="
                            this.empObject.DocumentType != 'NO Identification'
                        "
                        [disabled]="frmUpload!=null && (frmUpload.invalid || isUploadPending)"
                        (click)="employerSubmit(0)"
                    >
                        AGREE & SUBMIT
                    </button>
                    <button
                        mat-raised-button
                        color="accent"
                        class="submit-button mr-t-30"
                        aria-label="Sign Up"
                        *ngIf="
                            this.empObject.DocumentType == 'NO Identification'
                        "
                        (click)="employerSubmit(0)"
                    >
                        AGREE & SUBMIT
                    </button>
                </div>
                <div
                    class="fix-btn1"
                    *ngIf="
                        this.empObject.PaymentLink != null &&
                        this.empObject.PaymentLink != '' &&
                        this.empObject.PaymentLink.length > 0 &&
                        this.empObject.Insurance == 0 &&
                        this.empObject.DocumentType != 'NO Identification'
                    "
                >
                    <button
                        mat-raised-button
                        color="accent"
                        class="submit-button mr-t-30"
                        aria-label="Sign Up"
                        [disabled]="frmUpload.invalid || isUploadPending"
                        (click)="employerSubmit(1)"
                    >
                        PAY ONLINE
                    </button>
                    <button
                        mat-raised-button
                        color="accent"
                        class="submit-button mr-t-30"
                        aria-label="Sign Up"
                        [disabled]="frmUpload.invalid || isUploadPending"
                        (click)="employerSubmit(0)"
                    >
                        PAY OFFLINE
                    </button>
                </div>
                <div
                    class="fix-btn1"
                    *ngIf="
                        this.empObject.PaymentLink != null &&
                        this.empObject.PaymentLink != '' &&
                        this.empObject.PaymentLink.length > 0 &&
                        this.empObject.Insurance == 0 &&
                        this.empObject.DocumentType == 'NO Identification'
                    "
                >
                    <button
                        mat-raised-button
                        color="accent"
                        class="submit-button mr-t-30"
                        aria-label="Sign Up"
                        (click)="employerSubmit(1)"
                    >
                        PAY ONLINE
                    </button>
                    <button
                        mat-raised-button
                        color="accent"
                        class="submit-button mr-t-30"
                        aria-label="Sign Up"
                        (click)="employerSubmit(0)"
                    >
                        PAY OFFLINE
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<ng-template #location_confirmation>
    <form
        fxLayout="column"
        fxFlex="1 0 auto"
        name="TransferForm"
        [formGroup]="TransferForm"
        style="align-items: center; width: 100%"
    >
        <h1 class="center">Are you sure you want to change location?</h1>

        <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxFlex="1 0 auto"
            class="mar-t-30"
        >
            <button
                mat-raised-button
                class="warn mr-4"
                (click)="closepdfwindow()"
                mat-dialog-close
            >
                <mat-icon>close</mat-icon> Close
            </button>
            <button
                mat-raised-button
                (click)="changeQrLocation()"
                class="primary mr-4"
                mat-dialog-close
            >
                <mat-icon>send</mat-icon> Change Location
            </button>
        </div>
    </form>
</ng-template>
