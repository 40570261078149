<div id="academy-courses" class="page-layout simple">
    <div class="center">
        <div class="header accent p-24" fxLayout="row" fxLayoutAlign="space-between start">

            <div class="breadcrumb text-truncate h1" fxLayout="row" fxLayoutAlign="start center"
                [@animate]="{value:'*',params:{x:'50px'}}">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <span>Vendor Workflow</span>
                </div>

            </div>

        </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="center">
        <div class="content p-24">

            <form fxLayout="column" fxFlex="1 0 auto" name="vendorWorkflow" [formGroup]="vendorWorkflow"> 
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
    
                    <mat-form-field appearance="fill" fxFlex="50" class="mr-4">
                        <mat-label>Duration</mat-label>
                        <input matInput formControlName="duration" required [(ngModel)]="empObject.selftestpref_duration">
                        <mat-error>
                            Duration is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" fxFlex="50" >
                        <mat-label>Email Address</mat-label>
                        <input matInput formControlName="emailAddress" type="email"
                            required [(ngModel)]="empObject.selftestpref_email">
                        <mat-error>
                            Email is required
                        </mat-error>
                        <mat-error>
                            Please enter a valid email address
                        </mat-error>
                    </mat-form-field>
                </div>
                
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field class="mr-4" appearance="fill" fxFlex="100">
                        <mat-label>Message</mat-label>
                        <textarea matInput placeholder="" formControlName="message" required [(ngModel)]="empObject.selftestpref_msg"></textarea>
                        <mat-error>
                            Message is required
                        </mat-error>
                    </mat-form-field>
    
                </div>
    
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                    
                    <button mat-raised-button (click)="vendorwfSubmit()"
                        class="primary mr-4">
                        <mat-icon>save</mat-icon>Submit
                    </button>
                    <button mat-raised-button
                    class="accent mr-4" (click)="addItem()">
                    <mat-icon>add</mat-icon>Add Field
                </button>
                </div>
            </form>


        </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="center">
        <div id="about" fxLayout="row wrap">
            <div class="about-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="100">
                <div class="profile-box info-box general" fxLayout="column">
                    <div class="content p-24">
                        <div class="table-responsive">
                            
                                <table width="100%" class="simple invoice-table">
                                    <thead>
                                        <tr class="accent mat-header-row bdr-radius">
                                            <th>S No.</th>
                                           <th>Duration</th>
                                            <th>Email</th> 
                                            <th>Message</th> 
                                    </thead>
                                    <tbody>
                                        <tr class="mat-header-row" *ngFor="let item of items;let i=index">
                                            <td>{{i+1}} </td> 
                                            <td>
                                                <mat-form-field appearance="fill">
                                                    <mat-label>Duration</mat-label>
                                                    <input matInput formControlName="duration" [(ngModel)]="item.selftestpref_duration">
                                                     
                                                </mat-form-field> 
                                            </td>
                                            <td> <mat-form-field appearance="fill">
                                                <mat-label>Email</mat-label>
                                                <input matInput formControlName="Email" [(ngModel)]="item.selftestpref_email">
                                                
                                            </mat-form-field></td> 
                                            <td> <mat-form-field appearance="fill">
                                                <mat-label>Message</mat-label>
                                                <textarea matInput formControlName="Message" [(ngModel)]="item.selftestpref_msg"></textarea>
                                                
                                            </mat-form-field></td> 
                                            
                                        </tr>
                                        
                                    </tbody>
                                </table> 
                        </div>
                    </div>

                </div>
            </div>
        </div>


    </div>
</div>