import { Component, ElementRef, ViewChild, OnInit,NgModule,AfterViewInit } from '@angular/core'; 
import { CommonService } from 'app/common/Service/common.service';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog'; 
import { SnackbarService } from 'app/common/Service/snackbar.service';  
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service'; 
import { ActivatedRoute, Router, Routes } from '@angular/router'; 
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, AfterViewInit {
  usersdata: any = [];
  rolesdata: any = [];
  categories: any = []; currentCategory: string;
  storeData: any;
  usersdataList:any=[];
  locationList:any=[];
  index:number=0;
  searchTerm:string="";
  usercode:string="";
  btnText:string="Save";  
  pwvis: string = "";
  flgpw:number = 0;
  currentUserType:string="";
  pwlist:any=[];
  specimenDetails: FormGroup; 
  empObject: any = {};
  @ViewChild('add_User') add_User: ElementRef;
  @ViewChild('alertpop') alertpop: ElementRef;
  displayedColumns: string[] = ['SNo', 'UserCode','Password','UserType', 'Name','EmailID','Mobile','Status','Edit','Action'];
  dataSource= new MatTableDataSource(this.usersdata);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // MatPaginator Output 
  constructor(
     
    private service: CommonService , 
    private dialog: MatDialog,
    private _formBuilder: FormBuilder, 
    private _snackbarservice: SnackbarService,
    private _splashScreenService: FuseSplashScreenService,
    private _fuseNavigationService: FuseNavigationService,
    private router: Router, 
  ) {  
    this.currentCategory = 'all';
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  showpw(userid){
    let userfilter = this.pwlist.filter((course) => {
      return course.usercode==userid;

    });
    if(userfilter!=null && userfilter.length>0){ 
      this.pwvis='';
      this.pwlist=[]; 
    }
    else{
    this.pwlist.push({"usercode":userid});
    this.pwvis=userid; 
  } 
  }
  ngOnInit(): void {  
    let isaccess=this._fuseNavigationService.checkAccess("UserList")
    if(!isaccess){
      this._snackbarservice.showSnackbar("Unauthorised Access to the page");
      this.router.navigateByUrl('/auth/login'); 
    }
      //link : ['', Validators.required], 
      this.specimenDetails = this._formBuilder.group({
        name: ['', Validators.required],Role: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        Mnumber: ['null', [Validators.required, Validators.pattern("[0-9 ]{10}")]], 
 
  });
  
  this.empObject.firstlogin=false;
  this.empObject.passwordExpiry=false;
  var sessionobj = JSON.parse(localStorage.UserDetails); 
  this.getMasterData(64, 0,"");//EmployeerData
  this.getMasterData(10, 0,"");//EmployeerData
  this.getMasterData(35, 0,"");//RoleList
  }
  addUser() {
    this.empObject.Name = "";
    this.empObject.EmailAddress = "";
    this.empObject.MobileNo ="";
    this.empObject.role_id=0;
    this.usercode="";
    this.btnText="Save";
    this.openmodal(this.add_User);
  }
  openmodal(modal) {
    this.dialog.open(modal, { width: "700px", height: "auto", disableClose: true });
  }
  openmodal1(modal) {
    this.dialog.open(modal, { width: "400px", height: "auto", disableClose: true });
  }
  filterCoursesByCategory(_filterid): void {
    // Filter 
    this._splashScreenService.show();
    if (_filterid.value == "all"){
      this.getMasterData(64, 0, '');//Users 
      this.dataSource = new MatTableDataSource(this.usersdata);
      this.dataSource.paginator = this.paginator;
      this._splashScreenService.hide();
    }  
    else{
      this.getMasterData(56, _filterid.value, '');//Users 
    this.dataSource = new MatTableDataSource(this.usersdata);
    this.dataSource.paginator = this.paginator;
    this._splashScreenService.hide();
  }
}
filterUsersByType(_filterid): void {
  // Filter  
  this._splashScreenService.show();
  if (_filterid.value == "all"){
    this.getMasterData(64, 0, '');//Users 
    this.dataSource = new MatTableDataSource(this.usersdata);
    this.dataSource.paginator = this.paginator;
    this._splashScreenService.hide();
  }  
  else{
    this.getMasterData(72, 0, _filterid.value);//Users 
  this.dataSource = new MatTableDataSource(this.usersdata);
  this.dataSource.paginator = this.paginator;
  this._splashScreenService.hide();
}
}

  searchlist(): void {
    const searchTerm = this.searchTerm.toLowerCase();  
    if (searchTerm === '') {
      this.dataSource = new MatTableDataSource(this.usersdataList);
      this.dataSource.paginator = this.paginator;
    }
    else {
      this.usersdata = this.usersdataList.filter((course) => {
        return course.users_Fullname.toLowerCase().includes(searchTerm);

      });
      this.dataSource = new MatTableDataSource(this.usersdata);
      this.dataSource.paginator = this.paginator;
    }
  }
   
  closewindow(){
    this.dialog.closeAll();
    this.getMasterData(64, 0,"");//EmployeerData
  }
  edit_user(accessCode:string){
    this.openmodal(this.add_User);
    this.usercode=accessCode;
    this.btnText="Update";
    this.getMasterData(20, 0,accessCode);//EmployeerData
  }
  del_user(accessCode:string){ 
    this.openmodal1(this.alertpop);
    this.usercode=accessCode;   
  }
  act_user(accessCode:string){  
    this.usercode=accessCode;  
    this.getMasterData(22, 0,this.usercode);//EmployeerData
  }
  confirm_del(){ 
    this.getMasterData(21, 0,this.usercode);//EmployeerData
  }
  AllUserData:any=[];
  getMasterData(flg, filterId, code) {
       
    var clsmodel = {
        Type: flg,
        FilterId: filterId,
        Code:code
    }
    this.service.GetMasterData(clsmodel).subscribe(result => {

        var resultObj = result;
        if (resultObj["status"] == 200) {
             if (flg == 64 || flg==56 || flg==72) {   
                this.usersdataList = resultObj["resultObj"];  
                var flList=[];
                let Sno=1;
                this.usersdataList.forEach(element => {
                  element.Sno=Sno;
                  flList.push(element);
                  Sno++;
                });
                this.usersdata=flList;
                this.usersdataList=flList;
                this.dataSource = new MatTableDataSource(this.usersdata);
                this.dataSource.paginator = this.paginator;
            }
            else   if (flg == 35) {   
              this.rolesdata = resultObj["resultObj"];  
          } 
           else   if (flg == 10) {   
              this.locationList = resultObj["resultObj"];  
          }
            else if(flg==20){ 
              var userobj=resultObj["resultObj"];
              this.empObject.Name = userobj[0].users_Fullname;
              this.empObject.EmailAddress = userobj[0].users_Emailaddress;
              this.empObject.MobileNo = userobj[0].users_Mobile; 
              this.empObject.role_id=userobj[0].users_Roleid; 
            }
            else if(flg==21){
              this._snackbarservice.showSnackbar(resultObj["message"]); 
              this.getMasterData(64, 0,"");//EmployeerData
            }
            else if(flg==22){
              this._snackbarservice.showSnackbar(resultObj["message"]); 
              this.getMasterData(64, 0,"");//EmployeerData
            }
             
        }

    }, error => {
        console.log(error);
    });
    
}
 
 
specimenSubmit() {
  if (this.specimenDetails.invalid) {
      return;
  }
  this._splashScreenService.show();
this.empObject.EmployeeCode=this.usercode;
this.empObject.DepartmentId=this.empObject.role_id;
  this.service.SpecimenData(this.empObject).subscribe(result => {

      var resultObj = result;

      if (resultObj["status"] == 200) {
          this.specimenDetails.reset();
          this._snackbarservice.showSnackbar(resultObj["message"]);  
          this.getMasterData(64, 0,"");//EmployeerData
      }
      else
          this._snackbarservice.showSnackbar(resultObj["message"]);
      this._splashScreenService.hide();
  }, error => {
      console.log(error);
      this._splashScreenService.hide();
  });

}
validateNumber(event) { 
  const keyCode = event.keyCode;

  const excludedKeys = [8, 37, 39, 46];
  if (keyCode == 9 || keyCode == 13)
      return;
  if (!((keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) ||
      (excludedKeys.includes(keyCode)))) {
      event.preventDefault();
  }
}
}
const routes: Routes = [
  {
      path     : 'user-list',
      component: UserListComponent
       
  }
];